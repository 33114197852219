.new-login-container {
	display: flex;
	flex-direction: column;
	.login-screen {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #5439b2;
		height: 100vh;
		.atlas-logo {
			padding: 10px 0 20px 0;
		}
		.content {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			flex-wrap: nowrap;
			gap: 30px;
			padding: 0 30px;
			box-sizing: border-box;
		}
		.at-modal {
			.at-modal--backdrop.backlayer-show {
				opacity: 0.5 !important;
			}
			.modal-container {
				max-width: 375px;
				height: auto;
				max-height: none;
				transform: none;
				top: auto;
				left: auto;
				bottom: 50px;
				right: 50px;
			}
			.modal-header {
				padding: 0;
				.header-left {
					position: relative;
					background-color: #f2f5ff;
					height: 96px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					border-radius: 4px 4px 0 0;
					.icon {
						display: flex;
						align-items: center;
						position: absolute;
						left: 10px;
					}
					.header-text {
						color: $color-black-80;
						font-size: $medium-font-size;
						@extend .font--semibold;
					}
				}
			}
			.modal-content {
				max-height: 700px;
				.form-content {
					padding: 30px;
					.description {
						color: $color-black-60;
						font-size: $very-small-font-size;
						margin-bottom: 15px;
						line-height: 1.4;
					}
					.timer {
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 15px;
						margin-bottom: 25px;
						.units-container {
							width: 50px;
							text-align: center;
							.unit {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 40px;
								height: 40px;
								color: $color-blue;
								background-color: rgba($color: $color-blue, $alpha: 0.1);
								font-size: $medium-font-size;
								margin: 0 auto 7px auto;
								@extend .font--semibold;
							}
							.type {
								color: $color-black-60;
								font-size: $extra-small-font-size;
								text-transform: uppercase;
								@extend .font--semibold;
							}
						}
						.colon {
							position: relative;
							bottom: 12px;
							color: rgba($color: $color-blue, $alpha: 0.2);
							font-size: $extra-large-font-size;
						}
					}
					.alert {
						background-color: $color-yellow-10;
						padding: 10px;
						border-radius: $default-radius;
						margin-bottom: 25px;
						.time-left {
							display: flex;
							align-items: center;
							color: #a8870f;
							margin-bottom: 5px;
							.icon {
								margin-right: 7px;
								svg {
									position: relative;
									// top: 1px;
									width: 16px;
								}
							}
						}
						.description {
							margin-bottom: 0;
							span {
								@extend .font--semibold;
							}
						}
					}
					.at-accordion-container {
						border: 1px solid $border-color-dark;
						border-radius: $border-radius-default;
						.header {
							padding: 7px 10px;
							.title {
								color: $color-black-60;
								font-size: $very-small-font-size;
							}
						}
						.contents {
							padding: 10px;
							border-top: 1px solid $border-color-dark;
							.description {
								margin-bottom: 0;
								span {
									@extend .font--semibold;
								}
							}
						}
					}
					.points {
						display: flex;
						&:first-child {
							margin: 20px 0 12px 0;
						}
						&:last-child {
							margin: 12px 0 20px 0;
						}
						.point {
							display: flex;
							align-items: center;
							justify-content: center;
							flex-shrink: 0;
							width: 32px;
							height: 32px;
							font-size: $medium-font-size;
							box-sizing: border-box;
							border-radius: 50%;
							color: $color-blue-dark-2;
							background-color: rgba($color: $color-blue-dark-2, $alpha: 0.2);
							margin-right: 10px;
							@extend .font--semibold;
						}
						.text {
							color: $color-black-80;
							font-size: $very-small-font-size;
							line-height: 1.4;
						}
					}
					.at-btn {
						margin-top: 10px;
						width: 100%;
					}
					.action {
						margin-top: 15px;
						width: 100%;
						text-align: center;
						font-size: $very-small-font-size;
						color: $color-blue;
						cursor: pointer;
						@extend .font--semibold;
					}
				}
			}
		}
		.carousel-container {
			.btn-icon,
			.carousel-item-container {
				min-height: 405px;
			}
			.indicator {
				opacity: 0.5;
				&.active {
					opacity: 1;
					background-color: $mc-1;
				}
			}
		}
		.no-data-placeholder {
			position: relative;
			// bottom: 14px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background: #f8f8f8;
			width: 100%;
			height: 571px;
			border-radius: $default-radius;
			margin-bottom: 28px;
			img {
				width: 100px;
				margin-bottom: 5px;
				opacity: 0.2;
			}
			@include bp(768px) {
				height: 416px;
			}
			.message--large {
				color: #aaaaaa;
				font-size: $medium-font-size;
				width: 70%;
				text-align: center;
				margin-bottom: 30px;
			}
			.message--small {
				color: #aaaaaa;
				.link-text {
					color: #aaaaaa;
					text-decoration: underline;
					text-transform: lowercase;
					cursor: pointer;
					@extend .font--regular;
				}
			}
		}
		.blogs {
			max-width: 600px;
			width: 100%;
			height: fit-content;
			border-radius: $default-radius;
			overflow: hidden;
			.blog-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				position: relative;
				overflow: hidden;
				box-sizing: border-box;
				width: 100%;
				border-radius: $default-radius;
				background: $mc-2;
				&.placeholder {
					position: relative;
					height: 571px;
					margin-bottom: 28px;
					.img {
						flex-shrink: 0;
					}
					.info {
						display: flex;
						flex-direction: column;
						width: 100%;
						height: 100%;
						padding: 25px;
					}
					.desc {
						margin-top: 10px;
					}
					.btn {
						margin-top: auto;
					}
				}
				.feature-image {
					width: 100%;
					height: 405px;
					object-fit: contain;
					z-index: 10;
				}
				.background-image {
					width: 100%;
					height: 400px;
					object-fit: cover;
					position: absolute;
					filter: blur(12px);
					transform: scale(1.1);
				}
				.info {
					display: flex;
					flex-direction: column;
					width: 100%;
					min-height: 166px;
					height: 100%;
					padding: 25px;
					background-color: $mc-2;
					z-index: 10;
					box-sizing: border-box;
				}
				.text {
					width: auto;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: pre-wrap;
					&.description {
						-webkit-line-clamp: 2;
					}
				}
				.link {
					font-size: $medium-font-size;
					color: $link-color;
					a {
						color: $link-color;
						text-decoration: none;
					}
				}
				.description {
					font-size: $very-small-font-size;
					margin-top: 10px;
					color: $mc-5;
				}
				.read-more {
					margin-top: auto;
					.read-more-btn {
						padding-left: 15px;
						padding-right: 15px;
						height: 28px;
						line-height: 26px;
						background: $mc-2;
						color: $btn-color;
						font-size: $very-small-font-size;
					}
				}
			}
		}
		.login-form {
			position: relative;
			top: -14px;
			height: 571px;
			width: 400px;
			padding: 20px 30px;
			box-sizing: border-box;
			overflow-y: auto;
			background-color: #f8f8f8;
			border-radius: $default-radius;
			flex-shrink: 0;
			.up-logo {
				margin: 30px 0 50px 0;
				img {
					width: 200px;
				}
			}
			.form-header {
				font-size: $medium-large-font-size;
				font-weight: 600;
				margin-bottom: 25px;
			}
			.form-container {
				width: 100%;
				.at-input-container {
					width: 100%;
					margin-bottom: 30px;
					.at-input {
						height: 40px;
						font-size: $small-font-size;
					}
					.at-input-field {
						svg {
							position: relative;
							// top: 4px;
						}
						.loading-container {
							height: 40px;
						}
					}
				}
				.g-recaptcha {
					width: 100%;
					& > div {
						margin: 0 auto;
					}
				}
				.required-label {
					width: 100%;
					margin-top: 3px;
					div {
						width: 304px;
						margin: 0 auto;
						font-size: 12px;
						color: $color-red;
					}
				}
				.at-btn {
					width: 100%;
					margin-top: 25px;
					height: 40px;
					line-height: 38px;
					font-size: $small-font-size;
					.loading-container {
						width: 20px;
						height: 39px;
						.at-button-loading {
							width: 18px;
							height: 18px;
						}
					}
				}
				.btn-actions {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.at-btn {
						margin-top: 0;
					}
					.at-btn--secondary {
						margin-left: 10px;
					}
				}
				.password-container {
					.at-input-container {
						margin-bottom: 10px;
					}
					.forgot-pass {
						width: fit-content;
						margin-left: auto;
						margin-bottom: 20px;
						font-size: $very-small-font-size;
						color: $color-blue;
						cursor: pointer;
					}
				}
				.back-to-login {
					text-align: center;
					margin-top: 15px;
					font-size: $very-small-font-size;
					color: $color-blue;
					cursor: pointer;
				}
				.reset-success {
					display: flex;
					align-items: center;
					.icon {
						width: 26px;
						margin-right: 10px;
					}
					.msg {
						font-size: $small-font-size;
					}
				}
			}
			.login--warning {
				margin-top: 20px;
				color: #595334;
				padding: 15px 20px;
				background-color: #f6e58d;
				font-size: $very-small-font-size;
				line-height: 1.3;
			}
			.divider {
				display: flex;
				width: 100%;
				text-align: center;
				margin: 15px 0;
				white-space: nowrap;
				box-sizing: border-box;
				span {
					font-size: $very-small-font-size;
					color: $mc-7;
					padding: 0 10px;
				}
				&::before {
					content: "";
					position: relative;
					top: 50%;
					width: 50%;
					border-top: 1px solid $border-color-dark;
					border-bottom: 0;
					transform: translateY(50%);
				}
				&::after {
					content: "";
					position: relative;
					top: 50%;
					width: 50%;
					border-top: 1px solid $border-color-dark;
					border-bottom: 0;
					transform: translateY(50%);
				}
			}
			.try-new-login {
				width: 100%;
				height: 40px;
				line-height: 38px;
				font-size: $small-font-size;
			}
			.get-help {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 7px;
				font-size: $very-small-font-size;
				color: $color-dark-grey;
				margin-top: 25px;
				a {
					text-decoration: none;
				}
			}
		}
	}
	@include bp(768px) {
		.login-screen {
			height: auto;
			.atlas-logo {
				padding: 30px 0 0 0;
			}
			.content {
				height: auto;
				overflow-y: auto;
				flex-direction: column-reverse;
				align-items: center;
				max-width: 600px;
				padding: 0 15px;
			}
			.at-modal {
				.modal-container {
					width: 100%;
					max-width: none;
					transform: none;
					top: auto;
					left: 0;
					bottom: 0;
					right: 0;
					border-radius: 0;
				}
			}
			.login-form {
				max-width: 600px;
				width: 100% !important;
				padding: 20px !important;
				margin-top: 50px;
			}
			.blogs {
				margin-bottom: 30px;
				.feature-image {
					height: 250px !important;
				}
			}
			.carousel-container {
				.carousel-item-container {
					min-height: 250px !important;
				}
				.btn-icon {
					min-height: 250px !important;
					img {
						width: 15px;
					}
				}
			}
		}
	}
	.login-brands-selection {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100vh;
		background: $mc-3;
		.top-header {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 25px;
			border-bottom: 1px solid $border-color;
			background: #f8f8f8;
			box-sizing: border-box;
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
			img {
				height: 25px;
			}
		}
		.card-container {
			display: flex;
			flex-direction: column;
			max-width: 500px;
			width: 95%;
			max-height: 75vh;
			height: 100%;
			margin: 50px 0;
			border-radius: $default-radius;
			box-sizing: border-box;
			background: #f8f8f8;
			box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
			.card-header {
				padding: 30px 30px 15px 30px;
				font-size: $medium-large-font-size;
				font-weight: 600;
			}
			.text {
				padding: 0 30px;
				font-size: 12px;
				color: $mc-5;
			}
			.brands-list {
				padding: 0 5px;
				margin: 15px 0 5px 0;
				overflow-y: auto;
				.brand-container {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 20px 25px;
					border-bottom: 1px solid $border-color-dark;
					transition: 180ms;
					cursor: pointer;
					&:last-child {
						border-bottom: none;
					}
					&:hover {
						background: $mc-3;
						.arrow {
							transform: translateX(5px);
							opacity: 1;
						}
					}
					.brand-info {
						display: flex;
						align-items: center;
					}
					.brand-initials {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 45px;
						height: 45px;
						font-size: $medium-large-font-size;
						text-transform: uppercase;
						border-radius: $default-radius;
						margin-right: 10px;
						@extend .font--semibold;
						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}
						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}
						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}
						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}
						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
						img {
							width: 45px;
							height: 45px;
							object-fit: cover;
							border-radius: $default-radius;
							margin: 0;
						}
					}
					.brand-name {
						text-transform: capitalize;
						@extend .font--semibold;
					}
					.arrow {
						transform: translateX(-2px);
						transition: transform 180ms;
						opacity: 0.6;
						img {
							width: 20px;
							rotate: 180deg;
						}
					}
				}
			}
		}
	}
}
