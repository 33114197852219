.campaign-status-page {
	.basic-info {
		font-size: 24px;
		color: #000;
		position: relative;
		padding-left: 30px;
		margin-bottom: 40px;
		@include bp(768px) {
			margin-bottom: 20px;
		}
		img {
			height: 20px;
			left: 0px;
			position: absolute;
			top: 4px;
		}
	}
	background-color: #fff;
	border: solid 1px rgba(0, 0, 0, 0.05);
	margin-bottom: 15px;

	.view-info-section {
		font-size: 12px;
		margin-left: 10px;
		text-align: center;
		color: $color-blue;
		cursor: pointer;
	}
	.campaign-detail-container {
		padding: 25px;
		box-sizing: border-box;
		@include bp(768px) {
			padding: 10px;
		}
		.title {
			margin-bottom: 20px;
		}
		font-size: 14px;
		color: $mc-5;
		.meta-detail {
			& > div {
				margin-bottom: 25px;
				& > div {
					margin-bottom: 5px;
					&:last-child {
						color: #000;
					}
				}
			}
		}
	}

	.header {
		.title {
			font-size: $large-font-size;
			margin-bottom: 10px;
			@extend .font--semibold;
		}
		.description {
			font-size: $small-font-size;
		}
	}

	.section-1,
	.section-2,
	.section-3,
	.section-4 {
		padding: 25px;
		@include bp(768px) {
			padding: 10px;
		}
	}

	.section-5 {
		margin-bottom: 15px;
	}

	.status-progress-bar-container {
		position: relative;
		cursor: pointer;
		.status-progress-bar-container-rail {
			width: 100%;
			height: 15px;
			border: solid 1px rgba(0, 0, 0, 0.05);
			border-radius: 25px;
			overflow: hidden;
			position: absolute;
		}

		.label {
			display: inline-block;
			position: absolute;
			top: -40px;
			transition: 0.5s ease-out;
			will-change: left;
			transform: translateX(-50%);
			.label-content {
				box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.5);
				position: relative;
				border-radius: 3px;
				visibility: hidden;
			}
			.text {
				border-radius: 3px;
				position: relative;
				text-align: center;

				background: #fff;
				overflow: hidden;

				box-sizing: border-box;
				padding: 5px 20px;
				// width: 100%;
				// height: 100%;
				background: #fff;
				display: table;
				z-index: 2;
			}
			.arrow-container {
				z-index: 1;
				position: absolute;
				width: 15px;
				height: 15px;

				right: 50%;
				transform: translateX(50%);
				bottom: -5px;

				&:after {
					border-radius: 4px;
					background: #fff;
					box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.5);
					content: "";
					width: 100%;
					height: 100%;
					display: inline-block;
					transform: rotate(45deg);
				}
			}
		}

		&:hover {
			.label .label-content {
				visibility: visible;
			}
		}

		.status-progress-bar {
			position: absolute;
			left: 0;
			height: 15px;
			background: linear-gradient(to left, $color-blue, rgba($color: $color-blue, $alpha: 0.5), #f2f2f2);
			transition: 0.5s ease-out;
			will-change: transform;
			transform-origin: left;
			width: 100%;

			&:after {
				content: "";
				width: 29px;
				height: 29px;
				background: $color-blue;
				display: inline-block;
				right: -15px;
				bottom: 0;
				position: absolute;
				transform: rotate(45deg);
			}
		}
	}

	.report-table {
		.header-row {
			border-top: 1px solid $divider-color;
			border-bottom: 1px solid $divider-color;
		}
		.at-table-cell {
			&.name {
				padding-left: 25px;
				@include bp(768px) {
					padding-left: 10px;
				}
			}
			&.conv-count {
				text-align: right;
				padding-right: 25px;
			}
			&.conv-revenue {
				text-align: right;
				@include bp(768px) {
					padding-right: 10px;
				}
			}
		}
		a.at-table-cell.name {
			color: $color-blue;
			text-decoration: none;
		}
	}
}

.campaign-status-box-container {
	display: flex;
	@include bp(768px) {
		flex-direction: column;
	}
}
.campaign-highlight-box {
	flex: 1;
	margin-right: 20px;
	&:last-child() {
		margin-right: 0px;
	}
	padding: 10px;
	box-sizing: border-box;
	border-radius: 2px;
	border-radius: 2px;
	background-color: $mc-2;
	color: $mc-7;
	text-align: center;
	@include bp(768px) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 10px;
	}
	.title-container {
		text-align: left;
	}
	.title {
		text-align: left;
		font-size: 12px;
		margin-bottom: 10px;
		margin-right: 10px;
		@extend .font--semibold;
		display: inline-block;
		color: white;
		background-color: rgba(0, 0, 0, 0.2);
		padding: 2px 5px;
		border-radius: 2px;
		text-transform: uppercase;
	}
	.count {
		font-size: 20px;
		margin-top: 5px;
	}
	.unique-count {
		font-size: 12px;
		color: $mc-5;
	}
}

.campaign-report-placeholder {
	height: 200px;
	background: #f2f2f2;
	margin: 10px;
	font-size: 26px;
	text-align: center;

	& > div {
		padding-top: 100px - 13px;
	}
}

.campaign-status {
	&.completed {
		color: $color-green;
	}
	&.cancelled {
		color: $color-red;
	}
	&.scheduled,
	&.recurring {
		color: $color-blue;
	}
	&.running {
		color: #e67e22;
	}
	&.inactive {
		color: $mc-5;
	}
}
