@keyframes placeHolderShimmer {
	0% {
		transform: translateX(-100%);
		opacity: 0;
	}

	50% {
		transform: translateX(100%);
		opacity: 1;
	}

	100% {
		transform: translateX(-100%);
		opacity: 0;
	}
}

.items-container {
	.item-logs-banner {
		padding: 0 24px;
		.text-h4 {
			margin-top: 0px;
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
		}
	}
	.filters-second-section {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 15px 25px;

		.section-1 {
			display: flex;
			gap: 12px;

			.dropdown-custom {
				width: 230px;

				.container {
					height: 32px;
					padding: 1px 10px;
				}

				.dropdown-options {
					overflow-x: hidden;

					.option {
						height: 37px;
						padding: 1px 10px;
					}
				}

				.custom-option,
				.custom-label {
					display: flex;
					align-items: center;

					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 8px;
						width: 22px;
						height: 22px;
						text-transform: uppercase;
						font-size: $very-small-font-size;
						border-radius: $border-radius-default;
						@extend .font--semibold;

						img {
							width: 22px;
							height: 22px;
							object-fit: cover;
							border-radius: $border-radius-default;
						}

						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}

						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}

						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}

						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}

						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
					}
				}
			}

			.at--dropdown {
				width: 150px;
			}
			.at-btn--filter {
				box-sizing: border-box;
			}

			.filter-in-header {
				width: 90px;
				height: 34px;
				border: 1px solid #d0dadd;
				cursor: pointer;
				border-radius: $border-radius-default;

				.container {
					display: flex;
					gap: 10px;
					padding: 9px 6px;
				}
			}
		}

		.section-2 {
			display: flex;

			.search-filter {
				align-items: normal;

				.cancel-search {
					top: 10px;
				}
			}
		}
	}

	.filter-buttons {
		@include bp(768px) {
			display: flex;
			flex-direction: row-reverse;
			float: left;
			position: relative;
		}

		.container {
			border-radius: 2px;
		}
	}

	.bulk-actions-container {
		margin-right: 0;

		.at-btn--secondary {
			img {
				width: 18px;
				vertical-align: text-top;
			}
		}
	}

	.action-buttons-container {
		margin-right: 10px;
	}

	.credits-section-header.transactions-list-header {
		.bulk-actions-container {
			margin-right: 0;

			.at-btn--secondary {
				svg {
					// top: 1px;
				}

				img {
					width: 18px;
					vertical-align: text-top;
				}

				&:hover {
					svg {
						path {
							fill: $color-blue;
						}
					}
				}
			}
		}

		.search-input-container {
			display: flex;

			.search-filter {
				width: 170px;

				.at-input-field {
					.at-label {
						border-radius: 0;
						border-left: 0;

						img {
							padding-left: 0;
						}
					}

					.at-input {
						border-left: 0;
						border-radius: 0 4px 4px 0;
					}
				}

				.cancel-search {
					right: 24px;
				}
			}

			.multi-select-filter-container {
				margin: 0;
				width: 100px;

				.Select-control {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
	}

	.box-wrapper {
		padding: 0 24px;
	}
	.form-sidebar-container {
		.form-table {
			padding: 24px;

			.name {
				width: 30%;
				font-size: 14px;
				color: #363636;
				font-weight: 600;
			}
			.issue {
				width: 70%;
				.fix-error-issue-list-data {
					display: flex;
					align-items: center;
					flex-direction: row;
					gap: 16px;
				}
			}
		}
		.fix-error-msg-wrapper {
			margin: 0px 24px;
			display: flex;
			align-items: center;
			gap: 8px;
			img {
				margin-left: 8px;
			}
		}
	}

	.items-list-table-container {
		.transaction-rows {
			min-height: 100px;
		}

		.items {
			width: 30%;
			display: flex;
			padding-left: 25px;

			.item-image {
				height: 62px;
				width: 62px;
				display: inline-block;
				margin-right: 10px;
				vertical-align: top;
				border-radius: $default-radius;
				position: relative;
				overflow: hidden;
				box-sizing: border-box;
				background: #f2f2f2;
				z-index: 10;

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}

				&:after {
					content: " ";
					white-space: pre;
					animation-duration: 2s;
					animation-fill-mode: forwards;
					animation-iteration-count: infinite;
					animation-name: placeHolderShimmer;
					animation-timing-function: ease-in-out;
					background: linear-gradient(
						to right,
						rgba(228, 226, 226, 0) 0%,
						#979797 50%,
						rgba(228, 226, 226, 0) 100%
					);
					background-size: 100%;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					position: absolute;
					background-repeat: no-repeat;
					will-change: transform, opacity;
					z-index: -1;
				}
			}

			.item-desc {
				display: inline-block;
				width: calc(100% - 70px);
			}
		}

		.crm-title {
			width: 20%;
			display: flex;
			flex-direction: column;

			.desc-text {
				padding-top: 5px;
				font-size: 12px;
				@extend .font--semibold;
			}
		}

		.category {
			width: 20%;

			.category-container {
				display: flex;
				align-items: center;
				color: $color-red;
				margin-bottom: 5px;

				img {
					margin-left: 5px;
				}
			}

			.category-desc {
				color: $mc-6;
			}
		}

		.associated_locations {
			width: 15%;
			text-align: left;
		}

		.associated_brands {
			display: flex;
			flex-wrap: wrap;

			.brand-container {
				padding: 0 5px;

				.brand-img {
					height: 32px;
					width: 32px;
				}
			}
		}

		.at-header-text {
			&.price {
				width: 15%;
				padding-right: 25px;
				text-align: right;
			}

			&:last-child {
				padding-right: 35px;
			}
		}

		.at-table-cell--last-child {
			padding-right: 35px;
		}

		.at-cell-text {
			&.price {
				width: 15%;
				padding-right: 35px;
				text-align: right;
				display: flex;
				flex-direction: column;

				.markup-price {
					color: $mc-5;
					text-decoration: line-through;
					text-decoration-color: $mc-9;
				}
			}
		}
	}
}

.item-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}

	.form-sidebar-container {
		.item-error-msg-wrapper {
			margin: 12px 16px;
			margin-top: 0;
			border-radius: 2px;
			padding: 0 16px;
			display: flex;
			gap: 12px;
			align-items: center;

			p {
				color: $color-neutral-black-80;
				font-size: 14px;
			}
		}

		.form-content {
			padding: 0 25px 25px;
		}
	}

	.item-custom-title {
		display: flex;
		align-items: center;

		.food-type {
			height: 20px;
			width: 20px;
			margin-right: 10px;

			&:after {
				height: 10px;
				width: 10px;
			}
		}
	}

	.associated-platforms-tab {
		.channel-type {
			font-size: $very-small-font-size;
			font-weight: 600;
			margin-bottom: 15px;

			.info {
				position: relative;
				top: 1px;
				width: 12px;
				margin-left: 5px;
			}
		}

		.web-channel-associations-container {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			border: 1px solid $border-color;
			border-radius: $default-radius;

			.meraki-info {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				justify-content: center;
				padding: 10px 25px;
				width: 60%;

				span {
					margin-bottom: 15px;
				}
			}
		}

		.channel-type-container {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;

			.channel-row {
				display: flex;
				align-items: center;
				margin-bottom: 15px;

				&:last-of-type {
					margin-bottom: 0;
				}

				.channel-icon {
					height: 40px;
					margin-right: 15px;
					border-radius: $default-radius;
				}

				.aggregator-icon {
					height: 30px;
					margin-right: 20px;
					margin-top: 10px;
					border-radius: $default-radius;
				}

				.channel-label {
					font-size: $very-small-font-size;
					font-weight: 600;
				}

				.aggregator-label {
					margin-top: 10px;
					font-size: $very-small-font-size;
					font-weight: 600;
				}
			}

			&.direct-channels {
				width: 60%;
				margin-right: 20px;
				border: none;
			}

			&.aggregator-channels {
				padding: 15px 20px;
			}

			.aggregator-channel-row {
				display: flex;
				align-items: center;

				.channel-row {
					width: 65%;
					margin-right: 20px;
				}
			}
		}
	}

	.item-groups-tab {
		padding-bottom: 30px;

		.select-filter-custom {
			margin: 0;
		}

		.popover-container {
			top: 54px;
		}

		.header-container {
			display: flex;
			justify-content: space-between;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 25px;

			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}

			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}

		.search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;

			.at-input-container {
				width: 30%;
			}

			.multi-select-filter-container {
				width: 30%;
				opacity: 1;

				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}

		.item-groups-table-container {
			.transaction-header-row {
				top: 38px;
			}

			.at-cell-text {
				font-size: $very-small-font-size;
			}

			.name {
				width: 70%;
				padding-left: 15px;
			}

			.items {
				width: 30%;
				padding-right: 15px;
				text-align: right;
			}
		}
	}

	.associated-locations-tab,
	.related-items-tab {
		.associated-locations-tab {
			.form-filters-container {
				.section-1 {
					width: 50%;

					.multi-select-filter-container {
						width: 220px;
					}
				}
			}
		}
	}

	.associated-locations-tab,
	.related-items-tab {
		.platform-modes {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			.filter-text {
				font-size: $very-small-font-size;
				font-size: 12px;
				color: $mc-5;
			}

			.multi-select-filter-container {
				width: 130px;
				margin-left: 10px;

				.at-dropdown {
					.Select-control {
						border: none;
						border-bottom: 2px dashed $border-color-dark;
						border-radius: 0;
						background-color: white;

						&:hover {
							box-shadow: none;
						}
					}

					&.Select.is-open > .Select-control {
						border-color: $color-blue;
					}

					&.Select.is-focused:not(.is-open) > .Select-control {
						border-color: $border-color-dark;
					}
				}
			}

			.select-filter-custom {
				.dropdown-custom {
					width: 220px;
				}
			}
		}

		.dropdown-custom-item-details {
			margin-top: 0;

			.dropdown-custom {
				width: auto;
				font-size: $very-small-font-size;

				.container {
					height: 32px;
					padding: 1px 10px;
					border: 0px;
					border-bottom: 2px dashed $border-color-dark;
					border-radius: 0;

					.option {
						font-size: $very-small-font-size;
					}
				}

				.is-open {
					border-color: $color-blue;
				}

				.dropdown-options {
					.option {
						height: 37px;
						padding: 1px 10px;
					}
				}

				.custom-option,
				.custom-label {
					display: flex;
					align-items: center;

					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 8px;
						width: 22px;
						height: 22px;
						text-transform: uppercase;
						font-size: $very-small-font-size;
						border-radius: $border-radius-default;
						@extend .font--semibold;

						img {
							width: 22px;
							height: 22px;
							object-fit: cover;
							border-radius: $border-radius-default;
						}

						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}

						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}

						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}

						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}

						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
					}
				}
			}
		}

		.header-container {
			display: flex;
			justify-content: space-between;
			border-radius: $default-radius;
			margin-bottom: 25px;

			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}

			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}

		.search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;

			.at-input-container {
				width: 30%;
			}

			.multi-select-filter-container {
				width: 30%;
				opacity: 1;

				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}

		.crm-title-search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 35px;

			.at-input-container {
				width: 30%;
			}

			.multi-select-filter-container {
				width: 30%;
				opacity: 1;

				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}

		.platform-modes {
			display: flex;
			align-items: center;
			// justify-content: flex-end;
			margin-bottom: 10px;

			.filter-text {
				font-size: $very-small-font-size;
				font-size: 12px;
				color: $mc-5;
			}

			.multi-select-filter-container {
				width: 130px;
				margin-left: 10px;

				.at--dropdown {
					.react-select__control {
						border: none;
						border-bottom: 2px dashed $border-color-dark;
						border-radius: 0;
						background-color: white;
						font-size: $very-small-font-size;
						color: $mc-5;

						&:hover {
							box-shadow: none;
						}
					}

					.react-select__control--menu-is-open {
						border-color: $color-blue;
					}
				}
			}
		}

		.toggle-item-availability {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: 20px;

			.at-btn {
				margin-left: 10px;
			}
		}

		.item-locations-table-container {
			position: relative;
		}

		.table-info {
			position: absolute;
			top: -20px;
			right: 0;

			img {
				width: 12px;
			}

			@keyframes bounceIn {
				0%,
				20%,
				50%,
				80%,
				100% {
					transform: scale(1, 1);
				}

				40% {
					transform: scale(2.2, 2.2);
				}

				65% {
					transform: scale(2, 2);
				}
			}

			animation: bounceIn 2s ease-in-out 100ms;
			animation-fill-mode: forwards;
		}

		.columnar-table-container {
			position: relative;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			margin-top: 20px;

			.scroll-left {
				position: absolute;
				top: 16px;
				left: -15px;
				cursor: pointer;

				img {
					width: 6px;
					padding: 2px 4px;
					opacity: 0.6;
				}
			}

			.scroll-right {
				position: absolute;
				top: 16px;
				right: -15px;
				cursor: pointer;

				img {
					width: 6px;
					padding: 2px 4px;
					opacity: 0.6;
				}
			}

			.table-content {
				display: flex;
				width: 100%;
				position: relative;
				overflow-x: auto;
				// scroll-snap-type: x proximity;

				// prevent scroll-chaining to parent elements
				// overscroll-behavior: contain;
				&.loading {
					opacity: 0.6;
					pointer-events: none;
				}
			}

			.at-table-column {
				flex-shrink: 0;
				flex-grow: 1;
				// scroll-snap-align: start;
				min-width: 100px;
			}

			.at-table-header {
				display: flex;
				align-items: center;
				background-color: #f8f8f8;
				padding: 10px 15px;
				min-height: 30px;
				border-bottom: 1px solid $divider-color;

				.header-text {
					text-transform: uppercase;
					color: $mc-9;
					font-size: 12px;
					opacity: 0.6;
					@extend .font--semibold;

					&.col {
						display: flex;
						flex-direction: column;
					}

					.desc {
						text-transform: none;
						color: $mc-7;
						@extend .font--regular;
					}
				}
			}

			.at-table-cell {
				padding: 15px;
				border-bottom: 1px solid $divider-color;
				min-height: 70px;
				background-color: $mc-1;

				&:last-child {
					border-bottom: none;
				}

				&:nth-of-type(odd) {
					background-color: #f3f5f8;
				}

				// &:hover{
				// 	background-color: #f5f6ff;
				// }
				.cell-text {
					margin: auto;
					font-size: $very-small-font-size;

					&.not-found {
						color: $color-red;
					}

					.inherited-price {
						color: $mc-5;
						font-size: $extra-small-font-size;
					}
				}
			}

			.name {
				width: 200px;
				position: sticky;
				left: 0;
				z-index: 10;

				.at-table-header {
					padding-left: 15px;

					.header-text {
						opacity: 1;

						.at-checkbox {
							font-size: 12px;
							padding-bottom: 2px;

							.text {
								opacity: 0.6;
							}

							&.read-only {
								.text {
									opacity: 1;
								}
							}
						}

						.selected {
							.at-checkbox {
								.checkmark {
									border: 1px solid $color-blue;
								}
							}
						}
					}
				}

				.at-table-cell {
					padding-left: 15px;

					.at-checkbox {
						font-size: $very-small-font-size;

						&.read-only {
							.link-text {
								pointer-events: all;
							}
						}
					}

					.city-name {
						padding-left: 25px;
					}

					.tags {
						padding-left: 25px;
					}
				}

				.location {
					display: flex;

					.availableAtLocation {
						padding: 1px 6px;
						margin: 0 5px;
						height: 12px;
						border-radius: 10px;
						text-align: center;
						font-size: 11px;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: 180ms;
						cursor: pointer;

						&[data-is-available="true"] {
							color: $color-green;
							border: 1px solid $color-green;
							background: rgba(46, 204, 113, 0.1);
						}

						&[data-is-available="false"] {
							color: $color-red;
							border: 1px solid $color-red;
							background: rgba(192, 57, 43, 0.1);
						}

						.loader {
							display: flex;
							position: relative;
							bottom: -3px;

							& > div {
								width: 3px;
								height: 3px;
								background: $mc-5;
								border-radius: 50%;
								margin: 3px;
								animation: loader 0.3s infinite alternate;
							}

							& > div:nth-child(2) {
								animation-delay: 0.1s;
							}

							& > div:nth-child(3) {
								animation-delay: 0.2s;
							}

							&.out {
								padding: 0 8px;

								& > div {
									background: $color-red;
								}
							}

							&.in {
								padding: 0 17px;

								& > div {
									background: $color-green;
								}
							}
						}

						@keyframes loader {
							to {
								transform: translate(0, -5px);
							}
						}

						.stock {
							display: flex;
							margin-left: 5px;
							cursor: pointer;
							padding: 1px 0;

							&.out {
								img {
									width: 10px;
									border: 1px solid $color-red;
									border-radius: 30px;
									background: rgba(192, 57, 43, 0.1);
								}
							}

							&.in {
								img {
									width: 10px;
									border: 1px solid $color-green;
									border-radius: 30px;
									background: rgba(46, 204, 113, 0.1);
								}
							}
						}
					}
				}

				.city-name {
					margin-top: 5px;
				}
			}

			.availability {
				width: 100px;

				.at-switch {
					&.active {
						.switch-rail {
							background-color: $color-green;
						}
					}
				}
			}

			.stock-count {
				width: 125px;
			}

			.price {
				width: 145px;
			}

			.customize {
				text-align: right;
				min-width: 60px;
				position: sticky;
				right: 0;
				z-index: 10;

				.at-table-header,
				.at-table-cell {
					padding-right: 15px;
				}

				.action-item {
					width: 12px;
					padding: 5px;
					border: 1px solid $border-color-dark;
					border-radius: $default-radius;
					cursor: pointer;
				}
			}
		}

		.items-edit-locations-table-container {
			.transaction-header-row {
				top: 38px;
			}

			.transaction-rows {
				min-height: 70px;
			}

			.at-cell-text {
				font-size: $very-small-font-size;
			}

			.at-table-header {
				&.stock-count {
					display: flex;
					align-items: center;
				}

				&.name {
					display: flex;
					flex-direction: column;
					justify-content: center;
					opacity: 1;

					.checkbox-sort {
						position: relative;
					}

					.sort-button {
						position: absolute;
						height: 8px;
						margin-bottom: 4px;
						cursor: pointer;
						padding: 5px;
						left: -18px;
						top: -1px;
					}

					.at-checkbox {
						font-size: 12px;
						padding-bottom: 1px;

						.text {
							opacity: 0.6;
						}

						&.read-only {
							.text {
								opacity: 1;
							}
						}
					}

					.selected {
						.at-checkbox {
							.checkmark {
								border: 1px solid $color-blue;
							}
						}
					}
				}

				&.price,
				&.aggregator_price {
					display: flex;
					flex-direction: column;
				}

				.at-header-subtext {
					color: $mc-7;
					text-transform: none;
					@extend .font--regular;
				}
			}

			.name {
				width: 27%;
				padding-left: 15px;

				.location-desc {
					display: flex;
					align-items: center;

					.availableAtLocation {
						padding: 1px 6px;
						margin: 0 5px;
						height: 12px;
						border-radius: 10px;
						text-align: center;
						font-size: 11px;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: 180ms;
						cursor: pointer;

						&[data-is-available="true"] {
							color: $color-green;
							border: 1px solid $color-green;
							background: rgba(46, 204, 113, 0.1);
						}

						&[data-is-available="false"] {
							color: $color-red;
							border: 1px solid $color-red;
							background: rgba(192, 57, 43, 0.1);
						}

						.loader {
							display: flex;
							position: relative;
							bottom: -3px;

							& > div {
								width: 3px;
								height: 3px;
								background: $mc-5;
								border-radius: 50%;
								margin: 3px;
								animation: loader 0.3s infinite alternate;
							}

							& > div:nth-child(2) {
								animation-delay: 0.1s;
							}

							& > div:nth-child(3) {
								animation-delay: 0.2s;
							}

							&.out {
								padding: 0 8px;

								& > div {
									background: $color-red;
								}
							}

							&.in {
								padding: 0 17px;

								& > div {
									background: $color-green;
								}
							}
						}

						@keyframes loader {
							to {
								transform: translate(0, -5px);
							}
						}

						.stock {
							display: flex;
							margin-left: 5px;
							cursor: pointer;
							padding: 1px 0;

							&.out {
								img {
									width: 10px;
									border: 1px solid $color-red;
									border-radius: 30px;
									background: rgba(192, 57, 43, 0.1);
								}
							}

							&.in {
								img {
									width: 10px;
									border: 1px solid $color-green;
									border-radius: 30px;
									background: rgba(46, 204, 113, 0.1);
								}
							}
						}
					}
				}

				.at-checkbox {
					font-size: $very-small-font-size;

					&.read-only {
						.location-desc {
							pointer-events: all;
						}
					}
				}

				.city-name {
					margin-top: 5px;
					margin-left: 25px;
					color: $mc-6;
					font-size: $extra-small-font-size;
					font-weight: $font--semibold;
					text-transform: uppercase;
				}

				.tags {
					margin-left: 25px;
				}

				& > div {
					margin-bottom: 0;
				}
			}

			.availability {
				width: 15%;
				display: flex;
				align-items: center;

				.availability-toggle-switch {
					width: 100%;
					height: 100%;
					padding-left: 15px;

					.at-switch {
						&.active {
							.switch-rail {
								background-color: $color-green;
							}
						}
					}
				}
			}

			.stock-count {
				width: 15%;
			}

			.price {
				width: 20%;
			}

			.aggregator_price {
				width: 20%;
				display: flex;
				justify-content: space-between;

				.action-item {
					width: 12px;
					padding: 5px;
					border: 1px solid $border-color-dark;
					border-radius: $default-radius;
					cursor: pointer;
				}
			}
		}

		.items-edit-related-table-container {
			.transaction-header-row {
				top: 38px;
			}

			.transaction-rows {
				min-height: 90px;
			}

			.at-cell-text {
				font-size: $very-small-font-size;
			}

			.title {
				width: 55%;
				padding-left: 25px;
				display: flex;
				flex-direction: column;

				.item-desc {
					display: flex;

					.food-type {
						margin: auto 10px auto 0;
					}
				}

				.crm-title {
					color: $mc-5;
					padding-left: 22px;
				}

				.item_tags {
					padding-left: 23px;

					.tag-item {
						margin: 2px 5px 0px 0px;
					}
				}
			}

			.category {
				width: 25%;
			}

			.price {
				width: 20%;
				padding-right: 25px;
				text-align: right;
			}
		}
	}

	.item-modifier-group-tab {
		.header-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 30px;

			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}

			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-right: 15px;
				padding-top: 5px;

				&:nth-child(3) {
					@extend .font--semibold;
				}
			}
		}

		.header-container--padding {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
		}

		.modifier-list {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
			padding: 20px;

			.header-container {
				margin-bottom: 10px;

				&:nth-last-child(1) {
					margin-bottom: 0;
				}
			}
		}
	}

	.item-taxes-and-charges-tab {
		.taxes-container,
		.charges-container {
			margin-bottom: 50px;

			.taxes-header-text,
			.charges-header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
				margin-bottom: 15px;
			}

			.header-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border: 1px solid $border-color;
				border-radius: $default-radius;
				padding: 15px;
				margin-bottom: 25px;

				.header-text {
					font-size: $very-small-font-size;
					@extend .font--semibold;
				}

				.header-subtext {
					color: $mc-5;
					font-size: $very-small-font-size;
					padding-right: 15px;
					padding-top: 5px;
				}

				.link-text {
					font-size: $very-small-font-size;
					text-transform: initial;
				}
			}

			.taxes-list .header-container {
				margin-bottom: 10px;
			}

			.charges-list .header-container {
				margin-bottom: 10px;
			}
		}
	}

	.item-images-tab {
		.at-uploader-container-v2 {
			width: 50%;
		}

		.image-upload-container {
			width: 47%;
			margin-bottom: 15px;
			padding: 5px;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			box-sizing: border-box;

			.uploader-area-container {
				width: 100%;
				box-sizing: border-box;
				margin-bottom: 10px;
			}

			.multi-select-filter-container {
				opacity: 1;

				.meta-info {
					font-weight: normal;
					color: $mc-6;
					margin-bottom: 0;
					padding-bottom: 5px;
				}
			}

			.at-btn {
				width: 100%;
				margin-top: 10px;
			}
		}

		.image-container {
			margin-bottom: 15px;

			.header-text {
				@extend .font--semibold;
				margin-bottom: 15px;
			}

			.images {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.image-item {
					width: 47%;
					padding: 5px;
					border: 1px solid $border-color;
					border-radius: $default-radius;
					margin-bottom: 15px;
					box-sizing: border-box;
					position: relative;

					.image {
						display: inline-block;
						width: 100%;
						height: 200px;
						border-radius: $default-radius;
						position: relative;
						overflow: hidden;
						box-sizing: border-box;
						background: #f2f2f2;
						z-index: 0;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}

						&:after {
							content: " ";
							white-space: pre;
							animation-duration: 2s;
							animation-fill-mode: forwards;
							animation-iteration-count: infinite;
							animation-name: placeHolderShimmer;
							animation-timing-function: ease-in-out;
							background: linear-gradient(
								to right,
								rgba(228, 226, 226, 0) 0%,
								#979797 50%,
								rgba(228, 226, 226, 0) 100%
							);
							background-size: 100%;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							position: absolute;
							background-repeat: no-repeat;
							will-change: transform, opacity;
							z-index: -1;
						}
					}

					.at-checkbox {
						margin-top: 15px;
						font-size: $very-small-font-size;
					}

					.platform-tag {
						margin-top: 5px;

						.title {
							font-size: 12px;
							color: $mc-7;
							margin-bottom: 5px;
						}

						.platform-value {
							display: flex;
							flex-wrap: wrap;
							padding: 5px 5px 0 5px;
							border: 1px solid $border-color-dark;
							border-radius: $default-radius;
							font-size: $very-small-font-size;
							text-transform: capitalize;

							.tag {
								padding: 4px 5px;
								margin: 0 5px 5px 0;
								color: #007eff;
								border: 1px solid rgba(0, 126, 255, 0.24);
								background-color: rgba(0, 126, 255, 0.08);
								border-radius: 20px;
								font-size: 12px;
							}
						}
					}

					.image-hover-icons {
						position: absolute;
						height: 100%;
						width: 100%;
						top: 0;
						left: 0;
						background: rgba(0, 0, 0, 0.3);
						color: white;
						display: flex;
						justify-content: space-evenly;
						align-items: center;
						border-radius: $default-radius;

						img {
							width: 30px;
							height: auto;
							cursor: pointer;
						}
					}
				}
			}
		}
	}

	.item-custom-fields-container {
		.custom-field-group {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding-bottom: 20px;
			margin-bottom: 25px;
			position: relative;
			box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
			.delete-grp-content {
				padding: 12px;
			}

			.group-title {
				display: flex;
				border-bottom: 1px solid $border-color;
				align-items: center;
				padding-bottom: 25px;
				margin-bottom: 10px;
				padding: 20px;
				background-color: rgba($mc-2, 0.6);

				.title {
					font-weight: 600;
					color: $mc-8;
					margin-right: 20px;
				}

				.key-input.at-input-container {
					width: 73.5%;
				}

				.delete-icon {
					width: 15px;
					border: 1px solid $border-color-dark;
					border-radius: $default-radius;
					cursor: pointer;
					padding: 5px;
					margin-bottom: 5px;
					align-self: flex-end;
					margin-left: auto;
				}
			}

			.key-value-pair {
				.header {
					padding: 15px 20px;
					display: flex;
					justify-content: space-between;

					.key-input.at-input-container {
						width: 40%;
					}

					.key-input--wide.at-input-container {
						width: 48%;
					}

					.delete-icon {
						width: 15px;
						border: 1px solid $border-color-dark;
						border-radius: $default-radius;
						cursor: pointer;
						padding: 5px;
						margin-bottom: 5px;
						align-self: flex-end;
					}
				}

				.description {
					padding: 5px 20px 20px 20px;

					.value-input.at-textarea-container {
						width: 100%;
					}
				}
			}

			.action-buttons {
				border-top: 1px solid $border-color;
				padding-top: 20px;
				margin-top: 20px;
				padding-left: 20px;

				.save-btn {
					margin-right: 10px;
				}
			}
		}
	}

	.at-modal {
		.modal-container {
			width: 70%;

			.modal-content {
				min-height: 50px;
			}
		}

		.modal-gap-correction {
			padding: 0 25px;
			color: $mc-5;
		}
	}
}

.logs-sidebar {
	.at-top-bar-wrapper-v2 {
		position: sticky;
		left: 0;
	}
	.form-container {
		overflow: scroll;
	}
	.form-content {
		padding: 0 !important;
	}
	.table-header {
		top: 41px;
	}
	.pagination-div {
		position: fixed;
		padding: 10px 15px;
		bottom: 0px;
		margin-bottom: 15px;
		width: 720px;
	}
	.logs-container {
		.brand {
			.popover-container {
				min-width: 150px;
				padding: 8px 12px;
				top: 52px;
				align-items: center;
				left: auto;
				display: flex;
				left: 45px;
				z-index: 11111;
				padding: 1px 0px 0px 0px;
				left: 14px;
			}
			.popover-info-brand {
				padding: 12px;
				color: #3e495f;
				font-size: 14px;
				width: 400px;
				.brand-title {
					font-weight: 600;
				}
				.brand-desc {
					margin-top: 7px;
				}
			}
			.brand-selector {
				margin-bottom: 25px;
				margin-left: 25px;
				.container {
					border: none;
					justify-content: flex-start;
					height: 30px;
					padding: 5px 8px 5px 0;
					border: none;
				}
				width: fit-content;

				.dropdown-component {
					width: 329px;
					right: auto;
					left: 0;
					z-index: 160 !important;
				}

				.option {
					.custom-option,
					.custom-label {
						display: flex;
						align-items: center;

						div {
							@include truncate_text;
						}
						.logo {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 8px;
							text-transform: uppercase;
							border-radius: 4px;
							img {
								object-fit: cover;
								border-radius: 4px;
							}

							text-transform: uppercase;
							border-radius: $default-radius;
							margin-right: 10px;
							@extend .font--semibold;
							&.brown {
								color: $color-brown-dark;
								background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
							}
							&.blue {
								color: $color-blue-dark;
								background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
							}
							&.green {
								color: $color-green-dark;
								background-color: rgba($color: $color-green-dark, $alpha: 0.1);
							}
							&.red {
								color: $color-red-dark;
								background-color: rgba($color: $color-red-dark, $alpha: 0.1);
							}
							&.purple {
								color: $color-violet;
								background-color: rgba($color: $color-violet, $alpha: 0.1);
							}
						}
					}

					.custom-label {
						height: 30px;
						object-fit: cover;
						border-radius: 4px;
						font-size: 16px;
						margin-right: 8px;
						font-weight: 600;
						.logo {
							width: 30px;
							height: 30px;
							object-fit: cover;
							border-radius: 4px;
							font-size: 18px;
							img {
								width: 30px;
								height: 30px;
								object-fit: cover;
								border-radius: 4px;
							}
						}
					}
					.custom-option {
						font-size: $very-small-font-size;
						@extend .font--regular;
						.logo {
							width: 25px;
							height: 25px;
							object-fit: cover;
							border-radius: 4px;
							font-size: 14px;
							img {
								width: 25px;
								height: 25px;
								object-fit: cover;
								border-radius: 4px;
							}
						}
					}
				}
			}
		}
		.at-paginator {
			width: 725px;
			padding: 10px;
		}

		.filters {
			z-index: 151;
			position: sticky;
			left: 0;
			padding: 0 25px 25px 25px;
			display: flex;
			align-items: center;
			grid-gap: 12px;
			gap: 12px;
			font-weight: 400;

			.action-head,
			.status-head {
				color: #979797;
			}

			.row {
				.dropdown-custom {
					width: 130px;
				}
				.dropdown-component {
					width: 250px;
					right: auto;
					left: 0;
					z-index: 160 !important;
				}
				.custom-status,
				.custom-action {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					text-align: left;
					text-underline-position: from-font;
					text-decoration-skip-ink: none;
					color: #222222;
				}
			}
			.platform-filter {
				.option {
					text-transform: capitalize;
				}
			}
			.multi-select-filter-container {
				width: 130px;
			}
			.dropdown-date-time-filter .dropdown-custom .container {
				white-space: nowrap;
				.dropdown-label {
					margin-right: 0;
				}
			}
			.dropdown-label {
				margin-right: 0;
			}
		}
		.table-div {
			margin-bottom: 80px;
		}
		.item-action-logs-table {
			margin-bottom: 100px;
			width: 155%;
			background: white;
			z-index: 1;

			.performed-by {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			.action {
				width: 19%;
				display: flex;
				gap: 8px;
				padding-left: 28px;
				.img-div {
					width: 20px;
					height: 20px;
				}
				.action-info {
					font-family: Source Sans Pro;
					line-height: 20px;
					text-align: left;
					font-weight: 600;
					.action-txt {
						font-size: 14px;
						color: #222222;
						text-transform: capitalize;
					}
					.timestamp {
						color: #aaaaaa;
						font-family: Source Sans Pro;
						font-size: 12px;
						line-height: 18px;
					}
				}
			}

			.userName {
				width: 21%;
			}

			.userName,
			.locationName {
				.renderpopover {
					padding: 3px 2px;
				}
				.popover-wrapper-container {
					width: fit-content;
				}
				.down-right {
					width: fit-content;
					.popover-container {
						right: -17px;
						padding: 8px 12px;
						top: 30px;
						align-items: center;
						left: auto;
						display: flex;
						overflow-wrap: anywhere;

						&::before {
							left: auto;
						}
						&::after {
							left: auto;
						}
					}
					white-space: normal;
				}
				.popover-container::after {
					right: 12px;
				}
				.popover-container::before {
					right: 15px;
				}
				white-space: nowrap;
			}
			.locationName {
				width: 21%;
			}
			.table-list {
				&.status {
					padding: 4px 15px;
				}
			}
			.status {
				width: 12%;
				.failed,
				.success {
					text-transform: capitalize;
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 600;
					line-height: 22px;
				}
				.failed {
					background: #ffe5e5;
					color: #b02b2b;
					padding: 2px 8px;
					border-radius: 2px;
				}
				.success {
					background: #ecf7f0;
					color: #0b6e1b;
					padding: 2px 8px;
					border-radius: 2px;
				}
			}
			.message {
				width: 29%;
				overflow-wrap: anywhere;
				.down-right {
					width: fit-content;
					.popover-container {
						min-width: 150px;
						right: 0px;
						padding: 8px 12px;
						top: 52px;
						align-items: center;
						left: auto;
						display: flex;

						&::before {
							left: auto;
						}
						&::after {
							left: auto;
						}
					}
				}
				.popover-container::after {
					right: 12px;
				}
				.popover-container::before {
					right: 15px;
				}
				.popover-wrapper--content {
					overflow-wrap: anywhere;
				}
				.popover-container {
					overflow-wrap: anywhere;
				}
			}
		}
	}
}

.context-menu-button {
	position: inherit;
	top: 0;
	right: 10px;
	cursor: pointer;
	&:hover {
		background: $mc-1;
	}
	.at-btn {
		padding: 2px 6px 0px 12px;
	}
	.menu {
		top: 40px;
		right: 0px;
	}
}
