.gallery-container {
	.filter-buttons {
		@include bp(768px) {
			display: flex;
			flex-direction: row-reverse;
			float: left;
			position: relative;
		}
	}
	.action-buttons-container {
		margin-right: 10px;
	}
	.galleries-list-container {
		padding: 25px 10px 0 25px;
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		.at-uploader-container {
			margin: 0 15px 15px 0;
			flex: 1 0 240px;
			.uploader-area-container {
				width: auto;
				display: flex;
				// min-width: 40vw;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				height: 138px;
				.preview-image {
					height: 158px;
					border-radius: $default-radius;
					object-fit: cover;
				}
				.icon-edit {
					width: 30px;
				}
				.icon-delete {
					width: 30px;
				}
			}
		}
		.at-page-switch-container {
			margin: 0 15px 15px 0;
			flex: 1 0 240px;
			align-items: center;
			border: 1px dashed $color-blue;
			border-radius: $default-radius;
			background-color: rgba($color: $color-blue, $alpha: 0.1);
			position: relative;
			.page-switch-area-container {
				width: auto;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: row;
				height: 165px;
				.nav-icon {
					cursor: pointer;
					height: 50px;
					width: 50px;
					display: block;
					margin: 0 auto;
					filter: grayscale(0.1);
					opacity: 0.6;
					transition: 180ms;
					&:hover {
						opacity: 1;
						filter: grayscale(0);
					}
				}
				.nav-icon--disabled {
					cursor: default;
					height: 50px;
					width: 50px;
					display: block;
					margin: 0 auto;
					filter: grayscale(0.1);
					opacity: 0.2;
					transition: 180ms;
				}
				.count-span {
					border: 2px solid $color-blue;
					height: 70px;
					width: 70px;
					font-size: 25px;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					border-radius: 50%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					text-align: center;
					color: $color-blue;
				}
				.count-caption {
					font-size: 12px;
				}
			}
		}
		.gallery-image {
			margin: 0 15px 15px 0;
			height: 170px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1 0 240px;
			img {
				width: 100%;
				height: 170px;
				border-radius: $default-radius;
				object-fit: cover;
			}
			.image-hover-icons {
				position: absolute;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				background: rgba(0, 0, 0, 0.4);
				color: white;
				display: flex;
				justify-content: space-evenly;
				align-items: center;
				border-radius: $default-radius;
				img {
					width: 30px;
					height: auto;
					cursor: pointer;
				}
			}
		}
		.hidden-block {
			content: "";
			flex: 1 0 240px;
			padding: 5px;
			margin: 10px 5px 0 0;
			visibility: hidden;
			display: none;
		}
	}
}

.gallery-create-container,
.gallery-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}
	// restore styles of tags in select dropdown component
	.Select--multi .Select-value {
		max-width: max-content;
	}
	.Select--multi .Select-value-label {
		max-width: max-content;
	}
	.at-textarea-label {
		span {
			display: flex;
			justify-content: space-between;
			.toggle {
				color: $color-blue-light;
				cursor: pointer;
				&:hover {
					color: $color-blue;
				}
			}
		}
	}
	.at-top-bar-v2 {
		display: none;
	}
	.preview-container {
		.preview-label {
			display: flex;
			justify-content: space-between;
			color: $mc-5;
			font-size: 12px;
			margin-top: 3px;
			margin-bottom: 5px;
			.toggle {
				color: $color-blue-light;
				cursor: pointer;
				&:hover {
					color: $color-blue;
				}
			}
		}
		.preview {
			border-radius: $default-radius;
			border: 1px solid $border-color-dark;
			padding: 10px;
			margin-bottom: 5px;
		}
	}
	.image-container {
		width: auto;
		height: 400px;
		margin-bottom: 90px;
		position: relative;
		.no-items-placeholder {
			margin: auto;
			padding-top: 25%;
			padding-bottom: 25%;
		}
		.preview-image {
			box-shadow: $default-shadow;
			width: 100%;
			height: 400px;
			border-radius: $default-radius;
			text-align: center;
			object-fit: cover;
		}
		.image-hover-content {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.75);
			color: white;
			display: flex;
			flex-direction: row-reverse;
			border-radius: $default-radius;
			z-index: 10;
			.replace-info {
				width: 100%;
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				.replace-icon {
					height: 36px;
					width: 36px;
					margin-bottom: 16px;
				}
				.info {
					font-size: 16px;
					.bold-text {
						font-weight: 600;
					}
				}
			}
		}
		.image-info {
			display: flex;
			margin-top: 5px;
			color: $mc-5;
			font-size: $very-small-font-size;
			& > div {
				padding: 0 15px;
				border-left: 1px solid $mc-5;
				&:first-child {
					padding-left: 0;
					border: none;
				}
			}
			span {
				color: $mc-9;
			}
		}
		.image-info-action {
			display: flex;
			justify-content: space-between;
			margin-top: 16px;
			.info {
				font-size: $very-small-font-size;
				color: $mc-8;
				.filename {
					width: 450px;
					font-weight: 600;
					@include truncate_text;
				}
				.img-dimension {
					color: $mc-6;
					font-weight: 600;
				}
			}
			.action {
				.preview-btn {
					margin-right: 15px;
					.preview-img {
						height: 20px;
						width: 20px;
						top: 5px;
					}
				}
				.download-btn {
					svg {
						// top: 2px;
						path {
							fill: $mc-8;
						}
					}
					&:hover {
						svg {
							path {
								fill: $color-blue;
							}
						}
					}
				}
			}
		}
	}
}
