.reports-section {
	.at-top-bar-wrapper-v2 {
		margin-bottom: 15px;
		z-index: $zI-level-1;
	}
	.settings-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.search-filter {
			position: relative;
			margin: 10px 0 0 0;
		}
	}
	.reports-list-container {
		.report-card-container {
			padding: 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			margin-inline: 25px;
			margin-bottom: 30px;
			box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
			&:first-child {
				margin-top: 30px;
			}
			.header-container {
				display: flex;
				align-items: center;
				.icon {
					width: 30px;
					margin-right: 15px;
				}
				.header-title {
					font-size: $small-font-size;
					@extend .font--semibold;
				}
				.header-desc {
					margin: 5px 25px 0 0;
					font-size: $very-small-font-size;
					color: $mc-5;
				}
			}
		}
		.placeholder-main {
			padding-bottom: 20px;
		}
	}
	.export-report-container {
		// .overlay-sidebar-container {
		// 	.content {
		// 		overflow: visible;
		// 		overflow-x: visible;
		// 	}
		// }
		// .form-container {
		// 	overflow: unset;
		// }
		.footer {
			.send-email {
				width: 140px;
			}
		}
		.form-fields-container {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: space-between;
			&.row-half {
				& > div {
					width: 47%;
					margin-bottom: 30px;
				}
				.multi-select-filter-container {
					margin-bottom: 30px;
				}
			}
			&.row-full {
				& > div {
					width: 100%;
					margin-bottom: 30px;
				}
			}
			// .dropdown-custom {
			// 	.container {
			// 		.dropdown-clear, .dropdown-arrow {
			// 			position: relative;
			// 		}
			// 	}
			// }
			.dropdown-date-time-filter {
				.dropdown-custom {
					width: 100%;
					.dropdown-component {
						z-index: 450;
						position: relative;
						margin-top: 5px;
					}
				}
				.actions-container {
					.at-btn {
						width: 67px;
					}
				}
				// &.with-custom {
				// 	.dropdown-component {
				// 		left: -200px;
				// 	}
				// }
			}
			.react-tagsinput-input {
				width: 95%;
				box-sizing: border-box;
			}
		}
	}
}

.reports-container {
	.at-top-bar-wrapper-v2 {
		margin-bottom: 15px;
		z-index: $zI-level-1;
		z-index: 150;
	}
	.settings-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.search-filter {
			position: relative;
			margin: 10px 0 0 0;
			width: 250px;
			.at-input-container {
				width: 100%;
			}
		}
	}
	.modal-container {
		width: 80%;
		max-width: 550px;
		.modal-header {
			padding: 15px 25px;
			border-bottom: 1px solid $border-color;
			.header-left {
				flex-grow: 0;
				.title {
					margin-bottom: 0;
					font-size: $medium-font-size;
					color: $color-black-80;
				}
			}
		}
		.modal-content {
			.modal-sub-title {
				color: $color-neutral-black-80;
				font-size: $medium-font-size;
				margin-bottom: 15px;
				@extend .font--semibold;
			}
			padding: 25px;
			box-sizing: border-box;
			min-height: 60px;
			color: $color-black-80;
		}
		.modal-footer {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 15px 25px;
			border-top: 1px solid $border-color;
		}
	}
	.reports-list-container {
		.placeholder-main {
			padding: 50px 0;
			img {
				width: 260px;
				height: 200px;
			}
			.placeholder--text {
				font-size: $medium-font-size;
				color: $color-neutral-black-90;
			}
			.placeholder--sub-text {
				font-size: $very-small-font-size;
				margin-top: 12px;
			}
		}
		.reports-overview {
			margin-top: 25px;
			min-height: 65vh;
			padding: 0 25px;
			.group-container {
				margin-bottom: 30px;
				.group-header {
					display: flex;
					flex-direction: column;
					gap: 10px;
					margin-bottom: 15px;
					.group-name {
						display: flex;
						align-items: center;
						gap: 8px;
						font-size: $medium-large-font-size;
						color: $color-neutral-black-80;
						@extend .font--semibold;
					}
					.group-description {
						font-size: $very-small-font-size;
						color: $color-neutral-black-60;
					}
				}
				.reports-list {
					border: 1px solid $border-color;
					border-radius: $border-radius-default;
					.report {
						display: flex;
						align-items: center;
						gap: 15px;
						padding: 16px;
						border-bottom: 1px solid $border-color;
						&:last-child {
							border-bottom: none;
						}
						.detail {
							display: flex;
							flex-direction: column;
							gap: 6px;
							width: 90%;
							a {
								width: fit-content;
							}
							.name {
								font-size: $very-small-font-size;
								color: $color-neutral-black-80;
								text-transform: capitalize;
								@extend .font--semibold;
							}
							.description {
								font-size: $very-small-font-size;
								color: $color-neutral-black-60;
							}
							.highlight {
								background-color: #f7da85;
							}
						}
						.user-download {
							display: flex;
							flex-direction: column;
							align-items: flex-end;
							gap: 6px;
							// width: 35%;
							.last-download,
							.user {
								font-size: $extra-small-font-size;
								color: $color-neutral-black-50;
								span {
									color: $color-neutral-black-80;
									@extend .font--semibold;
								}
							}
						}
					}
				}
			}
		}
		.new-free-trial {
			.reports-overview {
				padding: 0;
			}
		}
		.export-report-history {
			.filters-container {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 15px 25px;
				margin-bottom: 15px;
				.left {
					display: flex;
					align-items: center;
					gap: 10px;
				}
				.right {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					gap: 10px;
				}
				.multi-select-filter-container {
					font-size: $very-small-font-size;
					.at--dropdown {
						&.downloaded-by {
							width: 160px;
						}
						&.category {
							width: 190px;
						}
						.menu-wrapper {
							z-index: 150 !important;
						}
					}
					.custom-value {
						color: $color-neutral-black-light;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						span {
							color: $color-neutral-black-dark;
							margin-left: 2px;
							@extend .font--semibold;
						}
					}
				}
				.dropdown-custom {
					.dropdown-component {
						z-index: 150 !important;
					}
				}
			}
			.custom-table-container {
				z-index: 120;
				margin: 0 25px;
				.table-header {
					z-index: 140;
				}
				.table-list {
					background-color: $mc-1;
					.table-cell {
						color: $color-neutral-black-80;
					}
					.filters-applied {
						.filter {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
						span {
							color: $color-neutral-black-60;
							margin-right: 5px;
						}
					}
					.exported-to {
						.email {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
						span {
							color: $color-neutral-black-50;
							margin-right: 5px;
						}
					}
					.popover-wrapper-container {
						width: fit-content;
						&.down-right {
							.popover-container {
								top: 28px;
								left: auto;
								right: -12px;
								width: max-content;
								max-width: 350px;
								z-index: 60;
								box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
								&::before {
									right: 20px;
									z-index: -10;
								}
								&::after {
									right: 16px;
									display: none;
								}
							}
						}
						.popover-container {
							.filters-applied-popover,
							.emails-popover {
								background-color: $mc-1;
								border-radius: $default-radius;
								// padding: 12px;
								box-sizing: border-box;
								font-size: $very-small-font-size;
								color: $color-neutral-black-dark;
								line-height: 1.2;
								z-index: 10;
								.header {
									padding: 8px 12px;
									font-size: $extra-small-font-size;
									color: $color-dark-grey;
									text-transform: uppercase;
									border-bottom: 1px solid $border-color;
									@extend .font--semibold;
								}
								.filters-list,
								.emails-list {
									max-height: 250px;
									overflow-y: auto;
									.filter-row,
									.email-row {
										display: flex;
										flex-direction: column;
										gap: 5px;
										padding: 8px 12px;
										border-bottom: 1px solid $border-color;
										font-size: $very-small-font-size;
										.name {
											color: $color-neutral-black-90;
											@extend .font--semibold;
										}
										.value {
											color: $color-neutral-black-60;
										}
										&:last-child {
											border-bottom: none;
										}
									}
								}
							}
						}
					}
				}
				.report-name {
					width: 20%;
					padding-left: 25px;
					.highlight {
						background-color: #f7da85;
					}
					.category {
						color: $color-neutral-black-50;
						font-size: $extra-small-font-size;
						margin-top: 8px;
					}
				}
				.downloaded-on {
					width: 20%;
					.user {
						color: $color-neutral-black-50;
						font-size: $extra-small-font-size;
						margin-top: 8px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
				.filters-applied {
					width: 25%;
					display: flex;
					flex-direction: column;
					gap: 6px;
					.more {
						cursor: pointer;
						@extend .font--semibold;
					}
				}
				.exported-to {
					width: 13%;
					display: flex;
					flex-direction: column;
					gap: 6px;
					.more {
						cursor: pointer;
						@extend .font--semibold;
					}
				}
				.size {
					width: 10%;
				}
				.access-report {
					width: 12%;
					padding-right: 25px;
					display: flex;
					justify-content: flex-end;
					.download {
						display: flex;
						height: fit-content;
						align-items: center;
						gap: 10px;
						cursor: pointer;
						@extend .font--semibold;
					}
				}
			}
		}
		.schedule-reports {
			.section-header-container {
				display: flex;
				align-items: baseline;
				justify-content: space-between;
				padding: 0 25px;
				margin-top: 25px;
				margin-bottom: 15px;
				.section-title {
					color: $color-neutral-black-80;
					font-size: $medium-font-size;
					@extend .font--semibold;
					.free-trial-active {
						padding: 2px 8px 2px 8px;
						gap: 8px;
						border-radius: 2px;
						font-size: 14px;
						font-weight: 600;
						line-height: 22px;
						text-align: left;
						color: $color-blue-40;
						background: $color-blue-15;
					}
				}
			}
			.section-filters {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 15px 25px;
				margin-bottom: 15px;
				.left {
					display: flex;
					align-items: center;
					gap: 10px;
				}
				.right {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					gap: 10px;
				}
				.multi-select-filter-container {
					font-size: $very-small-font-size;
					.at--dropdown {
						&.status {
							width: 140px;
						}
						&.scheduled-by {
							width: 160px;
						}
						.menu-wrapper {
							z-index: 150 !important;
						}
					}
					.custom-value {
						color: $color-neutral-black-light;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						span {
							color: $color-neutral-black-dark;
							margin-left: 2px;
							@extend .font--semibold;
						}
					}
				}
				.dropdown-custom {
					.dropdown-component {
						z-index: 150 !important;
					}
				}
			}
			.custom-table-container {
				margin: 0 25px;
				z-index: 120;
				.placeholder-main {
					padding: 20px 0;
				}
				.placeholder--action-btn {
					margin-top: 50px;
				}
				.table-header {
					z-index: 140;
				}
				.table-list {
					background-color: $mc-1;
					.table-cell {
						color: $color-neutral-black-80;
					}
					.popover-wrapper-container {
						width: fit-content;
						&.down-right {
							.popover-container {
								top: 28px;
								left: auto;
								right: -12px;
								width: max-content;
								max-width: 350px;
								z-index: 60;
								box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
								&::before {
									right: 20px;
									z-index: -10;
								}
								&::after {
									right: 16px;
									display: none;
								}
							}
						}
						.popover-container {
							.emails-popover {
								background-color: $mc-1;
								border-radius: $default-radius;
								// padding: 12px;
								box-sizing: border-box;
								font-size: $very-small-font-size;
								color: $color-neutral-black-dark;
								line-height: 1.2;
								z-index: 10;
								.header {
									padding: 8px 12px;
									font-size: $extra-small-font-size;
									color: $color-dark-grey;
									text-transform: uppercase;
									border-bottom: 1px solid $border-color;
									@extend .font--semibold;
								}
								.emails-list {
									max-height: 250px;
									overflow-y: auto;
									.email-row {
										display: flex;
										flex-direction: column;
										gap: 5px;
										padding: 8px 12px;
										border-bottom: 1px solid $border-color;
										font-size: $very-small-font-size;
										.name {
											color: $color-neutral-black-90;
											@extend .font--semibold;
										}
										.value {
											color: $color-neutral-black-60;
										}
										&:last-child {
											border-bottom: none;
										}
									}
								}
							}
						}
					}
				}
				.report-name {
					width: 23%;
					padding-left: 25px;
					.name {
						text-transform: capitalize;
					}
					.highlight {
						background-color: #f7da85;
					}
					.category {
						color: $color-neutral-black-50;
						font-size: $extra-small-font-size;
						margin-top: 8px;
					}
				}
				.scheduled-for {
					width: 18%;
				}
				.data-period {
					width: 17%;
				}
				.recipient {
					width: 20%;
					display: flex;
					flex-direction: column;
					gap: 6px;
					.email {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					.more {
						cursor: pointer;
						@extend .font--semibold;
					}
				}
				.schedule-status {
					width: 12%;
					.status {
						width: fit-content;
						display: flex;
						align-items: center;
						font-weight: 600;
						font-size: $very-small-font-size;
						color: $mc-8;
						padding: 2px 8px;
						border-radius: $border-radius-default;
						text-transform: capitalize;
						&.active {
							color: $color-green-70;
							background-color: $color-green-10;
						}
						&.paused {
							color: $color-yellow-70;
							background-color: $color-yellow-10;
						}
						&.deleted {
							color: $color-red-7;
							background-color: $color-red-1;
						}
					}
				}
				.schedule-actions {
					width: 10%;
					padding-right: 25px;
					display: flex;
					justify-content: flex-end;
					.context-menu {
						.icon-dots {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 28px;
							height: 28px;
							padding: 5px;
							box-sizing: border-box;
						}
						.menu {
							min-width: 100px;
							top: 32px;
							right: 0;
							.action-item {
								display: flex;
								align-items: center;
								gap: 8px;
							}
						}
						&.open {
							.icon-dots {
								background-color: #f5f5f5;
							}
						}
					}
				}
			}
		}
	}
	background: #ffffff;
	.schedule-report-intro {
		.modal-plan {
			margin: 16px 24px;
			border: 1px solid #eaeaea;
			padding: 16px;
			display: flex;
			gap: 16px;
			flex-direction: column;
			.free-trail {
				display: flex;
				gap: 8px;
				padding-bottom: 16px;
				border-bottom: 1px solid #eaeaea;
				font-family: Source Sans Pro;
				font-size: 18px;
				font-weight: 600;
				text-align: left;
				color: #646464;
				svg {
					scale: 1.2;
					align-self: center;
				}
				.your-plan {
					color: #363636;
				}
			}
			.all-plans {
				display: flex;
				flex-direction: column;
				gap: 8px;
				.plans {
					display: flex;
					gap: 8px;
					.svg {
						height: 20px;
						width: 20px;
						display: flex;
						align-items: center;
					}
					.text {
						font-family: Source Sans Pro;
						font-size: 16px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
					}
					.text-bold {
						font-weight: 600;
					}
				}

				.plan-benefits {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 700;
					line-height: 22px;
					text-align: left;
					color: #7a7a7a;
				}
				.plans-info {
					display: flex;
					flex-direction: column;
					gap: 6px;
				}
			}
		}
		.submit-btn {
			width: fit-content;
		}
		.free-trail-plan-wrap {
			height: 80vh;

			.free-trial-intro {
				width: 572px;
				border: 1px solid #eaeaea;
				margin-top: 10vh;
				margin-left: auto;
				margin-right: auto;
				background: #ffffff;
				.heading {
					align-items: center;
					display: flex;
					gap: 8px;

					font-size: 18px;
					font-weight: 600;
					text-align: left;
					color: #363636;
					padding: 16px 24px;
					border-bottom: 1px solid #eaeaea;
				}
				.info-div {
					border-left: 4px solid #3b5feb;
					border-radius: 4px;
					display: flex;
					gap: 12px;
					padding-left: 12px;
					margin: 16px 24px;
					padding: 12px 16px;
					background: #f2f5ff;
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
				}
				.btns {
					display: flex;
					justify-content: right;
					gap: 16px;
					padding: 12px 16px;
					border-top: 1px solid #eaeaea;
					.back {
						padding: 8px 16px 8px 16px;
						gap: 8px;
						border-radius: 2px;
						background: #ffffff;
						border: 1px solid #eaeaea;
					}
				}
			}
		}
		.schedule-reports-banner {
			background: #f2f5ff;
			margin-top: -15px;
			*,
			::after,
			::before {
				box-sizing: border-box;
			}
			display: flex;
			padding: 24px;
			.banner-text {
				padding: 10px 70px 0px 16px;
				flex-basis: 45%;
				display: flex;
				flex-direction: column;
				gap: 24px;
				justify-content: center;
				.description {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					text-align: left;
				}

				.banner-head {
					font-family: Source Sans Pro;
					font-size: 28px;
					font-weight: 600;
					line-height: 36px;
					text-align: left;
					color: #171717;
					.automate {
						color: #3b5feb;
					}
					.v-star {
						height: 28px;
						width: 28px;
					}
				}

				.trial-plan {
					.free-trail-btn {
						background: #3b5feb;
						border: none;
						color: #ffffff;
						padding: 8px 16px 8px 16px;
						gap: 8px;
						border-radius: 2px;
						height: auto;
						font-family: Source Sans Pro;
						font-size: 16px;
						font-weight: 600;
						line-height: 22px;
						text-align: left;
						cursor: pointer;
					}
					.hover-btn {
						opacity: 0.6;
					}
					.popover-wrapper-container.middle-right .popover-container {
						top: -1px;
						right: auto;
						left: 141px;
						align-items: center;
						display: flex;
						padding: 8px 15px;
						width: fit-content;
						min-width: none;
					}
					.popover-wrapper-container.middle-right .popover-container::after {
						top: 9px;
					}
					.popover-wrapper-container.middle-right .popover-container::before {
						top: 11px;
						left: -12px;
					}
					.popover-container {
						animation: info-anim 0.5s ease;
					}

					@keyframes info-anim {
						0% {
							opacity: 0;
							left: 135px;
						}
						100% {
							left: 141px;
							opacity: 1;
						}
					}
					.free-trial {
						margin-top: 12px;
						display: flex;
						align-items: center;
						gap: 8px;
						.text {
							font-family: Source Sans Pro;
							font-size: 12px;
							font-weight: 400;
							line-height: 16px;
							text-align: left;
							color: #363636;
						}
					}
				}
			}
			.banner-img {
				position: relative;
				overflow: hidden;
				border-radius: 12px;
				padding: 32px 0 32px 32px;
				background: linear-gradient(357.4deg, #622b85 -28.29%, #3a327b 69.58%, #564b9e 120.48%);
				flex-basis: 55%;
				.plane-path {
					position: absolute;
					top: 4%;
					height: 93%;
					width: 79%;
					left: 0;
				}
				.plane {
					position: absolute;
					right: 9%;
					top: 15%;
					width: 25%;
					z-index: 1;
				}
				.ellipse-bottom-right {
					position: absolute;
					bottom: 0;
					height: 112%;
					right: -22%;
					width: 69%;
				}
				.ellipse-top-left {
					position: absolute;
					top: 0;
					left: 0;
					height: 28%;
				}
				.lines-pattern {
					position: absolute;
					top: 0;
					width: 100%;
					left: 0;
				}
				.first-div {
					display: flex;
					justify-content: space-between;
					.top-customer {
						display: flex;
						flex-direction: column;
						align-self: center;
						.two-sparkles {
							width: 38.12px;
							height: 44px;
						}
					}
					.mobile-calendar {
						display: flex;
						flex-direction: column;
						gap: 40px;
						margin-right: 15px;
						.mobile-img {
							margin-left: 22px;
							width: 70.63px;
							height: 106.95px;
							top: 15.07px;
							left: 475.75px;
							gap: 0px;
							rotate: 179.93 deg;
							scale: 1.1;
						}
						@keyframes calendar-anime {
							0% {
								left: 45%;
							}
							100% {
								left: 0%;
							}
						}
						.calendar-schedule {
							animation: calendar-anime ease 0.5s;
							position: relative;
							padding: 6.62px;
							border-radius: 6.76px;
							background: #ffffff;
							display: flex;
							align-items: center;
							gap: 6.62px;
							border-radius: 6.76px;
							white-space: nowrap;
							overflow: hidden;
							.calendar {
								width: 18px;
								height: 18px;
							}
							font-family: Source Sans Pro;
							font-size: 13.24px;
							font-weight: 400;
							line-height: 24.39px;
							color: #753675;
						}
					}
					@keyframes customer-anime {
						0% {
							left: -45%;
						}
						100% {
							left: 0%;
						}
					}

					.customer-summary {
						animation: customer-anime ease 0.5s;
						background: #ffffff;
						padding: 18px 30px 15px 15px;
						display: flex;
						flex-direction: column;
						gap: 5px;
						height: fit-content;
						position: relative;
						overflow: hidden;
						border-radius: 9.8px;
						margin-left: 13px;

						.green-box {
							height: 18px;
							background: #ecf7f0;
							position: absolute;
							bottom: -9px;
							color: #0b6e1b;
							font-size: 14px;
							font-weight: 600;
							line-height: 22px;
							border-radius: 1.44px;
							padding: 1.44px 5.75px;
						}
						.summary {
							font-family: Source Sans Pro;
							font-size: 11.44px;
							font-weight: 600;
							line-height: 21.91px;
							text-align: left;
							color: #363636;
						}
						.sales {
							font-family: Source Sans Pro;
							font-size: 8.62px;
							font-weight: 400;
							line-height: 11.49px;
							text-align: left;
							color: #8a8a8a;
						}
						.customer {
							display: flex;
							gap: 8.76px;
							.contact {
								width: fit-content;
								background: #f2f5ff;
								padding: 4.38px 5.38px;
								color: #363636;
								border-radius: 4.38px;
								font-family: Source Sans Pro;
								font-size: 10.06px;
								font-weight: 400;
								line-height: 15.8px;
								white-space: nowrap;
								overflow: hidden;
							}
						}
					}
				}

				.second-div {
					display: flex;
					justify-content: space-between;
					margin-top: 85px;

					@keyframes schedule-details-anime {
						0% {
							left: -25%;
						}
						100% {
							left: 0%;
						}
					}
					.schedule-details {
						position: relative;
						animation: schedule-details-anime ease 0.55s;
						height: fit-content;
						padding: 13.57px 11.04px 18.16px 10.39px;
						border-radius: 7.79px;
						border-radius: 7.75px;
						z-index: 1;
						background: #ffffff;

						display: flex;
						flex-direction: column;
						gap: 20px;

						.detail {
							font-family: Source Sans Pro;
							font-size: 12.91px;
							font-weight: 600;
							line-height: 8.8px;
							color: #363636;
						}

						.sessions {
							display: flex;
							gap: 7px;
							align-items: end;
							.repeat-every {
								font-family: Source Sans Pro;
								font-size: 10px;
								font-weight: 400;
								line-height: 16px;
								text-align: left;
								color: #646464;
							}
							.repeat {
								display: flex;
								flex-direction: column;
								gap: 4px;
							}
							.box {
								border: 0.65px solid #eaeaea;
								padding: 2.16px 3.16px;

								display: flex;
								justify-content: space-between;
								font-family: Source Sans Pro;
								font-size: 12px;
								font-weight: 400;
								line-height: 22px;
								text-align: left;
								gap: 4px;
								.time {
									font-family: Source Sans Pro;
									font-size: 12px;
									font-weight: 600;
									line-height: 22px;
									text-align: left;
									color: #363636;
								}

								.month {
									color: #363636;
								}
								.date {
									color: #8a8a8a;
								}

								.dropdown-icon {
									width: 15px;
									display: flex;
								}
								.time {
									.dropdown-icon {
										margin-top: 4px;
									}
								}
							}
							.g-5 {
								gap: 5px;
							}
							.clock {
								display: flex;
								gap: 4px;
								flex-direction: column;
							}
						}
					}

					.reports {
						display: flex;
						flex-direction: column;
						gap: 28px;
						align-items: end;

						@keyframes s-sparkle-anime {
							0% {
								opacity: 1;
							}
							100% {
								opacity: 1;
								rotate: 360deg;
							}
						}

						.s-sparkle {
							animation: s-sparkle-anime ease 0.5s;
							margin-right: 10%;
							width: 25.71px;
							height: 32.55px;
						}

						z-index: 11;

						@keyframes urbanpiper-anime {
							0% {
								left: 25%;
							}
							100% {
								left: 0%;
							}
						}

						.urbanpiper {
							position: relative;
							animation: urbanpiper-anime ease 0.55s;
							padding: 15px 9px 0px 9px;
							border-radius: 5.45px;
							margin-right: -2px;

							background: #ffffff;
							.justify-between {
								display: flex;
								justify-content: space-between;
							}
							.text {
								font-family: Source Sans Pro;
								font-size: 9.55px;
								font-weight: 600;
								line-height: 8.56px;
								text-align: left;
								color: #363636;
								margin-right: 47px;
							}
							.support {
								font-family: Source Sans Pro;
								font-size: 5.96px;
								font-weight: 400;
								line-height: 8.82px;
								text-align: left;
								color: #8a8a8a;
							}
							.time {
								font-family: Source Sans Pro;
								font-size: 4.86px;
								font-weight: 400;
								line-height: 8.82px;
								text-align: left;
								color: #363636;
							}
							.comp {
								gap: 2.43px;
								display: flex;
								img {
									width: 16.35px;
									height: 16.35px;
									padding: 1.36px 0.68px 0.68px 0.68px;

									border-radius: 32.43px;
								}
								.info {
									display: flex;
									flex-direction: column;
									.desc {
										font-family: Source Sans Pro;
										font-size: 4.96px;
										font-weight: 400;
										line-height: 8.82px;
										text-align: left;
										color: #363636;
									}
									.name {
										font-family: Source Sans Pro;
										font-size: 6.58px;
										font-weight: 600;
										line-height: 8.82px;
										text-align: left;
										color: #363636;
									}
								}
							}
							.c-info {
								display: flex;
								flex-direction: column;
								gap: 4px;
								margin-bottom: 7px;
							}
						}
						.comp-info {
							display: flex;
						}

						.all-boxes {
							border: 0.1px solid #eaeaea;
							.boxes {
								background: #f5f5f5;
								display: flex;
								height: 10px;

								.box {
									height: 10px;
								}
								.flex-center {
									display: flex;
									justify-content: center;
									align-items: center;
								}
								.box1 {
									flex-basis: 5%;
									background: #ffffff;
									.box {
										background: #dbdbdb;
										width: 70%;
										height: 40%;
									}
								}
								.box2 {
									background: #ffffff;
									flex-basis: 25%;
									.box {
										background: #dbdbdb;
										width: 70%;
										height: 40%;
									}
								}
								.box3 {
									background: #ffffff;
									flex-basis: 60%;
									.box {
										background: #dbdbdb;
										width: 20%;
										height: 40%;
									}
								}
								.box4 {
									background: #ffffff;
									flex-basis: 10%;
									.box {
										width: 70%;
										height: 40%;
										background: #dbdbdb;
									}
								}
							}
							.zero {
								.index-zero {
									background: #f5f5f5;
									.box {
										height: 56%;
									}
								}
							}
						}
					}
				}
			}
		}

		.factors {
			display: flex;
			padding: 40px;
			gap: 40px;
			.factor {
				flex-basis: 25%;
				display: flex;
				flex-direction: column;
				padding: 14px;
				border: 1px solid #eaeaea;
				gap: 4px;
				.factor-img {
					height: 28px;
					width: 28px;
				}
				.title {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 600;
					line-height: 22px;
					text-align: left;
					color: #363636;
				}
				.desc {
					font-family: Source Sans Pro;
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
					text-align: left;
					color: #646464;
				}
			}
		}
	}
	.report-download {
		.form-sidebar-container {
			.title {
				text-transform: capitalize;
			}
		}
		.filters-container {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 40px;
			padding: 15px 0;
			.dropdown-date-time-filter {
				width: 47%;
				.dropdown-custom {
					width: 100%;
				}
			}
			.select-filter-custom {
				width: 47%;
			}
		}
		.status-info-wrapper {
			margin-top: 25px;
		}
		.delays-banner {
			.b-heading {
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 600;
				line-height: 22px;
				text-align: left;
				color: #363636;
			}
			.b-description {
				color: #646464;
				font-family: Source Sans Pro;
				font-size: 12px;
				font-weight: 400;
				line-height: 16px;
				text-align: left;
			}
			.text-h4 {
				margin-top: 0;
			}
		}
	}
	.share-on-email {
		.form-sidebar-container {
			.header {
				border-bottom: 1px solid $border-color;
				.title {
					margin-bottom: 0;
				}
			}
		}
		.filters-container {
			padding: 25px;
			padding-bottom: 28px;
			border-bottom: 1px solid $border-color;
			.at-input-container {
				width: 100%;
			}
			.tags-input-container {
				.react-tagsinput-input {
					width: 95%;
					box-sizing: border-box;
				}
				.react-tagsinput-tag {
					color: $color-neutral-black-60;
					background-color: $color-neutral-black-05;
					border: 1px solid $color-neutral-black-05;
					border-radius: $default-radius;
					font-family: "Source Sans Pro", sans-serif;
					font-weight: 600;
					.react-tagsinput-remove {
						position: relative;
						right: -5px;
						margin: 0;
						padding: 5px 7px 5px 2px;
						&:hover {
							background-color: transparent;
						}
						&::before {
							content: "×";
							display: inline-flex;
							align-items: center;
							justify-content: center;
							width: 14px;
							height: 14px;
							font-weight: 400;
							border-radius: 50%;
							color: $color-neutral-black-05;
							background-color: $color-black-40;
						}
					}
				}
			}
		}
		.choose-format {
			padding: 25px;
			.title {
				color: $color-neutral-black-60;
				font-size: $very-small-font-size;
				text-transform: uppercase;
				margin-bottom: 15px;
				@extend .font--semibold;
			}
			.export-types {
				display: flex;
				align-items: center;
				gap: 25px;
				.type {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 90px;
					height: 90px;
					border: 1px solid $border-color;
					border-radius: $border-radius-default;
					cursor: pointer;
					&.selected {
						border: 1px solid $color-blue-40;
					}
					.at-radiobutton {
						width: 20px;
						height: 20px;
						padding: 0;
						z-index: 10;
						position: absolute;
						top: 10px;
						right: 10px;
						.checkmark {
							width: 18px;
							height: 18px;
							border-radius: 50%;
							background-color: rgba($color: $mc-1, $alpha: 0.5);
							border: 2px solid $border-color;
							&::after {
								top: 2px;
								left: 2px;
								width: 10px;
								height: 10px;
							}
						}
						input:checked ~ .checkmark {
							background-color: $mc-1;
							border: 2px solid $color-blue;
						}
					}
					.icon {
						width: 40px;
						height: 47px;
						svg {
							width: 40px;
							height: 47px;
						}
					}
				}
			}
		}
	}
	.report-preview {
		.at-breadcrumbs-container {
			padding: 0 25px;
			padding-top: 15px;
		}
		.settings-header {
			.header-text {
				text-transform: capitalize;
			}
		}
		.status-info-wrapper {
			margin: 15px 25px;
			margin-bottom: 30px;
		}
		.filters-wrapper {
			margin-top: 20px;
			padding: 0 25px;
		}
		.report-preview-table-container {
			overflow-x: auto;
			margin: 30px 25px;
			.table-header {
				top: 0;
			}
			.table-list {
				.primary {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			.table-cell {
				&.align-right {
					display: flex;
					justify-content: flex-end;
				}
				&:first-child {
					padding-left: 25px;
				}
				&:last-child {
					padding-right: 25px;
				}
			}
		}
	}
	.schedule-report-create,
	.schedule-report-detail {
		.progress-bar-display {
			position: sticky;
			top: 0;
			z-index: 50;
		}
		.section-header-container {
			display: flex;
			align-items: baseline;
			justify-content: space-between;
			margin-top: 25px;
			margin-bottom: 15px;
			.section-title {
				color: $color-neutral-black-90;
				font-size: $medium-large-font-size;
				@extend .font--semibold;
			}
			.section-description {
				color: $color-neutral-black-60;
				font-size: $very-small-font-size;
				margin-top: 10px;
			}
			.search-filter {
				width: 250px;
				margin: 0;
				.at-input-container {
					width: 100%;
				}
			}
		}
		.note {
			color: $color-neutral-black-80;
			font-size: $very-small-font-size;
			margin-bottom: 35px;
			@extend .font--semibold;
		}
		.sub-title {
			color: $color-neutral-black-50;
			font-size: $small-font-size;
			margin-bottom: 16px;
			@extend .font--semibold;
		}
		.reports-overview {
			margin-top: 25px;
			.group-container {
				margin-bottom: 30px;
				.group-header {
					display: flex;
					flex-direction: column;
					gap: 10px;
					margin-bottom: 15px;
					.group-name {
						display: flex;
						align-items: center;
						gap: 8px;
						font-size: $medium-font-size;
						color: $color-neutral-black-80;
						@extend .font--semibold;
					}
					.group-description {
						font-size: $very-small-font-size;
						color: $color-neutral-black-60;
					}
				}
				.reports-list {
					border: 1px solid $border-color;
					border-radius: $border-radius-default;
					.report {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 15px;
						padding: 16px;
						border-bottom: 1px solid $border-color;
						cursor: pointer;
						&:last-child {
							border-bottom: none;
						}
						&.clickable {
							&:hover {
								background-color: #f5f5f5;
							}
						}
						.detail {
							display: flex;
							flex-direction: column;
							gap: 6px;
							width: 90%;
							a,
							.hyperlink {
								width: fit-content;
							}
							.name {
								font-size: $very-small-font-size;
								color: $color-neutral-black-80;
								text-transform: capitalize;
								@extend .font--semibold;
							}
							.description {
								font-size: $very-small-font-size;
								color: $color-neutral-black-60;
							}
							.highlight {
								background-color: #f7da85;
							}
						}
					}
				}
			}
		}
		.select-report-filters,
		.setup-schedule {
			.section-header-container {
				margin-bottom: 30px;
			}
			.filters-container {
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;
				gap: 40px;
				padding: 15px 0;
				margin-bottom: 30px;
				.dropdown-date-time-filter {
					width: 47%;
					.dropdown-custom {
						width: 100%;
					}
				}
				.select-filter-custom,
				.multi-select-filter-container,
				.at-input-container {
					width: 47%;
				}
				.tags-input-container {
					.react-tagsinput-input {
						width: 95%;
						box-sizing: border-box;
					}
					.react-tagsinput-tag {
						color: $color-neutral-black-60;
						background-color: $color-neutral-black-05;
						border: 1px solid $color-neutral-black-05;
						border-radius: $default-radius;
						font-family: "Source Sans Pro", sans-serif;
						font-weight: 600;
						.react-tagsinput-remove {
							position: relative;
							right: -5px;
							margin: 0;
							padding: 5px 7px 5px 2px;
							&:hover {
								background-color: transparent;
							}
							&::before {
								content: "×";
								display: inline-flex;
								align-items: center;
								justify-content: center;
								width: 14px;
								height: 14px;
								font-weight: 400;
								border-radius: 50%;
								color: $color-neutral-black-05;
								background-color: $color-black-40;
							}
						}
					}
				}
			}
		}
	}
	.schedule-report-history {
		.at-breadcrumbs-container {
			padding: 0 25px;
			padding-top: 15px;
		}
		.settings-header {
			.header-text {
				text-transform: capitalize;
			}
		}
		.placeholder-main {
			padding: 50px 0;
			img {
				width: 260px;
				height: 200px;
			}
			.placeholder--text {
				font-size: $medium-font-size;
				color: $color-neutral-black-90;
			}
			.placeholder--sub-text {
				font-size: $very-small-font-size;
				margin-top: 12px;
			}
		}
		.schedule-report-history-table-container {
			margin: 15px 25px;
			margin-bottom: 0;
			.table-list {
				background-color: $mc-1;
				.table-cell {
					color: $color-neutral-black-80;
				}
				.filters-applied {
					.filter {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					span {
						color: $color-neutral-black-60;
						margin-right: 5px;
					}
				}
				.recipient {
					.email {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					span {
						color: $color-neutral-black-50;
						margin-right: 5px;
					}
				}
				.popover-wrapper-container {
					width: fit-content;
					&.down-right {
						.popover-container {
							top: 28px;
							left: auto;
							right: -12px;
							width: max-content;
							max-width: 350px;
							z-index: 60;
							box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
							&::before {
								right: 20px;
								z-index: -10;
							}
							&::after {
								right: 16px;
								display: none;
							}
						}
					}
					.popover-container {
						.filters-applied-popover,
						.emails-popover {
							background-color: $mc-1;
							border-radius: $default-radius;
							box-sizing: border-box;
							font-size: $very-small-font-size;
							color: $color-neutral-black-dark;
							line-height: 1.2;
							z-index: 10;
							.header {
								padding: 8px 12px;
								font-size: $extra-small-font-size;
								color: $color-dark-grey;
								text-transform: uppercase;
								border-bottom: 1px solid $border-color;
								@extend .font--semibold;
							}
							.filters-list,
							.emails-list {
								max-height: 250px;
								overflow-y: auto;
								.filter-row,
								.email-row {
									display: flex;
									flex-direction: column;
									gap: 5px;
									padding: 8px 12px;
									border-bottom: 1px solid $border-color;
									font-size: $very-small-font-size;
									.name {
										color: $color-neutral-black-90;
										@extend .font--semibold;
									}
									.value {
										color: $color-neutral-black-60;
									}
									&:last-child {
										border-bottom: none;
									}
								}
							}
						}
					}
				}
			}
			.created-date {
				width: 15%;
				.user {
					color: $color-neutral-black-50;
					font-size: $extra-small-font-size;
					margin-top: 8px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			.scheduled-for {
				width: 12%;
			}
			.data-period {
				width: 13%;
			}
			.filters-applied {
				width: 25%;
				display: flex;
				flex-direction: column;
				gap: 6px;
				.more {
					cursor: pointer;
					@extend .font--semibold;
				}
			}
			.recipient {
				width: 15%;
				display: flex;
				flex-direction: column;
				gap: 6px;
				.more {
					cursor: pointer;
					@extend .font--semibold;
				}
			}
			.size {
				width: 10%;
			}
			.access-report {
				width: 10%;
				padding-right: 25px;
				display: flex;
				justify-content: flex-end;
				.download {
					display: flex;
					height: fit-content;
					align-items: center;
					gap: 10px;
					cursor: pointer;
					@extend .font--semibold;
				}
			}
		}
	}
}
