.meraki-web-section {
	.header-action-container {
		display: flex;
		.help-btn-container {
			margin-right: 10px;
			svg {
				// top: 3px;
			}
		}
	}
	.publish-changelog-container {
		margin: 0 25px;
	}
	.meraki-web-container {
		.at-top-bar-wrapper-v2 {
			z-index: 100;
		}
	}
	.workspace-container {
		display: flex;
		justify-content: space-between;
		padding: 25px;
		.workspace-theme {
			width: 60%;
			box-shadow: $default-shadow;
			padding: 25px;
			.theme-text {
				display: flex;
				justify-content: space-between;
			}
			.theme-name {
				font-weight: 600;
				font-size: $medium-font-size;
				text-transform: capitalize;
				margin-bottom: 5px;
			}
			.theme-subtext {
				color: $mc-6;
				margin-bottom: 20px;
			}
			.theme-feature-image {
				width: 100%;
				object-fit: cover;
				border-radius: $default-radius;
				border: 1px solid $border-color;
			}
		}
		.workspace-info {
			width: 30%;
			padding-top: 25px;
			.welcome-msg {
				font-weight: 600;
				margin-bottom: 20px;
				font-size: $medium-large-font-size;
			}
			.welcome-msg-subtext {
				color: $mc-6;
				margin-bottom: 20px;
			}
			.release-status {
				font-size: 12px;
				border: 1px solid $border-color-dark;
				border-radius: $default-radius;
				padding: 5px 10px;
				display: inline-block;
				margin-bottom: 10px;
				font-weight: bold;
				&.Published {
					background-color: $color-green;
					color: white;
					border-color: $color-green;
				}
			}
			.current-version {
				font-weight: 600;
				margin-bottom: 20px;
				font-size: $medium-large-font-size;
			}
			.published-by {
				color: $mc-6;
				margin-bottom: 20px;
				font-size: 12px;
			}
			.unpublished-count {
				font-weight: light;
				font-size: 48px;
				margin-bottom: 10px;
			}
			.unpublished-count-label {
				color: $mc-7;
				margin-bottom: 20px;
			}
			.store-url a {
				text-decoration: none;
				color: $color-blue-light;
				font-size: $very-small-font-size;
				font-weight: 600;
				cursor: pointer;
				display: flex;
				align-items: center;
				transition: 180ms;
				&:hover {
					color: $color-blue;
				}
				img {
					margin-right: 10px;
					width: 20px;
				}
			}
			.workspace-buttons {
				margin-bottom: 20px;
				.btn-start-building {
					margin-right: 10px;
				}
			}
			.get-help {
				display: flex;
				align-items: center;
				text-decoration: none;
				img {
					width: 15px;
					margin-right: 5px;
				}
				span {
					font-size: $very-small-font-size;
				}
			}
		}
		.status-tag {
			border-radius: $moderate-border-radius;
			padding: 4px 12px;
			font-size: $extra-small-font-size;
			color: $mc-1;
		}
		.inprogress-tag {
			background-color: $color-orange-light;
		}
		.saved-tag {
			background-color: $color-orange-light;
		}
		.published-tag {
			background-color: $color-green-light;
		}
		.failed-tag {
			background-color: $color-error;
		}
		.mockup-container {
			min-height: 330px;
			margin-top: 20px;
			@media only screen and (min-width: 2000px) {
				min-height: 650px;
			}
		}
		.image-desktop-top {
			position: absolute;
			width: 100%;
			z-index: 1;
			bottom: 0;
			left: 0;
			right: 0;
			aspect-ratio: 16/9;
		}
		.image-desktop-bottom {
			position: absolute;
			width: 95%;
			bottom: 0;
			z-index: 2;
			aspect-ratio: 16/9;
		}
		.image-mobile-top {
			position: absolute;
			width: 100%;
			z-index: 1;
			bottom: 0;
			left: 0;
			right: 0;
			aspect-ratio: 9/16;
		}
		.image-mobile-bottom {
			position: absolute;
			width: 90%;
			bottom: 0;
			z-index: 2;
			aspect-ratio: 9/16;
		}
		.publish-info {
			background-color: $warning-light;
			span {
				color: $color-yellow-dark;
			}
		}
	}
	.pages-container {
		.form-content {
			padding: 25px;
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
				}
			}
		}
		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
		.select-page-text {
			font-weight: 600;
			margin-bottom: 15px;
		}
		.select-page-dropdown {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;
			min-height: 50px;
			.at-switch-cnt {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.meta-info {
					margin-right: 10px;
				}
			}
			.page-selector-container {
				margin-bottom: 0;
				.dropdown-custom {
					.dropdown-arrow {
						position: absolute;
						right: 10px;
					}
				}
			}
		}
		.multi-select-filter-container {
			width: 250px;
			margin-bottom: 20px;
			.at--dropdown {
				font-size: $very-small-font-size;
			}
		}
		.page-details {
			.section-highlight {
				background-color: #f8f8f8;
				padding: 10px 25px;
				margin: 0 -25px;
				margin-bottom: 20px;
				font-size: 12px;
				font-weight: 600;
				color: $mc-6;
			}
			.page-settings-container {
				border: 1px solid $border-color;
				border-radius: $default-radius;
				width: 60%;
				margin-bottom: 20px;
				.page-setting-row {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 12px;
					&:hover {
						background-color: rgba(47, 88, 242, 0.05);
					}
					&:nth-of-type(odd) {
						background-color: #f3f5f8;
						&:hover {
							background-color: rgba(47, 88, 242, 0.05);
						}
					}
					.setting-label {
						font-weight: 600;
						font-size: $very-small-font-size;
						margin-bottom: 5px;
					}
					.setting-desc {
						font-size: 12px;
						color: $mc-6;
					}
					.at-switch-cnt {
						margin-top: -15px;
					}
				}
			}
			.page-seo-input {
				width: 60%;
				.at-input-container {
					margin-bottom: 15px;
				}
			}
		}
	}
	.labels-container {
		.form-content {
			padding: 25px;
		}
		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
		.label-filters-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 35px;
			.at-input-container {
				width: 30%;
				.at-input-label {
					text-transform: capitalize;
				}
				input::placeholder {
					font-size: 12px;
				}
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				margin-bottom: 0;
				.at--dropdown {
					font-size: $very-small-font-size;
				}
				.meta-info {
					font-weight: normal;
					color: $mc-6;
					margin-top: 3px;
					margin-bottom: 0;
					padding-bottom: 5px;
				}
			}
		}
		.labels-list-table-container {
			.transaction-rows {
				min-height: initial;
				.page {
					text-transform: capitalize;
				}
			}
			.page {
				width: 30%;
				padding-left: 25px;
			}
			.label {
				width: 30%;
				text-align: left;
			}
			.language {
				width: 30%;
			}
		}
	}
	.versions-container {
		.latest-version-status {
			padding: 25px;
			background-color: $color-blue;
			color: white;
			margin: 25px;
			border-radius: $default-radius;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.version-info {
				width: 60%;
				.version-no {
					font-weight: 600;
					font-size: $large-font-size;
					margin-bottom: 10px;
				}
				.version-date {
					font-size: $very-small-font-size;
				}
			}
			.unpublished-info {
				.unpublished-count {
					font-weight: 600;
					font-size: $large-font-size;
					margin-bottom: 10px;
					text-align: center;
				}
			}
		}
		.versions-list-table-container {
			.transaction-rows {
				min-height: initial;
			}
			.version {
				width: 10%;
				padding-left: 25px;
			}
			.comment {
				width: 20%;
				text-align: left;
			}
			.theme {
				width: 10%;
				padding-left: 25px;
			}
			.publishedOn {
				width: 20%;
			}
			.publishedBy {
				width: 20%;
				padding-right: 25px;
			}
			.action {
				width: 20%;
				text-align: right;
				padding-right: 25px;
				.action-item {
					color: $color-blue-light;
					margin-left: 10px;
					font-size: 12px;
					font-weight: bold;
					cursor: pointer;
					transition: 180ms;
					&:hover {
						color: $color-blue;
					}
				}
			}
		}
	}
	.domains-container {
		.open {
			width: 150px;
			height: 250px;
		}

		.status-container {
			margin: 0 24px;

			.status-alert-container {
				margin-bottom: 0px;

				.main-error-header {
					font-size: 14px;
				}
			}
		}

		.domain-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 24px 25px 5px 25px;

			.title {
				font-weight: 600;
			}
		}
		.at-modal .modal-container .modal-footer {
			border-top: 1px solid #eaeaea;
		}
		.domains-list-table-container {
			.at-table-row.transaction-rows.active {
				position: relative;
				z-index: 1;
			}

			.menu {
				padding-left: 0px !important;
				z-index: 1000 !important;

				.context-menu-item {
					width: auto;
					padding: 10px 25px 10px 15px;
					font-size: 14px;
					white-space: nowrap;
					background-color: #fff;
					transition: 180ms;
					cursor: pointer;
					&:hover {
						background-color: #f5f5f5;
					}
				}
			}

			.transaction-rows {
				min-height: initial;
			}
			.domain {
				width: 50%;
				padding-left: 25px;
				display: flex;

				.domain-internal {
					background: transparent;
					letter-spacing: 1px;
					font-weight: 600 !important;
					display: flex;
					margin-top: 16px;
					text-transform: uppercase;
					color: #8a8a8a;
					font-size: 12px;
				}
			}
			.hyperlink {
				&--black-color {
					color: $color-neutral-black-dark;
					&::after {
						background-color: $color-neutral-black-dark;
					}
				}
			}
			.verified {
				width: 25%;
				text-align: left;
				.domain-verified {
					color: $color-green;
					font-size: 12px;
					font-weight: bold;
					border: 1px solid $color-green;
					padding: 2px 4px;
					border-radius: $default-radius;
				}
				.domain-not-verified {
					font-size: 12px;
					font-weight: bold;
					text-decoration: underline;
					cursor: pointer;
				}
			}
			.action {
				width: 25%;
				text-align: right;
				padding-right: 25px;
			}
		}
		.add-domain-container {
			margin: 0 25px;
		}

		.domain-container {
			padding: 24px;

			.status-alert-container {
				margin-top: -24px;

				.main-error-header {
					font-size: 14px;
				}

				.error-icon {
					width: 50px;
				}
			}

			.domain-status-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 16px;
				border: 1px solid #eaeaea;
				border-radius: 4px;

				.domain-status-left {
					display: flex;
					gap: 12px;

					.domain-status-globe-bg {
						width: 56px;
						height: 56px;
						padding: 12px;
						background-color: #ecf0f7;
						border-radius: 4px;
					}

					.domain-status-title {
						margin: 0;
						line-height: 22px;
						font-size: 16px;
					}

					.domain-status {
						margin-top: 7px;
						span {
							font-size: 12px;
							color: #3b5feb;
							font-weight: 600;
							margin-left: 12px;
						}
					}
				}

				.domain-status-right {
					font-size: 14px;
					line-height: 22px;
				}
			}

			.hosting-service-title {
				font-size: 18px;
				line-height: 24px;
				margin-bottom: 4px;
				margin-top: 32px;
			}

			.hosting-service-desc {
				font-size: 14px;
				line-height: 22px;
			}

			.at-table-row {
				align-items: flex-start;
			}

			.help-articles {
				font-size: 14px;
				display: flex;
				align-items: center;
				gap: 12px;
				margin-top: 16px;

				a {
					font-size: 12px;
					display: flex;
					align-items: center;
					font-weight: 600;
					border-right: 1px solid #d0d0d0;
					cursor: pointer;
					color: #363636;

					img {
						width: 20px;
						height: 20px;
						margin: 0 12px 0 6px;
					}
				}
			}

			.aRecords-list-table-container {
				.record-type,
				.record-name {
					width: 16%;
				}

				.record-action {
					width: 10%;
				}

				.record-value {
					width: 50%;

					.error-message {
						font-size: 12px;
						color: #b02b2b;
						line-height: 16px;
					}
				}

				.record-value.missing-record {
					div {
						margin-bottom: 8px;
					}
				}
			}
		}
	}
	.apps-container {
		.form-content {
			padding: 25px;
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
				}
			}
		}
		.form-row {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;
			@include bp(768px) {
				flex-direction: column;
				margin-bottom: 0;
			}
			&.row-full > div {
				width: 100%;
				@include bp(768px) {
					margin-bottom: 30px;
				}
			}
			&.row-half > div {
				width: 47%;
				@include bp(768px) {
					width: auto;
					margin-bottom: 30px;
				}
			}
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
				}
				&.filter-section-item {
					opacity: 1;
					margin-bottom: 0;
					@include bp(768px) {
						margin-bottom: 30px;
					}
					.meta-info {
						font-weight: 400;
						margin-bottom: 0;
						padding-bottom: 5px;
					}
				}
			}
		}
		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
		.card-container {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
			margin-bottom: 25px;
			&:nth-last-of-type(1) {
				margin-bottom: 10px;
			}
			&.loading {
				opacity: 0.6;
				pointer-events: none;
			}
			.card-header {
				padding: 15px 25px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				@include bp(768px) {
					flex-direction: column;
					align-items: flex-start;
				}
				.header-container {
					display: flex;
					align-items: center;
					.app-icon {
						img {
							height: 40px;
						}
					}
					.app-header {
						display: flex;
						flex-direction: column;
						margin-left: 10px;
						flex-wrap: wrap;
						.header-text {
							font-size: $small-font-size;
							@extend .font--semibold;
						}
						.header-subtext {
							color: $mc-5;
							font-size: $very-small-font-size;
							padding-top: 5px;
						}
					}
				}
				.at-switch-cnt,
				.multi-select-filter-container {
					display: flex;
					align-items: center;
					padding-left: 100px;
					.at--dropdown {
						width: 230px;
					}
					.meta-info {
						margin-right: 10px;
					}
					.at-switch {
						.switch-rail {
							margin: 2px 0;
						}
						.switch-ball {
							top: 4px;
						}
					}
					.validation-message {
						margin: 0 0 0 10px;
					}
					@include bp(768px) {
						padding-left: 0px;
						margin-top: 25px;
					}
				}
				.actions {
					display: flex;
					align-items: center;
					.at-switch-cnt,
					.multi-select-filter-container {
						padding-left: 30px;
						@include bp(768px) {
							.at--dropdown {
								width: 100%;
							}
							width: 100%;
							padding-left: 0;
						}
					}
					@include bp(768px) {
						width: 100%;
						flex-direction: column;
						margin-top: 5px;
					}
				}
			}
			.form-container {
				padding: 15px 25px 20px 25px;
				border-top: 1px solid $border-color;
				.form-row {
					justify-content: flex-start;
					max-width: 950px;
					@include bp(768px) {
						max-width: 100%;
						flex-direction: column;
					}
					&:nth-last-child(1) {
						margin-bottom: 5px;
					}
					&.row-full > div {
						width: 64%;
						@include bp(768px) {
							width: auto;
							margin-bottom: 30px;
							&:last-child {
								margin: 0;
							}
						}
					}
					&.row-half > div {
						width: 30%;
						margin-right: 35px;
						@include bp(768px) {
							width: auto;
							margin-bottom: 30px;
							margin-right: 0;
							&:last-child {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
	.settings-container {
		.form-content {
			padding: 25px 25px 0 25px;
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
				}
			}
		}
		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
		.form-row {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 30px;
			max-width: 950px;
			@include bp(768px) {
				max-width: 100%;
				flex-direction: column;
				margin-bottom: 0px;
			}
			&.row-full > div {
				width: 64%;
				@include bp(768px) {
					width: auto;
					margin-bottom: 30px;
				}
			}
			&.row-half > div {
				width: 30%;
				margin-right: 40px;
				&:nth-last-child(1) {
					margin-right: 0px;
				}
				@include bp(768px) {
					width: auto;
					margin-bottom: 30px;
					margin-right: 0;
				}
			}
		}
		.meta-info {
			font-weight: normal;
		}
	}
	.raw-config-container {
		.form-content {
			padding: 25px;
		}
		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
	}
}

.page-selector-container {
	margin-bottom: 20px;
	.dropdown-custom {
		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 7px 8px;
			box-sizing: border-box;
			font-size: $very-small-font-size;
			.dropdown-arrow {
				position: relative;
			}
		}
		.dropdown-component {
			left: 0;
			right: initial;
			width: 250px;
			&:before {
				content: initial;
			}
		}
	}
	.pages-list-container {
		max-height: 400px;
		overflow-y: auto;
		.page-group-title {
			font-weight: bold;
			color: $mc-7;
			padding: 10px 15px;
			font-size: 12px;
			background-color: #f8f8f8;
		}
		.page-group-item {
			padding: 10px;
			font-size: $very-small-font-size;
			cursor: pointer;
			display: flex;
			align-items: center;
			img {
				width: 25px;
				margin-right: 10px;
			}
			&:hover {
				background-color: $mc-2;
			}
			&[data-selected="true"] {
				color: $color-blue;
			}
			&[data-selected="false"] {
				img {
					filter: grayscale(1);
					opacity: 0.8;
				}
			}
		}
		.create-page-btn {
			padding: 15px;
			color: $color-blue;
			font-size: $very-small-font-size;
			cursor: pointer;
		}
	}
}

.meraki-web-themes-section {
	.theme-selector-container {
		margin: 0 24px;
		padding: 24px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
		border-radius: $default-radius;
		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.header-text {
				flex: 1;
				.title {
					font-weight: 600;
					font-size: $medium-font-size;
					margin-bottom: 5px;
				}
				.subtext {
					color: $mc-6;
					margin-bottom: 20px;
				}
			}
			.header-img {
				width: 125px;
			}
		}
		.themes-grid-container {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.theme-item {
				width: 30%;
				margin-bottom: 20px;
				box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
				border-radius: $default-radius;
				background-color: #fbfcfc;
				padding: 15px;
				.header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 15px;
					.name {
						font-weight: 600;
						text-transform: capitalize;
					}
					.view-link {
						font-size: 12px;
						color: $color-blue;
						text-decoration: none;
					}
				}
				.feature-img {
					width: 100%;
					object-fit: cover;
					border-radius: $default-radius;
					border: 1px solid $border-color;
					margin-bottom: 15px;
				}
			}
		}
		.mockup-container {
			min-height: 250px;
			@media only screen and (min-width: 2000px) {
				min-height: 500px;
			}
		}
		.image-desktop-top {
			position: absolute;
			width: 100%;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			aspect-ratio: 16/9;
		}
		.feature-image-bottom {
			position: absolute;
			width: 72%;
			z-index: 2;
			margin-left: 1%;
			margin-top: 1%;
			aspect-ratio: 16/11.6;
			transform: rotateX(-20deg) skewX(-0.1deg);
		}
		.image-mobile-top {
			position: absolute;
			width: 100%;
			z-index: 1;
			bottom: 10%;
			left: 0;
			right: 0;
			aspect-ratio: 9/18;
		}
		.feature-image-mobile-bottom {
			position: absolute;
			width: 85%;
			bottom: 10%;
			z-index: 2;
			margin-bottom: 12%;
			aspect-ratio: 9/18;
		}
	}
	.meraki-guide-container {
		margin: 25px;
		padding: 20px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
		border-radius: $default-radius;
		.title {
			font-weight: 600;
			font-size: $medium-font-size;
			margin-bottom: 5px;
		}
		.subtext {
			color: $mc-6;
			margin-bottom: 20px;
		}
	}
}

.meraki-web-theme-details-container {
	.form-sidebar-container .header .title {
		text-transform: capitalize;
	}
	.theme-image-gallery {
		white-space: nowrap;
		overflow-y: auto;
		&::-webkit-scrollbar {
			display: none;
		}
		.gallery-item {
			margin-right: 25px;
			margin-bottom: 25px;
			border-radius: $default-radius;
			border: 1px solid $border-color;
			width: 60%;
			object-fit: cover;
		}
	}
	.theme-info {
		color: $mc-7;
		font-size: $very-small-font-size;
		.theme-description {
			margin-bottom: 15px;
		}
	}
}

.meraki-web-locked {
	padding: 25px;
	.meraki-web-info-container {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		.intro-container {
			width: 45%;
			padding: 0 25px;
			.header {
				font-size: $large-font-size;
				margin-bottom: 25px;
				@extend .font--semibold;
				img {
					position: relative;
					top: 3px;
					width: 26px;
					margin-right: 5px;
					object-fit: cover;
				}
			}
			.description {
				color: $mc-7;
				margin-bottom: 25px;
			}
			.actions {
				.subscribe {
					margin-right: 10px;
				}
			}
		}
		.meraki-frodo-demo-container {
			width: 55%;
			padding: 0 25px;
			img {
				width: 100%;
				min-width: 400px;
				max-width: 700px;
				object-fit: cover;
			}
		}
		@include bp(1070px) {
			flex-direction: column;
			align-items: flex-start;
			.intro-container {
				width: 100%;
				margin-bottom: 25px;
				padding: 0;
			}
			.meraki-frodo-demo-container {
				display: flex;
				align-items: center;
				width: 100%;
				padding: 0;
				img {
					width: 80%;
					margin: 15px auto;
					min-width: 300px;
					object-fit: cover;
				}
			}
		}
	}
	.meraki-web-features-container {
		display: flex;
		padding: 25px 0;
		.feature {
			width: 50%;
			display: flex;
			padding: 0 25px;
			img {
				height: fit-content;
				margin-right: 10px;
				padding: 10px;
				border-radius: $default-radius;
				background-color: $color-blue;
			}
			.info {
				.header {
					font-size: $medium-font-size;
					margin-bottom: 10px;
					@extend .font--semibold;
				}
				.description {
					color: $mc-7;
				}
			}
		}
		@include bp(1070px) {
			flex-direction: column;
			padding: 0;
			.feature {
				margin: 0 0 30px 0;
				padding: 0;
				width: 100%;
			}
		}
	}
}

.primary-modal,
.delete-modal {
	.modal-header {
		padding: 16px 24px !important;
	}

	.title {
		text-align: left;
		font-size: 18px !important;
	}

	.primary-modal-content {
		padding: 25px;
		border: 1px solid #f0f0f0;
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 12px 16px !important;

		.at-btn {
			width: fit-content;
		}
	}
}

.link {
	color: #3b5feb;
	cursor: pointer;
	font-weight: 600;
}

.remove-btn {
	color: #d64949 !important;
	border-color: #d64949 !important;
}

.btn-disabled {
	cursor: not-allowed !important;

	&:hover {
		filter: brightness(0.95);
	}
}

#observer-container {
	height: 83vh;
	overflow-y: scroll;
	overflow-x: hidden;
}

.hero-section-container {
	display: flex;
	justify-content: flex-end;
	background: linear-gradient(180deg, rgb(227, 244, 255) 0%, rgb(222, 228, 255) 100%);
	height: 93vh;

	.hero-wrapper {
		width: 94%;
		display: flex;
		justify-content: space-between;
		position: relative;

		.hero-section-left-sub {
			width: 40%;
			position: absolute;
			left: -50%;
			top: 15%;

			.title {
				padding: 5px 20px 6px 14px;
				background: linear-gradient(90deg, rgba(135, 69, 220, 1) 0%, rgba(35, 65, 235, 1) 100%);
				width: fit-content;
				color: white;
				font-weight: 400;
				border-radius: 2px;
				font-size: 14px;
			}

			.online-ordering {
				width: 28rem;
			}

			p {
				font-weight: 400;
				font-size: 18px;
				color: #646464;
				line-height: 26px;
				margin-bottom: 32px;
				width: 27rem;
			}
		}

		.hero-section-right-sub {
			width: 40%;
			position: absolute;
			right: -60%;
			top: 12%;

			.images-container {
				position: relative;

				.image-one {
					position: relative;
					border-top-left-radius: 8px;
					border-bottom-left-radius: 8px;
					min-width: 100%;
				}

				.image-two-container {
					position: absolute;
					top: 6%;
					width: 46%;
					left: -22%;

					.image-two {
						min-width: 100%;
						border-radius: 8px;
					}
				}
			}
		}
	}

	.animate-complete {
		.hero-section-right-sub {
			right: 0%;
			transition: all linear 0.5s;
		}
		.hero-section-left-sub {
			left: 0%;
			transition: all linear 0.5s;
		}
	}
}

.benefits-section-container {
	padding: 4rem;
	height: 93vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.benefits-sub-container {
		transition: all linear 0.5s;
		opacity: 0;
		width: 95%;
		.benefits-wrapper {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 1.1875rem;
			margin: 60px 0;

			.benefit-card {
				border: 1px solid #bbc3e4;
				padding: 1rem;
				border-radius: 4px;

				h5 {
					font-size: 14px;
					font-weight: 600;
					margin: 12px 0 6px 0;
					line-height: 22px;
				}

				p {
					font-size: 14px;
					font-weight: 400;
					color: #646464;
					line-height: 22px;
					width: 17rem;
				}
			}
		}
	}
}

.animate-complete {
	.benefits-sub-container {
		opacity: 1 !important;
	}
}

.branding-section-container {
	padding: 4rem;
	display: flex;
	justify-content: center;
	height: 93vh;
	background-color: #f9f9f9;

	.branding-sub-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 95%;

		h1 {
			display: flex;
			gap: 0.5rem;
		}

		.meraki-features {
			max-height: 40px;
			overflow: hidden;
			position: relative;
			width: 100%;

			.meraki-features-scroll {
				display: flex;
				flex-direction: column;
				position: absolute;
				gap: 1rem;
				animation: 4s linear 0s infinite normal none running feature-scroll;

				span:nth-of-type(3n-1) {
					color: #793775;
					font-weight: 700;
				}

				span:nth-of-type(3n) {
					color: #086a1d;
					font-weight: 700;
				}

				@keyframes feature-scroll {
					0%,
					10% {
						top: 0;
					}
					20%,
					30% {
						top: -57px;
					}
					40%,
					50% {
						top: -114px;
					}
					60%,
					70% {
						top: -171px;
					}
					80%,
					90% {
						top: -228px;
					}
					100% {
						top: -285px;
					}
				}
			}
		}

		.branding-section-left-sub {
			width: 50%;
			display: flex;
			position: relative;
			align-items: center;

			.left-sub-wrapper {
				position: absolute;
				width: 100%;
				left: -100%;
				transition: all linear 0.5s;
			}

			.animation-complete {
				left: 0;
			}

			.branding-description {
				width: 24rem;
				line-height: 22px;
				margin-bottom: 2rem;
				color: #363636;
				font-weight: 400;
			}
		}

		.branding-section-right-sub {
			background-color: #ecf0f7;
			width: 50%;
			height: 85%;
			border-radius: 4px;
			opacity: 0;
			transition: all linear 1s;
			overflow: hidden;

			.branding-img-background {
				position: relative;
				padding: 2.375rem 1.75rem;
				width: 100%;
				height: 100%;

				.image-one,
				.image-two {
					position: absolute;
				}

				.image-one {
					transition: all linear 1s;
					width: 90%;
					right: 6%;
					top: 105%;

					&.animation-complete {
						top: 5%;
					}
				}

				.image-two {
					width: 41%;
					right: -1%;
					opacity: 0;

					&.animation-complete {
						top: 25.5%;
						animation: slide-in-delay 2s cubic-bezier(0.18, 0.89, 0.32, 1.28) 1s 1 forwards;
					}

					@keyframes slide-in-delay {
						from {
							top: 105%;
						}

						to {
							top: 23%;
							opacity: 1;
						}
					}
				}
			}
		}

		.branding-section-right-sub.animation-complete {
			opacity: 1;
		}
	}
}

.marketing-section-container {
	padding: 4rem;
	display: flex;
	justify-content: center;
	height: 93vh;

	.marketing-sub-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 95%;
		opacity: 0;

		&.animation-complete {
			transition: all linear 1.5s;
			opacity: 1;
		}

		.marketing-section-left-sub,
		.marketing-section-right-sub {
			width: 50%;
		}

		.marketing-section-left-sub {
			position: relative;
			height: 85%;

			.marketing-left-sub-wrapper {
				background-color: #f7edf7;
				border-radius: 4px;
				position: absolute;
				width: 100%;
				height: 100%;
				overflow: hidden;

				img {
					position: absolute;
				}

				img.phone {
					bottom: -90%;
					left: 20%;
					width: 59%;

					&.animation-complete {
						animation: slide-in 1s linear 1s 1 forwards;
					}

					@keyframes slide-in {
						0% {
							bottom: -90%;
						}

						50% {
							bottom: -90%;
						}

						100% {
							bottom: 0%;
						}
					}
				}

				img.message {
					right: 9%;
					top: 39%;
					width: 46%;
					opacity: 0;

					&.animation-complete {
						animation: fade-in 1s linear 4.5s 1 forwards;
					}

					@keyframes fade-in {
						95% {
							opacity: 1;
						}

						100% {
							opacity: 1;
						}
					}
				}

				img.notification {
					bottom: 5%;
					left: 15%;
					width: 70%;
					opacity: 0;

					&.animation-complete {
						animation: fade-in 1s linear 3.5s 1 forwards;
					}

					@keyframes fade-in {
						75% {
							opacity: 1;
						}

						100% {
							opacity: 1;
						}
					}
				}

				img.campain {
					top: 26%;
					left: 9%;
					width: 37%;
					opacity: 0;

					&.animation-complete {
						animation: fade-in 1s linear 2.5s 1 forwards;
					}

					@keyframes fade-in {
						60% {
							opacity: 1;
						}

						100% {
							opacity: 1;
						}
					}
				}
			}
		}

		.marketing-section-right-sub {
			display: flex;
			flex-direction: column;
			align-items: start;
			padding: 11%;
			padding-right: 0;

			h1 {
				max-width: 84%;
				margin-bottom: 0;
			}

			p {
				line-height: 22px;
				font-size: 16px;
				color: #363636;
				margin: 16px 0 32px 0;
				max-width: 84%;
			}
		}
	}
}

.checkout-section-container {
	padding: 4rem;
	display: flex;
	justify-content: center;
	height: 93vh;
	background-color: #f9f9f9;

	.checkout-sub-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 95%;

		.checkout-section-left-sub,
		.checkout-section-right-sub {
			width: 50%;
		}

		.checkout-section-right-sub {
			height: 88%;

			.checkout-right-sub-wrapper {
				position: relative;
				background-color: #ecf7f6;
				width: 100%;
				height: 100%;
				overflow: hidden;

				img {
					position: absolute;
				}

				img.checkout {
					bottom: -2%;
					left: 18%;
					width: 61%;
					opacity: 0;

					&.animation-complete {
						animation: fade-in 1s linear 1.5s 1 forwards;
					}
				}

				img.phone {
					bottom: -100%;
					left: 28%;
					width: 41%;

					&.animation-complete {
						animation: slide-in 1s linear 0.5s 1 forwards;
					}
				}

				img.notification {
					bottom: 18%;
					left: 23%;
					width: 52%;
					opacity: 0;

					&.animation-complete {
						animation: fade-in 1s linear 4s 1 forwards;
					}
				}

				img.scooter {
					left: 2%;
					bottom: 22.5%;
					width: 13%;
					opacity: 0;

					&.animation-complete {
						animation: fade-in 1s linear 4.5s 1 forwards;
					}
				}

				img.razor-pay {
					bottom: 38%;
					right: 10%;
					width: 23%;
					opacity: 0;

					&.animation-complete {
						animation: fade-in 1s linear 2s 1 forwards;
					}
				}

				img.pay-tabs {
					top: 32%;
					right: 13%;
					width: 21%;
					opacity: 0;

					&.animation-complete {
						animation: fade-in 1s linear 2.5s 1 forwards;
					}
				}

				img.stripe {
					top: 16%;
					left: 72%;
					width: 19%;
					opacity: 0;

					&.animation-complete {
						animation: fade-in 1s linear 3s 1 forwards;
					}
				}

				img.wallet {
					top: 3%;
					right: 3%;
					width: 12%;
					opacity: 0;

					&.animation-complete {
						animation: fade-in 1s linear 3.5s 1 forwards;
					}
				}

				img.shadow-fax {
					top: 45%;
					left: 5%;
					width: 25.5%;
					opacity: 0;

					&.animation-complete {
						animation: fade-in 1s linear 5s 1 forwards;
					}
				}

				img.add-logs {
					top: 21%;
					left: 6%;
					width: 23%;
					opacity: 0;

					&.animation-complete {
						animation: fade-in 1s linear 5.5s 1 forwards;
					}
				}

				@keyframes fade-in {
					from {
						opacity: 0;
					}

					to {
						opacity: 1;
					}
				}
			}
		}

		.checkout-section-left-sub {
			padding: 11%;
			padding-left: 0%;
			position: relative;

			.checkout-left-sub-wrapper {
				position: absolute;
				top: -18%;
				opacity: 0;

				h1 {
					max-width: 56%;
					margin-bottom: 0;
				}

				p {
					line-height: 22px;
					font-size: 16px;
					color: #363636;
					margin: 16px 0 32px 0;
					max-width: 67%;
				}

				&.animation-complete {
					animation: fade-slide-in 1s linear 0.5s 1 forwards;
				}

				@keyframes fade-slide-in {
					0% {
						opacity: 0;
						top: -18%;
					}

					50% {
						opacity: 0.5;
						top: -9%;
					}

					100% {
						opacity: 1;
						top: -11%;
					}
				}
			}
		}
	}
}

#observer-container {
	.header {
		font-weight: 600;
		font-size: 36px;

		span,
		span.blue {
			font-weight: 700;
		}

		span.blue {
			color: #2542b9;
		}
	}

	button.demo-btn {
		background-color: #2542b9;
		border-radius: 4px;
		padding-left: 1.9rem;
		padding-right: 1.9rem;
		font-size: 16.24px;
		line-height: 24.37px;
	}
}

@media (min-height: 800px) {
	#observer-container {
		height: 87vh;
	}
}
