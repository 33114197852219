.invoice-section {
	background-color: #fff;
	padding-bottom: 0px;

	.due-date-text {
		padding: 0 25px;
		margin-bottom: 25px;
		font-size: $medium-font-size;
		@extend .font--semibold;
	}

	.details-container {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding: 10px 10px 15px 10px;
		.payment-link {
			display: flex;
			justify-content: flex-end;
			padding: 25px;
		}
	}
	.details {
		display: flex;
		justify-content: space-between;
		width: 40%;
		padding: 10px 15px;
		&.highlight {
			background-color: $color-neutral-black-05;
		}
		.details-names {
			width: 50%;
			font-size: $very-small-font-size;
			color: $color-neutral-black-70;
			.names {
				&:first-child {
					padding-top: 0;
				}
				padding-top: 10px;
			}
			.highlight {
				color: $color-neutral-black-90;
				@extend .font--semibold;
			}
		}
		.details-values {
			text-align: right;
			width: 50%;
			font-size: $very-small-font-size;
			color: $color-neutral-black-70;
			.values {
				&:first-child {
					padding-top: 0;
				}
				padding-top: 10px;
			}
			.highlight {
				color: $color-neutral-black-90;
				@extend .font--semibold;
			}
		}
	}
	.preview-note {
		padding: 0 25px;
		margin-bottom: 15px;
		font-size: $extra-small-font-size;
		color: $color-neutral-black-60;
	}
}

.invoice-detail-view {
	.form-sidebar-container {
		.header {
			border-bottom: 1px solid $divider-color;
		}
		.header-right {
			align-items: baseline;
		}
		.upiper-id {
			font-size: $very-small-font-size;
			color: $color-blue;
			@extend .font--semibold;
		}
	}
}

.invoice-detail-section-header {
	padding: 25px 25px 20px 25px;
	@include bp(768px) {
		padding: 10px;
	}
	.header-text {
		float: left;
		width: 75%;
		@include bp(768px) {
			float: none;
		}
		.title {
			display: flex;
			align-items: center;
			font-size: $large-font-size;
			margin-bottom: 5px;
			@extend .font--semibold;
			img {
				margin-left: 25px;
				max-height: 20px;
			}
			.status {
				margin-left: 15px;
				.chip {
					width: fit-content;
					padding: 5px 10px;
					font-size: $very-small-font-size;
					text-transform: capitalize;
					border-radius: 2px;
					@extend .font--semibold;
					&.paid {
						color: $color-green-70;
						background-color: $color-green-10;
					}
					&.unpaid {
						color: $color-yellow-70;
						background-color: $color-yellow-10;
					}
					&.void {
						color: $color-neutral-black-60;
						background-color: $color-neutral-black-05;
					}
					&.overdue {
						color: $color-red-70;
						background-color: $color-red-1;
					}
					&.partially-paid {
						color: $color-yellow-70;
						background-color: $color-yellow-10;
					}
					&.partially-paid-pending {
						color: $color-yellow-70;
						background-color: $color-yellow-10;
					}
					&.partially-paid-overdue {
						color: $color-red-70;
						background-color: $color-red-1;
					}
					&.awaiting-payment {
						color: $color-yellow-70;
						background-color: $color-yellow-10;
					}
					&.pending {
						color: $color-yellow-70;
						background-color: $color-yellow-10;
					}
					&.expired {
						color: $color-red-70;
						background-color: $color-red-1;
					}
				}
			}
		}
		.invoice-from {
			margin: 5px 0px;
			float: left;
			font-size: $very-small-font-size;
			width: 40%;
			color: $mc-6;
			.payment-link {
				padding-top: 10px;
				color: #2f58f2;
				@extend .font--semibold;
			}
		}
		.invoice-to {
			margin: 5px 50px;
			float: left;
			left: 45%;
			width: 40%;
			font-size: $very-small-font-size;
			color: $mc-6;
		}
	}
	.header-action-button {
		margin-top: 7px;
		float: right;
		.button-container {
			text-align: right;
			padding-top: 20px;
			margin-top: 20px;
			margin-bottom: 20px;
			img {
				margin-right: 30px;
				margin-bottom: 5px;
			}
			.button-title {
				font-size: $very-small-font-size;
				color: $color-blue;
				@extend .font--semibold;
				display: inline-block;
				vertical-align: top;
			}
			.loader {
				margin-left: 90px;
				margin-bottom: 7px;
				border: 2px solid #f3f3f3;
				border-radius: 50%;
				border-top: 2px solid #2f58f2;
				width: 20px;
				height: 20px;
				-webkit-animation: spin 2s linear infinite; /* Safari */
				animation: spin 2s linear infinite;
			}

			/* Safari */
			@-webkit-keyframes spin {
				0% {
					-webkit-transform: rotate(0deg);
				}
				100% {
					-webkit-transform: rotate(360deg);
				}
			}

			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		}
		.invoice-date-text {
			font-size: $very-small-font-size;
			color: $color-neutral-black-80;
			.invoice-date {
				margin-left: 2px;
				@extend .font--semibold;
			}
		}
		@include bp(768px) {
			float: none;
		}
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.invoice-detail-section-footer {
	overflow: auto;
	background-color: $mc-2;
	@include bp(768px) {
		padding: 10px;
	}
	.history {
		width: 60%;
		padding-top: 15px;
	}
}

.invoice-details-table {
	.header-row {
		border-top: 1px solid $divider-color;
		border-bottom: 1px solid $divider-color;
	}
	.at-table--6cols {
		.title {
			width: 15%;
			padding-left: 25px;
			@extend .font--semibold;
			@include bp(768px) {
				padding-left: 10px;
			}
		}
		.description {
			width: 20%;
			@include bp(768px) {
				width: 10%;
			}
		}
		.rate {
			width: 20%;
		}
		.quantity {
			width: 12%;
		}
		.tax {
			width: 12%;
		}
		.amount {
			width: 15%;
			text-align: right;
			padding-right: 25px;
			@extend .font--semibold;
			@include bp(768px) {
				padding-right: 10px;
			}
		}
	}
	.at-cell-text {
		font-size: $very-small-font-size;
		color: $mc-9;
	}
	.invoice-item-rows {
		min-height: 50px;
		border-bottom: 1px solid $divider-color;
		padding-top: 10px;
		padding-bottom: 10px;

		&:nth-child(2n + 1) {
			background-color: #f3f5f8;
			border-bottom: 1px solid $divider-color;
			&:hover {
				background-color: rgba(47, 88, 242, 0.05);
			}
		}
		.view-detail {
			text-transform: uppercase;
			font-size: 12px;
			position: relative;
			padding-right: 20px;
			margin-top: 10px;
			display: inline-block;
			img {
				top: 0px;
				right: 0px;
				height: 12px;
				position: absolute;
			}
			a {
				color: $color-blue;
				text-decoration: none;
			}
		}
	}
}

.transaction-details-status {
	padding: 25px;
	.status-item-container {
		display: flex;
		flex-direction: row;
		.timeline-container {
			position: relative;
			.mask-circle {
				height: 30px;
				width: 30px;
				border-radius: 50%;
				background-color: white;
				.timeline-circle {
					height: 30px;
					width: 30px;
					border-radius: 50%;
					margin-top: 5px;
					display: flex;
					justify-content: center;
					align-items: center;
					.timeline-concentric-circle {
						height: 16px;
						width: 16px;
						border-radius: 50%;
					}
				}
			}
			.animated-circle {
				animation: scale-up-down 1s linear 0s infinite;
				.timeline-circle {
					animation: scale-up-down 1s linear 0s infinite;
					.timeline-concentric-circle {
						animation: scale-up-down 1s linear 0s infinite;
					}
				}
			}
			.timeline-bar {
				background-color: $mc-4;
				width: 4px;
				height: 100%;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
			}
		}
		.status-info {
			padding-left: 10px;
			min-height: 120px;
			.status-title {
				color: $mc-5;
				display: inline-block;
				font-size: medium;
				margin: 5px 0;
				.status-tag {
					height: 20px;
					padding: 4px 8px;
					width: fit-content;
					border-radius: $default-radius;
					margin-bottom: 5px;
					box-sizing: content-box;
				}
			}
			.status-update-time {
				color: $mc-7;
				margin-bottom: 5px;
				font-size: $very-small-font-size;
				@extend .font--regular;
			}
		}
	}
}
