.layout-container {
	padding-left: $sidebar-width;
	transition: padding 300ms ease-in-out;
	@include bp(768px) {
		padding-left: 0px;
	}
	&.minimized {
		padding-left: $sidebar-width-minimized;
	}
}

.custom_fc_frame {
	bottom: 5px !important;
	right: 5px !important;
	left: auto !important;
}

.atlas-top-navbar {
	position: fixed;
	z-index: $zI-level-3;
	.center-block-breadcrumbs {
		display: flex;
		align-items: center;
		gap: 6px;
	}
	.top-navbar-icons {
		display: flex;
		align-items: center;
		gap: 20px;
		svg {
			width: 20px;
			height: 20px;
			padding: 5px;
			border-radius: $border-radius-default;
			box-sizing: content-box;
			cursor: pointer;
			&:hover {
				background-color: $mc-2;
			}
		}
		.meraki-changelog {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			box-sizing: content-box;
			border-radius: $border-radius-default;
			&:hover {
				background-color: $mc-2;
			}
			.icon-changelog {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			#HW_badge_cont {
				position: absolute;
			}
			#HW_badge {
				position: relative;
				top: -1px;
				left: 19px;
				transform: scale(0.42);
				background: $color-blue;
				font-size: 12px;
			}
			.HW_animated {
				transform: scale(0.62) !important;
				animation: none !important;
				transform: translate(0);
				transform-origin: unset !important;
			}
			.HW_softHidden {
				transform: scale(0.42) !important;
			}
		}
	}
	.notifications-block {
		position: relative;
		.notifications-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			cursor: pointer;
			.count {
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				top: 1px;
				left: 18px;
				background-color: $color-blue;
				color: white;
				border-radius: 20px;
				padding: 0 3px;
				width: auto;
				min-width: 11px;
				height: 11px;
				line-height: 11px;
				font-size: 8px;
				box-sizing: border-box;
			}
		}
		.notifications-menu {
			position: absolute;
			right: -15px;
			top: 35px;
			background-color: white;
			box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
			border: 1px solid $border-color;
			border-radius: $default-radius;
			width: max-content;
			width: 500px;
			min-height: 100px;
			max-height: 300px;
			overflow-y: auto;
			z-index: 10000;
			// prevent scroll-chaining to parent elements
			overscroll-behavior: contain;
			.header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 10px 25px;
				border-bottom: 1px solid $border-color-dark;
				background-color: $mc-1;
				position: sticky;
				top: 0;
				z-index: 100;
				.title {
					font-size: $medium-font-size;
					@extend .font--semibold;
				}
				.mark-read {
					display: flex;
					align-items: center;
					font-size: 12px;
					color: $color-blue;
					// color: $mc-1;
					cursor: pointer;
					transition: 180ms;
					img {
						width: 12px;
						margin-right: 5px;
					}
					&:hover {
						scale: 1.1;
					}
				}
			}
			.message-container {
				position: relative;
				padding: 12px 25px;
				border-bottom: 1px solid $border-color;
				transition: 180ms;
				cursor: default;
				&:last-child {
					border-bottom: none;
				}
				&:hover {
					background-color: $mc-2;
				}
				.section {
					font-weight: 600;
					margin-bottom: 10px;
					text-transform: capitalize;
					span {
						text-transform: capitalize;
					}
				}
				.message {
					display: flex;
					font-size: $very-small-font-size;
					margin-bottom: 10px;
					.link-text {
						@extend .font--regular;
					}
					.platform-icon {
						margin-right: 10px;
						img {
							width: 32px;
							object-fit: cover;
							border-radius: $default-radius;
						}
					}
				}
				.info {
					display: flex;
					align-items: flex-end;
					justify-content: space-between;
					height: 15px;
					.date-time {
						color: $mc-5;
						font-size: 12px;
					}
					.actions {
						.mark-as-read {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 15px;
							height: 15px;
							border: 1px solid rgba($color: $color-blue, $alpha: 0.2);
							background-color: rgba($color: $color-blue, $alpha: 0.08);
							border-radius: $default-radius;
							opacity: 0.6;
							transition: 180ms;
							cursor: pointer;
							img {
								width: 12px;
								object-fit: cover;
							}
							&:hover {
								opacity: 1;
								scale: 1.1;
							}
						}
					}
				}
				.new-notification {
					position: absolute;
					top: 18px;
					left: 10px;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background-color: $color-blue;
				}
				&.new {
					opacity: 1;
				}
				&.read {
					opacity: 0.6;
				}
			}
			.placeholder {
				text-align: center;
				padding: 70px 15px;
				color: rgba($color: $mc-5, $alpha: 0.7);
				font-style: italic;
			}
		}
	}
}

.page-container {
	width: 100%;
	box-sizing: border-box;
	width: 100%;
	margin: auto;
	padding-top: 64px;
	&:before {
		content: " ";
		display: table;
	}
	& > .content {
		display: block;
		margin: 0px 30px;
	}
	@include bp(768px) {
		padding-top: 70px;
		& > .content {
			margin: 0px 10px;
		}
	}
	.top-nav-bar {
		margin-top: -20px;
		margin-bottom: 20px;
		width: 100%;
		max-height: 47px;
		background-color: #fff;
		.tab-container {
			max-width: 95%;
			margin: auto;
			display: flex;
			justify-content: center;
		}
		.tab {
			border-radius: 4px;
			color: $mc-9;
			opacity: 0.6;
			text-decoration: none;
			max-height: 30px;
			max-width: 15%;
			background-color: inherit;
			float: left;
			border: none;
			outline: none;
			cursor: pointer;
			padding: 5px;
			transition: 0.3s;
			font-family: Source Sans Pro;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 18px;
			margin: 5px 10px;
			padding-left: 20px;
			padding-right: 20px;
			border-radius: 4px;
			&.active {
				background: rgba(47, 88, 242, 0.1);
				color: #2f58f2;
				opacity: 1;
			}
			.tab-desc {
				display: flex;
				align-items: center;
				img {
					margin-left: 5px;
					width: 15px;
				}
			}
		}
	}
	.content-container {
		max-width: 100%;
		margin: auto;
	}
}
