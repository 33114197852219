.range-slider-container {
	position: relative;
	min-height: 28px;
	color: $color-light-black;
	font-size: $extra-small-font-size;
	.current-value {
		position: absolute;
		left: 0;
	}
	.max-value {
		position: absolute;
		right: 0;
	}
	// basic range slider styles
	.at-range-slider {
		background: $color-lightest-grey;
		width: 100%;
		height: 9px;
		border-radius: $default-radius;
		-webkit-appearance: none;
		appearance: none;
		background-image: linear-gradient($color-blue, $color-blue);
		background-repeat: no-repeat;
		transition: all 10ms ease-in-out;
	}

	// slider thumb styles
	.at-range-slider::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		background: #f2f2f2;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 1px solid $divider-color;
		box-shadow: $default-shadow;
	}

	.at-range-slider::-moz-range-thumb {
		-webkit-appearance: none;
		appearance: none;
		background: #f2f2f2;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 1px solid $divider-color;
		box-shadow: $default-shadow;
	}
	.at-range-slider::-ms-thumb {
		-webkit-appearance: none;
		appearance: none;
		background: #f2f2f2;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 1px solid $divider-color;
		box-shadow: $default-shadow;
	}
	// slider track styles
	.at-range-slider::-webkit-slider-runnable-track {
		-webkit-appearance: none;
		box-shadow: none;
		border: none;
		background: transparent;
	}

	.at-range-slider::-moz-range-track {
		-webkit-appearance: none;
		box-shadow: none;
		border: none;
		background: transparent;
		appearance: none;
	}

	.at-range-slider::-ms-track {
		-webkit-appearance: none;
		box-shadow: none;
		border: none;
		background: transparent;
		appearance: none;
	}
}
