.custom-integrations-section {
	min-height: 90vh;

	&.section-container-common {
		padding-bottom: 0;

		.filter-section-item {
			margin-bottom: 0;
		}
	}

	.integrations-header {
		.highlight {
			@extend .font--semibold;
		}
	}

	.header-action-button {
		margin-top: 10px;
		display: flex;
		gap: 12px;

		.multi-select-filter-container {
			width: 200px;

			.react-select__control {
				border-radius: 2px;
			}
		}

		.dropdown-custom {
			width: 200px;

			.container {
				height: 32px;
				padding: 1px 10px;
				border-radius: $border-radius-default;
			}

			.dropdown-component {
				z-index: 1000;
			}

			.dropdown-options {
				.option {
					height: 37px;
					padding: 1px 10px;
				}
			}

			.custom-option,
			.custom-label {
				display: flex;
				align-items: center;

				.logo {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 8px;
					width: 22px;
					height: 22px;
					text-transform: uppercase;
					font-size: $very-small-font-size;
					border-radius: $border-radius-default;
					@extend .font--semibold;

					img {
						width: 22px;
						height: 22px;
						object-fit: cover;
						border-radius: $border-radius-default;
					}

					&.brown {
						color: $color-brown-dark;
						background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
					}

					&.blue {
						color: $color-blue-dark;
						background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
					}

					&.green {
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					}

					&.red {
						color: $color-red-dark;
						background-color: rgba($color: $color-red-dark, $alpha: 0.1);
					}

					&.purple {
						color: $color-violet;
						background-color: rgba($color: $color-violet, $alpha: 0.1);
					}
				}
			}
		}

		@include bp(768px) {
			width: auto;
			padding: 0 5px;
		}
	}
}

.custom-integrations-container {
	.section-header {
		font-family: Source Sans Pro;
		font-weight: 600;
		font-size: $medium-font-size;
		line-height: 24px;
		margin-bottom: 0px;
		padding-bottom: 0px;
		letter-spacing: 0%;
	}

	.form-container {
		height: 100%;

		.form-content {
			padding: 0 25px 15px 25px;

			@include bp(768px) {
				padding: 15px;
			}
		}

		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
	}

	.card-container {
		border: 1px solid $border-color;
		border-radius: $default-radius;
		margin: 15px 25px 30px 25px;

		.card-header {
			padding: 24px;
			display: flex;
			justify-content: space-between;
			cursor: pointer;

			.header-container {
				display: flex;
				align-items: center;

				.app-icon {
					img {
						height: 56px;
					}
				}

				.app-header {
					display: flex;
					flex-direction: column;
					margin-left: 10px;
					flex-wrap: wrap;

					.header-text {
						font-size: $small-font-size;
						@extend .font--semibold;
					}

					.header-subtext {
						color: $mc-5;
						font-size: $very-small-font-size;
						padding-top: 5px;
					}
				}
			}

			.collapse-button {
				align-items: center;
				display: flex;
			}

			.pos-info {
				font-size: $small-font-size;
				text-transform: capitalize;
				@extend .font--semibold;
			}
		}

		.form-container.collapsed {
			display: none;
		}

		.form-container {
			padding: 25px 25px 0;
			border-top: 1px solid $border-color;

			.form-row {
				max-width: 970px;
				display: flex;
				flex-wrap: wrap;

				@include bp(1285px) {
					width: 100%;
				}

				@include bp(768px) {
					max-width: 100%;
					flex-direction: column;
				}

				&:nth-last-child(1) {
					margin-bottom: 5px;
				}

				& > div {
					width: 29%;
					margin-right: 40px;
					margin-bottom: 30px;

					@include bp(1285px) {
						width: 35%;
					}

					@include bp(768px) {
						width: auto;
						margin-right: 0;
					}
				}

				.at-json-input-container {
					width: 100%;
				}

				.no-items-placeholder {
					flex-grow: 1;
					margin-right: 0;
				}

				// override style for select filter component inside form
				.multi-select-filter-container {
					.at--dropdown {
						font-size: $very-small-font-size;
					}

					&.filter-section-item {
						opacity: 1;

						.meta-info {
							font-weight: 400;
							margin-bottom: 0;
							padding-bottom: 5px;
						}
					}
				}
			}
		}
	}
}

.custom-POS-container {
	.section-header {
		font-family: Source Sans Pro;
		font-weight: 600;
		font-size: $medium-font-size;
		line-height: 24px;
		margin-bottom: 0px;
		padding-bottom: 0px;
		letter-spacing: 0%;
	}

	.integration-heading {
		font-family: Source Sans Pro;
		font-weight: 600;
		font-size: $medium-large-font-size;
		line-height: 32px;
		letter-spacing: 0%;
		text-align: center;
	}

	.form-container {
		display: flex;
		flex-direction: column;
		height: 90vh;

		.form-content {
			margin-bottom: auto;

			@include bp(768px) {
				padding: 15px;
			}
		}

		.footer {
			display: flex;
			justify-content: flex-end;
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
	}

	.card-container {
		border: 1px solid $border-color;
		border-radius: $default-radius;
		margin: 15px 25px 30px 25px;

		.card-header {
			padding: 24px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-container {
				display: flex;
				align-items: center;

				.app-icon {
					img {
						height: 56px;
					}
				}

				.app-header {
					display: flex;
					flex-direction: column;
					margin-left: 10px;
					flex-wrap: wrap;

					.header-text {
						font-size: $small-font-size;
						@extend .font--semibold;
					}

					.header-subtext {
						color: $mc-5;
						font-size: $very-small-font-size;
						padding-top: 5px;
					}
				}
			}

			.collapse-button {
				align-items: center;
				display: flex;
			}

			.collapse-button.expanded {
				align-items: center;
				display: flex;

				svg {
					transform: rotate(180deg);
				}
			}

			.pos-info {
				font-size: $small-font-size;
				text-transform: capitalize;
				@extend .font--semibold;
			}
		}

		.form-container.collapsed {
			display: none;
		}

		.form-container {
			border-top: 1px solid $border-color;

			.form-row {
				max-width: 970px;
				display: flex;
				flex-wrap: wrap;

				@include bp(1285px) {
					width: 100%;
				}

				@include bp(768px) {
					max-width: 100%;
					flex-direction: column;
				}

				&:nth-last-child(1) {
					margin-bottom: 5px;
				}

				& > div {
					width: 29%;
					margin-right: 40px;
					margin-bottom: 30px;

					@include bp(1285px) {
						width: 35%;
					}

					@include bp(768px) {
						width: auto;
						margin-right: 0;
					}
				}

				.at-json-input-container {
					width: 100%;
				}

				.no-items-placeholder {
					flex-grow: 1;
					margin-right: 0;
				}

				// override style for select filter component inside form
				.multi-select-filter-container {
					.at--dropdown {
						font-size: $very-small-font-size;
					}

					&.filter-section-item {
						opacity: 1;

						.meta-info {
							font-weight: 400;
							margin-bottom: 0;
							padding-bottom: 5px;
						}
					}
				}
			}
		}
	}

	.POS-status {
		display: flex;
		padding: 3px 8px;
		gap: 8px;
		justify-content: center;
		border-radius: 2px;

		.text {
			font-family: Source Sans Pro;
			font-size: $very-small-font-size;
			font-weight: 600;
			line-height: 22px;
			text-align: left;
		}
	}

	.POS-status.POS-connection-success {
		background-color: $color-green-10;
		color: $color-green-70;
	}

	.POS-status.POS-connection-inprogress {
		background-color: $color-yellow-10;
		color: $color-yellow-70;
	}

	.POS-status.POS-connection-error {
		color: $color-red;
		background-color: rgba($color-red, 0.2);
	}

	.pos-authorization {
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		.card-container {
			border-radius: 4px;
			border-width: 1px;
			gap: 10px;
			padding: 36px;
			width: 100%;
			border: 1px solid $border-color;
			border-radius: $border-radius-default;
			display: flex;
			flex-direction: row;

			.header {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.platforms {
					display: flex;
					align-items: center;
					gap: 10px;
					margin-bottom: 25px;

					.platform {
						display: flex;
						align-items: center;

						img {
							width: 56px;
							border-radius: $border-radius-default;
						}
					}

					.integrate {
						img {
							width: 20px;
						}
					}
				}

				.status-icon {
					margin-bottom: 15px;

					img {
						width: 60px;
					}
				}

				.loader-backdrop {
					position: relative;
					background: none;
				}

				.header-text {
					font-size: $medium-large-font-size;
					color: $color-neutral-black-90;
					margin-bottom: 10px;
					@extend .font--semibold;
				}

				.header-sub-text {
					font-size: $very-small-font-size;
					color: $color-dark-grey;
					margin-bottom: 25px;
					text-align: center;
					line-height: 1.6;
				}
			}

			.content {
				padding: 0px 50px;

				.description {
					color: $color-black-60;
					font-size: $very-small-font-size;
					margin-bottom: 20px;
					line-height: 1.6;
				}

				.benifits {
					display: flex;
					flex-direction: column;
					gap: 20px;

					.point {
						display: flex;
						align-items: flex-start;
						gap: 10px;

						.text {
							color: $color-black-80;
							font-size: $very-small-font-size;
						}
					}
				}

				span {
					text-transform: capitalize;
				}
			}
		}
	}

	.pos-disclaimer {
		display: flex;
		box-sizing: border-box;

		.disclaimer-container {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			gap: 10px;
			padding: 16px;
			width: 100%;
			border: 1px solid $border-color;
			border-radius: $border-radius-default;
			display: flex;
			flex-direction: column;
			background: $color-neutral-black-05;
			margin: 15px 25px 30px 25px;

			.disclaimer-header {
				display: flex;
				align-items: center;
				letter-spacing: 0%;

				img {
					width: 20px;
				}

				.disclaimer-text {
					margin-left: 10px;
					font-weight: 600;
					font-size: $medium-font-size;
					line-height: 24px;
				}
			}

			.disclaimer-description {
				font-weight: 400;
				width: 75%;
				font-size: $small-font-size;
				line-height: 22px;
				letter-spacing: 0%;
				color: $color-neutral-black-60;
				margin-bottom: 16px;
			}

			.button-container {
				display: flex;
				flex-direction: row;

				.action-buttons-container {
					margin-right: 16px;
					border-radius: 2px;
					gap: 8px;
					font-weight: 600;
					font-size: $small-font-size;
					line-height: 20px;
					letter-spacing: 0%;
					text-align: center;

					svg {
						padding: 0px 5px;
					}
				}
			}

			.disclaimer-check {
				margin-top: 32px;
				display: flex;
				align-items: center;

				.at-checkbox {
					font-size: 20px;
				}

				.text {
					font-weight: 600;
					font-size: $medium-font-size;
					letter-spacing: 0%;
				}
			}
		}
	}
}

.pos-connection-failed {
	gap: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 18vh;

	.failure-container-wrapper {
		width: 453px;
		height: 283px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.error-icon {
			img {
				width: 103px;
				height: 103px;
			}
		}

		.error-heading {
			font-family: Source Sans Pro;
			font-weight: 600;
			font-size: $medium-large-font-size;
			line-height: 32px;
			letter-spacing: 0%;
			text-align: center;
			margin: 12px 0px;
		}

		.error-description {
			font-family: Source Sans Pro;
			font-weight: 400;
			font-size: $very-small-font-size;
			line-height: 22px;
			letter-spacing: 0%;
			text-align: center;
			width: 100%;
			margin-bottom: 40px;
			color: $color-neutral-black-60;
		}

		.button-container {
			display: flex;
			flex-direction: row;

			.action-buttons-container {
				margin-right: 24px;
				border-radius: 2px;
				gap: 8px;
				font-weight: 600;
				font-size: $small-font-size;
				line-height: 20px;
				letter-spacing: 0%;
				text-align: center;

				svg {
					padding: 0px 5px;
				}
			}
		}
	}
}
