@keyframes slideUp {
	from {
		transform: translateY(20px);
	}
	to {
		transform: translateY(0px);
	}
}
.meraki-section {
	.modal-main-content {
		padding: 24px;
		.main-content {
			font-weight: 600;
			font-size: $medium-font-size;
			margin-bottom: 24px;
		}
		.sub-content {
			font-size: $small-font-size;
		}
	}
	.anouncement-confirmation--modal {
		.modal-container {
			.modal-header {
				border-bottom: 1px solid #f0f0f0;
				text-align: left;
			}
			.modal-footer {
				border-top: 1px solid #f0f0f0;
				text-align: right;
			}
		}
	}

	&.section-container-common {
		padding-bottom: 0;
	}

	.settings-header {
		display: flex;
		justify-content: space-between;
		.header-action--container {
			.announcement-create-button {
				margin-top: 16px;
				margin-right: 10px;
			}
		}
	}
	.meraki-navigation {
		display: flex;
		align-items: center;
		position: sticky;
		bottom: 20px;
		left: 50%;
		width: fit-content;
		padding: 7px 20px;
		margin: -20px 0 20px 0;
		background-color: $color-green;
		border: 1px solid $color-green;
		border-radius: $default-radius;
		box-shadow: $default-shadow;
		text-transform: capitalize;
		cursor: pointer;
		z-index: 20;
		@include bp(768px) {
			bottom: 80px;
			transform: translateX(-50%);
		}
		span {
			color: #fff;
			font-size: $small-font-size;
			font-weight: 600;
		}
		img {
			width: 17px;
			margin-right: 5px;
		}
		.nav-list {
			position: absolute;
			bottom: 125%;
			left: 50%;
			transform: translateX(-50%);
			background-color: #fff;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			box-shadow: $default-shadow;
			min-height: 50px;
			max-height: 400px;
			width: 250px;
			overflow-y: auto;
			color: $mc-7;
			padding: 5px 10px 5px 15px;
			font-size: $small-font-size;
			transition: 180ms;
			.nav-list-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: relative;
				margin-bottom: 5px;
				padding: 1px 3px;
				&:hover {
					color: $mc-9;
				}
				&:nth-last-child(1) {
					margin-bottom: 0;
				}
				&[data-active="true"]:before {
					content: " ";
					height: 6.5px;
					width: 6.5px;
					border-radius: 50%;
					background-color: $color-green;
					position: absolute;
					top: 50%;
					left: -3%;
					transform: translateY(-50%);
				}
				img {
					width: 20px;
				}
			}
		}
	}
}
.meraki-container {
	.at-top-bar-wrapper-v2 {
		z-index: $zI-level-1;
	}
	.form-container {
		height: 100%;
		.form-content {
			padding: 25px 0;

			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
				}
				&.filter-section-item {
					opacity: 1;
					margin-bottom: 0;
					.meta-info {
						font-weight: 400;
						margin-bottom: 0;
						padding-bottom: 5px;
					}
				}
			}
		}
		.form-row {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;
			@include bp(768px) {
				flex-direction: column;
				margin-bottom: 0;
			}
			&.row-full > div {
				width: 100%;
				@include bp(768px) {
					margin-bottom: 30px;
				}
			}
			&.row-half > div {
				width: 47%;
				@include bp(768px) {
					width: auto;
					margin-bottom: 30px;
				}
			}
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
				}
				&.filter-section-item {
					opacity: 1;
					margin-bottom: 0;
					@include bp(768px) {
						margin-bottom: 30px;
					}
					.meta-info {
						font-weight: 400;
						margin-bottom: 0;
						padding-bottom: 5px;
					}
				}
			}
		}
		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
			&.exception {
				margin-left: 25%;
				border-left: 1px solid $border-color;
			}
		}
		.sidebar-row {
			padding: 12px 25px;
		}
	}
	.card-container {
		border: 1px solid $border-color;
		border-radius: $default-radius;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
		margin-bottom: 25px;
		&.loading {
			opacity: 0.6;
			pointer-events: none;
		}
		.card-header {
			padding: 20px 25px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include bp(768px) {
				flex-direction: column;
				align-items: flex-start;
			}
			.announcementRight {
				display: flex;
				gap: 10px;
				.at-switch-cnt {
					padding: 0px;
				}
			}
			.header-container {
				display: flex;
				align-items: center;
				.app-icon {
					img {
						height: 40px;
					}
				}
				.app-header {
					display: flex;
					flex-direction: column;
					margin-left: 10px;
					flex-wrap: wrap;
					.header-text {
						font-size: $small-font-size;
						@extend .font--semibold;
					}
					.header-subtext {
						color: $mc-5;
						font-size: $very-small-font-size;
						padding-top: 5px;
					}
				}
			}
			.at-switch-cnt,
			.multi-select-filter-container {
				display: flex;
				align-items: center;
				padding-left: 100px;
				.at--dropdown {
					width: 230px;
				}
				.meta-info {
					margin-right: 10px;
				}
				.at-switch {
					.switch-rail {
						margin: 2px 0;
					}
					.switch-ball {
						top: 4px;
					}
				}
				.validation-message {
					margin: 0 0 0 10px;
				}
				@include bp(768px) {
					padding-left: 0px;
					margin-top: 25px;
				}
			}
			.actions {
				display: flex;
				align-items: center;
				.at-switch-cnt,
				.multi-select-filter-container {
					padding-left: 30px;
					@include bp(768px) {
						.at--dropdown {
							width: 100%;
						}
						width: 100%;
						padding-left: 0;
					}
				}
				@include bp(768px) {
					width: 100%;
					flex-direction: column;
					margin-top: 5px;
				}
			}
		}
	}

	.settings {
		padding: 0 25px;
		.form-row {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 30px;
			max-width: 950px;
			@include bp(768px) {
				max-width: 100%;
				flex-direction: column;
				margin-bottom: 0px;
			}
			&.row-full > div {
				width: 64%;
				@include bp(768px) {
					width: auto;
					margin-bottom: 30px;
				}
			}
			&.row-half > div {
				width: 30%;
				margin-right: 40px;
				&:nth-last-child(1) {
					margin-right: 0px;
				}
				@include bp(768px) {
					width: auto;
					margin-bottom: 30px;
					margin-right: 0;
				}
			}
		}
	}

	.apps {
		padding: 0 25px;
		@include bp(768px) {
			padding: 0 15px;
		}
		.form-container {
			padding: 25px;
			border-top: 1px solid $border-color;
			.form-row {
				justify-content: flex-start;
				max-width: 950px;
				@include bp(768px) {
					max-width: 100%;
					flex-direction: column;
				}
				&:nth-last-child(1) {
					margin-bottom: 5px;
				}
				&.row-full > div {
					width: 64%;
					@include bp(768px) {
						width: auto;
						margin-bottom: 30px;
					}
				}
				&.row-half > div {
					width: 30%;
					margin-right: 35px;
					@include bp(768px) {
						width: auto;
						margin-bottom: 30px;
						margin-right: 0;
					}
				}
			}
		}
		.config-form-container {
			padding: 25px 25px 0;
			border-top: 1px solid $border-color;
			.shimmer {
				&:nth-last-child(1) {
					margin-bottom: 25px;
				}
			}
			.config-form-row {
				max-width: 980px;
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 30px;
				@include bp(1265px) {
					width: 100%;
				}
				@include bp(768px) {
					max-width: 100%;
					flex-direction: column;
				}
				&:nth-last-child(1) {
					margin-bottom: 5px;
				}
				& > div {
					width: 29%;
					margin-right: 35px;
					margin-bottom: 30px;
					&:nth-last-child(1) {
						margin-right: 0;
					}
					@include bp(1265px) {
						width: 35%;
					}
					@include bp(768px) {
						width: auto;
						margin-right: 0;
					}
				}
				// override style for select filter component inside form
				.multi-select-filter-container {
					.at--dropdown {
						font-size: $very-small-font-size;
					}
					&.filter-section-item {
						opacity: 1;
						.meta-info {
							font-weight: 400;
							margin-bottom: 0;
							padding-bottom: 5px;
						}
					}
				}
			}
		}
	}
	.announcement {
		padding: 0 25px;
		@include bp(768px) {
			padding: 0 15px;
		}
		.placeholder-main {
			.placeholder--text {
				font-weight: 600;
				font-size: $medium-large-font-size;
				margin-bottom: 8px;
			}
			.placeholder--subtext {
				font-size: $small-font-size;
				line-height: 22px;
				margin-bottom: 20px;
			}
			.placeholder--action-btn {
				margin-bottom: 15px;
			}
		}
		.announcement-top-bar {
			.all-announcement {
				width: 169px;
				height: 28px;
				font-weight: 600;
				font-size: 20px;
				line-height: 28px;
				margin-bottom: 8px;
			}
			.caution {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 8px;
				width: 554px;
				height: 36px;
				margin-bottom: 24px;
				background: #fef6e9;
				border-radius: 4px;
				.caution-icon {
					margin-left: 8px;
				}
				.caution-statement {
					font-size: $very-small-font-size;

					color: #c2831c;
				}
			}
		}
		.form-container {
			padding: 25px;
			border-top: 1px solid $border-color;
			.row-half {
				.multi-select-filter-container {
					.at--dropdown {
						.react-select__control {
							min-height: 38px;
						}
					}
				}
			}
			.form-row {
				justify-content: space-beween;
				.at-input-container {
					.at-input {
						height: 38px;
					}
					.at-input-field {
						.character-limit {
							margin-top: 10px;
							font-weight: 600;
							font-size: 15px;
							background: white;
							padding-left: 5px;
						}
					}
				}

				@include bp(768px) {
					flex-direction: column;
				}
				&:nth-last-child(1) {
					margin-bottom: 5px;
				}
				&.row-full > div {
					width: 100%;

					@include bp(768px) {
						margin-bottom: 30px;
					}
				}
				&.row-half > div {
					width: 47%;

					@include bp(768px) {
						width: auto;
						margin-bottom: 30px;
					}
				}
			}
		}
		.config-form-container {
			padding: 25px 25px 0;
			border-top: 1px solid $border-color;
			.shimmer {
				&:nth-last-child(1) {
					margin-bottom: 25px;
				}
			}
			.config-form-row {
				max-width: 980px;
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 30px;
				@include bp(1265px) {
					width: 100%;
				}
				@include bp(768px) {
					max-width: 100%;
					flex-direction: column;
				}
				&:nth-last-child(1) {
					margin-bottom: 5px;
				}
				& > div {
					width: 29%;
					margin-right: 35px;
					margin-bottom: 30px;
					&:nth-last-child(1) {
						margin-right: 0;
					}
					@include bp(1265px) {
						width: 35%;
					}
					@include bp(768px) {
						width: auto;
						margin-right: 0;
					}
				}
				// override style for select filter component inside form
				.multi-select-filter-container {
					.at--dropdown {
						font-size: $very-small-font-size;
					}
					&.filter-section-item {
						opacity: 1;
						.meta-info {
							font-weight: 400;
							margin-bottom: 0;
							padding-bottom: 5px;
						}
					}
				}
			}
		}

		.dropdown-custom {
			.container {
				padding: 8px 24px;
				.dropdown-arrow {
					margin-right: -7px;
				}

				width: auto;

				span {
					height: 20.5px;
					font-size: $extra-small-font-size;
					text-align: center;
					margin-top: -3px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: -18px;
					padding: 1px;
				}
			}
			.dropdown-component {
				left: 0;
				.dropdown-option {
					padding: 5px 10px;
					text-align: center;
					color: $mc-1;
				}
			}
		}
		.dropdown-options-box {
			font-size: $extra-small-font-size;
			display: flex;
			flex-direction: column;
			gap: 10px;
			padding: 12px;
			.dropdown-options {
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
			.default-color {
				font-weight: 600;
				font-size: 12px;
				color: #979797;
			}
			.custom-color {
				font-weight: 600;
				font-size: 12px;
				color: #979797;
			}
			.add-color-icon {
				display: flex;
				gap: 6px;
				.add-color {
					font-size: 12px;
					font-weight: 600;
				}
			}
		}
	}

	.templates {
		padding: 0 25px;
		@include bp(768px) {
			padding: 0 15px;
		}
		.card-header {
			padding: 15px 25px 10px;
		}
		.form-container {
			padding: 25px;
			.form-row {
				justify-content: flex-start;
				@include bp(768px) {
					flex-direction: column;
				}
				&:nth-last-child(1) {
					margin-bottom: 5px;
				}
				&.row-full > div {
					width: 100%;
					@include bp(768px) {
						margin-bottom: 0;
					}
				}
				.at-textarea {
					min-height: 200px;
				}
				.preview {
					border-radius: $default-radius;
					border: 1px solid $border-color;
					padding: 10px;
				}
			}
		}
	}
	.messages {
		padding: 0 25px;
		@include bp(768px) {
			padding: 0 15px;
		}
		.card-header {
			padding: 15px 25px 10px;
		}
		.form-container {
			padding: 25px;
			border-top: 1px solid $border-color;
			.form-row {
				justify-content: flex-start;
				@include bp(768px) {
					max-width: 100%;
					flex-direction: column;
				}
				&:nth-last-child(1) {
					margin-bottom: 5px;
				}
				&.row-full > div {
					width: 100%;
					@include bp(768px) {
						margin-bottom: 0px;
					}
				}
				.at-textarea {
					min-height: 100px;
				}
			}
		}
	}
}
