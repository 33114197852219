.menu-edit-container {
	padding-top: 24px;
	.at-breadcrumbs-container {
		padding: 0 24px;
	}
	.at-top-bar-wrapper-v2 {
		z-index: 10;
	}
	&--header {
		margin-top: 10px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 12px;
		padding: 0 24px;
		.header-text-container {
			width: 50%;
			.title {
				font-size: $large-font-size;
				font-weight: $font--semibold;
				margin-bottom: 5px;
				width: 100%;
				@include truncate_text;
			}
			.sub-title {
				color: $mc-6;
				width: 100%;
				@include truncate_text;
			}
		}
		.header-actions-container {
			height: 36px;
			align-items: center;
			display: flex;
			gap: 12px;
			.pending-changes-count {
				color: $color-neutral-black-50;
			}
			.react-select__control {
				border: none;
				min-width: 90px;
				background-color: $color-blue;
				.react-select__single-value {
					color: white;
				}
				.react-select__dropdown-indicator {
					svg {
						color: white;
					}
				}
			}
		}
		.shimmer-container {
			.shimmer {
				height: 20px;
				width: 40vw;
				margin-bottom: 10px;
			}
			.shimmer-small {
				width: 20vw;
			}
		}
	}

	&--preview-text-container {
		padding: 15px 24px;
		.preview-text-content {
			border-radius: $border-radius-default;
			background-color: $color-blue-1;
			padding: 12px;
			display: flex;
			gap: 8px;
			.info-icon {
				height: 16px;
				width: 16px;
				margin-top: 1.5;
			}
			.info-text-container {
				font-size: $very-small-font-size;
				.info-text-header {
					color: $color-neutral-black-80;
					font-weight: $font--semibold;
				}
				.info-text-subheader {
					margin-top: 4px;
					color: $color-neutral-black-60;
				}
			}
		}
	}

	.placeholder-main {
		margin-bottom: 15px;
		img {
			height: 200px;
		}
	}

	.menu-creation-container {
		.subsection-info {
			background-color: $color-blue-1;
			display: flex;
			padding: 12px;
			gap: 8px;
			margin-bottom: 24px;
			position: relative;
			.info-icon-container {
				.info-icon {
					height: 15px;
					width: 15px;
				}
			}
			.info-text {
				font-size: $very-small-font-size;
				.header {
					color: $color-light-black;
					font-weight: $font--semibold;
				}
				.sub-header {
					color: $mc-6;
				}
			}
			.icon-close {
				position: absolute;
				height: 9px;
				width: 9px;
				right: 8px;
				top: 8px;
			}
		}
	}

	.placeholder-main {
		padding: 50px 0;
		.at-btn {
			width: 30%;
		}
		@include bp(768px) {
			.at-btn {
				width: 300px;
			}
		}
	}

	.reorder-items-container {
		padding: 15px 0;
		border-top: 1px solid $border-color;
		.entity-accordion-container {
			.accordion-title {
				position: relative;
				transition: all 200ms ease-in-out;
				.drag-icon {
					position: absolute;
					left: 5px;
					top: 33%;
					visibility: hidden;
				}
				&:hover {
					background-color: $color-neutral-black-05;
				}
			}
		}
		.animation-container {
			width: 100%;
			height: 600px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.lottie {
				width: 50%;
			}
			.info-text {
				font-weight: $font--semibold;
				color: $mc-6;
			}
		}
		.entity-accordion-container {
			border: none;
			.title-expander-container {
				.section-title-container {
					display: flex;
					gap: 10px;
					font-size: $very-small-font-size;
					font-weight: $font--semibold;
					color: $color-light-black;
					align-items: center;
					.section-items-count {
						font-weight: 400;
						color: $mc-6;
						font-size: $extra-small-font-size;
					}
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		.subcategories-container {
			border: none;
			.accordion-title {
				padding-left: 48px;
			}
		}
		.item-details-container {
			padding: 16px 24px 16px 48px;
			display: flex;
			gap: 10px;
			align-items: center;
			font-size: $very-small-font-size;
			position: relative;
			.item-image {
				width: 32px;
				height: 32px;
				object-fit: cover;
			}
			.drag-icon {
				position: absolute;
				left: 5px;
				top: 33%;
				visibility: hidden;
			}
			&:last-child {
				margin-bottom: 0;
			}
			&:hover {
				background-color: $color-neutral-black-05;
				.drag-icon {
					visibility: visible;
				}
			}
		}
	}

	.copy-menu-container {
		.existing-menus-selection {
			padding: 24px;
			.multi-select-filter-container {
				width: 50%;
			}
			.search-filter {
				width: 50%;
				margin-right: 0;
				.at-input-container {
					width: 100%;
				}
			}
			.form-table {
				border-left: 1px solid $border-color;
				border-right: 1px solid $border-color;
				.menuInfo {
					width: 40%;
					&--container {
						.menu-image-title {
							display: flex;
							gap: 8px;
							.menu-image-container {
								background-color: $color-lightest-grey;
								height: 64px;
								width: 64px;
								display: flex;
								justify-content: center;
								align-items: center;
								border-radius: $border-radius-default;
								.menu-icon {
									object-fit: cover;
									height: 64px;
									width: 64px;
									border-radius: $border-radius-default;
								}
							}
							.menu-info-container {
								display: flex;
								flex-direction: column;
								gap: 4px;
								.menu-title {
									font-size: $very-small-font-size;
									font-weight: $font--semibold;
								}
								.brand {
									font-weight: $font--semibold;
									color: $color-neutral-black-40;
									text-transform: uppercase;
								}
							}
						}
					}
				}
				.categoryCount {
					width: 20%;
				}
				.itemCount {
					width: 20%;
					&--container {
						display: flex;
						gap: 8px;
						align-items: flex-start;
						justify-content: flex-start;
						.item-icon {
							width: 16px;
							height: 16px;
						}
					}
				}
				.publishStatus {
					width: 20%;
					&--container {
						.status-block {
							padding: 2px 8px;
							background-color: $color-lightest-grey;
							color: $color-neutral-black-50;
							border-radius: $border-radius-default;
							text-transform: capitalize;
						}
						.archived {
							color: $color-red;
							background-color: rgba($color-red, 0.2);
						}
						.Failed {
							color: $color-red;
							background-color: rgba($color-red, 0.2);
						}
						.Published {
							color: $color-green;
							background-color: $color-green-10;
						}
						.Draft {
							color: $color-neutral-black-50;
							background-color: $color-neutral-black-05;
						}
					}
				}
			}
		}
		.sections-items-selection {
			padding: 24px;
			.menu-structure-info {
				background-color: $color-blue-1;
				display: flex;
				padding: 12px;
				gap: 8px;
				position: relative;
				line-height: 1.5;
				.info-icon-container {
					.info-icon {
						height: 15px;
						width: 15px;
					}
				}
				.info-text {
					font-size: $very-small-font-size;
					.header {
						color: $color-light-black;
						font-weight: $font--semibold;
					}
					.sub-header {
						color: $mc-6;
					}
				}
				.icon-close {
					position: absolute;
					height: 9px;
					width: 9px;
					right: 8px;
					top: 8px;
				}
			}
			.selector-search-container {
				padding-top: 16px;
				.multi-select-filter-container {
					width: 47%;
				}
				.search-filter {
					width: 47%;
					margin-right: 0;
					.at-input-container {
						width: 100%;
					}
				}
			}
			.selection-cta {
				display: flex;
				justify-content: flex-end;
				margin-bottom: 16px;
				.deselect {
					color: $color-red;
					&::after {
						background-color: $color-red;
					}
				}
			}
			.expandable-collapsible-menu-contents {
				.entity-accordion-container {
					margin-bottom: 16px;
					.title-expander-container {
						.section-title-container {
							display: flex;
							gap: 10px;
							font-size: $very-small-font-size;
							font-weight: $font--semibold;
							color: $color-light-black;
							align-items: center;
							.section-items-count {
								font-weight: 400;
								color: $mc-6;
								font-size: $extra-small-font-size;
							}
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
				.subcategories-container {
					border: none;
					padding-left: 48px;
				}
				.item-details-container {
					padding: 12px 24px 12px 48px;
					font-size: $very-small-font-size;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.item-info {
						display: flex;
						gap: 10px;
						align-items: center;
						.item-image {
							width: 32px;
							height: 32px;
							object-fit: cover;
						}
					}
					.at-checkbox {
						height: 18px;
						width: 18px;
						padding-left: 0;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.add-items-to-section-container {
		padding-bottom: 25px;
		.filters-search-container {
			display: flex;
			justify-content: space-between;
			padding: 0 24px;
			margin-bottom: 16px;
			.filters-container {
				display: flex;
				gap: 12px;
				.multi-select-filter-container {
					width: 120px;
				}
			}
			.search-filter {
				margin-right: 0;
			}
		}
		.form-table {
			border-left: 1px solid $border-color;
			border-right: 1px solid $border-color;
			margin: 25px 0px;
			&--header {
				.at-checkbox {
					padding-left: 25px;
					width: min-content;
				}
			}
			&--list-item {
				.at-checkbox {
					width: min-content;
				}
			}
			.crmData {
				width: 30%;
			}
			.crm-data {
				width: 100%;
				p {
					margin: 0;
				}
				.crm-data-pos-id {
					font-size: 12px;
					font-weight: 600;
					color: #aaaaaa;
					margin-top: 4px;
					text-transform: uppercase;
				}
			}
			.item {
				width: 55%;
				overflow: hidden;
				.item--container {
					width: 100%;
				}
				.item-details-container {
					display: flex;
					gap: 16px;

					.item-title {
						display: inline-block;
						span {
							white-space: initial;
						}
						.primary-name {
							font-weight: $font--semibold;
						}
						.secondary-name {
							font-size: 12px;
							color: $color-neutral-black-40;
							display: block;
							margin-top: 4px;
						}
						.at-foodtype-indicator {
							flex-shrink: 0;
						}
					}
					.item-img {
						width: 64px;
						height: 64px;
						object-fit: cover;
					}
				}
			}
			.category {
				width: 0%;
			}
			.price {
				text-align: center;
				width: 15%;
				.price--container {
					width: 100%;
				}
			}
			.crmTitle {
				width: 25%;
				.crmTitle--container {
					width: 100%;
					@include truncate_text;
				}
			}
		}
	}

	.test-cart-container {
		padding: 0px 24px 24px 24px;
		.divider {
			border: 1px solid $divider-color;
		}
		.item-qty-selection-table {
			.form-table {
				border-left: 1px solid $border-color;
				border-right: 1px solid $border-color;
				.item {
					width: 60%;
					.item-details-container {
						width: 100%;
						display: flex;
						gap: 10px;
						.item-img {
							width: 64px;
							height: 64px;
							object-fit: cover;
							border-radius: 2px;
						}
						.at-foodtype-indicator {
							min-width: 16px;
							min-height: 16px;
						}
						.item-title {
							display: flex;
							flex-direction: column;
							width: 100%;

							.title {
								color: $color-black-80;
								white-space: normal;
							}
						}
						.addons {
							color: #646464;
							font-size: 12px;
							margin: 2px 0px;
							white-space: normal;
						}
					}
				}
				.quantity {
					width: 20%;
					justify-content: center;
					.quantity-wrapper {
						display: flex;
						align-items: center;
						justify-content: space-between;
						.minus-icon {
							padding: 0px 12px 0px 8px;
							cursor: pointer;
						}
						.plus-icon {
							padding: 0px 8px 0px 12px;
							cursor: pointer;
						}
					}
				}
				.pricePerUnit {
					width: 10%;
					justify-content: center;
				}
				.subtotal {
					width: 10%;
					justify-content: center;
				}
			}
		}
		.locations-selection-table-container {
			padding: 0 20px;
			.filters-container {
				display: flex;
				justify-content: space-between;
				margin-bottom: 24px;
				.multi-select-filter-container {
					width: 150px;
				}
				.search-filter {
					margin-right: 0;
				}
			}
			.form-table {
				border-left: 1px solid $border-color;
				border-right: 1px solid $border-color;
				.name {
					width: 50%;
				}
				.city {
					width: 50%;
				}
			}
		}
		.platform-selection-container {
			border-top: 1px solid $border-color;
			padding: 0 24px;
			padding-top: 30px;
			.header {
				font-weight: $font--semibold;
				color: $mc-6;
				font-size: $very-small-font-size;
				padding-left: 10px;
			}
			.radio-value-container {
				text-transform: capitalize;
				color: #363636;
				padding: 8px 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.radio-selector {
					display: flex;
					gap: 12px;
				}
				&:last-child {
					padding-bottom: 16px;
				}
				.platform-logo-container {
					.platform-logo {
						height: 30px;
						width: 30px;
					}
				}
			}
		}
		.bill-details {
			width: 50%;
			float: right;
			padding: 0px 16px;
			.bill-section {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.bill-section-title,
				.bill-section-price {
					color: $color-neutral-black-60;
					font-size: 14px;
				}
				.bill-subtotal-title {
					color: $color-black-80;
					font-size: 18px;
					font-weight: 600;
				}
			}
		}
	}

	.menu-configurations-container {
		.shimmer-container {
			padding: 24px;
		}
		.list-shimmer {
			width: 100%;
		}
		.menu-config-header {
			padding: 24px;

			.menu-config-header-top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.search-filter {
					margin-right: 0;
				}
				.category-items-count {
					font-size: $medium-font-size;
					font-weight: $font--semibold;
					color: $color-dark-grey;
					display: flex;
					.loader-backdrop {
						position: static;
						display: block;
						height: 20px;
						width: 20px;
						background: transparent;
						.loading-indicator-container {
							height: 0;
							width: 0;
							.loading-indicator {
								height: 20px;
								width: 20px;
							}
						}
						&:last-child {
							padding-left: 5px;
						}
					}
				}
			}

			.menu-config-header-bottom {
				height: 24px;
				margin-top: 25px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.selected-items-count {
					font-size: $small-font-size;
					color: $color-dark-grey;
				}
			}
		}
		.main-categories-section {
			border: 1px solid $border-color;
			height: 55vh;
			margin: 0 24px;
			margin-bottom: 15px;
			display: flex;
			.categories-contents-container {
				width: 70%;
				overflow-y: auto;
				box-sizing: border-box;
				padding-bottom: 25px;
				// prevent scroll-chaining to parent elements
				overscroll-behavior: contain;
			}
			.selected-category-content--container {
				position: relative;
				display: flex;
				flex-direction: column;
				&:last-child {
					min-height: 95%;
					margin-bottom: 25px;
				}
				.section-header-container {
					// position: sticky;
					// position: -webkit-sticky;
					// top: 0;
					// z-index: 1;

					box-sizing: border-box;
					padding: 25px;
					background-color: white;
					width: 100%;
					display: flex;
					justify-content: space-between;
					.main-section-details {
						width: 80%;
						display: flex;
						flex-direction: column;
						gap: 2px;
						font-size: $very-small-font-size;
						.title-and-entity-length {
							width: 70%;
							display: flex;
							gap: 8px;
							.title {
								font-weight: $font--semibold;
								color: $color-neutral-black-dark;
								@include truncate_text;
							}
							.entities-count {
								width: 30%;
								color: $color-neutral-black-60;
							}
						}
						.description {
							width: 100%;
							font-size: $extra-small-font-size;
							color: $color-neutral-black-50;
							@include truncate_text;
						}
					}
					.menu-header-cta {
						font-size: $very-small-font-size;
					}
				}
				.box-shadow {
					box-shadow: $box-shadow;
				}
				.menu-item-list {
					padding: 0px 25px;
				}
				.form-table {
					display: flex;
					flex-direction: column;
					box-sizing: border-box;
					border-left: 1px solid $border-color;
					border-right: 1px solid $border-color;
					&--header {
						position: static;
					}
					&--list-item {
						overflow: visible;
						.at-checkbox {
							width: min-content;
						}
					}
					.item {
						width: 45%;
						overflow: hidden;
						.item-details-container {
							width: 100%;
							display: flex;
							gap: 10px;
							.item-img {
								width: 64px;
								height: 64px;
								object-fit: cover;
							}
							.at-foodtype-indicator {
								flex-shrink: 0;
							}
						}
					}
					.activeRules {
						width: 15%;
						display: flex;
						justify-content: center;
						.number-of-rules {
							display: flex;
							align-items: center;
							gap: 8px;
						}
					}
					.price {
						width: 15%;
					}
					.issues {
						width: 20%;
						display: flex;
						justify-content: center;
					}
					.contextMenu {
						width: 5%;
						position: relative;
					}
					margin-bottom: 16px;
				}
				.add-items-cta {
					height: 16px;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					font-size: $very-small-font-size;
					border: 1px solid $border-color;
					padding: 12px 16px;
					.sub-category-details {
						display: flex;
						gap: 6px;
						.sub-category-name {
							font-weight: $font--semibold;
							color: $color-neutral-black-dark;
						}
						.items-length {
							color: $color-neutral-black-60;
						}
					}
				}
				.justify-space-between {
					justify-content: space-between;

					.actions-container {
						display: flex;
						gap: 8px;
					}
					.context-menu {
						position: relative;
						top: 0;
						right: 0;
					}
				}
				.empty-items-placeholder {
					border: 1px solid $border-color;
					padding: 72px 24px;
					display: flex;
					gap: 15px;
					justify-content: center;
					align-items: center;
					margin-bottom: 16px;
					.empty-items-icon {
						height: 60px;
						width: 80px;
					}
					.empty-items-text {
						font-size: $medium-large-font-size;
						font-weight: $font--semibold;
						color: $mc-6;
					}
				}
				.placeholder-main {
					padding: 0;
				}
			}
		}
	}
	.preview-cart-header {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 24px 24px 24px;
		.header-text {
			.revert-link {
				color: $color-blue;
				display: flex;
				gap: 10px;
				margin-bottom: 10px;
				cursor: pointer;
				align-items: center;
				font-weight: $font--semibold;
				img {
					width: 12px;
					height: 9px;
				}
			}
			.menu-title {
				font-weight: $font--semibold;
				font-size: $large-font-size;
			}
		}
		.header-ctas {
			display: flex;
			gap: 12px;
		}
		.test-cart-btn-wrapper {
			position: relative;

			.test-cart-items {
				background-color: $color-blue;
				color: #fff;
				position: absolute;
				border-radius: 50%;
				font-size: 12px;
				width: 20px;
				height: 20px;
				top: -10px;
				right: -10px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.status-alert-container {
		margin-bottom: 10px;
	}
	.preview-cart-container {
		border: 1px solid $border-color;
		margin: 0 24px;
		margin-bottom: 20px;
		height: 55vh;
		display: flex;
		.parent-categories-list {
			width: 25%;
		}
		.preview-sections-container {
			height: 100%;
			overflow-y: scroll;
			width: 75%;
			box-sizing: border-box;
			// prevent scroll-chaining to parent elements
			overscroll-behavior: contain;
		}
		.preview-items-container {
			&:last-child {
				min-height: 100%;
			}
			.items-header {
				display: flex;
				padding: 24px 24px 20px 24px;
				font-size: $very-small-font-size;
				color: $color-dark-grey;
				font-weight: $font--semibold;
				gap: 8px;
				background: $mc-1;
				position: sticky;
				top: -1px;
				border-bottom: 1px solid $border-color;
				z-index: 10;
				transition: 180ms;
				&.shadow {
					box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
				}
				.items-count {
					font-weight: 400;
					color: $mc-6;
				}
			}
			.preview-item {
				margin-bottom: 20px;
				padding: 0 24px;
				padding: 24px;
				.rule-badge-wrapper {
					.popover-container {
						left: 110px;
						.rule-badge-popover {
							p {
								padding: 0px 4px 0px 8px;
								font-size: 14px;
							}
						}
					}
				}
				.rule-applied-badge {
					display: flex;
					align-items: center;
					width: fit-content;
					border-radius: 2px;
					margin-bottom: 16px;
					gap: 4px;
					padding: 2px 6px;
					background-color: #f2f5ff;
					color: #374f78;
					svg {
						width: 12px;
						height: 12px;
					}
					p {
						margin: 0;
						font-size: 12px;
					}
				}
				.preview-item-flex {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}

				.quantity-btn:hover .minus-icon,
				.quantity-btn:hover .plus-icon {
					filter: brightness(0) saturate(100%) invert(29%) sepia(58%) saturate(4732%) hue-rotate(223deg)
						brightness(99%) contrast(98%);
				}
				.quantity-btn {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 12px 0px;

					.minus-icon,
					.plus-icon {
						padding: 0 16px;
					}

					span {
						padding: 0 8px;
					}
				}
				.item-info {
					width: auto;
					max-width: 75%;
					.foodtype-title {
						display: flex;
						gap: 8px;
						.foodtype-indicator {
							height: 12px;
							width: 12px;
							position: relative;
						}
						.veg {
							border: 2px solid #388e3c;
							&::after {
								position: absolute;
								height: 8px;
								width: 8px;
								content: "";
								top: 50%;
								left: 50%;
								border-radius: 50%;
								background-color: #388e3c;
								transform: translate(-50%, -50%);
							}
						}
						.egg {
							border: 2px solid #a8870f;
							&::after {
								position: absolute;
								height: 8px;
								width: 8px;
								content: "";
								top: 50%;
								left: 50%;
								border-radius: 50%;
								background-color: #a8870f;
								transform: translate(-50%, -50%);
							}
						}
						.non-veg {
							border: 2px solid #bf360c;
							&::after {
								position: absolute;
								height: 0px;
								width: 0px;
								content: "";
								border-left: 4px solid transparent;
								border-right: 4px solid transparent;
								border-bottom: 8px solid #bf360c;
								top: 15%;
								left: 15%;
							}
						}
						.food-title {
							font-weight: $font--semibold;
							color: $color-light-black;
						}
					}
					.item-description {
						padding-top: 10px;
						color: $color-dark-grey;
						font-size: $very-small-font-size;
					}
					.prices-container {
						margin-top: 24px;
						font-size: $very-small-font-size;
						display: flex;
						gap: 4px;
						.markup-price {
							color: $mc-6;
							position: relative;
							&::before {
								position: absolute;
								content: "";
								left: 0;
								top: 50%;
								right: 0;
								border-top: 1px solid;
								border-color: inherit;
								-webkit-transform: rotate(336deg);
								-moz-transform: rotate(336deg);
								-ms-transform: rotate(336deg);
								-o-transform: rotate(336deg);
								transform: rotate(336deg);
							}
						}
						.actual-price {
							font-weight: $font--semibold;
							color: $color-light-black;
						}
					}
					.food-contents {
						margin-top: 12px;
						font-size: $extra-small-font-size;
						color: $color-dark-grey;
					}
					@include bp(768px) {
						width: 100%;
					}
				}
				.item-actions {
					width: 150px;
					display: flex;
					justify-content: flex-end;
					position: relative;
					.item-image {
						height: 100px;
						width: 150px;
						object-fit: cover;
						border-radius: $border-radius-default;
					}
					.at-btn {
						position: absolute;
						bottom: -18px;
						left: 25px;
					}
					@include bp(768px) {
						width: 100%;
					}
					.customisable-text {
						position: absolute;
						font-size: $extra-small-font-size;
						color: $mc-6;
						bottom: -35px;
						right: 40px;
					}
				}
			}
			.placeholder-main {
				border-bottom: 1px solid $border-color;
				margin-bottom: 0;
			}
			&--border-bottom {
				border-bottom: 1px solid $border-color;
				width: 100%;
			}
			.empty-items-placeholder {
				border-bottom: 1px solid $border-color;
				padding: 72px 24px;
				display: flex;
				gap: 15px;
				justify-content: center;
				align-items: center;
				.empty-items-icon {
					height: 60px;
					width: 80px;
				}
				.empty-items-text {
					font-size: $medium-large-font-size;
					font-weight: $font--semibold;
					color: $mc-6;
				}
			}
		}
		.parent-categories-list {
			.parent-sections-list-container {
				height: 100%;
			}
		}
	}
	.parent-categories-list {
		width: 30%;
		border-right: 1px solid $border-color;
		height: 100%;
		position: relative;
		.parent-sections-list-container {
			height: 80%;
			overflow-y: scroll;
		}
		.parent-category {
			padding: 15px 24px;
			color: $color-dark-grey;
			position: relative;
			cursor: pointer;
			.parent-category--name {
				width: 90%;
				display: block;
				@include truncate_text;
			}
			&--selected {
				border-left: 3px solid $color-blue;
				background-color: #f2f5ff;
				color: $color-blue;
				font-weight: $font--semibold;
			}
			&--selected-includes-subsection {
				padding-left: 10px;
				// &::before {
				// 	content: "";
				// 	position: absolute;
				// 	top: 40px;
				// 	left: 17px;
				// 	width: 2px;
				// 	height: 25px;
				// 	background-color: #d0dadd;
				// }
				&::after {
					content: ".";
					position: absolute;
					color: #d0dadd;
					top: 14px;
					left: 13px;
					font-size: 38px;
				}
			}
			.context-menu {
				.menu {
					.action-item {
						font-weight: 400;
						color: $color-neutral-black-dark;
					}
				}
			}
		}
		.sub-sections-list {
			background-color: #f2f5ff;
			color: $color-blue;
			font-weight: $font--semibold;
			.sub-section {
				width: 65%;
				padding: 15px 24px;
				padding-left: 42px;
				@include truncate_text;
				position: relative;
				box-sizing: border-box;
				&::before {
					content: "";
					position: absolute;
					top: 24px;
					left: 20px;
					width: 13px;
					height: 2px;
					background-color: #d0dadd;
				}
				&::after {
					content: ".";
					position: absolute;
					color: #d0dadd;
					top: -7px;
					left: 26px;
					font-size: 38px;
				}
				.line--vertical {
					position: absolute;
					left: 20px;
					top: 0;
					width: 2px;
					height: 50px;
					background-color: #d0dadd;
					&.half {
						height: 25px;
					}
				}
			}
		}
		.cta-container {
			padding-left: 24px;
			min-height: 24px;
			.context-menu {
				right: unset;

				.menu {
					top: -82px;
					right: -65px;
				}
			}
		}
		.action-content {
			display: flex;
			gap: 6px;
		}
		.reorder-menu--cta {
			position: absolute;
			box-shadow: $default-shadow;
			display: flex;
			flex-direction: column;
			justify-content: center;

			background-color: white;
			height: 80px;
			width: 100%;
			bottom: 0;
			.plus {
				width: 12px;
				text-align: center;
			}
		}
	}

	.version-preview-container {
		.location-platform-selector {
			margin-top: 24px;
		}
	}
	.customisable-item-drawer-container {
		.shimmer-container {
			padding: 24px;
		}
		.item-basic-details {
			padding: 24px;
			display: flex;
			gap: 24px;
			.item-image-container {
				img {
					border-radius: $border-radius-default;
					width: 200px;
					height: 150px;
				}
			}
			.item-info {
				.foodtype-item-name {
					display: flex;
					gap: 8px;
					align-items: center;
					.foodtype-indicator {
						height: 18px;
						width: 18px;
						position: relative;
					}
					.veg {
						border: 2px solid #388e3c;
						&::after {
							position: absolute;
							height: 12px;
							width: 12px;
							content: "";
							top: 50%;
							left: 50%;
							border-radius: 50%;
							background-color: #388e3c;
							transform: translate(-50%, -50%);
						}
					}
					.non-veg {
						border: 2px solid #bf360c;
						&::after {
							position: absolute;
							height: 0px;
							width: 0px;
							content: "";
							border-left: 6px solid transparent;
							border-right: 7px solid transparent;
							border-bottom: 13px solid #bf360c;
							top: 15%;
							left: 15%;
						}
					}
					.item-name {
						font-weight: $font--semibold;
						color: $color-light-black;
						font-size: $medium-large-font-size;
					}
				}
				.desc {
					margin-top: 4px;
					color: $color-dark-grey;
					margin-bottom: 24px;
				}
				.food-content {
					font-size: $very-small-font-size;
					color: $color-dark-grey;
				}
			}
		}
		.modifier-quantity-selector-container {
			border: 1px solid $border-color;
			margin-top: 28px;
			margin-left: 24px;
			margin-right: 24px;
			margin-bottom: 28px;

			.header {
				padding: 16px;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid $border-color;
				margin-bottom: 8px;
				.action-header {
					font-size: $medium-font-size;
					font-weight: $font--semibold;
					color: $color-dark-grey;
					.qty-min-limit {
						font-weight: 400;
						font-size: $very-small-font-size;
					}
				}
				.total-modifiers-count {
					font-size: $very-small-font-size;
					color: $color-dark-grey;
				}
			}
			.radio-value-container {
				padding: 8px 16px;
				display: flex;
				justify-content: space-between;
				.radio-selector {
					display: flex;
					gap: 12px;
					label {
						display: flex;
						align-items: center;
						gap: 8px;
						color: $color-neutral-black-60;
						span {
							color: $color-neutral-black-80;
						}
					}
				}
				&:last-child {
					padding-bottom: 16px;
				}
			}
		}
	}

	// publish history table styling
	.publish-history-table-container {
		padding: 24px;
		position: relative;
		.form-table {
			border-right: 1px solid $border-color;
			border-left: 1px solid $border-color;
			&--header {
				top: 96px;
			}
			&--list-item {
				overflow: visible;
				position: relative;
			}
			.version {
				width: 20%;
			}
			.associatedItemCount {
				width: 16%;
			}
			.associatedLocations {
				width: 16%;
				.locations-list {
					display: flex;
					gap: 8px;
					.popover-container {
						padding: 4px 8px;
						color: $color-neutral-black-60;
						font-size: $extra-small-font-size;
						.loc-name {
							line-height: 1.5;
						}
					}
				}
			}
			.associatedPlatforms {
				width: 24%;
				.platform-logos {
					display: flex;
					gap: 8px;
					flex-wrap: wrap;
					.platform-logo {
						height: 28px;
						width: 28px;
						border-radius: $border-radius-default;
					}
				}
			}
			.lastPublishedOn {
				width: 20%;
				&--container {
					.last-publish-data-time {
						display: flex;
						flex-direction: column;
						.time {
							color: $color-neutral-black-50;
						}
					}
				}
			}
			.contextMenu {
				width: 4%;
				.context-menu {
					position: static;
					top: 0;
					right: 0;
					.menu {
						top: 40px;
						right: 18px;
					}
				}
			}
		}
	}

	// publish version details style
	.publish-history-details-container {
		border: 1px solid #eaeaea;
		width: 97%; // this is an adjustment for the overflow of the table
		margin: 24px;
		min-height: 50vh;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		.list-shimmer {
			width: 100%;
		}
		.date-time-history-list {
			width: 30%;
			max-width: 240px;
			min-width: 150px;
			border-right: 1px solid #eaeaea;
			min-height: 300px;
			position: relative;
			font-size: $very-small-font-size;
			.timestamp-author-container {
				padding: 15px 24px;
				color: $color-neutral-black-60;
				position: relative;
				cursor: pointer;
				.author-name-container {
					color: $color-neutral-black-40;
					width: 100%;
					@include truncate_text;
				}

				&--selected {
					border-left: 3px solid $color-blue;
					background-color: $color-blue-1;
					color: $color-blue;
					.timestamp-container {
						font-weight: $font--semibold;
					}
					.author-name-container {
						color: $color-blue;
					}
				}
			}
		}
		.selected-timestamp-locations-list {
			padding: 20px 24px;
			width: calc(100% - 240px); // 240px is the max width of the date-time-history-list
			box-sizing: border-box;
			.metadata-filters-container {
				margin-bottom: 16px;
				.metadata-publish-status {
					margin-bottom: 8px;
					width: fit-content;
					display: flex;
					justify-content: center;
					text-transform: capitalize;
					.pub-stat-span {
						padding: 2px 8px;
						border-radius: $default-radius;
						color: $mc-5;
						background-color: $mc-2;
						text-transform: capitalize;
					}
					.failed {
						color: $color-red-7;
						background-color: $color-red-1;
					}
					.draft {
						color: $color-neutral-black-50;
						background-color: $color-neutral-black-05;
					}
					.intermediate-state {
						background-color: $color-yellow-10;
						color: $color-yellow-70;
					}
					.success {
						background-color: $color-green-10;
						color: $color-green-70;
					}
					.publish-success-icon {
						padding: 0 16px;
					}
				}
				.metadata-container {
					display: flex;
					gap: 8px;
					font-size: $very-small-font-size;
					font-weight: $font--semibold;
					color: $color-neutral-black-60;
					margin-bottom: 24px;
					.loc-count {
						color: $color-neutral-black-50;
					}
					.platform-count {
						color: $color-neutral-black-50;
					}
				}
				.metadata-filters-row {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.metadata-filter-city {
						width: 120px;
					}
				}
				.search-filter {
					margin-right: 0;
				}
			}
			.form-table-wrapper {
				width: 100%;
				overflow-x: auto;
				.form-table {
					border-left: 1px solid $border-color;
					border-right: 1px solid $border-color;
					width: fit-content;
					min-width: 100%;
					box-sizing: border-box;
					.locationName {
						min-width: 100px;
						width: 25%;
						font-weight: $font--semibold;
					}
					.city {
						min-width: 80px;
						width: 25%;
					}
					.publishStatus {
						min-width: 80px;
						width: 25%;
						display: flex;
						justify-content: center;
						&--container {
							.pub-stat-span {
								padding: 2px 8px;
								border-radius: $default-radius;
								color: $mc-5;
								background-color: $mc-2;
								text-transform: capitalize;
							}
							.failed {
								color: $color-red-7;
								background-color: $color-red-1;
							}
							.draft {
								color: $color-neutral-black-50;
								background-color: $color-neutral-black-05;
							}
							.intermediate-state {
								background-color: $color-yellow-10;
								color: $color-yellow-70;
							}
							.success {
								background-color: $color-green-10;
								color: $color-green-70;
							}
							.publish-success-icon {
								padding: 0 16px;
							}
						}
					}
					&--list-item {
						overflow: visible;
					}
				}
			}
		}
	}
	.published-version-restore--modal {
		.modal-header {
			text-align: left;
			padding: 16px 24px;
			.header-left {
				.title {
					font-size: $medium-font-size;
					color: $color-neutral-black-80;
				}
			}
		}
		&--failure {
			border: 1px solid $color-red-7;
			padding: 24px;
			color: $color-red-7;
			background-color: $color-red-1;
			border-radius: $border-radius-default;
		}
		&--success {
			border: 1px solid $color-green-70;
			padding: 24px;
			color: $color-green-70;
			background-color: $color-green-10;
			border-radius: $border-radius-default;
		}
		.modal-content {
			padding: 0 24px;
			color: $color-neutral-black-80;
			line-height: 1.5;
			.body-main-text {
				font-weight: $font--semibold;
				font-size: $medium-font-size;
			}
			.body-subtext {
				font-size: $small-font-size;
			}
		}
		.modal-footer {
			padding: 16px 12px;
			text-align: right;
		}
	}

	// entity deletion styles
	.menu-entity-deletion--modal {
		.at-modal {
			.modal-header {
				padding: 15px 25px;
				.header-left {
					.title {
						color: $color-light-black;
						text-align: left;
						font-size: 18px;
					}
				}
				border-bottom: 1px solid $border-color;
			}
			.modal-container {
				width: 30%;
				min-width: 505px;
			}
			.modal-footer {
				text-align: right;
				border-top: 1px solid $border-color;
				.restore-action {
					background-color: white;
					border: 1px solid $color-green;
					color: $color-green;
					&:hover {
						background-color: $color-green;
						color: $mc-1;
						opacity: 1;
					}
				}
			}
			.entity-details {
				padding: 25px;
				.warning {
					font-weight: $font--semibold;
					color: $color-light-black;
					margin-bottom: 24px;
				}
				.confirm-process {
					display: flex;
					gap: 13px;
					align-items: center;
					.at-checkbox {
						position: relative;
						padding-left: 0;
						.checkmark {
							position: relative;
							display: block;
							height: 14px;
							width: 14px;
						}
					}
				}
				.end-result {
					display: flex;
					padding: 25px 0;
					border-radius: $border-radius-default;
					justify-content: center;
					&.success {
						background-color: rgba($color-green, 0.1);
						color: $color-green;
						border: 1px solid $color-green;
					}
					&.failure {
						background-color: rgba($color-red, 0.1);
						color: $color-red;
						border: 1px solid $color-red;
					}
				}
			}
		}
	}
	.menu-rules-container {
		&--header {
			display: flex;
			flex-direction: column;
			padding: 24px 24px 16px 24px;
			gap: 16px;
			.count-create-cta-container {
				display: flex;
				justify-content: space-between;
				.count {
					font-size: $medium-font-size;
					color: $color-neutral-black-80;
					font-weight: $font--semibold;
				}
			}
			.rule-selector-search-container {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.dropdown-filter-container {
					display: flex;
					gap: 12px;
					flex-wrap: wrap;
					.multi-select-filter-container {
						width: 150px;
					}
				}
				.multi-select-filter-container {
					width: 200px;
				}
				.search-filter {
					margin-right: 0;
				}
			}
		}
		.rules-basic-info-container {
			padding: 24px;
			.rules-basic-info-content {
				border: 1px solid $border-color;
				.header-section {
					padding: 12px 16px;
					border-bottom: 1px solid $border-color;
					.title-subtitle-container {
						display: flex;
						justify-content: space-between;
						.actions-container {
							display: flex;
							gap: 18px;
							.at-switch-cnt {
								.at-switch {
									.switch-rail {
										margin: 0;
										background-color: $btn-grey-color;
									}
									.switch-ball {
										top: 2px;
									}
									&.active {
										.switch-rail {
											background-color: $color-green-50;
										}
									}
								}
							}
							.context-menu {
								position: relative;
								top: 0;
								right: 0;
								.icon-dots {
									padding-top: 4px;
								}
								.menu {
									top: 30px;
									.action-item--archive {
										color: $color-red;
									}
								}
							}
						}
					}
					.title {
						color: $color-neutral-black-60;
						font-weight: $font--semibold;
					}
					.sub-title {
						color: $color-neutral-black-50;
						font-size: $very-small-font-size;
					}
				}
				.rule-info {
					padding: 12px;
					border-bottom: 1px solid $border-color;
					.header-actions-container {
						display: flex;
						justify-content: space-between;

						.rule-title {
							font-weight: $font--semibold;
							font-size: $very-small-font-size;
							color: $color-neutral-black-50;
							margin-bottom: 12px;
							.hyperlink {
								color: inherit;
								&::after {
									background-color: $color-neutral-black-50;
								}
							}
						}
					}
					.rule-description {
						padding: 16px 0;
						font-size: $very-small-font-size;
						color: $color-neutral-black-60;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						row-gap: 10px;
					}
					&:last-child {
						border-width: 0;
					}
				}
				margin-bottom: 24px;
			}
		}
	}
	.rules-creation-container {
		.rules-info-preview-container {
			border-top: 1px solid $border-color;
			display: flex;
			width: 100%;
			height: 100%;
			overflow: hidden;
		}
		.rule-basic-information-container {
			padding: 0 24px;
			padding-top: 24px;
			width: 50%;
			border-right: 1px solid $border-color;
			min-height: 76vh;
			overflow-y: scroll;
			.rule-name-input {
				.at-input {
					height: 54px;
					padding: 16px;
				}
				position: relative;
				.validation-message {
					bottom: 0;
				}
				margin-bottom: 24px;
			}
			.rule-section-container {
				box-sizing: content-box;
				display: grid;
				margin-bottom: 10px;
				grid-template-columns: 1fr 3fr;
				.selectors-container {
					.do-not-sell-checkbox {
						margin: 4px 0px;
					}
					width: 100%;
					.multi-select-filter-container {
						width: 100%;
						margin-bottom: 12px;
					}
					.location-plf-selection-drawer-cta-container {
						margin: 12px 0px;
					}
					.select-locations-cta {
						box-sizing: border-box;
						position: relative;
						margin-bottom: 12px;
						width: 100%;
						height: auto;
						font-weight: 600;
						.opted-locations-list {
							.edit-loc-icon {
								position: absolute;
								top: 8px;
								right: 0px;
							}
						}
					}
					.item-group-select-list {
						margin-bottom: 12px;
					}
					.select-error-location-indicator {
						border: 1px solid $color-red-5;
					}
					.attribute-container {
						position: relative;
						margin-top: 12px;
						margin-bottom: 20px;
						.close-icon {
							position: absolute;
							top: 15px;
							right: -15px;
							height: 8px;
							width: 8px;
							cursor: pointer;
						}
						&--primary {
							margin-top: 0;
						}
					}
					.select-error-indicator {
						.react-select__control {
							border: 1px solid $color-red-5;
						}
					}
				}
				.title {
					width: 40px;
					height: auto;
					padding: 5px 0;
					padding-right: 32px;
					line-height: 22px;
					font-weight: $font--semibold;
					color: $color-neutral-black-50;
					min-height: 120px;
					position: relative;
					text-align: center;
					&::after {
						position: absolute;
						content: "";
						top: 40px;
						left: 15px;
						min-height: 50px;
						height: 70%;
						width: 2px;
						background-color: $color-neutral-black-50;
					}
				}
				.values-input {
					display: flex;
					width: 100%;
					gap: 8px;
				}
				&:last-child {
					.title {
						&::after {
							min-height: 0;
							height: 0;
						}
					}
				}
			}
			.overlapping-error-card {
				display: flex;
				align-items: center;
				img {
					width: 12px;
					height: 12px;
				}
				margin-bottom: 24px;
				padding: 8px;
				gap: 8px;
				border-radius: 2px;
				font-size: 12px;
				line-height: 16px;
				color: $color-neutral-black-60;
				background: linear-gradient(0deg, rgba(255, 229, 229, 0.4) 0%, rgba(255, 229, 229, 0.4) 100%), #fff;
				.attr-header {
					font-weight: 400;
				}
				.attr-list {
					font-weight: $font--semibold;
				}
			}
		}
		.preview-wrapper {
			width: 50%;
			display: flex;
			flex-direction: column;
			height: 100%;
			.header {
				padding: 24px;
				text-transform: uppercase;
				font-size: $extra-small-font-size;
				font-weight: 600;
				color: $color-neutral-black-60;
				box-shadow: 0px 4px 4px 0px #4242420a;

				position: sticky;
				top: 0;
				z-index: 10;
			}
			.preview-container {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				overflow-y: auto;
				box-sizing: border-box;
			}
			.rule-preview-container {
				padding: 24px;
				border-bottom: 1px solid #eaeaea;

				.status-container {
					margin-bottom: 12px;
					.status {
						padding: 2px 8px;
						font-size: $extra-small-font-size;
						border-radius: $border-radius-default;
						&.default {
							background-color: $color-neutral-black-05;
							color: $color-neutral-black-50;
						}
						&.overriden {
							color: #374f78;
							background-color: #ecf0f7;
						}
					}
				}
				.item-details-container {
					display: flex;
					gap: 8px;
					margin-bottom: 32px;
					.image-placeholder {
						height: 72px;
						width: 72px;
						border-radius: $border-radius-default;
						background: linear-gradient(126deg, #eaeaea -23.35%, rgba(234, 234, 234, 0) 105.52%);
					}
					.item-info-container-placeholder {
						.item-name {
							font-size: $very-small-font-size;
							font-weight: $font--semibold;
							color: $color-neutral-black-80;
							margin-bottom: 12px;
						}
						.item-details-pending {
							width: 195px;
							height: 12px;
							background: linear-gradient(126deg, #eaeaea -23.35%, rgba(234, 234, 234, 0) 105.52%);
							margin-bottom: 12px;
						}
					}
				}
				.entity-details-container {
					display: flex;
					gap: 8px;
					margin-bottom: 32px;
					.image-container {
						.entity-image {
							height: 72px;
							width: 72px;
							object-fit: cover;
							border-radius: $border-radius-default;
						}
					}
					.entity-info-container {
						.entity-name {
							font-size: $very-small-font-size;
							font-weight: $font--semibold;
							color: $color-neutral-black-80;
							margin-bottom: 2px;
							width: 200px;
							@include truncate_text;
						}
						.entity-description {
							width: 200px;
							margin-bottom: 2px;
							font-size: $extra-small-font-size;
							color: $color-neutral-black-60;
						}
						.entity-pricing-container {
							display: flex;
							gap: 4px;
							.markup-price {
								color: $mc-6;
								position: relative;
								&::before {
									position: absolute;
									content: "";
									left: 0;
									top: 50%;
									right: 0;
									border-top: 1px solid;
									border-color: inherit;
									-webkit-transform: rotate(336deg);
									-moz-transform: rotate(336deg);
									-ms-transform: rotate(336deg);
									-o-transform: rotate(336deg);
									transform: rotate(336deg);
								}
							}
						}
					}
				}
			}
		}

		.locations-selection-table-container {
			padding: 0 20px;
			.status-alert-container {
				padding: 15px;
				margin: 24px 0;
				display: flex;
				align-items: flex-start;
				border: 1px solid $border-color;
				border-left: 4px solid $color-red-5;
				border-radius: $border-radius-default;
				background: linear-gradient(0deg, rgba(255, 229, 229, 0.4) 0%, rgba(255, 229, 229, 0.4) 100%), #fff;
				gap: 12px;
				.error-text-link {
					color: $color-dark-grey;
					.main-error-header {
						font-size: $medium-font-size;
						font-weight: $font--semibold;
						margin-bottom: 4px;
					}
					.main-error-desc {
						font-size: $very-small-font-size;
						margin-bottom: 12px;
					}
					.hyperlink {
						display: flex;
						align-items: center;
						gap: 4px;
						img {
							width: 16px;
							height: 16px;
						}
					}
				}
			}
			.filters-container {
				display: flex;
				justify-content: space-between;
				margin-bottom: 24px;
				.multi-select-filter-container {
					width: 150px;
				}
				.search-filter {
					margin-right: 0;
				}
			}
			.conflicting-locations-drawer {
				padding: 0px 24px;
				.conflict-message {
					border-radius: 2px;
					border-left: 4px solid $color-red-5;
					background: linear-gradient(0deg, rgba(255, 229, 229, 0.4) 0%, rgba(255, 229, 229, 0.4) 100%), #fff;
					margin-bottom: 24px;
					display: flex;
					gap: 12px;
					padding: 12px;
					img {
						margin-bottom: 12px;
					}
					p {
						margin: 0;
						font-size: $very-small-font-size;
						line-height: 22px;
						color: $color-neutral-black-80;
					}
				}
				.resolve-message {
					border-radius: 2px;
					border-left: 4px solid $color-green-70;
					background: $color-green-10;
					margin-bottom: 24px;
					display: flex;
					gap: 12px;
					padding: 12px;
					align-items: center;
					p {
						margin: 0;
						font-size: $very-small-font-size;
						line-height: 22px;
						color: $color-neutral-black-80;
					}
				}
			}
			.form-table {
				margin-top: 24px;
				border-right: 1px solid $border-color;
				border-left: 1px solid $border-color;
				margin-bottom: 24px;

				.name {
					width: 50%;
				}
				.city {
					width: 30%;
				}
				.isConflicting {
					width: 20%;
				}
			}
			.sort-by-association-checkbox-container {
				display: flex;
				font-size: $very-small-font-size;
				color: $color-neutral-black-60;
				margin-bottom: 12px;
				align-items: center;
				justify-content: flex-end;
			}
		}
		.at-paginator {
			padding-right: 0;
		}
	}
	.menu-pending-changes-warning--modal {
		.modal-content {
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.modal-header {
			.header-left {
				.title {
					color: $color-light-black;
					text-align: left;
					font-size: 18px;
				}
			}
			border-bottom: 1px solid $border-color;
		}
		.modal-footer {
			text-align: right;
			border-top: 1px solid $border-color;
		}
	}
	.menu-preview-configuration-selection {
		.form-container {
			padding: 0 24px;
			display: flex;
			flex-direction: column;
			gap: 32px;
		}
	}
	.location-platform-selector {
		padding: 0 24px 24px 24px;
		display: flex;
		gap: 12px;
		.multi-select-filter-container {
			width: 200px;
		}
	}
	.language-selector-container {
		margin-left: auto;
		.multi-select-filter-container {
			width: 100px;
		}
	}
	.rules-conflicting-modal-container {
		.modal-container {
			max-width: 572px;

			.modal-header {
				box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
			}
			.modal-close {
				display: none;
			}
			.error-rule-header {
				display: flex;
				img {
					margin-right: 8px;
				}
				p {
					margin: 0;
				}
			}
			.rules-conflicting-modal-content {
				padding: 24px;
				p {
					margin: 0;
				}
				color: $color-neutral-black-80;
				.conflict-list {
					margin: 16px 0px;
					p {
						margin: 3px 0px;
					}
					span {
						font-weight: 600;
					}
				}
			}
			.modal-footer {
				display: flex;
				flex-direction: row-reverse;
				border-top: 1px solid #eaeaea;
				padding: 12px 16px;
				font-weight: 600;
				font-size: 16px;
				.at-btn {
					width: unset;
					border-radius: 2px;
				}
				.at-btn--secondary {
					background-color: $color-blue-40;
					color: $mc-1;
				}
			}
		}
	}
	.resolve-conflict-drawer {
		border-top: 1px solid $mc-3;
		padding: 24px;
		.conflict-message {
			border-radius: 2px;
			border-left: 4px solid $color-red-5;
			background: linear-gradient(0deg, rgba(255, 229, 229, 0.4) 0%, rgba(255, 229, 229, 0.4) 100%), #fff;
			margin-bottom: 24px;
			display: flex;
			gap: 12px;
			padding: 12px;
			img {
				margin-bottom: 12px;
			}
			p {
				margin: 0;
				font-size: $very-small-font-size;
				line-height: 22px;
				color: $color-neutral-black-80;
			}
		}
		.resolve-message {
			border-radius: 2px;
			border-left: 4px solid $color-green-70;
			background: $color-green-10;
			margin-bottom: 24px;
			display: flex;
			gap: 12px;
			padding: 12px;
			align-items: center;
			p {
				margin: 0;
				font-size: $very-small-font-size;
				line-height: 22px;
				color: $color-neutral-black-80;
			}
		}
		.rule-card {
			border-radius: 4px;
			border: 1px solid $mc-3;
		}

		.new-rule-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 16px;
			p {
				margin: 0;
				color: $color-neutral-black-60;
				font-weight: $font--semibold;
			}
			border-bottom: 1px solid $mc-3;
		}
		.new-rule-body {
			margin: 16px;
			.new-rule-name {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 12px;
				p {
					color: $color-neutral-black-50;
					font-size: $very-small-font-size;
					font-weight: $font--semibold;
				}
				.context-menu {
					position: relative;
					top: 0;
					right: 0;
					.icon-dots {
						padding-top: 4px;
					}
					.menu {
						top: 30px;
						.action-item--archive {
							color: $color-red;
						}
					}
				}
			}
			.separator {
				border: 1px solid $mc-3;
				margin: 16px 0px;
			}
		}
		.new-rule-card {
			margin-bottom: 24px;
		}
		.rule-description-conflict-highlighter {
			color: $color-neutral-black-60;
			font-size: $very-small-font-size;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			row-gap: 10px;
		}
	}
	.form-sidebar-container {
		.form-container {
			.fix-error-drawer-table-wrapper {
				padding: 0 24px;
				.fix-error-msg-wrapper {
					border-radius: 2px;
					padding: 0 16px;
					margin-bottom: 12px;
					display: flex;
					gap: 12px;
					align-items: center;
					p {
						color: $color-neutral-black-80;
						font-size: 14px;
					}
				}
				.form-table {
					border: 1px solid $border-color;
					.form-table--header {
						.item {
							width: 30%;
						}
						.issue {
							width: 45%;
						}
						.platforms {
							width: 25%;
						}
					}
					.form-table--list {
						&:last-child {
							border-bottom: none;
						}
						.item {
							width: 30%;
							.fix-error-item-list-data {
								.name {
									font-size: $very-small-font-size;
									font-weight: 600;
									color: $color-neutral-black-80;
								}
								.type {
									text-transform: uppercase;
									font-size: $extra-small-font-size;
									font-weight: 600;
									color: $color-neutral-black-40;
								}
							}
						}
						.issue {
							width: 45%;
							white-space: unset;
							.fix-error-issue-list-data {
								display: flex;
								gap: 8px;
							}
						}
						.platforms {
							width: 25%;
							min-height: inherit;
							&--container {
								display: flex;
								align-items: center;
							}
							.fix-error-issue-list-data {
								display: flex;
								align-items: center;
								gap: 8px;
								img {
									width: 28px;
									height: 28px;
									object-fit: cover;
									border-radius: 2px;
								}
							}
						}
					}
				}
			}
		}
	}

	.create-section-container {
		.form-container {
			.item-error-msg-wrapper {
				margin: 12px 16px;
				border-radius: 2px;
				padding: 0 16px;
				display: flex;
				gap: 12px;
				align-items: center;
				p {
					color: $color-neutral-black-80;
					font-size: 14px;
				}
			}
		}
	}
	.rule-preview-drawer {
		.preview-heading {
			font-size: 12px;
			color: $color-neutral-black-60;
			text-transform: uppercase;
			font-weight: 600;
			margin-bottom: 20px;
		}
		.form-sidebar-container {
			.rule-preview-data-wrapper {
				padding: 24px;
				.rule-applied-badge {
					display: flex;
					align-items: center;
					width: fit-content;
					border-radius: 2px;
					margin-bottom: 12px;
					gap: 4px;
					padding: 2px 6px;
					background-color: #f2f5ff;
					color: #374f78;
					svg {
						width: 12px;
						height: 12px;
					}
					p {
						margin: 0;
						font-size: $extra-small-font-size;
					}
				}
				.status-container {
					margin-bottom: 12px;
					.status {
						padding: 2px 8px;
						font-size: $extra-small-font-size;
						border-radius: $border-radius-default;
						&.default {
							background-color: $color-neutral-black-05;
							color: $color-neutral-black-50;
						}
					}
				}
				.entity-details-container {
					display: flex;
					gap: 8px;
					margin-bottom: 32px;
					.image-container {
						.entity-image {
							height: 72px;
							width: 72px;
							object-fit: cover;
							border-radius: $border-radius-default;
						}
					}
					.entity-info-container {
						.entity-name {
							font-size: $extra-small-font-size;
							font-weight: $font--semibold;
							color: $color-neutral-black-80;
							margin-bottom: 2px;
							@include truncate_text;
						}
						.entity-description {
							width: 300px;
							margin-bottom: 2px;
							font-size: $extra-small-font-size;
							color: $color-neutral-black-60;
						}
						.entity-pricing-container {
							display: flex;
							gap: 4px;
							.markup-price {
								color: $color-neutral-black-50;
								font-size: $extra-small-font-size;
								position: relative;
								&::before {
									position: absolute;
									content: "";
									left: 0;
									top: 50%;
									right: 0;
									border-top: 1px solid;
									border-color: inherit;
									-webkit-transform: rotate(336deg);
									-moz-transform: rotate(336deg);
									-ms-transform: rotate(336deg);
									-o-transform: rotate(336deg);
									transform: rotate(336deg);
								}
							}
							.default-price {
								color: $color-neutral-black-80;
								font-weight: 600;
								font-size: $extra-small-font-size;
							}
						}
					}
				}
			}
			.header {
				border-bottom: 1px solid $divider-color;
			}
		}
	}
}
