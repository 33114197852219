.billing-container {
	margin-top: 10px;
}
.campaign-tariff-header {
	.header {
		margin-bottom: 10px;
		.subheader-text {
			display: flex;
			align-items: center;
			padding: 10px 25px;
			img {
				margin-right: 10px;
				width: 18px;
			}
		}
	}
}
.campaign-tariff {
	padding: 10px 25px 0 25px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	@include bp(768px) {
		flex-direction: column-reverse;
		align-items: center;
	}
	.header-action-button {
		.button-container {
			text-align: right;
			img {
				margin-right: 25px;
				margin-bottom: 5px;
			}
			.button-title {
				font-size: $very-small-font-size;
				color: $color-blue;
				@extend .font--semibold;
				display: inline-block;
				vertical-align: top;
			}
		}
		.conversion-text {
			margin-top: 10px;
			font-size: 14px;
			color: $mc-6;
			margin-bottom: -20px;
		}
	}
}
.ledger {
	.header {
		.header-text {
			@extend .font--semibold;
			position: sticky;
			z-index: 1;
			padding: 10px 25px;
			margin-bottom: 10px;
			font-size: 12px;
			background-color: $mc-2;
			color: $mc-7;
			text-transform: uppercase;
			line-height: 15px;
			@include bp(768px) {
				position: initial;
			}
		}
		.subheader-text {
			display: flex;
			align-items: center;
			padding: 10px 25px;
			margin-bottom: 10px;
			img {
				margin-right: 10px;
				width: 18px;
			}
		}
	}
	.ledger-info {
		padding: 0 25px;
		display: flex;
		flex-direction: column;
	}
	.credit-balance {
		font-size: $medium-font-size;
		color: $mc-9;
		@extend .font--semibold;
		span.title {
			color: $mc-6;
		}
		.balance {
			margin-left: 6px;
		}
		@include bp(768px) {
			margin-bottom: 10px;
		}
	}
	.credits-used {
		height: 25px;
		display: flex;
		align-items: center;
		font-size: $small-font-size;
		color: $mc-9;
		margin-top: 10px;
		@extend .font--semibold;
		span.title {
			color: $mc-6;
		}
		@include bp(768px) {
			margin-bottom: 10px;
		}
		.shimmer {
			display: inline-block;
		}
	}
	.filters-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
		padding: 0 25px;
		.search-icon {
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
		}
		.dismiss-search {
			cursor: pointer;
			position: absolute;
			right: 22px;
			top: 15px;
			transform: translateY(-50%);
			height: 11px;
			width: 11px;
			img {
				width: 100%;
			}
		}
		.search-input {
			display: inline-block;
			vertical-align: top;
			outline: 0;
			padding: 0 0 0 30px;
			font-size: $very-small-font-size;
			border: 1px solid $border-color-dark;
			width: 200px;
			height: 36px;
			box-sizing: border-box;
			border-radius: $border-radius-default;
			margin-right: 10px;
			@include bp(768px) {
				margin-right: 0px;
				width: 100%;
			}
			&::placeholder {
				color: #d2d2d2;
			}
		}
		.search-input-holder {
			position: relative;
			@include bp(768px) {
				margin-top: 10px;
				display: flex;
			}
		}
	}
	.header-action-button {
		display: flex;
		align-items: center;
		.filter-buttons {
			display: flex;
			flex-direction: row-reverse;
			align-items: flex-end;
			margin-left: 10px;
			& > div {
				float: right;
				position: relative;
				@include bp(768px) {
					float: none;
				}
			}
			.filter-button-container {
				display: inline-block;
				vertical-align: top;
				border: 1px solid $mc-4;
				padding: 8px 16px;
				cursor: pointer;
				text-align: center;
				img {
					display: inline-block;
					vertical-align: top;
				}
				.filter-button-title {
					display: inline-block;
					vertical-align: top;
				}
			}
			.filter-count {
				background: $color-blue;
				color: #fff;
				width: 20px;
				height: 20px;
				line-height: 20px;
				font-size: 12px;
				border-radius: 100%;
				display: inline-block;
				position: absolute;
				right: -10px;
				top: -10px;
			}
		}
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
.credits-section-header {
	padding: 15px 25px 15px 25px;
	@include bp(768px) {
		padding: 10px;
	}
	.header-text {
		float: left;
		@include bp(768px) {
			float: none;
		}
		.title {
			font-size: $large-font-size;
			margin-bottom: 5px;
			@extend .font--semibold;
		}
		.subtitle {
			color: $mc-6;
			font-size: $small-font-size;
		}
	}
	.header-action-button {
		float: right;
		margin-top: 14px;
		.button-container {
			text-align: right;
			margin-bottom: 20px;
			img {
				margin-right: 25px;
				margin-bottom: 5px;
			}
			.button-title {
				font-size: $very-small-font-size;
				color: $color-blue;
				@extend .font--semibold;
				display: inline-block;
				vertical-align: top;
			}
		}
		.conversion-text {
			font-size: 14px;
			color: $mc-6;
			margin-bottom: -20px;
		}
		.credit-balance {
			font-size: $medium-font-size;
			color: $mc-9;
			text-align: right;
			margin-bottom: 15px;
			@extend .font--semibold;
			span.title {
				color: $mc-6;
			}
		}

		@include bp(768px) {
			float: none;
			.credit-balance {
				text-align: left;
				margin-top: 10px;
			}
		}
		.filter-buttons {
			& > div {
				float: right;
				position: relative;
				@include bp(768px) {
					float: none;
				}
			}
			.search-icon {
				position: absolute;
				left: 10px;
				top: 50%;
				transform: translateY(-50%);
			}
			.dismiss-search {
				cursor: pointer;
				position: absolute;
				right: 31px;
				top: 44%;
				transform: translateY(-50%);
				height: 11px;
				width: 11px;
				img {
					width: 100%;
				}
			}
			.search-input {
				display: inline-block;
				vertical-align: top;
				outline: 0;
				padding: 0 0 0 30px;
				font-size: $very-small-font-size;
				border: 0;
				border-bottom: 1px solid $divider-color;
				width: 200px;
				height: 36px;
				box-sizing: border-box;
				margin-right: 10px;
				@include bp(768px) {
					margin-right: 0px;
					width: 100%;
				}
			}
			.search-input-holder {
				@include bp(768px) {
					margin-top: 10px;
					display: flex;
				}
			}
			.filter-button-container {
				display: inline-block;
				vertical-align: top;
				border: 1px solid $mc-4;
				padding: 8px 16px;
				cursor: pointer;
				text-align: center;
				img {
					display: inline-block;
					vertical-align: top;
				}
				.filter-button-title {
					display: inline-block;
					vertical-align: top;
				}
			}
			.filter-count {
				background: $color-green;
				color: #fff;
				width: 20px;
				height: 20px;
				line-height: 20px;
				font-size: 12px;
				border-radius: 100%;
				display: inline-block;
				position: absolute;
				right: -10px;
				top: -10px;
			}
		}
		.bulk-actions-container {
			margin-right: 10px;
			.at-btn--secondary {
				svg {
					position: relative;
					// top: 2px;
				}
			}
		}
		.action-buttons-container {
			margin-right: 10px;
		}
		.help-btn-container {
			margin-right: 10px;
			.at-btn--secondary {
				padding: 0 14px;
				svg {
					position: relative;
					// top: 3px;
				}
			}
		}

		.help-icon {
			position: relative;
			top: 3px;
			margin-right: 15px;
			padding: 3px;
			border-radius: 50%;
			background-color: rgba($color: #6c717a, $alpha: 0.1);
			cursor: pointer;
			img {
				width: 25px;
				height: 25px;
				opacity: 0.35;
				text-align: center;
				display: block;
			}
		}
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.tariff-details-container {
	@include bp(768px) {
		padding: 15px 10px;
	}
	.tariff-details-item {
		margin-bottom: 5px;
		.tariff-source,
		.seperator,
		.tariff-credit {
			display: inline-block;
			vertical-align: top;
			font-size: $very-small-font-size;
			color: $mc-7;
		}
		.tariff-source {
			width: 200px;
			@include bp(768px) {
				width: 50%;
			}
		}
		.tariff-credit {
			margin-left: 50px;
		}
	}
}

.credit-transactions-list-table {
	margin: 20px 25px 0 25px;
	border: 1px solid $border-color;
	border-radius: $default-radius;
	margin-bottom: 15px;
	.header-row {
		border-bottom: 1px solid $divider-color;
	}
	.at-table--5cols {
		.title {
			width: 36%;
			padding-left: 25px;
			@include bp(768px) {
				padding-left: 10px;
			}
		}
		.txnid {
			width: 15%;
			text-align: right;
			@include bp(768px) {
				width: 10%;
			}
		}
		.datetime {
			width: 20%;
		}
		.user {
			width: 17%;
		}
		.credits {
			width: 12%;
			text-align: right;
			padding-right: 25px;
			@include bp(768px) {
				padding-right: 10px;
			}
		}
	}
	.at-cell-text {
		font-size: $very-small-font-size;
		color: $mc-9;
		@extend .font--semibold;
	}
	.credit-transaction-rows {
		min-height: 80px;
		border-bottom: 1px solid $divider-color;
		padding-top: 10px;
		padding-bottom: 10px;

		&:last-child {
			border-bottom: 0px;
		}

		&.credit {
			.credits {
				color: $color-green;
			}
		}
		&.debit {
			.credits {
				color: $color-red;
			}
		}

		.view-detail {
			text-transform: uppercase;
			font-size: 12px;
			position: relative;
			padding-right: 20px;
			margin-top: 10px;
			display: inline-block;
			img {
				top: 0px;
				right: 0px;
				height: 12px;
				position: absolute;
			}
			a {
				color: $color-blue;
				text-decoration: none;
			}
		}
	}
}

.credit-transaction-meta-info {
	.transaction-type-icon {
		margin-right: 10px;
		vertical-align: top;
	}
	.info-container {
		display: inline-block;
		vertical-align: top;
		width: 85%;
		@include bp(768px) {
			width: 100%;
		}
		.transaction-purpose {
			color: $mc-7;
			margin-bottom: 5px;
		}
		.transaction-id,
		.transaction-notes {
			margin-bottom: 5px;
			color: $mc-5;
			font-size: $very-small-font-size;
			@extend .font--regular;
		}
		.transaction-notes {
			word-break: break-word;
		}
	}
}
