@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.auth-service-login-container {
	min-height: 100vh;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	.page-container {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
		padding: 35px 0;
	}
	.atlas-logo {
		text-align: center;
		img {
			width: 130px;
		}
	}
	.header {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		img {
			position: relative;
			width: 45px;
			top: 1px;
			margin-right: 10px;
		}
		.at-btn {
			margin-top: 15px;
			height: 32px;
			line-height: 30px;
		}
	}
	.text {
		&.text--large {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: $small-font-size;
			color: $mc-7;
		}
		&.text--small {
			font-size: 13px;
			color: $mc-7;
		}
		&.text--medium {
			padding: 17px 32px 0;
			-webkit-animation: fade 1s;
			animation: fade 1s;
		}
		.logout {
			color: $color-blue;
			font-size: 12px;
			opacity: 0.6;
			cursor: pointer;
			&:hover {
				opacity: 1;
				text-decoration: underline;
			}
		}
	}
	.user-form-container {
		position: relative;
		padding: 25px 30px;
		max-width: 450px;
		min-height: 50px;
		.subtitle {
			color: $mc-5;
			margin-top: 6px;
		}
		.loader-backdrop {
			background-color: transparent;
		}
		.loading-indicator-container {
			position: relative;
			width: 45px;
			height: 45px;
			top: 1px;
			margin-right: 10px;
			@keyframes animation-in-out {
				0%,
				100% {
					transform: scale(0);
				}
				50% {
					transform: scale(1);
				}
			}
			.loading-indicator {
				position: absolute;
				height: 45px;
				width: 45px;
				background-color: $color-blue;
				border-radius: 100%;
				opacity: 0.6;
				top: 0px;
				left: 0px;
			}
			.loading-indicator-in {
				animation: 2.1s ease-in-out 1s infinite normal none running animation-in-out;
			}
			.loading-indicator-out {
				animation: 2.1s ease-in-out 0s infinite normal none running animation-in-out;
			}
		}
	}
}
