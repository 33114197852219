@keyframes placeHolderShimmer {
	0% {
		transform: translateX(-100%);
		opacity: 0;
	}

	50% {
		transform: translateX(100%);
		opacity: 1;
	}

	100% {
		transform: translateX(-100%);
		opacity: 0;
	}
}

.locations-container {
	.filter-buttons {
		@include bp(768px) {
			display: flex;
			flex-direction: row-reverse;
			float: left;
			position: relative;
		}

		.search-input-container {
			display: flex;

			.search-filter {
				width: 170px;

				.at-input-field {
					.at-label {
						border-radius: 0;
						border-left: 0;

						img {
							padding-left: 0;
						}
					}

					.at-input {
						border-left: 0;
						border-radius: 0 4px 4px 0;
					}
				}

				.cancel-search {
					right: 24px;
				}
			}

			.multi-select-filter-container {
				margin: 0;
				width: 100px;

				.Select-control {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
	}

	.secondary-action-buttons-container {
		.at-btn--secondary {
			img {
				width: 18px;
				vertical-align: text-top;
			}
			&:hover {
				svg {
					path {
						fill: $color-blue;
					}
				}
			}
		}
	}

	.action-buttons-container {
		z-index: 100;

		span {
			overflow: visible;
		}

		margin-right: 10px;

		[aria-label][data-balloon-pos]:after {
			white-space: pre;
			margin-top: 10px;
			overflow: visible;
		}

		&.no-margin {
			margin-right: 0;
		}

		&.publish-catalogue {
			img {
				width: 16px;
				vertical-align: text-top;
			}
		}

		&.store-toggle {
			img {
				width: 28px;
				vertical-align: top;
				margin-top: 1px;
			}
		}
	}

	.locations-filters-search {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 25px;
		margin: 10px 0 20px 0;
		.filters {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			.select-filter-custom {
				margin-right: 10px;
				margin-bottom: 10px;
			}
			.dropdown-custom {
				width: 220px;
				.container {
					height: 32px;
					padding: 1px 10px;
				}
				.dropdown-options {
					.option {
						height: 37px;
						padding: 1px 10px;
					}
				}
				.custom-option,
				.custom-label {
					display: flex;
					align-items: center;
					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 8px;
						width: 22px;
						height: 22px;
						text-transform: uppercase;
						font-size: $very-small-font-size;
						border-radius: $default-radius;
						@extend .font--semibold;
						img {
							width: 22px;
							height: 22px;
							object-fit: cover;
							border-radius: $default-radius;
						}
						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}
						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}
						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}
						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}
						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
					}
				}
			}
			.multi-select-filter-container {
				width: 150px;
				margin-right: 10px;
				margin-bottom: 10px;
				.menu-wrapper {
					z-index: 150 !important;
				}
			}
			.filter-in-header {
				position: relative;
				margin-bottom: 10px;
				.filter-count {
					background: $color-blue;
					color: #fff;
					width: 20px;
					height: 20px;
					line-height: 20px;
					font-size: 12px;
					border-radius: 100%;
					display: inline-block;
					position: absolute;
					right: -10px;
					top: -10px;
				}
			}
		}
		.search {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			.search-filter {
				margin-right: 0;
				margin-bottom: 10px;
				width: 250px;
				position: relative;
				.at-input-container {
					width: 100%;
				}
			}
			.select-filter-custom {
				margin-bottom: 10px;
				margin-left: 10px;
				.at-checkbox {
					padding: 10px 5px 10px 25px;
				}
			}
		}
	}

	.bulk-actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 25px;
		margin: 0 0 15px 0;
		.count {
			display: flex;
			align-items: center;
			font-size: $very-small-font-size;
			color: $mc-5;
			span {
				margin-left: 10px;
				font-size: $very-small-font-size;
				color: $color-blue-light;
				transition: 180ms;
				cursor: pointer;
				&:hover {
					color: $color-blue;
				}
			}
		}
		.actions-container {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			.action {
				font-size: $very-small-font-size;
				color: $color-blue;
				margin-left: 20px;
				cursor: pointer;
				@extend .font--semibold;
				&.archive {
					color: $color-red;
				}
				&.restore {
					color: $color-green;
				}
			}
			.multi-select-filter-container {
				width: 110px;
				margin-left: 20px;
				.react-select__control {
					border: none;
					min-height: auto;
				}
				.react-select__value-container {
					overflow: initial;
					padding: 0;
					width: fit-content;
				}
				.react-select__single-value {
					width: fit-content;
					overflow: initial;
					white-space: initial;
					text-overflow: initial;
					font-size: $very-small-font-size;
					color: $color-blue;
					@extend .font--semibold;
				}
				.react-select__dropdown-indicator {
					color: $color-blue;
					padding: 2px 0 2px 3px;
				}
			}
		}
	}

	.platforms {
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: 44px;
		padding: 15px 25px;
		margin: 10px 0px 25px 0px;
		border: 1px solid $border-color;
		border-left: none;
		border-right: none;
		background-color: $mc-1;
		position: sticky;
		top: 57px;
		z-index: 20;

		.header-container {
			.header-text {
				font-size: $small-font-size;
				@extend .font--semibold;
			}

			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}

		.status-container {
			display: flex;
			align-items: center;
			justify-content: center;

			& > div {
				text-align: center;
				padding: 0 20px;
			}

			.value {
				font-size: $medium-font-size;
				@extend .font--semibold;
			}

			.name {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}

			.loader {
				display: flex;
				position: relative;
				bottom: -3px;
				padding: 6px 0;
				justify-content: center;

				& > div {
					width: 3px;
					height: 3px;
					background: $mc-8;
					border-radius: 50%;
					margin: 3px;
					animation: loader 0.3s infinite alternate;
				}

				& > div:nth-child(2) {
					animation-delay: 0.1s;
				}

				& > div:nth-child(3) {
					animation-delay: 0.2s;
				}
			}

			@keyframes loader {
				to {
					transform: translate(0, -5px);
				}
			}
		}

		.platform-container {
			display: flex;
			align-items: center;
			position: relative;

			.multi-select-filter-container {
				margin: 0;
				width: 200px;

				.Select-value {
					padding-left: 52px;
				}

				.at-dropdown {
					position: relative;

					.Select-menu-outer {
						width: 155px;
					}
				}
			}
		}
	}

	.at-modal {
		.modal-container {
			.modal-header {
				padding: 20px 25px;
			}
			.modal-content {
				padding: 0 25px;
				min-height: 50px;
			}
		}
	}

	.locations-list-table-container {
		margin-bottom: 10px;
		.transactions-list-table {
			margin-top: 0;
		}
		.transaction-rows {
			min-height: 80px;
		}
		.transaction-header-row {
			top: 57px;
		}
		.title {
			width: 25%;
			padding-left: 25px;
			display: flex;
			flex-direction: column;
			.location-title {
				display: flex;
			}
			.city {
				padding-top: 5px;
				&.aligned {
					padding-left: 25px;
				}
				span {
					color: $mc-5;
				}
			}
			.tags {
				&.aligned {
					padding-left: 25px;
				}
			}
		}
		.city {
			width: 15%;
		}
		.location_id {
			width: 13%;
			.desc-text {
				font-size: 12px;
				@extend .font--semibold;
			}
		}
		.associated_items {
			width: 12%;
		}
		.menu_status {
			width: 20%;
			[aria-label][data-balloon-pos]:after {
				white-space: pre;
				margin-top: 2px;
				line-height: 1.5;
				top: 22px;
				right: -6px;
			}
			[aria-label][data-balloon-pos]:before {
				top: 14px;
				right: 1px;
			}
			.info {
				position: relative;
				top: 1px;
				height: 12px;
				width: 12px;
				margin-top: 2.5px;
				margin-left: 5px;
			}
			.verification-status,
			.publish-status {
				margin-bottom: 4px;
				&:last-child {
					margin-bottom: 0;
				}
				div {
					margin-bottom: 4px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				span {
					color: $mc-5;
				}
				.error,
				.failed,
				.terminated,
				.ack_failed {
					color: $color-red;
				}
				.success,
				.passing,
				.ack_success {
					color: $color-green;
				}
			}
			.time-stamp {
				color: $mc-5;
			}
			.link-text {
				margin-top: 10px;
			}
			.shimmer {
				margin-bottom: 5px !important;
			}
		}
		.location_status {
			width: 20%;
			[aria-label][data-balloon-pos]:after {
				white-space: pre;
				margin-top: 2px;
				line-height: 1.5;
				top: 22px;
				right: -6px;
			}
			[aria-label][data-balloon-pos]:before {
				top: 14px;
				right: 1px;
			}
			.info {
				position: relative;
				top: 1px;
				height: 12px;
				width: 12px;
				margin-top: 2.5px;
				margin-left: 5px;
			}
			.current-status,
			.last-action {
				margin-bottom: 4px;
				&:last-child {
					margin-bottom: 0;
				}
				div {
					margin-bottom: 4px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				span {
					color: $mc-5;
					text-transform: capitalize;
					white-space: nowrap;
				}
				.error,
				.failed,
				.disabled,
				.disconnected,
				.terminated,
				.ack_failed {
					opacity: 1;
					color: $color-red;
				}
				.success,
				.enabled,
				.passing,
				.ack_success {
					color: $color-green;
				}
			}
		}
		.associated_platforms {
			display: flex;
			justify-content: space-between;
			width: 35%;
			padding-right: 25px;
			.platforms-list {
				display: flex;
				flex-wrap: wrap;
				height: fit-content;
				.platform {
					position: relative;
					img {
						width: 24px;
						height: 24px;
						object-fit: cover;
						margin-right: 15px;
						margin-bottom: 15px;
						border-radius: $default-radius;
						cursor: pointer;
						transition: 180ms;
					}
					.state {
						position: absolute;
						top: -5px;
						right: 9px;
						width: 7px;
						height: 7px;
						border-radius: 50%;
						box-sizing: content-box;
						&.enabled {
							background-color: $color-green;
							border: 2px solid $mc-1;
						}
						&.disabled {
							opacity: 1;
							background-color: $color-red;
							border: 2px solid $mc-1;
						}
						&.not-live {
							background-color: $mc-5;
							border: 2px solid $mc-1;
						}
						&.disconnected {
							background-color: $mc-5;
							border: 2px solid $mc-1;
						}
					}
					.verification-status {
						position: absolute;
						bottom: -12px;
						right: -6px;
						img {
							width: 12px;
							object-fit: contain;
						}
					}
				}
			}
			.popover-wrapper-container {
				.popover-wrapper--content {
					[aria-label][data-balloon-pos]:after {
						right: 15px;
						top: 65%;
					}
					[aria-label][data-balloon-pos]:before {
						right: 23px;
						top: 65%;
					}
				}
				.popover-container {
					top: 35px;
					right: 0;
					&::before {
						right: 17px;
					}
					&::after {
						right: 13px;
					}
					.lpa-logs-container {
						position: relative;
						.header {
							position: sticky;
							top: 0;
							padding: 10px 15px;
							font-size: 12px;
							border-bottom: 1px solid $border-color;
							background-color: $mc-1;
							color: rgba($color: $mc-9, $alpha: 0.6);
							border-radius: 4px 4px 0 0;
							text-transform: uppercase;
							box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
							@extend .font--semibold;
						}
						.logs {
							width: 500px;
							min-height: 120px;
							max-height: 300px;
							overflow-y: auto;
							.log-container {
								display: flex;
								align-items: center;
								justify-content: space-between;
								border-bottom: 1px solid $border-color;
								padding: 10px 15px;
								.brand-container {
									display: flex;
									align-items: center;
									.brand-initials {
										display: flex;
										align-items: center;
										justify-content: center;
										width: 32px;
										height: 32px;
										text-transform: uppercase;
										border-radius: $default-radius;
										margin-right: 10px;
										@extend .font--semibold;
										&.brown {
											color: $color-brown-dark;
											background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
										}
										&.blue {
											color: $color-blue-dark;
											background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
										}
										&.green {
											color: $color-green-dark;
											background-color: rgba($color: $color-green-dark, $alpha: 0.1);
										}
										&.red {
											color: $color-red-dark;
											background-color: rgba($color: $color-red-dark, $alpha: 0.1);
										}
										&.purple {
											color: $color-violet;
											background-color: rgba($color: $color-violet, $alpha: 0.1);
										}
										img {
											width: 32px;
											height: 32px;
											object-fit: cover;
											border-radius: $default-radius;
											margin: 0;
										}
									}
									.brand-info {
										display: flex;
										flex-direction: column;
										.brand-name {
											text-transform: capitalize;
											margin-bottom: 3px;
											@extend .font--semibold;
										}
										.brand-status {
											display: flex;
											align-items: center;
											.platform-status {
												font-size: 12px;
												margin-right: 5px;
												color: $mc-7;
												&.offline {
													color: $color-red;
												}
												&.online {
													color: $color-green;
												}
											}
											.ext-link {
												position: relative;
												top: 1px;
												cursor: pointer;
												img {
													margin: 0;
													width: 15px;
													height: 15px;
													opacity: 0.6;
												}
											}
										}
									}
								}
								.action-container {
									display: flex;
									flex-direction: column;
									.action {
										width: auto;
										display: flex;
										flex-direction: row;
										align-items: center;
										justify-content: flex-end;
										color: $mc-7;
										margin-bottom: 3px;
										@extend .font--semibold;
										img {
											position: relative;
											top: -1px;
											width: 14px;
											height: 14px;
											margin: 0;
											margin-right: 5px;
											object-fit: contain;
											filter: grayscale(1) brightness(0.8);
										}
									}
									.date-time {
										text-align: right;
										font-size: 12px;
										color: $mc-5;
									}
									.link-text {
										text-align: right;
										margin-top: 7px;
									}
								}
							}
							.no-items-placeholder {
								border-bottom: none;
							}
						}
						.actions {
							padding: 10px 15px;
							text-align: right;
							box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
							div {
								color: $color-blue;
								cursor: pointer;
								@extend .font--semibold;
							}
						}
					}
				}
			}
		}
		.associated_brands {
			display: flex;
			justify-content: space-between;
			width: 35%;
			padding-right: 25px;
			.brands-list {
				display: flex;
				flex-wrap: wrap;
				height: fit-content;
				.brand-initials {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 24px;
					height: 24px;
					text-transform: uppercase;
					border-radius: $default-radius;
					margin-right: 15px;
					margin-bottom: 15px;
					@extend .font--semibold;
					&.brown {
						color: $color-brown-dark;
						background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
					}
					&.blue {
						color: $color-blue-dark;
						background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
					}
					&.green {
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					}
					&.red {
						color: $color-red-dark;
						background-color: rgba($color: $color-red-dark, $alpha: 0.1);
					}
					&.purple {
						color: $color-violet;
						background-color: rgba($color: $color-violet, $alpha: 0.1);
					}
					img {
						width: 24px;
						height: 24px;
						object-fit: cover;
						border-radius: $default-radius;
						margin: 0;
					}
				}
			}
		}
		.table-header {
			z-index: 70;
			.table-cell {
				display: flex;
				align-items: center;
				&.title {
					flex-direction: row;
				}
			}
		}
		.status {
			overflow: visible;
			overflow-y: visible;
			overflow-x: visible;
		}

		.meraki-status {
			display: flex;
			align-items: flex-start;
			width: 15%;

			img {
				width: 16px;
				margin-left: 5px;
			}

			.enable {
				color: $color-green;
			}

			.disable {
				color: $color-red;
			}

			.dotted-dropdown {
				cursor: pointer;
				display: inline-block;
				color: $mc-5;
				border-radius: $default-radius;
				cursor: pointer;
				font-weight: 900;
				text-align: left;
				position: absolute;
				top: 14px;
				right: 15px;
			}
		}
		.platform_id {
			display: flex;
			align-items: flex-start;
			width: 15%;
			img {
				width: 18px;
				opacity: 0.4;
				cursor: pointer;
				margin-left: 5px;
			}
		}
		.verification {
			width: 15%;
			color: $mc-5;

			&.passing,
			&.success {
				color: $color-green;
			}

			&.error,
			&.failed,
			&.terminated {
				color: $color-red;
			}

			&.created {
				color: $color-java;
			}

			&.processing {
				color: $color-blue;
			}

			&.warning {
				color: $color-yellow;
			}

			.status {
				margin-bottom: 5px;
			}

			.link-text {
				font-weight: normal;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.publish {
			width: 15%;
		}

		.status {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			width: 15%;
			position: relative;

			.status-main {
				display: flex;
				flex-direction: row;

				img {
					width: 16px;
					margin-left: 5px;
				}

				&.enable {
					color: $color-green;
				}

				&.disable {
					color: $color-red;
				}

				&.not-live {
					color: $mc-5;
				}

				&.churn {
					color: $mc-5;
				}
			}

			.dotted-dropdown {
				cursor: pointer;
				display: inline-block;
				color: $mc-5;
				border-radius: $default-radius;
				cursor: pointer;
				font-weight: 900;
				text-align: left;
				position: absolute;
				top: 6px;
				right: 15px;
			}
		}

		.action {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 15%;

			.current-action {
				display: flex;
				align-items: flex-start;

				img {
					width: 18px;
					margin-left: 5px;
				}
			}
		}

		.current-action-status {
			display: flex;
			width: 100%;
			flex-direction: row;
			overflow: visible;
			overflow-x: visible;
			overflow-y: visible;

			[aria-label][data-balloon-pos]:after {
				white-space: pre;
				margin-top: 2px;
			}

			[aria-label][data-balloon-pos]:before {
				top: 60%;
			}

			.ack_failed {
				color: $color-red;
			}

			.ack_success {
				color: $color-green;
			}

			.failed {
				color: $color-red;
			}

			.na {
				color: $mc-5;
			}

			.info {
				height: 12px;
				width: 12px;
				margin-top: 2.5px;
				margin-left: 5px;
			}

			.last-action {
				color: $mc-5;
			}

			.action-info {
				display: flex;
				flex-direction: row;
			}
		}

		.current-action-date {
			color: $mc-5;
			margin-top: 1px;
		}
	}

	.locations-items-association-container {
		.at-paginator {
			padding: 0;
		}

		.search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;

			&:nth-child(2) {
				margin-bottom: 35px;
			}

			.at-input-container {
				width: 30%;
			}

			.multi-select-filter-container {
				width: 30%;
				opacity: 1;

				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}

		.modifier-search-container {
			justify-content: flex-start;
			gap: 20px;
		}

		.locations-table-container {
			.transaction-header-row {
				top: 0px !important;
			}

			.transaction-rows {
				min-height: 90px;
			}

			.at-cell-text {
				font-size: $very-small-font-size;
			}

			.name {
				width: 60%;
				padding-left: 25px;

				.at-checkbox {
					font-size: $very-small-font-size;
					display: flex;

					& > span {
						margin: auto 10px auto 0;
					}

					.food-type {
						margin-left: 5px;
					}
				}

				.crm-title {
					color: $mc-5;
					padding-left: 52px;
				}

				.item_tags {
					padding-left: 53px;

					.tag-item {
						margin: 2px 5px 0px 0px;
					}
				}
			}

			.modifier-name {
				width: 50%;

				.crm-title {
					padding-left: 24px;
				}
			}

			.category {
				width: 20%;
			}

			.associated-locations {
				width: 30%;
			}

			.city {
				width: 20%;
				text-align: right;
				padding-right: 25px;
			}

			.at-header-text {
				line-height: 17px;
				opacity: 1;
				color: $mc-7;

				&.name {
					&:hover {
						background: $mc-2;
					}
				}

				.at-checkbox {
					font-size: 12px;
				}
			}
		}
	}

	.copy-catalogue-container {
		.alert {
			display: flex;
			background-color: rgba(245, 166, 35, 0.05);
			border: 1px solid rgba(245, 166, 35, 0.4);
			padding: 15px;
			border-radius: $default-radius;
			margin-top: 20px;
			margin-bottom: 20px;

			img {
				height: 18px;
				vertical-align: top;
				margin-right: 5px;
			}

			span {
				font-size: $very-small-font-size;
			}
		}
	}

	.locations-brands-association-container {
		.form-container {
			.form-content {
				padding: 0 25px 25px 25px;
			}
		}
		.at-paginator {
			padding: 0;
		}
		.search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;
			&:nth-child(2) {
				margin-bottom: 35px;
			}
			.at-input-container {
				width: 30%;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.brands-table-container {
			.transaction-header-row {
				top: 0px !important;
			}
			.transaction-rows {
				min-height: 90px;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.name {
				width: 60%;
				padding-left: 25px;
				.at-checkbox {
					font-size: $very-small-font-size;
					display: flex;
					& > span {
						margin: auto 10px auto 0;
					}
				}
				.brand-info {
					display: flex;
					overflow: hidden;
				}
				.brand-initials {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-shrink: 0;
					width: 32px;
					height: 32px;
					text-transform: uppercase;
					color: $mc-7;
					background-color: rgba($color: $mc-7, $alpha: 0.1);
					font-size: $small-font-size;
					border-radius: $default-radius;
					@extend .font--semibold;
					&.brown {
						color: $color-brown-dark;
						background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
					}
					&.blue {
						color: $color-blue-dark;
						background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
					}
					&.green {
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					}
					&.red {
						color: $color-red-dark;
						background-color: rgba($color: $color-red-dark, $alpha: 0.1);
					}
					&.purple {
						color: $color-violet;
						background-color: rgba($color: $color-violet, $alpha: 0.1);
					}
					img {
						width: 32px;
						height: 32px;
						border-radius: $default-radius;
						object-fit: cover;
					}
				}
				.brand-name {
					margin-left: 10px;
					text-transform: capitalize;
				}
			}
			.at-header-text {
				line-height: 17px;
				opacity: 1;
				color: $mc-7;
				&.name {
					&:hover {
						background: $mc-2;
					}
				}
				.at-checkbox {
					font-size: 12px;
				}
			}
		}
	}
}

.location-create-container {
	display: flex;
	flex-direction: column;
	&.section-container-common {
		height: auto;
		min-height: 95vh;
		padding: 0;
		margin: 0;
	}
	.progress-bar-display {
		width: 100%;
		.info {
			.title {
				.refresh {
					display: flex;
					align-items: center;
					margin-left: 10px;
					svg {
						width: 18px;
						height: 18px;
						transition: 500ms;
						padding: 2px;
						cursor: pointer;
						path {
							transition: 500ms;
							fill: #cccccc;
						}
					}
					&:hover {
						svg {
							transform: rotate(90deg);
							path {
								fill: $color-blue;
							}
						}
					}
				}
			}
		}
	}
	.settings-header {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		padding-bottom: 25px;
		border-bottom: 1px solid $border-color;
		@include bp(768px) {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			.numerical-display {
				margin-top: 20px;
			}
		}
		.numerical-display {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			transition: 400ms;
			.display {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.step {
				margin-bottom: 10px;
				text-transform: uppercase;
				font-size: $very-small-font-size;
				font-weight: 600;
				color: $mc-7;
			}
			&--value {
				min-height: 16px;
				min-width: 16px;
				display: grid;
				place-content: center;
				border: 2px solid rgba($mc-9, 0.2);
				border-radius: 50%;
				position: relative;
			}
			.selected {
				border: 2px solid $color-blue;
				background-color: #eaeefe;
			}
			.previous {
				border: 2px solid $color-blue;
				background-color: #eaeefe;
				color: $mc-1;
				.fill {
					width: 12px;
					height: 12px;
					border-radius: 50%;
					background-color: $color-blue;
				}
			}
			&--divider {
				width: 60px;
				height: 3px;
				background-color: rgba($mc-9, 0.2);
				border-radius: $default-radius;
				margin: 0 2px;
				transition: width 0.5 ease-in-out;
				@include bp(768px) {
					width: 30px;
				}
			}
			.passed {
				background-color: $color-blue;
				transition: all 0.5s ease-in;
			}
		}
	}
	.form-container {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		position: relative;
		.form-content {
			flex-grow: 1;
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at-dropdown {
					font-size: $very-small-font-size;
				}
				&.filter-section-item {
					opacity: 1;
					margin-bottom: 0;
					.meta-info {
						font-weight: 400;
						margin-bottom: 0;
						padding-bottom: 5px;
					}
				}
			}
		}
		.section-title {
			font-size: $medium-large-font-size;
			margin-bottom: 25px;
			@extend .font--semibold;
		}
		.section-subtitle {
			font-size: $small-font-size;
			margin-bottom: 25px;
			color: $color-dark-grey;
		}
		.form-row {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;
			max-width: 600px;
			position: relative;
			@include bp(768px) {
				flex-direction: column;
				margin-bottom: 0;
			}
			&.row-full > div {
				width: 100%;
				@include bp(768px) {
					margin-bottom: 30px;
				}
			}
			&.row-half > div {
				width: 47%;
				@include bp(768px) {
					width: auto;
					margin-bottom: 30px;
				}
			}
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at-dropdown {
					font-size: $very-small-font-size;
				}
				&.filter-section-item {
					opacity: 1;
					margin-bottom: 0;
					@include bp(768px) {
						margin-bottom: 0;
					}
					.meta-info {
						font-weight: 400;
						margin-bottom: 0;
						padding-bottom: 5px;
					}
				}
			}
			.at-input-field {
				.at-label {
					span {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
			&.timing-group {
				&.row-full {
					display: flex;
					flex-direction: column;
				}
				.add-timing-group {
					margin-top: 5px;
					font-size: $very-small-font-size;
					color: $color-blue;
					cursor: pointer;
				}
			}
		}
		.footer {
			display: flex;
			justify-content: flex-end;
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
	}
	.location-count-selection {
		padding: 30px 25px;
		.types {
			display: flex;
			align-items: center;
			@include bp(768px) {
				flex-direction: column;
				align-items: flex-start;
			}
		}
		.selection-type {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 280px;
			height: 180px;
			border: 2px solid $border-color;
			border-radius: $default-radius;
			margin-right: 25px;
			margin-bottom: 25px;
			transition: 180ms;
			cursor: pointer;
			.single {
				img {
					scale: 1.1;
				}
			}
			.multiple {
				display: flex;
				align-items: center;
				img {
					scale: 0.8;
				}
			}
			.caption {
				margin-top: 15px;
			}
			&.selected {
				border-color: $color-blue;
				.caption {
					color: $color-blue;
				}
			}
		}
	}
	.city-selection {
		padding: 30px 25px;
		.title {
			font-weight: 600;
			margin-bottom: 15px;
		}
		.cities-list {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			.city-container {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 120px;
				height: 130px;
				border: 1px solid $border-color;
				border-radius: $default-radius;
				margin-right: 15px;
				margin-bottom: 15px;
				transition: 180ms;
				cursor: pointer;
				&:last-child {
					margin-right: 0;
				}
				&:hover {
					scale: 1.05;
				}
				svg {
					scale: 1.2;
					margin-bottom: 30px;
				}
				.name {
					position: absolute;
					bottom: 20px;
				}
				&.selected {
					scale: 1.05;
					border-color: $color-blue;
					color: $color-blue;
					box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.2);
					svg > path {
						transition: 0.5s;
						fill: $color-blue;
						stroke: none;
					}
				}
			}
		}
	}
	.location-details {
		padding: 30px 25px;
	}
	.multi-location-details {
		.section-title {
			padding: 30px 25px;
			margin-bottom: 0;
		}
		.multi-locations-list-table-container {
			.at-input-container {
				.at-input-field {
					.at-label {
						span {
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
					.at-input--label {
						padding: 0px 10px 0px 42px;
					}
				}
			}
			.multi-select-filter-container {
				.react-select__loading-indicator {
					display: none;
				}
			}
			.table-list {
				display: flex;
				align-items: center;
				font-size: $very-small-font-size;
				border-bottom: none;
				padding: 20px 0;
				// &:last-child {
				// 	border-bottom: 1px solid $border-color;
				// }
			}
			.num {
				width: 30px;
				padding: 9px 0;
				text-align: right;
				color: $mc-5;
			}
			.name {
				padding-left: 12px;
				width: 20%;
			}
			.address {
				width: 25%;
			}
			.city {
				width: 15%;
			}
			.phone {
				width: 15%;
			}
			.email {
				width: 22%;
				padding-right: 25px;
			}
		}
		.add-rows {
			display: flex;
			align-items: flex-end;
			padding: 15px 25px 50px 25px;
			margin-top: 5px;
			border-top: 1px solid $border-color;
			.at-input-container {
				width: 100px;
			}
			.at-btn {
				margin-left: 10px;
			}
		}
	}
	.brands-selection {
		.section-title {
			padding: 30px 25px;
			margin-bottom: 0;
		}
		.brands {
			padding: 0 25px;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			gap: 30px;
			margin-bottom: 30px;
			.no-items-placeholder {
				width: 100%;
			}
		}
		.brand-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			width: 140px;
			height: 170px;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			// margin-right: 40px;
			// margin-bottom: 30px;
			font-size: $very-small-font-size;
			transition: 180ms;
			cursor: pointer;
			&:last-child {
				margin-right: 0;
			}
			&:hover {
				scale: 1.05;
			}
			&.selected {
				border-color: $color-blue;
				scale: 1.05;
				box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.2);
				.brand-name {
					color: $color-blue;
				}
			}
			.at-checkbox {
				width: 20px;
				height: 20px;
				padding: 0;
				z-index: 10;
				position: absolute;
				top: 14px;
				right: 14px;
				.checkmark {
					width: 18px;
					height: 18px;
					border-radius: 50%;
					background-color: rgba($color: $mc-1, $alpha: 0.5);
					&::after {
						top: 3px;
						left: 6px;
						border-width: 0 2px 2px 0;
					}
				}
				input:checked ~ .checkmark {
					background-color: $color-blue;
					border-color: transparent;
				}
			}
			.brand-initials {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100px;
				height: 100px;
				text-transform: uppercase;
				font-size: 42px;
				border-radius: $default-radius;
				@extend .font--semibold;
				&.brown {
					color: $color-brown-dark;
					background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
				}
				&.blue {
					color: $color-blue-dark;
					background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
				}
				&.green {
					color: $color-green-dark;
					background-color: rgba($color: $color-green-dark, $alpha: 0.1);
				}
				&.red {
					color: $color-red-dark;
					background-color: rgba($color: $color-red-dark, $alpha: 0.1);
				}
				&.purple {
					color: $color-violet;
					background-color: rgba($color: $color-violet, $alpha: 0.1);
				}
				img {
					width: 100px;
					height: 100px;
					border-radius: $default-radius;
					object-fit: cover;
				}
			}
			.brand-name {
				text-align: center;
				margin-top: 15px;
				text-transform: capitalize;
				width: 100px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		.locations-brands-list-table-container {
			font-size: $very-small-font-size;
			margin-bottom: 50px;
			.table-list {
				.context-menu {
					top: 18px;
				}
			}
			.name {
				width: 30%;
				display: flex;
				padding-left: 25px;
			}
			.city {
				width: 20%;
				display: flex;
			}
			.brand {
				display: flex;
				justify-content: space-between;
				width: 50%;
				padding-right: 25px;
				.select-filter-custom {
					width: 100%;
					margin-right: 25px;
				}
				.dropdown-custom {
					width: 100%;
					.dropdown-component {
						z-index: 100;
						.dropdown-options {
							.option {
								text-transform: capitalize;
							}
						}
					}
					.container {
						height: auto;
						min-height: 30px;
						padding: 2px 10px 2px 2px;
						.placeholder {
							padding: 0 8px;
						}
						.option {
							.custom-label {
								display: flex;
								flex-wrap: wrap;
							}
							.brand-tag {
								display: flex;
								align-items: center;
								margin: 4px 8px;
								.brand-initials {
									display: flex;
									align-items: center;
									justify-content: center;
									width: 22px;
									height: 22px;
									text-transform: uppercase;
									font-size: 12px;
									color: $mc-7;
									background-color: rgba($color: $mc-7, $alpha: 0.1);
									border-radius: $default-radius;
									margin-right: 5px;
									@extend .font--semibold;
									&.brown {
										color: $color-brown-dark;
										background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
									}
									&.blue {
										color: $color-blue-dark;
										background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
									}
									&.green {
										color: $color-green-dark;
										background-color: rgba($color: $color-green-dark, $alpha: 0.1);
									}
									&.red {
										color: $color-red-dark;
										background-color: rgba($color: $color-red-dark, $alpha: 0.1);
									}
									&.purple {
										color: $color-violet;
										background-color: rgba($color: $color-violet, $alpha: 0.1);
									}
									img {
										width: 22px;
										height: 22px;
										border-radius: $default-radius;
										object-fit: cover;
									}
								}
								.brand-name {
									font-size: 12px;
									text-transform: capitalize;
								}
							}
						}
					}
					@include bp(768px) {
						.container,
						.dropdown-component {
							margin: 0;
						}
					}
				}
			}
		}
	}
	.brand-catalogue {
		.section-title {
			padding: 30px 25px;
			margin-bottom: 0;
		}
		.section-subtitle {
			padding: 0 25px;
			line-height: 1.3;
		}
		.select-location-catalogue {
			display: flex;
			align-items: center;
			padding: 0 25px;
			.multi-select-filter-container {
				width: 100%;
				max-width: 350px;
				margin-right: 25px;
			}
			.preview {
				position: relative;
				top: 10px;
				font-size: $very-small-font-size;
				color: $color-blue;
				cursor: pointer;
				margin-right: 25px;
				@extend .font--semibold;
			}
		}
		.brands-list-table-container {
			margin-bottom: 30px;
			.table-list {
				display: flex;
				font-size: $very-small-font-size;
				.context-menu {
					top: 18px;
				}
			}
			.name {
				display: flex;
				width: 25%;
				padding-left: 25px;
			}
			.brands {
				display: flex;
				align-items: center;
				width: 30%;
				padding-left: 25px;
				.brand-container {
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					height: 36px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				.brand-initials {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 25px;
					height: 25px;
					text-transform: uppercase;
					color: $mc-7;
					background-color: rgba($color: $mc-7, $alpha: 0.1);
					font-size: $very-small-font-size;
					border-radius: $default-radius;
					@extend .font--semibold;
					&.brown {
						color: $color-brown-dark;
						background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
					}
					&.blue {
						color: $color-blue-dark;
						background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
					}
					&.green {
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					}
					&.red {
						color: $color-red-dark;
						background-color: rgba($color: $color-red-dark, $alpha: 0.1);
					}
					&.purple {
						color: $color-violet;
						background-color: rgba($color: $color-violet, $alpha: 0.1);
					}
					img {
						width: 25px;
						height: 25px;
						border-radius: $default-radius;
						object-fit: cover;
					}
				}
				.brand-name {
					margin-left: 10px;
					text-transform: capitalize;
				}
			}
			.location {
				display: flex;
				align-items: center;
				width: 70%;
				padding-right: 25px;
				.location-container {
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					width: 100%;
					position: relative;
					&:last-child {
						margin-bottom: 0;
					}
				}
				.multi-select-filter-container {
					width: 100%;
					max-width: 350px;
					margin-right: 25px;
					.react-select__loading-indicator {
						display: none;
					}
				}
				.preview {
					font-size: $very-small-font-size;
					color: $color-blue;
					cursor: pointer;
					margin-right: 25px;
					@extend .font--semibold;
				}
			}
			&.multi {
				.brands {
					width: 25%;
					align-items: flex-start;
					flex-direction: column;
					padding-left: 15px;
				}
				.location {
					width: 50%;
					align-items: flex-start;
					flex-direction: column;
					.context-menu {
						position: absolute;
						top: 2px;
						right: -10px;
					}
				}
			}
		}
	}
	.preview-catalogue {
		.category-container {
			padding: 0 25px 10px 25px;
			.name {
				color: $mc-7;
				font-size: $very-small-font-size;
				margin-bottom: 2px;
				text-transform: capitalize;
				span,
				img {
					margin-right: 10px;
				}
				span.root-category {
					font-weight: 600;
				}
			}
			.item-count {
				color: $mc-7;
				font-size: 12px;
			}
		}
		.items-list-table-container {
			margin-bottom: 50px;
			&.custom-table-container {
				.table-header {
					top: 0px !important;
				}
				.table-list {
					font-size: $very-small-font-size;
				}
			}
			.item {
				display: flex;
				width: 70%;
				padding-left: 25px;
				.item-image {
					height: 62px;
					width: 62px;
					display: inline-block;
					margin-right: 10px;
					vertical-align: top;
					border-radius: $default-radius;
					position: relative;
					overflow: hidden;
					box-sizing: border-box;
					background: #f2f2f2;
					z-index: 10;
					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
					&:after {
						content: " ";
						white-space: pre;
						animation-duration: 2s;
						animation-fill-mode: forwards;
						animation-iteration-count: infinite;
						animation-name: placeHolderShimmer;
						animation-timing-function: ease-in-out;
						background: linear-gradient(
							to right,
							rgba(228, 226, 226, 0) 0%,
							#979797 50%,
							rgba(228, 226, 226, 0) 100%
						);
						background-size: 100%;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						position: absolute;
						background-repeat: no-repeat;
						will-change: transform, opacity;
						z-index: -1;
					}
				}
				.item-info {
					display: flex;
					align-items: center;
					height: min-content;
					.item-title {
						text-transform: capitalize;
						@extend .font--semibold;
					}
					.food-type {
						margin-right: 10px;
					}
				}
			}
			.price {
				width: 30%;
				padding-right: 25px;
				.markup-price {
					color: $mc-5;
					text-decoration: line-through;
					text-decoration-color: $mc-9;
				}
			}
		}
	}
	.platform-association {
		.form-content {
			padding: 30px 25px;
		}
		.dropdown-custom {
			width: 100%;
			max-width: 600px;
			.dropdown-component {
				z-index: 200;
			}
			.container {
				height: auto;
				min-height: 32px;
				padding: 2px 10px 2px 2px;
				.placeholder {
					position: relative;
					left: 5px;
				}
				.option {
					.custom-label {
						display: flex;
						flex-wrap: wrap;
					}
					.platform-tag {
						display: flex;
						align-items: center;
						margin: 3px;
						color: #007eff;
						background-color: #ebf5ff;
						border: 1px solid rgba(0, 126, 255, 0.24);
						border-radius: $default-radius;
						cursor: default;
						.platform {
							padding: 4px 6px;
						}
						.cross {
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 4px;
							width: 16px;
							height: 16px;
							cursor: pointer;
							&:hover {
								background-color: rgba(0, 126, 255, 0.08);
							}
							svg {
								position: relative;
								// top: 0.5px;
								width: 14px;
								height: 14px;
								fill: #007eff;
							}
						}
					}
				}
			}
			@include bp(768px) {
				.container,
				.dropdown-component {
					margin: 0;
				}
			}
		}
		.brands-platforms-list-table-container {
			margin-bottom: 50px;
			&.custom-table-container {
				.table-header {
					top: 57px;
				}
				.table-list {
					font-size: $very-small-font-size;
				}
			}
			.name {
				width: 20%;
				padding-left: 25px;
				.location-name {
					display: flex;
					align-items: center;
					height: 55px;
				}
			}
			.brands {
				display: flex;
				width: 23%;
				padding-left: 25px;
				.brand-container {
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					height: 55px;
					&:last-child {
						margin-bottom: 10px;
					}
				}
				.brand-initials {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 25px;
					height: 25px;
					text-transform: uppercase;
					color: $mc-7;
					background-color: rgba($color: $mc-7, $alpha: 0.1);
					font-size: $small-font-size;
					border-radius: $default-radius;
					@extend .font--semibold;
					&.brown {
						color: $color-brown-dark;
						background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
					}
					&.blue {
						color: $color-blue-dark;
						background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
					}
					&.green {
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					}
					&.red {
						color: $color-red-dark;
						background-color: rgba($color: $color-red-dark, $alpha: 0.1);
					}
					&.purple {
						color: $color-violet;
						background-color: rgba($color: $color-violet, $alpha: 0.1);
					}
					img {
						width: 25px;
						height: 25px;
						border-radius: $default-radius;
						object-fit: cover;
					}
				}
				.brand-name {
					margin-left: 10px;
					text-transform: capitalize;
				}
			}
			.aggr-platforms {
				width: 12%;
				.plf-name {
					display: flex;
					align-items: center;
					height: 55px;
					margin-bottom: 25px;
					&:last-child {
						margin-bottom: 10px;
					}
					img {
						width: 24px;
						height: 24px;
						object-fit: cover;
						margin-right: 7px;
						border-radius: $default-radius;
					}
				}
			}
			.platform-action {
				display: flex;
				align-items: center;
				height: 55px;
				margin-bottom: 25px;
				&:last-child {
					margin-bottom: 10px;
				}
				span {
					cursor: pointer;
					@extend .font--semibold;
				}
				.open {
					color: $color-blue;
				}
				.reset {
					color: $color-red;
				}
				.meraki-details-set {
					display: flex;
					align-items: center;
					flex-wrap: nowrap;
					white-space: nowrap;
					z-index: 10;
					img {
						width: 14px;
						height: 14px;
						margin-right: 7px;
					}
					span {
						margin-left: 50px;
					}
				}
			}
			.platform-id {
				width: 25%;
				.at-input-container {
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 55px;
					margin-bottom: 25px;
					.validation-message {
						top: 42px;
					}
					&:last-child {
						margin-bottom: 10px;
					}
				}
			}
			.platform-url {
				width: 40%;
				padding-right: 25px;
				.at-input-container {
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 55px;
					margin-bottom: 25px;
					.validation-message {
						top: 42px;
					}
					&:last-child {
						margin-bottom: 10px;
					}
				}
			}
			&.non-multi-brand {
				.aggr-platforms {
					padding-left: 25px;
				}
			}
			&.multi {
				.table-header {
					.brands,
					.aggr-platforms,
					.platform-id,
					.platform-url {
						padding: 10px 15px;
					}
				}
				.table-list {
					padding: 12px 0;
				}
				.brands {
					width: 20%;
					align-items: flex-start;
					flex-direction: column;
					padding: 7px 0;
					.brand-info {
						margin-bottom: 20px;
						border-bottom: 1px solid $border-color;
						width: 100%;
						padding: 0 15px;
						padding-bottom: 10px;
						.brand-container {
							margin-bottom: 20px;
							&:last-child {
								margin-bottom: 20px;
							}
						}
						&:last-child {
							margin-bottom: 0;
							border: none;
							.brand-container {
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
				.aggr-platforms {
					padding: 7px 0;
					.brand-platforms {
						margin-bottom: 20px;
						border-bottom: 1px solid $border-color;
						padding: 0 15px;
						padding-bottom: 10px;
						.plf-name {
							margin-bottom: 20px;
							&:last-child {
								margin-bottom: 20px;
							}
						}
						&:last-child {
							margin-bottom: 0;
							border: none;
							.plf-name {
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
				.platform-id {
					padding: 7px 0;
					.brand-platforms {
						margin-bottom: 20px;
						border-bottom: 1px solid $border-color;
						padding: 0 15px;
						padding-bottom: 10px;
						.at-input-container {
							margin-bottom: 20px;
							&:last-child {
								margin-bottom: 20px;
							}
						}
						&:last-child {
							margin-bottom: 0;
							border: none;
							.at-input-container {
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
				.platform-url {
					padding: 7px 0;
					.brand-platforms {
						margin-bottom: 20px;
						border-bottom: 1px solid $border-color;
						padding: 0 25px 0 15px;
						padding-bottom: 10px;
						.at-input-container {
							margin-bottom: 20px;
							&:last-child {
								margin-bottom: 20px;
							}
						}
						&:last-child {
							margin-bottom: 0;
							border: none;
							.at-input-container {
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
	.meraki-details {
		.form-content {
			padding: 0 25px;
		}
		.location-delivery-area {
			position: relative;
			.options {
				display: flex;
				align-items: center;
				height: 35px;
				gap: 15px;
				margin-bottom: 15px;
				.at-radiobutton {
					font-size: $very-small-font-size;
				}
			}
			.polygon-delete {
				position: absolute;
				top: 0px;
				right: 0;
			}
		}
		.gmap-container {
			height: 500px;
		}
	}
	.location-create-success,
	.location-create-queued,
	.location-create-processing,
	.location-create-failed,
	.location-create-errors {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: auto;
		padding: 150px 25px;
		position: relative;
		.icon-check,
		.icon-cross,
		.icon-alert {
			width: 65px;
			z-index: 10;
		}
		.success-gif {
			object-fit: cover;
			position: absolute;
			top: 0;
		}
		.message {
			margin: 30px 0;
			font-size: $large-font-size;
			z-index: 10;
			@extend .font--semibold;
		}
		a {
			z-index: 10;
		}
		.loader-backdrop {
			position: relative;
			width: fit-content;
			height: fit-content;
			background-color: transparent;
		}
		.progress-bar {
			max-width: 600px;
		}
		.select-location {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			.multi-select-filter-container {
				margin-bottom: 20px;
				width: 100%;
				max-width: 300px;
			}
		}
		.actions {
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}
}

.location-edit-container {
	.form-container {
		.form-content {
			padding: 0;
		}
	}
	.at-switch-cnt [aria-label][data-balloon-pos]:after,
	.multi-select-filter-container [aria-label][data-balloon-pos]:after,
	.at-input-container [aria-label][data-balloon-pos]:after {
		width: 200px;
		left: -50px;
	}
	.archive-restore-container,
	.manage-polygons-container,
	.locations-items-association-container,
	.copy-catalogue-container,
	.item-location-customization-container {
		.form-container {
			.form-content {
				position: relative;
				padding: 0 25px 25px 25px;
			}
		}
	}
	.basic-info-tab,
	.direct-platform-tab,
	.self-delivery-tab {
		padding: 0 25px;
		.basic-info-brand-id {
			font-size: 12px;
			color: $mc-5;
			margin-top: 5px;
		}
	}
	.self-delivery-tab {
		.self-delivery-input {
			.validation-message {
				position: static;
			}
		}
	}
	.popover-container {
		top: 72px;
		left: -10px !important;
		width: 400px;
		&::before {
			top: -12px;
		}
		&::after {
			top: -10px;
		}
		.popover-info {
			padding: 12px;
			color: $mc-7;
			font-size: $very-small-font-size;
			.popover-header {
				font-weight: 600;
			}
			.popover-message {
				margin-top: 7px;
			}
		}
	}
	.select-filter-custom {
		&.brand-selector,
		&.brand-filter-custom {
			margin-top: 19px;
			.dropdown-custom {
				.container {
					justify-content: flex-start;
					height: 30px;
					padding: 5px 8px 5px 0;
					border: none;
					span {
						margin-right: 10px;
					}
					.option {
						font-size: $small-font-size;
						@extend .font--semibold;
					}
				}
				.dropdown-options {
					.option {
						height: 37px;
						padding: 1px 10px;
					}
				}
				.custom-option,
				.custom-label {
					display: flex;
					align-items: center;
					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 8px;
						width: 30px;
						height: 30px;
						text-transform: uppercase;
						font-size: $medium-font-size;
						border-radius: $default-radius;
						@extend .font--semibold;
						img {
							width: 30px;
							height: 30px;
							object-fit: cover;
							border-radius: $default-radius;
						}
						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}
						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}
						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}
						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}
						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
					}
				}
				.custom-option {
					.logo {
						width: 25px;
						height: 25px;
						font-size: $very-small-font-size;
						img {
							width: 25px;
							height: 25px;
						}
					}
				}
			}
		}
	}
	.wrapper-sticky-top {
		top: 0px;
	}

	.aggr-platform-tab {
		padding: 0 25px;
		margin-bottom: 50px;
		.select-filter-custom {
			margin: 0;
		}
		.popover-container {
			top: 54px;
		}
		.at-modal {
			.modal-container {
				.modal-header {
					padding: 20px 25px;
					text-align: center;
					border-bottom: none;
					.title {
						font-size: $medium-large-font-size;
					}
				}
				.modal-content {
					padding: 0 25px;
					min-height: 50px;
				}
			}
		}
	}

	.manage-polygons-container {
		.map-loading {
			position: absolute;
			top: 0;
			left: 25px;
			right: 25px;
			border-radius: 0;
		}
		.polygons-table-container {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			font-size: $very-small-font-size;
			max-height: 45vh;
			overflow-y: auto;
			.table-header {
				position: sticky;
				top: 0px;
				background-color: #f6f7f8;
				border-bottom: 1px solid $border-color;
				box-sizing: border-box;
				z-index: 10;
				.table-row {
					display: flex;
					.table-cell {
						padding: 10px 15px;
						font-size: 12px;
						color: $mc-9;
						opacity: 0.6;
						text-transform: uppercase;
						box-sizing: border-box;
						flex-grow: 1;
						overflow: hidden;
						list-style: none;
						@extend .font--semibold;
					}
				}
			}
			.table-body {
				.table-row {
					display: flex;
					align-items: center;
					border-bottom: 1px solid $border-color;
					padding-top: 8px;
					padding-bottom: 8px;
					min-height: 70px;
					box-sizing: border-box;
					&.selected {
						outline: 2px solid $color-blue;
						outline-offset: -2px;
					}
					&:nth-of-type(odd) {
						background-color: $mc-2;
					}
					&:last-child {
						border-bottom: none;
					}
					&:hover {
						background-color: rgba(47, 88, 242, 0.05);
					}
					.table-cell {
						text-overflow: ellipsis;
						box-sizing: border-box;
						flex-grow: 1;
						list-style: none;
						padding: 7px 15px;
					}
				}
			}
			.name {
				width: 30%;
				display: flex;
				align-items: center;
				span {
					position: relative;
					bottom: 1px;
					color: $color-blue;
					cursor: pointer;
					@extend .font--semibold;
				}
			}
			.delivery-charge {
				width: 70%;
				display: flex;
				align-items: center;
				.at-input-container {
					width: 80%;
				}
			}
			.delete-polygon {
				margin-left: auto;
				flex-shrink: 0;
				position: relative;
				top: 2px;
				img {
					width: 15px;
					padding: 5px;
					border: 1px solid $color-red;
					border-radius: $default-radius;
					cursor: pointer;
				}
			}
			.no-items-placeholder {
				img {
					width: 200px;
				}
				padding: 10px 0 30px 0;
			}
		}
		.at-modal {
			.modal-container {
				width: 70%;
				.modal-header {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					padding: 25px;
					text-align: center;
					border-bottom: none;
					.title {
						vertical-align: top;
						line-height: normal;
						font-size: $medium-large-font-size;
						margin-bottom: 5px;
						color: $mc-7;
					}
				}
				.modal-content {
					min-height: 50px;
					text-align: center;
				}
			}
			.modal-message {
				padding: 0 25px;
				color: $mc-5;
			}
		}
	}

	.at-modal {
		.modal-container {
			width: 80%;
			.modal-header {
				padding: 15px 25px;
				border-bottom: 1px solid $border-color;
				text-align: left;
				.title {
					font-size: $small-font-size;
					margin-bottom: 0;
					color: $mc-9;
					.modal-title {
						display: flex;
						align-items: center;
						position: relative;
						top: 2px;
						img {
							margin-right: 5px;
							width: 18px;
						}
					}
				}
			}
			.modal-content {
				min-height: 50px;
				padding: 0 25px;
				.text {
					color: $mc-7;
					font-size: $very-small-font-size;
				}
				ul {
					padding: 0 0 0 15px;
					margin: 15px 0 0 0;
					li:first-child {
						margin-bottom: 20px;
					}
					li:last-child {
						margin-bottom: 0px;
					}
					li::marker {
						color: $mc-7;
					}
				}
				span {
					color: $mc-9;
					@extend .font--semibold;
				}
				.all-platforms {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					margin-top: 15px;
				}
				.platform-container {
					display: flex;
					align-items: center;
					margin-right: 25px;
					margin-bottom: 12px;
					min-width: 135px;
					color: $mc-7;
					font-size: $very-small-font-size;
					img {
						border-radius: 4px;
						width: 20px;
						margin-right: 5px;
					}
				}
			}
		}
	}

	.platform-card {
		.loader-backdrop {
			background: rgba(0, 0, 0, 0.1);
			z-index: 100;
		}

		.fulfillment-action-message {
			padding: 0 15px;
			margin: 35px 10px 15px 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			.close {
				position: absolute;
				font-size: $medium-font-size;
				top: 5px;
				right: 22px;
				padding: 0 5px;
				font-weight: 600;
				color: $mc-7;
				cursor: pointer;
			}

			.fulfillment-response {
				padding: 15px;
				border-radius: $default-radius;
				text-align: center;
				font-size: $very-small-font-size;
				width: 100%;

				&.success {
					color: $color-green;
					border: 1px solid $color-green;
					background: rgba(46, 204, 113, 0.1);
				}

				&.fail {
					color: $color-red;
					border: 1px solid $color-red;
					background: rgba(192, 57, 43, 0.1);
				}
			}
		}

		.logis-text-cnt {
			font-size: 12px;
			color: $mc-5;
			padding-top: 3px;
		}

		.logis-service-img {
			width: 30px;
			display: block;
			margin-top: 10px;
		}

		.logistics-action-message {
			padding: 0 15px;
			margin: 35px 10px 15px 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			.close {
				position: absolute;
				font-size: $medium-font-size;
				top: 5px;
				right: 22px;
				padding: 0 5px;
				font-weight: 600;
				color: $mc-7;
				cursor: pointer;
			}

			.logistics-response {
				padding: 15px;
				border-radius: $default-radius;
				text-align: center;
				font-size: $very-small-font-size;
				width: 100%;

				&.success {
					color: $color-green;
					border: 1px solid $color-green;
					background: rgba(46, 204, 113, 0.1);
				}

				&.fail {
					color: $color-red;
					border: 1px solid $color-red;
					background: rgba(192, 57, 43, 0.1);
				}
			}
		}

		border: 1px solid $border-color;
		border-radius: $default-radius;
		position: relative;
		margin-bottom: 25px;
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);

		.platform-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px 25px;

			.platform-info {
				display: flex;
				align-items: center;

				img {
					margin-right: 10px;
					height: 28px;
					border-radius: $default-radius;
				}

				span {
					font-size: $small-font-size;
					text-transform: capitalize;
					@extend .font--semibold;
				}

				.name {
					display: flex;
					flex-direction: column;
				}

				.turn-on-time {
					font-size: 12px;
					color: $mc-5;
					text-transform: none;
					margin-top: 3px;
					@extend .font--regular;
				}
			}

			.action-log-container {
				text-align: right;
				color: $mc-7;
				font-size: $very-small-font-size;

				.action-log-item {
					margin-bottom: 5px;
				}
			}
		}

		.form-row {
			padding: 0 25px;
			margin-bottom: 25px;
			position: relative;

			&:last-child {
				margin-bottom: 25px;
			}

			a img {
				position: absolute;
				width: 20px;
				padding: 5px;
				opacity: 0.3;
				cursor: pointer;
				top: 25px;
				right: 66px;
			}
		}

		.platform-action-message {
			padding: 0 25px;
			margin: 10px 0 15px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			.close {
				position: absolute;
				font-size: $medium-font-size;
				top: 3px;
				right: 32px;
				padding: 0 5px;
				font-weight: 600;
				color: $mc-7;
				cursor: pointer;
			}

			.publish-response {
				padding: 15px;
				border-radius: $default-radius;
				text-align: center;
				font-size: $very-small-font-size;
				width: 100%;

				&.success {
					color: $color-green;
					border: 1px solid $color-green;
					background: rgba(46, 204, 113, 0.1);
				}

				&.fail {
					color: $color-red;
					border: 1px solid $color-red;
					background: rgba(192, 57, 43, 0.1);
				}
			}
		}

		.form-action {
			padding: 10px 25px 15px 25px;

			.at-btn {
				margin-right: 10px;
			}
		}

		.platform-action {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			position: relative;
			top: 3px;
			.context-menu {
				position: relative;
				top: 0;
				right: 0;
				margin-left: 10px;
				border: 1px solid $border-color-dark;
				border-radius: $border-radius-default;
				.icon-dots {
					padding: 9px 15px;
				}
				.menu {
					top: 40px;
					right: 0px;
				}
			}
			.toggle-status {
				display: flex;
				align-items: center;
				.dropdown-date-time-filter {
					margin-left: 5px;
					// &.with-custom {
					// 	.dropdown-component {
					// 		left: -250px;
					// 	}
					// }
					.dropdown-custom {
						.dropdown-component {
							z-index: 500;
							.date-time-calendar {
								padding: 10px 12px;
							}
							.time-selector {
								margin-bottom: 15px;
								.meridiem-container {
									margin-left: 15px;
								}
								.multi-select-filter-container {
									width: 42px;
								}
							}
							.date-time-range-selector {
								.date {
									width: 75px;
								}
							}
						}
						.container {
							padding: 8px 5px;
							min-width: 0;
							width: 60px;
							border: none;
							border-radius: $default-radius;
							color: $mc-7;
							&:hover {
								background: $mc-2;
							}
						}
						.dropdown-arrow {
							padding-left: 3px;
						}
						.at-btn {
							color: $mc-1;
							border: none;
						}
						.react-select__dropdown-indicator {
							display: none;
						}
					}
				}
				.at-switch-cnt {
					.meta-info {
						font-size: $very-small-font-size;
						color: $mc-7;
						margin-left: 10px;
					}
				}
			}

			.status {
				color: $mc-5;
				font-size: $very-small-font-size;
				cursor: default;
			}

			.at-btn {
				margin-left: 10px;
				color: $mc-7;
				border: 1px solid $mc-4;

				&:last-child {
					margin: 0;
				}

				&:hover {
					color: $mc-9;
					border: 1px solid $mc-5;
				}
			}

			.at-btn--danger {
				border: 1px solid $color-red;
				color: $color-red;
				background-color: white;

				&:hover {
					border: 1px solid $color-red;
					color: #fff;
					background: $color-red;
				}
			}

			.at-btn--success {
				border: 1px solid $color-green;
				color: $color-green;
				background-color: white;

				&:hover {
					border: 1px solid $color-green;
					color: #fff;
					background: $color-green;
				}
			}
			.at-switch-cnt {
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				margin-left: 15px;
				.meta-info {
					position: relative;
					top: 0;
					margin-left: 5px;
				}
				.at-switch {
					.switch-rail {
						margin: 0;
						margin-top: 4px;
					}
					.switch-ball {
						top: 6px;
					}
					&.active {
						.switch-rail {
							background-color: $color-green;
						}
					}
				}
			}
		}
	}
	.associated-brands-container {
		padding: 0 25px;
		padding-bottom: 30px;
		.header-container {
			display: flex;
			justify-content: space-between;
			border-radius: $default-radius;
			padding: 15px 0;
			margin-bottom: 25px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.bulk-actions {
			padding: 0;
		}
		.associated-brands-list-container {
			&.custom-table-container {
				.table-header {
					top: 38px !important;
				}
				.table-list {
					font-size: $very-small-font-size;
				}
			}
			.name {
				display: flex;
				width: 30%;
				.brand-info {
					display: flex;
					overflow: hidden;
				}
				.brand-initials {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-shrink: 0;
					width: 32px;
					height: 32px;
					text-transform: uppercase;
					color: $mc-7;
					background-color: rgba($color: $mc-7, $alpha: 0.1);
					font-size: $small-font-size;
					border-radius: $default-radius;
					@extend .font--semibold;
					&.brown {
						color: $color-brown-dark;
						background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
					}
					&.blue {
						color: $color-blue-dark;
						background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
					}
					&.green {
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					}
					&.red {
						color: $color-red-dark;
						background-color: rgba($color: $color-red-dark, $alpha: 0.1);
					}
					&.purple {
						color: $color-violet;
						background-color: rgba($color: $color-violet, $alpha: 0.1);
					}
					img {
						width: 32px;
						height: 32px;
						border-radius: $default-radius;
						object-fit: cover;
					}
				}
				.brand-name {
					margin-left: 10px;
					text-transform: capitalize;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					@extend .font--semibold;
				}
			}
			.location-id {
				width: 15%;
			}
			.items-count {
				width: 15%;
			}
			.assoc-platforms {
				display: flex;
				flex-wrap: wrap;
				gap: 15px;
				padding-right: 25px;
				width: 40%;
				.platform {
					position: relative;
					img {
						width: 23px;
						height: 23px;
						object-fit: cover;
						border-radius: $default-radius;
					}
					.state {
						position: absolute;
						top: -5px;
						right: -5px;
						width: 7px;
						height: 7px;
						border-radius: 50%;
						box-sizing: content-box;
						&.enabled {
							background-color: $color-green;
							border: 2px solid $mc-1;
						}
						&.disabled {
							opacity: 1;
							background-color: $color-red;
							border: 2px solid $mc-1;
						}
						&.not-live {
							background-color: $mc-5;
							border: 2px solid $mc-1;
						}
						&.disconnected {
							background-color: $mc-5;
							border: 2px solid $mc-1;
						}
					}
				}
			}
		}
	}
	.location-groups-tab {
		padding: 0 25px;
		padding-bottom: 30px;
		.select-filter-custom {
			margin: 0;
		}
		.popover-container {
			top: 54px;
		}
		.header-container {
			display: flex;
			justify-content: space-between;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 25px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;
			.at-input-container {
				width: 30%;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.location-groups-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.name {
				width: 70%;
				padding-left: 15px;
			}
			.locations {
				width: 30%;
				padding-right: 15px;
				text-align: right;
			}
		}
	}
	.timings-container {
		.holiday-schedule-selector {
			padding: 24px;
			.multi-select-filter-container {
				width: 350px;
				margin-bottom: 24px;
			}
			.action-buttons-container {
				display: flex;
				gap: 10px;
			}
		}
		.form-row {
			padding: 24px;
			border-bottom: 1px solid $color-neutral-black-10;
			margin-bottom: 0;
		}
		.holiday-hour-banner {
			display: flex;
			padding: 16px;
			width: fit-content;
			border-radius: 2px;
			align-items: center;
			gap: 12px;
			background-color: #f2f5ff;
			border-left: 6px solid #3b5feb;
			margin: 0px 24px;
			p {
				margin: 0;
				font-weight: 400;
				color: #363636;
				font-size: 14px;
			}
			img {
				width: 20px;
				height: 20px;
			}
		}
		.regular-schedule-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px 24px 15px 24px;
			.schedule-header {
				color: $color-neutral-black-80;
				font-weight: 600;
				font-size: $very-small-font-size;
			}
			.schedule-header--description {
				color: $color-neutral-black-60;
				font-size: $extra-small-font-size;
			}
		}
		.brand-filter-upcoming-holiday-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 150px;
			border-bottom: 1px solid $color-neutral-black-10;
			padding: 0 24px 24px 24px;
			.upcoming-holiday {
				display: flex;
				align-items: center;
				gap: 4px;
				p {
					font-size: $very-small-font-size;
					color: $color-neutral-black-50;
					margin: 0;
				}
			}
			.brand-filter-custom {
				.container {
					height: 30px;
					.custom-label {
						font-size: $small-font-size;
						font-weight: 600;
						color: $color-neutral-black-80;
					}
					.logo {
						width: 30px;
						height: 30px;
						img {
							width: 30px;
							height: 30px;
						}
					}
				}
				.dropdown-component {
					width: 329px;
					right: auto;
					left: 0;
					z-index: 160 !important;
				}
			}
		}
		.upcoming-dropdown {
			padding: 12px 24px 24px 24px;
		}
		.dropdown-container {
			transition: 200ms;
			position: relative;
			.action-buttons-container {
				margin-top: 12px;
				display: flex;
				gap: 10px;
				transition: 300ms;
			}
		}
		.header-text {
			display: flex;
			align-items: baseline;
			justify-content: space-between;
			margin-bottom: 10px;
			font-size: $medium-font-size;
			@extend .font--semibold;
			.cascade {
				color: $color-blue-light;
				font-size: $extra-small-font-size;
				cursor: pointer;
				@extend .font--regular;
				&:hover {
					color: $color-blue;
				}
			}
		}
		.select-filter-custom {
			margin: 0;
		}
		.popover-wrapper-container {
			width: 50%;
			.popover-container {
				top: 54px;
			}
		}
		.card-container {
			padding: 15px;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			margin-bottom: 50px;
		}
		.platform-timing-details-container {
			.timing-details {
				height: 53px;
				border: 1px solid $border-color;
				border-radius: $default-radius;
				margin-bottom: 16px;
				padding: 10px 15px;
				display: flex;
				justify-content: space-between;
				&:last-child {
					margin-bottom: 0;
				}
				.platform-details {
					width: 25%;
					display: flex;
					align-items: center;
					gap: 10px;
					.platform-logo-container {
						.platform-img {
							height: 30px;
							width: 30px;
							object-fit: contain;
							border-radius: $default-radius;
						}
					}
					.platform-name {
						width: 75%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						color: $mc-7;
						font-size: $very-small-font-size;
					}
				}
				.status-container {
					width: 35%;
					display: flex;
					align-items: center;
					margin-left: auto;
					.status {
						display: flex;
						align-items: center;
						height: 80%;
						max-width: 80%;
						padding: 0 10px;
						border-radius: $default-radius;
						gap: 8px;
						font-size: $very-small-font-size;
						.status-text {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						.status-icon {
							position: relative;
							top: 2px;
						}
					}
					.success {
						background-color: rgba(46, 204, 113, 0.1);
						color: $color-green;
					}
					.failure {
						background-color: rgba($color-red, 0.1);
						color: $color-red;
						.status-icon {
							.cancel {
								svg {
									path {
										stroke: $color-red;
									}
								}
							}
						}
					}
					.unsupported {
						background-color: $mc-3;
						color: $mc-8;
					}
				}

				.update-action-container {
					display: flex;
					justify-content: right;
					align-items: center;
					width: 25%;
					.at-btn {
						display: flex;
						align-items: center;
						justify-content: center;
						transition: 300ms;
						.icon-container {
							padding-right: 8px;
							svg {
								position: relative;
								// top: 3px;
								padding-right: 0;
							}
						}
						&:hover {
							svg {
								g {
									fill: $color-blue;
								}
							}
						}
					}
					.updating {
						.icon-container {
							padding-right: 8px;
							svg {
								padding-right: 0;
								animation-name: spin;
								animation-duration: 2000ms;
								animation-iteration-count: infinite;
								animation-timing-function: linear;
							}
						}
					}
					@keyframes spin {
						from {
							transform: rotate(0deg);
						}
						to {
							transform: rotate(360deg);
						}
					}
				}
			}
		}
		.at-modal {
			.modal-container {
				width: 70%;
				.modal-header {
					padding: 25px;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					text-align: center;
					border-bottom: none;
					.title {
						color: $mc-7;
						font-size: $medium-large-font-size;
					}
				}
				.modal-content {
					padding: 0;
					min-height: 50px;
				}
			}
			.modal-gap-correction {
				padding: 0 25px;
				color: $mc-5;
			}
		}
		.cascade-timings-container {
			.form-content {
				padding: 0 25px;
			}
			.search-container {
				display: flex;
				justify-content: space-between;
				margin-bottom: 35px;
				.at-input-container {
					width: 30%;
				}
				.multi-select-filter-container {
					width: 30%;
					opacity: 1;
					.meta-info {
						font-weight: normal;
						color: $mc-6;
					}
				}
			}
			.success-container {
				.success-message {
					margin: 25px;
					padding: 20px;
					font-size: $small-font-size;
					color: $color-green;
					border: 1px solid $color-green;
					border-radius: $default-radius;
					background-color: rgba(46, 204, 113, 0.1);
				}
				.buttons {
					width: 100%;
					padding: 20px 25px;
					margin-top: 45vh;
					box-sizing: border-box;
					background: white;
					box-shadow: -4px -2px 4px 0px rgba(0, 0, 0, 0.08);
					z-index: 10;
					animation: slideUp 180ms;
					transition: 180ms ease-in-out;
					* {
						margin: 10px;
					}
				}
			}
			.cascade-brands-table-container {
				margin-bottom: 30px;
				.transaction-header-row {
					top: -0.2px;
					.at-header-text {
						opacity: 1;
					}
					.at-checkbox {
						font-size: $extra-small-font-size;
						height: 16px;
					}
				}
				.transaction-rows {
					min-height: 70px;
				}
				.at-cell-text {
					display: flex;
					align-items: center;
					font-size: $very-small-font-size;
					.at-checkbox {
						font-size: $very-small-font-size;
						.checkmark {
							top: 6px;
						}
					}
				}
				.name {
					width: 60%;
					padding-left: 25px;
					.brand-info {
						display: flex;
						align-items: center;
					}
					.brand-initials {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
						width: 28px;
						height: 28px;
						text-transform: uppercase;
						color: $mc-7;
						background-color: rgba($color: $mc-7, $alpha: 0.1);
						font-size: $very-small-font-size;
						border-radius: $default-radius;
						@extend .font--semibold;
						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}
						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}
						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}
						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}
						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
						img {
							width: 28px;
							height: 28px;
							border-radius: $default-radius;
							object-fit: cover;
						}
					}
					.brand-name {
						margin-left: 10px;
						text-transform: capitalize;
					}
				}
				.timings {
					width: 40%;
					padding-right: 25px;
				}
			}
		}
	}
	.menus-association-container {
		.brand-selector-container {
			padding: 0 24px 24px 24px;
			border-bottom: 1px solid $border-color;
			.select-filter-custom {
				.dropdown-component {
					width: 50%;
					left: 0;
				}
			}
		}
		.placeholder-main {
			padding-top: 50px;
		}
		.header-container {
			padding: 24px;
			.header {
				color: $color-neutral-black-60;
				font-size: $medium-font-size;
				font-weight: $font--semibold;
			}
			.sub-header {
				color: $color-neutral-black-50;
				font-size: $very-small-font-size;
			}
		}
		.menu-info-container {
			border: 1px solid $border-color;
			padding: 12px;
			margin: 0 24px 24px 24px;
			border-radius: $default-radius;
			.menu-metadata-container {
				.title-desc-actions-container {
					display: flex;
					justify-content: space-between;
					.title-desc-container {
						line-height: 1.5;
						.header {
							color: $color-neutral-black-dark;
							font-weight: $font--semibold;
							.version {
								color: $color-neutral-black-40;
							}
						}
						.sub-header {
							font-size: $very-small-font-size;
							color: $color-neutral-black-50;
						}
						margin-bottom: 16px;
					}
					.actions-container {
						display: flex;
						height: 20px;
						gap: 16px;
						img {
							cursor: pointer;
						}
						position: relative;
						.context-menu {
							.action-item {
								display: flex;
								align-items: center;
								gap: 8px;
							}
						}
					}
				}
				.item-sections-count-container {
					padding-bottom: 16px;
					font-size: $very-small-font-size;
					color: $color-neutral-black-60;
					display: flex;
					gap: 4px;
				}
				.platforms-publish-details-container {
					border-top: 1px solid $border-color;
					padding-top: 12px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.platforms-container {
						display: flex;
						gap: 8px;
						.platform-image {
							height: 24px;
							width: 24px;
							border-radius: $default-radius;
						}
					}
					.publish-info {
						font-size: $extra-small-font-size;
						color: $color-neutral-black-50;
						.status {
							color: $color-green;
							background-color: rgba($color-green, 0.1);
							padding: 2px 8px;
						}
					}
				}
			}
		}
		.menu-view-container {
			height: 100%;
			.categories-container {
				border: 1px solid $border-color;
				height: 80%;
				margin: 0 24px;
				display: flex;

				.selected-section-content--container {
					width: 70%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 20px 16px;
					height: 80%;
					overflow-y: scroll;
					.header-container {
						width: 100%;
						.title {
							width: 80%;
							font-weight: 600;
							color: $color-neutral-black-80;
							font-size: $very-small-font-size;
							@include truncate_text;
						}
						.sub-title {
							font-size: $extra-small-font-size;
							color: $color-neutral-black-50;
							width: 80%;
							@include truncate_text;
						}
					}
					.preview-items-container {
						width: 100%;
						.items-header {
							display: flex;
							padding: 24px 24px 20px 24px;
							font-size: $very-small-font-size;
							color: $color-dark-grey;
							font-weight: $font--semibold;
							gap: 8px;
							.items-count {
								font-weight: 400;
								color: $mc-6;
							}
						}
						.preview-item {
							display: flex;
							border-bottom: 1px solid $border-color;
							flex-wrap: wrap;
							justify-content: space-between;
							margin-bottom: 32px;
							margin-top: 24px;
							padding: 0 24px;
							padding-bottom: 10px;
							.item-info {
								width: auto;
								max-width: 75%;
								.foodtype-title {
									display: flex;
									gap: 8px;
									.foodtype-indicator {
										height: 12px;
										width: 12px;
										position: relative;
									}
									.veg {
										border: 2px solid #388e3c;
										&::after {
											position: absolute;
											height: 8px;
											width: 8px;
											content: "";
											top: 50%;
											left: 50%;
											border-radius: 50%;
											background-color: #388e3c;
											transform: translate(-50%, -50%);
										}
									}
									.non-veg {
										border: 2px solid #bf360c;
										&::after {
											position: absolute;
											height: 0px;
											width: 0px;
											content: "";
											border-left: 4px solid transparent;
											border-right: 4px solid transparent;
											border-bottom: 8px solid #bf360c;
											top: 15%;
											left: 15%;
										}
									}
									.food-title {
										width: 180px;
										font-weight: $font--semibold;
										color: $color-light-black;
										@include truncate_text;
									}
								}
								.item-description {
									color: $color-dark-grey;
									font-size: $very-small-font-size;
								}
								.prices-container {
									margin-top: 24px;
									font-size: $very-small-font-size;
									display: flex;
									gap: 4px;
									.markup-price {
										color: $mc-6;
										position: relative;
										&::before {
											position: absolute;
											content: "";
											left: 0;
											top: 50%;
											right: 0;
											border-top: 1px solid;
											border-color: inherit;
											-webkit-transform: rotate(336deg);
											-moz-transform: rotate(336deg);
											-ms-transform: rotate(336deg);
											-o-transform: rotate(336deg);
											transform: rotate(336deg);
										}
									}
									.actual-price {
										font-weight: $font--semibold;
										color: $color-light-black;
									}
								}
								@include bp(768px) {
									width: 100%;
								}
							}
							.item-actions {
								width: 150px;
								display: flex;
								justify-content: flex-end;
								position: relative;
								.item-image {
									height: 70px;
									width: 100px;
									object-fit: cover;
									border-radius: $border-radius-default;
								}
								@include bp(768px) {
									width: 100%;
								}
							}
						}
					}
					&-flex-start {
						justify-content: flex-start;
					}
				}
			}
			.parent-categories-list {
				width: 30%;
				border-right: 1px solid $border-color;
				height: 100%;
				position: relative;
				.parent-sections-list-container {
					height: 100%;
					overflow-y: scroll;
				}
				.parent-category {
					padding: 15px 24px;
					color: $color-dark-grey;
					position: relative;
					cursor: pointer;
					.parent-category--name {
						width: 90%;
						display: block;
						@include truncate_text;
					}
					&--selected {
						border-left: 3px solid $color-blue;
						background-color: #f2f5ff;
						color: $color-blue;
						font-weight: $font--semibold;
					}
					&--selected-includes-subsection {
						padding-left: 10px;
						&::before {
							content: "";
							position: absolute;
							top: 40px;
							left: 17px;
							width: 2px;
							height: 25px;
							background-color: #d0dadd;
						}
						&::after {
							content: ".";
							position: absolute;
							color: #d0dadd;
							top: 10px;
							left: 13px;
							font-size: 36px;
						}
					}
				}
				.sub-sections-list {
					background-color: #f2f5ff;
					color: $color-blue;
					font-weight: $font--semibold;
					.sub-section {
						width: 65%;
						padding: 15px 24px;
						padding-left: 36px;
						@include truncate_text;
						position: relative;
						&::before {
							content: "";
							position: absolute;
							top: 25px;
							left: 20px;
							width: 13px;
							height: 2px;
							background-color: #d0dadd;
						}
						&::after {
							content: ".";
							position: absolute;
							color: #d0dadd;
							top: -5px;
							left: 26px;
							font-size: 36px;
						}
						.line--vertical {
							position: absolute;
							left: 20px;
							top: 0;
							width: 2px;
							height: 50px;
							background-color: #d0dadd;
							&.half {
								height: 25px;
							}
						}
					}
				}
			}
		}
		.table-wrapper {
			padding: 0px 24px 24px 24px;
		}
		.menu-detail-container {
			.filters-wrapper {
				display: flex;
				padding: 0px 24px 24px 24px;
				align-items: center;
				justify-content: space-between;
				.filters-wrapper-left {
					display: flex;
					gap: 24px;
					align-items: center;
					.platform-selector-wrapper {
						width: 150px;
						// padding: 0 24px 24px 24px;
						.select-filter-custom {
							width: 100%;
							margin-top: 0;
							.dropdown-custom {
								width: 220px;
								.container {
									height: 32px;
									padding: 1px 10px;
								}
								.dropdown-options {
									.option {
										height: 37px;
										padding: 1px 10px;
									}
								}
								.custom-option,
								.custom-label {
									display: flex;
									align-items: center;
									.logo {
										display: flex;
										align-items: center;
										justify-content: center;
										margin-right: 8px;
										width: 22px;
										height: 22px;
										text-transform: uppercase;
										font-size: $very-small-font-size;
										border-radius: $default-radius;
										@extend .font--semibold;
										img {
											width: 22px;
											height: 22px;
											object-fit: cover;
											border-radius: $default-radius;
										}
										&.brown {
											color: $color-brown-dark;
											background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
										}
										&.blue {
											color: $color-blue-dark;
											background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
										}
										&.green {
											color: $color-green-dark;
											background-color: rgba($color: $color-green-dark, $alpha: 0.1);
										}
										&.red {
											color: $color-red-dark;
											background-color: rgba($color: $color-red-dark, $alpha: 0.1);
										}
										&.purple {
											color: $color-violet;
											background-color: rgba($color: $color-violet, $alpha: 0.1);
										}
									}
								}
							}
							.container {
								padding: 0 10px;
								border: 1px solid $border-color-dark;
								justify-content: space-between;
								height: 35px;
							}
						}
					}
				}
				.status-filter {
					width: 150px;
				}
			}
			.menu-detail-list-table-container {
				.transaction-rows {
					min-height: 100px;
				}
				.item {
					width: 50%;
				}
				.status {
					width: 30%;
				}
				.price {
					width: 20%;
				}
				.status {
					.available,
					.unavailable {
						width: fit-content;
						padding: 2px 8px;
						border-radius: 2px;
						font-weight: 600;
					}
					.available {
						background-color: #ecf7f0;
						color: #0b6e1b;
					}
					.unavailable {
						background-color: #f5f5f5;
						color: #646464;
					}
				}
				.item {
					.item-wrapper {
						display: flex;
						gap: 8px;
						img {
							width: 64px;
							height: 64px;
							border-radius: 2px;
						}
						.item-img-unavailable {
							opacity: 40%;
						}
						.item-details {
							p {
								margin: 0;
							}
							display: flex;
							flex-direction: column;
							gap: 8px;
							.title {
								color: $color-neutral-black-80;
								font-size: 14px;
								font-weight: 600;
							}
							.crm-title {
								color: #aaaaaa;
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
	.catalogue-tab {
		.header-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 30px;

			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}

			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-right: 15px;
				padding-top: 5px;

				&:nth-child(3) {
					@extend .font--semibold;
				}
			}

			.action-buttons {
				.button {
					display: inline-block;

					&:first-child {
						margin-right: 10px;
					}
				}
			}
		}

		.location-catalogue-container {
			padding-bottom: 50px;
		}

		.search-container {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -10px;
			margin-bottom: 35px;

			&:first-child {
				margin-bottom: 15px;
			}

			& > div {
				width: 30%;
				padding: 0 10px;
			}

			&:after {
				content: "";
				flex: auto;
			}

			.multi-select-filter-container {
				opacity: 1;

				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}

			.switch-filters {
				display: flex;
				justify-content: space-around;
			}
		}

		.category-navigation {
			position: absolute;
			bottom: 20px;
			left: 50%;
			transform: translateX(-50%);
			padding: 7px 20px;
			background-color: $color-green;
			border: 1px solid $color-green;
			border-radius: $default-radius;
			box-shadow: $default-shadow;
			text-transform: capitalize;
			cursor: pointer;

			span {
				color: #fff;
				font-size: $very-small-font-size;
				font-weight: 600;
			}

			img {
				width: 15px;
				margin-right: 5px;
				vertical-align: bottom;
			}

			.categories-list {
				position: absolute;
				bottom: 125%;
				left: 50%;
				transform: translateX(-50%);
				background-color: #fff;
				border: 1px solid $border-color;
				border-radius: $default-radius;
				box-shadow: $default-shadow;
				min-height: 150px;
				max-height: 300px;
				width: 150px;
				overflow-y: auto;
				color: $mc-7;
				padding: 10px 15px;
				font-size: $very-small-font-size;
				transition: 180ms;

				.category-list-item {
					position: relative;
					margin-bottom: 5px;

					&:hover {
						color: $mc-8;
					}

					&[data-active="true"]:before {
						content: " ";
						height: 6px;
						width: 6px;
						border-radius: 50%;
						background-color: $color-green;
						position: absolute;
						top: 50%;
						left: -7%;
						transform: translateY(-50%);
					}
				}
			}
		}

		.location-catalogue-category {
			margin-bottom: 30px;

			.name {
				color: $mc-7;
				font-size: $very-small-font-size;

				span,
				img {
					margin-right: 5px;
				}

				span.root-category {
					font-weight: 600;
				}
			}

			.item-count {
				color: $mc-7;
				font-size: 12px;
			}
		}

		.location-category-items-table-container {
			.transactions-list-table {
				margin-top: 10px;

				.at-table-row {
					align-items: center;
					border-radius: $default-radius;
					box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
					background-color: #fff;
					border: solid 1px $border-color;
					margin-bottom: 20px;
					padding: 5px 0;

					@include bp(768px) {
						margin-left: 10px;
						margin-right: 10px;
						box-shadow: none;
					}

					&[data-is-available="false"] {
						filter: grayscale(1);
					}
				}

				.at-table-cell {
					padding: 5px 10px;
				}

				.at-cell-text {
					font-size: $very-small-font-size;
				}

				& > .at-table-row-based.item-grid {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

					.at-table-row {
						width: 30%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.at-table-cell {
							flex-grow: initial;
						}

						.items {
							width: 100%;

							.item-image {
								display: inline-block;
								width: 100%;
								height: 120px;
								margin-right: 0;
								margin-bottom: 15px;
								border-radius: $default-radius;
								position: relative;
								overflow: hidden;
								box-sizing: border-box;
								background: #f2f2f2;
								z-index: 10;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}

								&:after {
									content: " ";
									white-space: pre;
									animation-duration: 2s;
									animation-fill-mode: forwards;
									animation-iteration-count: infinite;
									animation-name: placeHolderShimmer;
									animation-timing-function: ease-in-out;
									background: linear-gradient(
										to right,
										rgba(228, 226, 226, 0) 0%,
										#979797 50%,
										rgba(228, 226, 226, 0) 100%
									);
									background-size: 100%;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									position: absolute;
									background-repeat: no-repeat;
									will-change: transform, opacity;
									z-index: -1;
								}
							}

							.item-desc {
								width: calc(100% - 20px);

								.food-type {
									margin-right: 5px;
								}

								.item-recommended {
									height: 12px;
									margin-left: 5px;
								}

								.desc-text {
									min-height: 50px;
								}
							}

							.item-price {
								.markup-price {
									color: $mc-5;
									text-decoration: line-through;
									text-decoration-color: $mc-9;
								}
							}

							.platform-images {
								width: 100%;
								margin-top: 10px;

								.platform-icon {
									margin-right: 15px;
									height: 20px;
									border-radius: $default-radius;
								}
							}
						}

						.customize {
							width: 100%;
							text-align: left;

							.action-item {
								width: 12px;
								padding: 5px;
								border: 1px solid $border-color-dark;
								border-radius: $default-radius;
								cursor: pointer;
							}
						}

						.inspect-mode-container {
							display: flex;
							flex-direction: column;
							font-size: 12px;
							width: 100%;
							position: relative;
							bottom: -5px;
							color: $color-blue;
							background-color: rgba(47, 88, 242, 0.1);
							border-radius: 0 0 4px 4px;

							.header {
								padding: 10px 10px 5px 10px;
								text-decoration: underline;
								@extend .font--semibold;
							}

							.platform-ilpa-info {
								padding: 10px;

								&:nth-last-child(1) {
									padding-bottom: 10px;
								}

								.highlight {
									padding: 0 1px;
									width: fit-content;
									border-radius: $default-radius;
									background-color: rgba(255, 217, 0, 0.3);
								}

								.action-timestamp {
									opacity: 0.6;
								}
							}
						}
					}
				}

				& > .at-table-row-based.item-row {
					.items {
						width: 80%;
						display: flex;

						.item-image {
							display: inline-block;
							height: 60px;
							width: 65px;
							margin-right: 10px;
							border-radius: $default-radius;
							position: relative;
							overflow: hidden;
							box-sizing: border-box;
							background: #f2f2f2;
							z-index: 10;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}

							&:after {
								content: " ";
								white-space: pre;
								animation-duration: 2s;
								animation-fill-mode: forwards;
								animation-iteration-count: infinite;
								animation-name: placeHolderShimmer;
								animation-timing-function: ease-in-out;
								background: linear-gradient(
									to right,
									rgba(228, 226, 226, 0) 0%,
									#979797 50%,
									rgba(228, 226, 226, 0) 100%
								);
								background-size: 100%;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								position: absolute;
								background-repeat: no-repeat;
								will-change: transform, opacity;
								z-index: -1;
							}
						}

						.item-desc {
							width: 50%;

							.food-type {
								margin-right: 5px;
							}

							.item-recommended {
								height: 12px;
								margin-left: 5px;
							}

							.desc-text {
								margin-top: 5px;
							}
						}

						.item-price {
							width: 12%;
							align-self: center;
							margin-left: 20px;

							.markup-price {
								color: $mc-5;
								text-decoration: line-through;
								text-decoration-color: $mc-9;
							}
						}

						.platform-images {
							align-self: center;
							width: 30%;
							text-align: right;

							.platform-icon {
								margin-left: 5px;
								height: 17px;
								border-radius: $default-radius;
							}
						}
					}

					.customize {
						width: 5%;
						text-align: right;

						.action-item {
							width: 12px;
							padding: 5px;
							border: 1px solid $border-color-dark;
							border-radius: $default-radius;
							cursor: pointer;
						}
					}

					.inspect-mode-container {
						display: flex;
						// align-items: center;
						flex-direction: column;
						font-size: 12px;
						width: 100%;
						position: relative;
						bottom: -5px;
						color: $color-blue;
						background-color: rgba(47, 88, 242, 0.1);
						border-radius: 0 0 4px 4px;

						.header {
							width: 50px;
							padding: 10px 10px 5px 10px;
							text-decoration: underline;
							@extend .font--semibold;
						}

						.platforms-container {
							display: flex;
							flex-wrap: wrap;
						}

						.platform-ilpa-info {
							padding: 10px;
							margin-right: 25px;

							.highlight {
								padding: 0 1px;
								width: fit-content;
								border-radius: $default-radius;
								background-color: rgba(255, 217, 0, 0.3);
							}

							.action-timestamp {
								opacity: 0.6;
							}
						}
					}
				}
			}
		}
	}
	.publish-logs-tab {
		.search-container {
			display: flex;
			margin-bottom: 35px;
			.at-input-container {
				width: 30%;
				margin-right: 35px;
			}
			.multi-select-filter-container {
				width: 30%;
				margin-right: 35px;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.publish-log-item {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			font-size: 12px;
			color: $mc-7;
			margin-bottom: 25px;
			box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);

			.highlight {
				font-weight: 600;
			}
			.log-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px;
				background-color: rgba($color: $mc-2, $alpha: 0.6);
				.log-action {
					display: flex;
					align-items: center;
					img {
						height: 20px;
						margin-right: 10px;
						border-radius: $default-radius;
					}
				}
				.log-status {
					display: flex;
					align-items: center;
					font-weight: 600;
					font-size: $very-small-font-size;
					color: $mc-8;
					min-width: 50px;
					&.ACK_SUCCESS {
						color: $color-green;
						span {
							margin-top: 1px;
						}
					}
					&.ACK_FAILED {
						color: $color-red;
						span {
							margin-top: 2px;
						}
					}
					img {
						margin-right: 5px;
					}
				}
			}
			.log-result {
				border-top: 1px solid $border-color;
				padding: 10px;
				.result-text {
					margin-bottom: 5px;
				}
			}
			.log-timeline {
				padding: 10px;
				display: flex;
				align-items: center;
				img {
					height: 20px;
					margin-right: 10px;
				}
			}
		}
	}
	.hub-channels-navigation {
		display: flex;
		align-items: center;
		position: sticky;
		bottom: 20px;
		left: 43%;
		width: fit-content;
		padding: 7px 20px;
		background-color: $color-green;
		border: 1px solid $color-green;
		border-radius: $default-radius;
		box-shadow: $default-shadow;
		text-transform: capitalize;
		cursor: pointer;
		z-index: 200;
		@include bp(768px) {
			bottom: 0px;
			transform: translateX(-50%);
			left: 55%;
		}
		span {
			color: #fff;
			font-size: $very-small-font-size;
			font-weight: 600;
		}
		img {
			width: 15px;
			margin-right: 5px;
		}
		.channels-list {
			position: absolute;
			bottom: 125%;
			left: 50%;
			transform: translateX(-50%);
			background-color: #fff;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			box-shadow: $default-shadow;
			min-height: 100px;
			max-height: 400px;
			width: 200px;
			overflow-y: auto;
			color: $mc-7;
			padding: 5px 10px 5px 15px;
			font-size: $small-font-size;
			transition: 180ms;
			.channel-list-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: relative;
				margin-bottom: 5px;
				padding: 1px 3px;
				&:hover {
					color: $mc-9;
				}
				&:nth-last-child(1) {
					margin-bottom: 0;
				}
				&[data-active="true"]:before {
					content: " ";
					height: 6.5px;
					width: 6.5px;
					border-radius: 50%;
					background-color: $color-green;
					position: absolute;
					top: 50%;
					left: -4%;
					transform: translateY(-50%);
				}
				img {
					width: 18px;
					height: 18px;
					border-radius: $default-radius;
					object-fit: cover;
				}
			}
		}
	}
}

.at-modal {
	.modal-container {
		.modal-content {
			overflow-y: visible;
		}

		.details-entry {
			padding: 0px 24px 10px 24px;

			.input {
				margin-top: 12px;
				position: relative;

				.at-input-container {
					margin-top: 20px;
				}

				textarea {
					box-sizing: border-box;
					border: 1px solid $border-color-dark;
					border-radius: $default-radius;
					width: 100%;
					height: 57px;
					margin-top: 20px;
					font-size: $very-small-font-size;
					outline: none;
					resize: none;
					padding: 5px 10px;
				}

				.label {
					position: absolute;
					top: -3px;
					font-weight: 600;
					font-size: $very-small-font-size;
					color: rgba(0, 0, 0, 0.6);
				}

				.label-polygon {
					top: -32px;
				}

				.label-gmap-section {
					top: -22px;
				}
			}

			.input-map {
				.switch-mode {
					margin-bottom: 32px;
					display: flex;

					.radio-div {
						padding: 0px 16px 0px 0px;

						.at-radiobutton {
							font-size: 16px;
						}
					}
				}

				.at-btn--secondary {
					margin-right: 10px;
				}

				.gmap-container-meraki {
					.action-buttons {
						.polygon-delete {
							position: absolute;
							top: -16px;
							right: 0;
						}
					}
				}
			}
		}

		.timing-group-selector {
			margin: auto;
			text-align: center;
			width: 60%;

			.label {
				font-weight: 600;
				font-size: $very-small-font-size;
				color: rgba(0, 0, 0, 0.6);
				text-align: left;
				margin-bottom: 10px;
			}

			.add-timing-group {
				display: flex;

				span {
					font-size: $very-small-font-size;
					text-align: left;
					color: $color-blue;
					text-transform: initial;
					position: relative;
					cursor: pointer;

					&::after {
						content: "";
						position: absolute;
						width: 100%;
						transform: scaleX(0);
						height: 1px;
						bottom: 0;
						left: 0px;
						background-color: $color-blue;
						transform-origin: bottom right;
						transition: transform 0.25s ease-out;
					}

					&:hover::after {
						transform: scaleX(1);
						transform-origin: bottom left;
					}
				}
			}
		}
	}
}

.locations-go-live-container {
	.form-sidebar-container {
		.header {
			padding-bottom: 0;
		}
	}
	.go-live-placeholder {
		.placeholder--image-container {
			img {
				height: 140px;
				width: 140px;
				object-fit: contain;
			}
		}
	}
}

.locations-publish-catalogue-container,
.locations-store-toggle-container,
.locations-go-live-container {
	.progress-bar-display {
		position: sticky;
		top: 0;
		z-index: 200;
		margin-bottom: 30px;
		.info {
			padding: 5px 25px 10px 25px;
		}
	}
	.section {
		padding: 25px;
		border-bottom: 1px solid $border-color;
		&:first-child {
			border-top: 1px solid $border-color;
		}
		&:last-child {
			border-bottom: none;
		}
		.section-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0;
			margin-bottom: 15px;
			font-size: 12px;
			color: $color-dark-grey;
			text-transform: uppercase;
			@extend .font--semibold;
			.select-all {
				padding: 0;
				color: $color-blue;
				background: $mc-1;
				text-transform: capitalize;
				font-size: 12px;
				cursor: pointer;
			}
		}
		.at-checkbox {
			margin-bottom: 15px;
			.checkmark {
				top: 4px;
			}
			.platform-info {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.platform-logo {
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: $default-radius;
					img {
						width: 25px;
						height: 25px;
						border-radius: $default-radius;
						object-fit: cover;
					}
				}
			}
		}
		&.snooze {
			margin-bottom: 500px;
		}
	}
	.select-filter-custom {
		&.brands {
			.dropdown-custom {
				.container {
					height: 32px;
					padding: 1px 10px;
					border-radius: $border-radius-default;
				}
				.dropdown-options {
					.option {
						height: 37px;
						padding: 1px 10px;
					}
				}
				.custom-option,
				.custom-label {
					display: flex;
					align-items: center;
					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 8px;
						width: 22px;
						height: 22px;
						text-transform: uppercase;
						font-size: $very-small-font-size;
						border-radius: $default-radius;
						@extend .font--semibold;
						img {
							width: 22px;
							height: 22px;
							object-fit: cover;
							border-radius: $default-radius;
						}
						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}
						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}
						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}
						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}
						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
					}
				}
			}
		}
	}
	.dropdown-date-time-filter {
		width: 100%;
		padding: 0;
		.dropdown-custom {
			.dropdown-component {
				.date-time-calendar {
					padding: 10px 12px;
				}
				.time-selector {
					margin-bottom: 15px;
					.meridiem-container {
						margin-left: 15px;
					}
					.multi-select-filter-container {
						width: 42px;
					}
				}
				.date-time-range-selector {
					.date {
						width: 75px;
					}
				}
			}
			.react-select__dropdown-indicator {
				display: none;
			}
		}
	}
	.section-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 25px;
		font-size: $medium-font-size;
		margin-bottom: 25px;
		@extend .font--semibold;
		.at-btn {
			@extend .font--regular;
		}
	}
	.selected-locations {
		.locations-list-table {
			margin-bottom: 30px;
			.table-header {
				top: 35px !important;
			}
			.table-list {
				min-height: 40px;
			}
			.location {
				width: 60%;
				padding-left: 25px;
			}
			.city {
				width: 40%;
				padding-right: 25px;
			}
		}
	}
	.brands-selection,
	.platforms-selection {
		.brands-list,
		.platforms-list {
			padding: 0 25px;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			gap: 30px;
			margin-bottom: 30px;
			.loading-shimmer {
				width: 100%;
			}
		}
		.no-items-placeholder {
			width: 100%;
		}
	}
	.brand-container,
	.platform-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 120px;
		height: 140px;
		border: 1px solid $border-color;
		border-radius: $default-radius;
		font-size: $very-small-font-size;
		transition: 180ms;
		cursor: pointer;
		&:hover {
			scale: 1.05;
		}
		&.selected {
			border-color: $color-blue;
			scale: 1.05;
			box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.2);
			.brand-name,
			.platform-name {
				color: $color-blue;
			}
		}
		.at-checkbox {
			width: 20px;
			height: 20px;
			padding: 0;
			z-index: 10;
			position: absolute;
			top: 12px;
			right: 16px;
			.checkmark {
				width: 16px;
				height: 16px;
				border-radius: 50%;
				background-color: rgba($color: $mc-1, $alpha: 0.5);
				&::after {
					top: 2px;
					left: 5px;
					border-width: 0 2px 2px 0;
				}
			}
			input:checked ~ .checkmark {
				background-color: $color-blue;
				border-color: transparent;
			}
		}
		.brand-initials,
		.platform-logo {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 70px;
			height: 70px;
			text-transform: uppercase;
			font-size: 36px;
			border-radius: $default-radius;
			@extend .font--semibold;
			&.brown {
				color: $color-brown-dark;
				background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
			}
			&.blue {
				color: $color-blue-dark;
				background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
			}
			&.green {
				color: $color-green-dark;
				background-color: rgba($color: $color-green-dark, $alpha: 0.1);
			}
			&.red {
				color: $color-red-dark;
				background-color: rgba($color: $color-red-dark, $alpha: 0.1);
			}
			&.purple {
				color: $color-violet;
				background-color: rgba($color: $color-violet, $alpha: 0.1);
			}
			img {
				width: 70px;
				height: 70px;
				border-radius: $default-radius;
				object-fit: cover;
			}
		}
		.brand-name,
		.platform-name {
			text-align: center;
			margin-top: 15px;
			text-transform: capitalize;
			width: 100px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
	.brand-platform-container {
		.brand {
			display: flex;
			align-items: center;
			padding: 10px 25px;
			.brand-initials {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 25px;
				height: 25px;
				text-transform: uppercase;
				color: $mc-7;
				background-color: rgba($color: $mc-7, $alpha: 0.1);
				font-size: $very-small-font-size;
				border-radius: $default-radius;
				@extend .font--semibold;
				&.brown {
					color: $color-brown-dark;
					background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
				}
				&.blue {
					color: $color-blue-dark;
					background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
				}
				&.green {
					color: $color-green-dark;
					background-color: rgba($color: $color-green-dark, $alpha: 0.1);
				}
				&.red {
					color: $color-red-dark;
					background-color: rgba($color: $color-red-dark, $alpha: 0.1);
				}
				&.purple {
					color: $color-violet;
					background-color: rgba($color: $color-violet, $alpha: 0.1);
				}
				img {
					width: 25px;
					height: 25px;
					border-radius: $default-radius;
					object-fit: cover;
				}
			}
			.brand-name {
				margin-left: 10px;
				text-transform: capitalize;
				@extend .font--semibold;
			}
		}
		.locations-list-table {
			margin-bottom: 30px;
			&.custom-table-container {
				.table-header {
					top: 35px !important;
				}
				.table-list {
					min-height: 40px;
					padding: 15px 0;
				}
				.location {
					width: 20%;
					padding-left: 25px;
					.name {
						display: flex;
						align-items: center;
						height: 34px;
					}
				}
				.platform {
					width: 20%;
					display: flex;
					flex-direction: column;
					.plf-container {
						display: flex;
						align-items: center;
						margin-bottom: 50px;
						height: 34px;
						img {
							width: 25px;
							height: 25px;
							object-fit: cover;
							border-radius: $default-radius;
							margin-right: 10px;
						}
						.name {
							text-transform: capitalize;
						}
						&:last-child {
							margin-bottom: 10px;
						}
					}
				}
				.platform-id {
					width: 25%;
					display: flex;
					flex-direction: column;
					.plf-container {
						margin-bottom: 50px;
						&:last-child {
							margin-bottom: 20px;
						}
					}
				}
				.platform-url {
					width: 35%;
					padding-right: 25px;
					display: flex;
					flex-direction: column;
					.plf-container {
						margin-bottom: 50px;
						&:last-child {
							margin-bottom: 20px;
						}
					}
				}
			}
		}
	}
	.platforms-list-table {
		margin-bottom: 30px;
		&.custom-table-container {
			.table-header {
				top: 35px !important;
			}
			.table-list {
				min-height: 40px;
			}
			.platform {
				width: 50%;
				padding-left: 25px;
				display: flex;
				align-items: center;
				img {
					width: 25px;
					height: 25px;
					object-fit: cover;
					border-radius: $default-radius;
					margin-right: 10px;
				}
			}
			.status {
				width: 50%;
				padding-right: 25px;
			}
		}
	}
	.biz-platforms-section {
		display: flex;
		flex-wrap: wrap;
		padding: 5px 25px;
		margin-bottom: 30px;
		.platform-container {
			border: 1px solid $mc-4;
			border-radius: $default-radius;
			margin-right: 20px;
			margin-bottom: 15px;
			transition: 30ms;
			cursor: pointer;

			&:nth-last-child(1) {
				margin-right: 0;
			}

			&.selected {
				outline: 1px solid $color-blue;
			}

			.platform-icon {
				padding: 10px 15px;
				display: flex;
				align-items: center;

				img {
					width: 30px;
					border-radius: $default-radius;
				}
			}
		}
	}

	.publish-response-section {
		padding: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		.publish-response {
			padding: 20px;
			border-radius: $default-radius;
			text-align: center;
			width: 100%;
			&.success {
				color: $color-green;
				border: 1px solid $color-green;
				background: rgba(46, 204, 113, 0.1);
			}
			&.fail {
				color: $color-red;
				border: 1px solid $color-red;
				background: rgba(192, 57, 43, 0.1);
			}
			.message-header {
				@extend .font--semibold;
			}
			.message-body {
				margin-top: 10px;
			}
		}
	}
}
.locations-verify-catalogue-container {
	.section {
		&:last-child {
			border-bottom: 0;
		}
	}
	.menu-verification-placeholder {
		.placeholder--image-container {
			img {
				height: 140px;
				width: 140px;
				object-fit: contain;
			}
		}
	}
}
