.timing-groups-section {
	.header-wrapper {
		.context-menu {
			position: unset;
			svg {
				right: -1px;
				fill: #fff;
				height: 16px;
				width: 16px;
			}
			.menu {
				top: 40px;
			}
			.custom-btn {
				.at-btn {
					display: flex;
					align-items: center;
					gap: 8px;
					svg {
						padding: 0;
					}
				}
			}
		}
	}

	.multi-select-filter-container {
		width: 220px;
	}
	.timing-group-filters-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 24px;
		.row-flex {
			display: flex;
			align-items: center;
			gap: 12px;
		}
	}
	.credits-section-header {
		.at-multi-actions-button-container {
			.button-title {
				color: $mc-1;
			}
		}
	}
	.holiday-hour-banner {
		display: flex;
		padding: 16px;
		border-radius: 2px;
		align-items: center;
		gap: 12px;
		background-color: #f2f5ff;
		border-left: 6px solid #3b5feb;
		margin: 24px 24px 0px 24px;
		p {
			margin: 0;
			font-weight: 600;
			color: #363636;
			font-size: 18px;
		}
		img {
			width: 24px;
			height: 24px;
		}
	}

	.timing-groups-list-container {
		margin: 0px 24px 10px 24px;
		border-radius: 4px;
		border-right: 1px solid $color-neutral-black-10;
		border-left: 1px solid $color-neutral-black-10;
		.context-menu {
			.menu {
				background-color: #f2f5ff;
			}

			.action-item--archive {
				color: $color-red;
			}
			.action-item--restore {
				color: $color-green-50;
			}
			.action-item--archive:hover,
			.action-item--restore:hover {
				background-color: #fff !important;
			}
		}
		.slot-list {
			display: flex;
			flex-direction: column;
			gap: 4px;
			p {
				margin: 0;
				color: $color-neutral-black-60;
				font-size: $very-small-font-size;
			}
		}
		.table-header {
			z-index: 70;
			white-space: nowrap;

			span {
				color: $color-neutral-black-50;
				font-size: $extra-small-font-size;
				font-weight: $font--semibold;
			}
			.table-cell {
				display: flex;
				align-items: center;
				&.title {
					flex-direction: row;
				}
			}
			.menu {
				padding: 0;
				width: 0px;
			}
		}
		.table-list {
			.at-table-cell {
				padding: 10px 15px;
			}
			.name {
				color: #363636;
				font-size: 16px;
				font-weight: 600;
			}
			.name,
			.schedule,
			.time,
			.location {
				width: 25%;
			}
		}

		.name {
			.link-text {
				display: inline-block;
			}
			.internal-name-text {
				margin-top: 5px;
				font-weight: 400;
				line-height: 16px;
				font-size: $extra-small-font-size;
			}
		}
		.schedule {
			color: $color-neutral-black-80;
			font-size: $very-small-font-size;
		}

		.brands-list {
			display: flex;
			flex-wrap: wrap;
			height: fit-content;
			.brand-initials {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 24px;
				height: 24px;
				text-transform: uppercase;
				border-radius: $default-radius;
				margin-right: 15px;
				margin-bottom: 15px;
				@extend .font--semibold;
				&.brown {
					color: $color-brown-dark;
					background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
				}
				&.blue {
					color: $color-blue-dark;
					background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
				}
				&.green {
					color: $color-green-dark;
					background-color: rgba($color: $color-green-dark, $alpha: 0.1);
				}
				&.red {
					color: $color-red-dark;
					background-color: rgba($color: $color-red-dark, $alpha: 0.1);
				}
				&.purple {
					color: $color-violet;
					background-color: rgba($color: $color-violet, $alpha: 0.1);
				}
				img {
					width: 24px;
					height: 24px;
					object-fit: cover;
					border-radius: $default-radius;
					margin: 0;
				}
			}
		}
	}
}
.location-association-entity-wrapper,
.timing-groups-section {
	.dropdown-custom {
		width: 220px;
		.container {
			height: 32px;
			padding: 1px 10px;
		}
		.dropdown-options {
			.option {
				height: 37px;
				padding: 1px 10px;
			}
		}
		.custom-option,
		.custom-label {
			display: flex;
			align-items: center;
			.logo {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 8px;
				width: 22px;
				height: 22px;
				text-transform: uppercase;
				font-size: $very-small-font-size;
				border-radius: $default-radius;
				@extend .font--semibold;
				img {
					width: 22px;
					height: 22px;
					object-fit: cover;
					border-radius: $default-radius;
				}
				&.brown {
					color: $color-brown-dark;
					background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
				}
				&.blue {
					color: $color-blue-dark;
					background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
				}
				&.green {
					color: $color-green-dark;
					background-color: rgba($color: $color-green-dark, $alpha: 0.1);
				}
				&.red {
					color: $color-red-dark;
					background-color: rgba($color: $color-red-dark, $alpha: 0.1);
				}
				&.purple {
					color: $color-violet;
					background-color: rgba($color: $color-violet, $alpha: 0.1);
				}
			}
		}
	}
}
.location-association-entity-wrapper {
	padding: 0px 24px 24px 24px;
	.brand-location-filter {
		display: flex;
		margin-bottom: 16px;
		align-items: center;
		gap: 10px;
		.filter-text {
			font-size: $very-small-font-size;
			font-size: 12px;
			color: $mc-5;
		}
	}
	.header-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 24px;
		p {
			margin: 0;
		}
		.associated-locations-header {
			font-size: $very-small-font-size;
			color: $color-neutral-black-80;
			font-weight: 600;
			margin-bottom: 4px;
		}
		.associated-locations-subheader {
			font-size: $extra-small-font-size;
			color: $color-neutral-black-60;
		}
	}
	.location-form-table {
		margin: 24px;
		.form-table {
			border: 1px solid $color-neutral-black-10;
			.name,
			.city {
				width: 50%;
			}
		}
	}
	.filters-container {
		padding: 0px 24px 24px 24px;
		display: flex;
		justify-content: space-between;

		.search-filter {
			margin-right: 0;
			margin-left: 8px;
		}

		.dropdown-filters-container {
			display: flex;
			gap: 8px;
			.react-select__control {
				width: 140px;
			}
			.select-filter-custom {
				.dropdown-custom {
					width: 150px;
					.container {
						height: 32px;
						padding: 1px 10px;
					}
					.dropdown-options {
						.option {
							height: 37px;
							padding: 1px 10px;
						}
					}
					.custom-option,
					.custom-label {
						display: flex;
						align-items: center;
						.logo {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 8px;
							width: 22px;
							height: 22px;
							text-transform: uppercase;
							font-size: $very-small-font-size;
							border-radius: $border-radius-default;
							@extend .font--semibold;
							img {
								width: 22px;
								height: 22px;
								object-fit: cover;
								border-radius: $border-radius-default;
							}
							&.brown {
								color: $color-brown-dark;
								background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
							}
							&.blue {
								color: $color-blue-dark;
								background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
							}
							&.green {
								color: $color-green-dark;
								background-color: rgba($color: $color-green-dark, $alpha: 0.1);
							}
							&.red {
								color: $color-red-dark;
								background-color: rgba($color: $color-red-dark, $alpha: 0.1);
							}
							&.purple {
								color: $color-violet;
								background-color: rgba($color: $color-violet, $alpha: 0.1);
							}
						}
					}
				}
			}
		}
	}
	.all-brands-banner {
		display: flex;
		padding: 16px;
		border-radius: 2px;
		align-items: center;
		gap: 12px;
		background-color: #f2f5ff;
		border-left: 6px solid #3b5feb;
		margin-bottom: 16px;
		p {
			margin: 0;
			font-weight: 400;
			color: #363636;
			font-size: 14px;
		}
		img {
			width: 20px;
			height: 20px;
		}
	}
	.location-association-list-container {
		.transaction-header-row {
			top: 37px;
		}
		.brands-list {
			display: flex;
			flex-wrap: wrap;
			height: fit-content;
			.brand-initials {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 24px;
				height: 24px;
				text-transform: uppercase;
				border-radius: $default-radius;
				margin-right: 15px;
				margin-bottom: 15px;
				@extend .font--semibold;
				&.brown {
					color: $color-brown-dark;
					background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
				}
				&.blue {
					color: $color-blue-dark;
					background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
				}
				&.green {
					color: $color-green-dark;
					background-color: rgba($color: $color-green-dark, $alpha: 0.1);
				}
				&.red {
					color: $color-red-dark;
					background-color: rgba($color: $color-red-dark, $alpha: 0.1);
				}
				&.purple {
					color: $color-violet;
					background-color: rgba($color: $color-violet, $alpha: 0.1);
				}
				img {
					width: 24px;
					height: 24px;
					object-fit: cover;
					border-radius: $default-radius;
					margin: 0;
				}
			}
		}
		.transactions-list-table {
			margin-top: 0px;
		}
		border: 1px solid $color-neutral-black-10;

		.name {
			width: 50%;
		}
		.city {
			width: 50%;
		}
	}
}

.holiday-schedule-wrapper {
	.all-brands-banner {
		display: flex;
		padding: 16px;
		border-radius: 2px;
		align-items: center;
		gap: 12px;
		background-color: #f2f5ff;
		border-left: 6px solid #3b5feb;
		margin-top: 24px;
		p {
			margin: 0;
			font-weight: 400;
			color: #363636;
			font-size: 14px;
		}
		img {
			width: 20px;
			height: 20px;
		}
	}
	.closed-all-day {
		font-size: $very-small-font-size;
		color: $color-neutral-black-60;
		font-weight: $font--semibold;
	}
	[aria-label][data-balloon-pos]:after {
		left: -120px;
	}
	.new-date-range-wrapper {
		.dropdown-custom {
			width: 100%;
		}
	}
	.holiday-timeslots {
		display: flex;
		gap: 16px;
		flex-direction: column;
		margin-top: 24px;

		.add-slot {
			.at-btn--secondary {
				width: auto;
				border: none;
				color: #3b5feb;
				font-size: $extra-small-font-size;
				font-weight: $font--semibold;
				padding-left: 0px;
				padding-top: 8px;
			}
		}
	}
	.timing-slot-wrapper {
		display: flex;
		align-items: flex-end;
		gap: 32px;
		.timing-slot-wrapper--slot {
			width: 45%;
			display: grid;
			align-items: flex-end;
			gap: 8px;
			grid-template-columns: repeat(3, 1fr);
		}
		.cancel-timeslot {
			padding: 10px;
			cursor: pointer;
		}
	}
	.dropdown-custom {
		.meta-info {
			margin: 0;
			padding-bottom: 5px;
		}
		.container {
			padding: 8px 10px;
			width: 100%;
			border: 1px solid $mc-4;
			border-radius: 2px;
			box-sizing: border-box;
			.dropdown-arrow {
				position: absolute;
				right: 10px;
			}
		}
	}
	.react-datepicker {
		border: none;
		box-shadow: none;
		left: 0;
		width: 100%;
		.react-datepicker__month-container {
			float: unset;
		}
		.react-datepicker__header {
			border-bottom: 1px solid $border-color;
			background-color: $mc-1;
		}
		.react-datepicker__current-month {
			color: $color-dark-grey;
			font-size: $very-small-font-size;
		}
		.react-datepicker__navigation {
			position: absolute;
			top: 6px;
			background: url("/assets/icons/dropdown-1.svg") no-repeat;
			background-size: 8px 8px;
			background-position: center;
			width: 20px;
			height: 20px;
			border: 1px solid rgba($color: #000000, $alpha: 0.6);
			border-radius: $border-radius-default;
			filter: invert(0.8);
		}
		.react-datepicker__navigation--previous {
			left: 8px;
			rotate: 90deg;
		}
		.react-datepicker__navigation--next {
			right: 8px;
			rotate: -90deg;
		}
		.react-datepicker__day-names {
			display: flex;
			align-items: center;
			justify-content: space-around;
			margin-top: 15px;
			.react-datepicker__day-name {
				font-size: 10px;
				font-weight: 600;
				color: $mc-6;
				text-transform: uppercase;
			}
		}
		.react-datepicker__week {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.react-datepicker__day {
			margin: 3px;
			padding: 5px;
			border-radius: $border-radius-default;
			font-size: $very-small-font-size;
		}
		& > div {
			&:nth-of-type(3) {
				margin-left: 20px;
			}
		}
	}
}
