.nivo-chart-line {
	padding: 0 25px;
	box-sizing: border-box;
	width: 100%;
	height: 600px;
	text {
		font-family: inherit !important;
	}
	.no-items-placeholder {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fafafa;
		border-radius: $default-radius;
	}
}

.nivo-chart-bar {
	padding: 0 25px;
	box-sizing: border-box;
	width: 100%;
	height: 600px;
	text {
		font-family: inherit !important;
	}
	.custom-tooltip {
		display: none;
		visibility: hidden;
		position: absolute;
		top: 0px;
		left: 0px;
		pointer-events: none;
		display: flex;
		align-items: center;
		justify-items: center;
		width: auto;
		height: auto;
		z-index: 50;
		.nivo-chart-tooltip {
			min-width: 100px;
		}
	}
	.no-items-placeholder {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fafafa;
		border-radius: $default-radius;
	}
}
.custom-legends-bar {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 30px;
	margin-top: 25px;
	.row {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 25px;
		.date-filter {
			position: relative;
			top: 1px;
			margin-right: 10px;
			font-size: $extra-small-font-size;
			text-transform: uppercase;
			color: $color-neutral-black-medium;
			@extend .font--semibold;
		}
	}
}

.nivo-chart-pie {
	padding: 0 25px;
	box-sizing: border-box;
	width: 100%;
	height: 450px;
	text {
		font-family: inherit !important;
	}
	.center-metric-pie {
		fill: $color-neutral-black-light;
		.metric-label {
			font-size: $small-font-size;
		}
		.metric-value {
			font-size: $large-font-size;
			@extend .font--semibold;
		}
	}
	.no-items-placeholder {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fafafa;
		border-radius: $default-radius;
	}
}

.nivo-chart-sankey {
	padding: 0 25px;
	box-sizing: border-box;
	width: 100%;
	height: 600px;
	text {
		font-family: inherit !important;
	}
	.label-container-sankey {
		.id {
			font-size: $very-small-font-size;
			color: $color-neutral-black-medium;
		}
		.value {
			font-size: $medium-font-size;
			color: $color-neutral-black-dark;
			margin-top: 4px;
			@extend .font--semibold;
			&.top {
				font-size: $medium-large-font-size;
			}
		}
	}
	.no-items-placeholder {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fafafa;
		border-radius: $default-radius;
	}
}

.nivo-chart-heatmap {
	position: relative;
	&.clickable {
		canvas {
			cursor: pointer;
		}
	}
	.responsive {
		padding: 0 25px;
		box-sizing: border-box;
		width: 100%;
		height: 600px;
	}
	.custom {
		margin: 0 25px;
		box-sizing: border-box;
		height: 600px;
		border: 1px solid $border-color;
		border-radius: $border-radius-default;
		overflow-x: auto;
		overflow-y: hidden;
	}
	.label-container--left {
		position: absolute;
		top: 1px;
		left: 25px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		box-sizing: border-box;
		background-color: $mc-1;
		border-radius: 2px 0 0 0;
		border-left: 1px solid $border-color;
		.label {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			text-align: right;
			padding: 0 12px 0 6px;
			font-size: $extra-small-font-size;
			color: #333;
			box-sizing: border-box;
		}
	}
	text {
		font-family: inherit !important;
	}
	.no-items-placeholder {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fafafa;
		border-radius: $default-radius;
	}
	.custom-legend-heatmap {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 50px;
		margin-top: 40px;
		flex-wrap: wrap;
		.cells-container {
			display: flex;
			align-items: center;
		}
		.ticks-container {
			display: flex;
			align-items: center;
			margin-top: 8px;
			.tick {
				text-align: center;
				font-size: 13px;
				color: $color-neutral-black-dark;
			}
		}
		.available {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.unavailable {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
}

.nivo-chart-tooltip {
	padding: 10px;
	border: 1px solid $border-color;
	border-radius: $default-radius;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
	background: $mc-1;
	width: max-content;
	.current {
		display: flex;
		align-items: stretch;
		gap: 10px;
	}
	.compare {
		display: flex;
		align-items: stretch;
		gap: 10px;
		margin-top: 15px;
	}
	.highlight {
		width: 3px;
	}
	.info {
		padding-right: 15px;
		.index {
			font-size: $very-small-font-size;
			color: $color-neutral-black-light;
			margin-bottom: 8px;
			text-transform: capitalize;
			unicode-bidi: plaintext;
			text-align: left;
			bdi {
				padding-right: 2px;
			}
		}
		.label {
			font-size: $very-small-font-size;
			color: $color-neutral-black-medium;
			margin-bottom: 4px;
			text-transform: capitalize;
		}
		.value {
			font-size: $medium-font-size;
			color: $color-neutral-black-medium;
			@extend .font--semibold;
			&.right {
				text-align: right;
			}
		}
		.compare-index {
			font-size: $very-small-font-size;
			color: $color-neutral-black-light;
			margin-top: 1px;
			margin-bottom: 6px;
			text-transform: capitalize;
			unicode-bidi: plaintext;
			text-align: left;
			bdi {
				padding-right: 2px;
			}
		}
		.compare-label {
			font-size: $very-small-font-size;
			color: $color-neutral-black-medium;
			margin-bottom: 4px;
			text-transform: capitalize;
		}
		.compare-value {
			font-size: $very-small-font-size;
			color: $color-neutral-black-medium;
			@extend .font--semibold;
		}
		.info-tip {
			font-size: $very-small-font-size;
			color: $color-neutral-black-50;
			margin-top: 8px;
			@extend .font--semibold;
		}
	}
	&.bar {
		.label {
			text-transform: capitalize;
		}
	}
	&.sankey {
		.info {
			padding-right: 0;
		}
	}
}

.nivo-chart-loader {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: #fafafa;
	border-radius: $default-radius;
	.loading {
		width: 30px;
		height: 30px;
		box-sizing: border-box;
		border-radius: 50%;
		border: 3px solid $color-blue;
		border-top-color: transparent;
		border-right-color: transparent;
		-webkit-animation: animation-spin 0.5s infinite linear;
		-o-animation: animation-spin 0.5s infinite linear;
		animation: animation-spin 0.5s infinite linear;
	}
}
