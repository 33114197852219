.billing-section {
	.billing-banner {
		padding: 15px 25px;
		.\!border-l-4 {
			border-left-width: 4px !important;
		}
		.banner-text {
			display: flex;
			flex-direction: column;
			gap: 10px;
			.header {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.body {
				width: 100%;
				max-width: 700px;
				p {
					margin-bottom: 10px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				p {
					&:has(+ ul) {
						margin-bottom: 0;
					}
				}
				ul {
					margin-bottom: 10px;
				}
				b {
					@extend .font--regular;
				}
			}
		}
	}
	.summary {
		padding: 15px 25px;
		.heading {
			font-size: $small-font-size;
			margin-bottom: 10px;
			@extend .font--semibold;
		}
		.summary-cards {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 25px;
			.shimmer {
				flex-grow: 1;
			}
			.card-container {
				display: flex;
				flex-direction: column;
				gap: 10px;
				padding: 22px 25px;
				flex-grow: 1;
				min-width: 300px;
				border: 1px solid $border-color;
				border-radius: $border-radius-default;
				.title {
					font-size: $very-small-font-size;
					color: $color-dark-grey;
				}
				.value {
					font-size: $medium-large-font-size;
					color: $color-neutral-black-90;
					span {
						@extend .font--semibold;
					}
				}
			}
		}
	}
}
.invoices-section-header {
	padding: 0 25px;
	min-height: 45px;
	margin-top: 20px;
	display: flex;
	align-items: center;
	@include bp(768px) {
		padding: 10px;
	}
	.header-action-button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		@include bp(768px) {
			flex-direction: column;
			align-items: flex-start;
		}
		.subheader-text {
			display: flex;
			align-items: center;
			img {
				margin-right: 10px;
				width: 18px;
			}
			@include bp(768px) {
				margin-bottom: 10px;
			}
		}
		.button-container {
			text-align: right;
			margin-bottom: 20px;
			img {
				margin-right: 25px;
				margin-bottom: 5px;
			}
			.button-title {
				font-size: $very-small-font-size;
				color: $color-blue;
				@extend .font--semibold;
				display: inline-block;
				vertical-align: top;
			}
		}
		.conversion-text {
			font-size: 14px;
			color: $mc-6;
			margin-bottom: -20px;
		}
		.credit-balance {
			padding-top: 10px;
			font-size: $medium-font-size;
			color: $mc-9;
			text-align: right;
			margin-bottom: 15px;
			@extend .font--semibold;
			span.title {
				color: $mc-6;
			}
		}

		@include bp(768px) {
			float: none;
			.credit-balance {
				text-align: left;
				margin-top: 10px;
			}
		}
		.filter-buttons {
			& > div {
				float: right;
				position: relative;
				@include bp(768px) {
					float: none;
				}
			}
			.search-icon {
				position: absolute;
				left: 10px;
				top: 50%;
				transform: translateY(-50%);
			}
			.dismiss-search {
				cursor: pointer;
				position: absolute;
				right: 31px;
				top: 50%;
				transform: translateY(-50%);
				height: 11px;
				width: 11px;
				img {
					width: 100%;
				}
			}
			.search-input {
				display: inline-block;
				vertical-align: top;
				outline: 0;
				padding: 0 0 0 30px;
				font-size: $very-small-font-size;
				border: 0;
				border-bottom: 1px solid $divider-color;
				width: 200px;
				height: 36px;
				box-sizing: border-box;
				margin-right: 10px;
				@include bp(768px) {
					margin-right: 0px;
					width: 100%;
				}
			}
			.search-input-holder {
				@include bp(768px) {
					margin-top: 10px;
					display: flex;
				}
			}
			.filter-button-container {
				display: inline-block;
				vertical-align: top;
				border: 1px solid $mc-4;
				padding: 8px 16px;
				cursor: pointer;
				text-align: center;
				img {
					display: inline-block;
					vertical-align: top;
				}
				.filter-button-title {
					display: inline-block;
					vertical-align: top;
				}
			}
			.filter-count {
				background: $color-green;
				color: #fff;
				width: 20px;
				height: 20px;
				line-height: 20px;
				font-size: 12px;
				border-radius: 100%;
				display: inline-block;
				position: absolute;
				right: -10px;
				top: -10px;
			}
		}
	}
}

.invoices-list-table {
	margin: 30px 25px;
	border: 1px solid $border-color;
	border-radius: $default-radius;
	margin-bottom: 15px;
	.header-row {
		border-bottom: 1px solid $divider-color;
	}
	.at-table--5cols {
		.invoice_date {
			width: 15%;
		}
		.invoice_num {
			width: 20%;
			text-align: left;
			padding-left: 25px;
			@include bp(768px) {
				width: 10%;
			}
		}
		.amount {
			width: 15%;
		}
		.status {
			width: 23%;
			.chip {
				width: fit-content;
				padding: 5px 10px;
				font-size: $very-small-font-size;
				text-transform: capitalize;
				border-radius: 2px;
				@extend .font--semibold;
				&.paid {
					color: $color-green-70;
					background-color: $color-green-10;
				}
				&.unpaid {
					color: $color-yellow-70;
					background-color: $color-yellow-10;
				}
				&.void {
					color: $color-neutral-black-60;
					background-color: $color-neutral-black-05;
				}
				&.overdue {
					color: $color-red-70;
					background-color: $color-red-1;
				}
				&.partially-paid {
					color: $color-yellow-70;
					background-color: $color-yellow-10;
				}
				&.partially-paid-pending {
					color: $color-yellow-70;
					background-color: $color-yellow-10;
				}
				&.partially-paid-overdue {
					color: $color-red-70;
					background-color: $color-red-1;
				}
				&.awaiting-payment {
					color: $color-yellow-70;
					background-color: $color-yellow-10;
				}
				&.pending {
					color: $color-yellow-70;
					background-color: $color-yellow-10;
				}
				&.expired {
					color: $color-red-70;
					background-color: $color-red-1;
				}
			}
		}
		.paid_on {
			width: 15%;
		}
		.payment {
			width: 12%;
		}
	}
	.at-cell-text {
		font-size: $very-small-font-size;
		color: $mc-9;
		@extend .font--regular;
		.sub-text {
			color: $mc-6;
			margin-top: 4px;
		}
	}
	.transaction-rows {
		min-height: 80px;
		border-bottom: 1px solid $divider-color;
		padding-top: 10px;
		padding-bottom: 10px;

		&:last-child {
			border-bottom: 0px;
		}
		.view-detail {
			text-transform: uppercase;
			font-size: 12px;
			position: relative;
			padding-right: 20px;
			margin-top: 10px;
			display: inline-block;
			img {
				top: 0px;
				right: 0px;
				height: 12px;
				position: absolute;
			}
			a {
				color: $color-blue;
				text-decoration: none;
			}
		}
	}
}
