.draggable-modifier-accordion-container {
	display: flex;
	flex-direction: column;
	gap: 16px;
	.draggable-entity-accordion {
		padding: 0;
		.accordion-title {
			padding: 0;
			padding: 16px 16px 16px 24px;
			position: relative;
			transition: all 200ms ease-in-out;
			.title-expander-container {
				width: 100%;
				.dropdown-arrow {
					cursor: pointer;
				}
			}
			&:hover {
				background-color: $color-blue-1;
				.drag-icon {
					visibility: visible;
				}
				.draggable-entity-title {
					.draggable-entity-title-left {
						.variant,
						.add-on {
							background-color: $mc-1;
						}
					}
				}
			}
			.drag-icon {
				position: absolute;
				left: 1%;
				top: 50%;
				transform: translateY(-50%);
				visibility: hidden;
			}

			.add-on {
				border-radius: $default-radius;
				padding: 2px 8px;
				color: #374f78;
				background-color: #ecf0f7;
				font-size: $extra-small-font-size;
			}
			.variant {
				border-radius: $default-radius;
				padding: 2px 8px;
				color: #753675;
				background-color: #f7edf7;
				font-size: $extra-small-font-size;
			}

			.draggable-entity-title {
				width: 100%;
				margin-inline-start: 12px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.draggable-entity-title-left {
				.draggable-entity-title-row {
					display: flex;
					align-items: center;
					gap: 8px;
					margin-bottom: 10px;
					.draggable-entity-name {
						font-size: $very-small-font-size;
						font-weight: 600;
					}
					.draggable-entity-price {
						font-size: $very-small-font-size;
						color: $color-neutral-black-60;
					}
				}

				.draggable-entity-crm-title {
					margin: 0;
					font-size: $extra-small-font-size;
					color: $color-black-40;
				}
			}

			.draggable-entity-context-menu {
				// fix for context menu three dots and modal
				top: 30%;
				// transform: translateY(-50%); // adding transform causes issues with z-index, please don't use this

				cursor: pointer;
				&:hover {
					background-color: $color-blue-20;
					border-radius: $border-radius-default;
				}
				.draggable-entity-context-menu-modal {
					p {
						padding: 10px 12px;
						&:hover {
							background-color: $mc-2;
						}
					}
					.draggable-entity-context-menu-modal-remove {
						color: $color-red;
					}
				}

				.icon-dots {
					height: 24px;
					width: 24px;
					padding: 4px;
				}
			}
		}

		.nested-entity-accordion {
			border: 0;
			margin-left: 24px;
			.accordion-title {
				margin-left: 16px;
				min-height: 42px;
				padding: 4px;
				height: fit-content;
				&:hover {
					background-color: $mc-1;
				}
			}
			.nested-entity-accordion-title {
				margin-inline-start: 8px;
				display: flex;
				flex-direction: column;
				gap: 4px;
				.nested-entity-accordion-title-row {
					display: flex;
					align-items: center;
					gap: 8px;
					margin: 0;
					font-size: $very-small-font-size;
					.nested-entity-accordion-name {
						margin: 0;
						font-weight: 600;
					}
					.nested-entity-accordion-price {
						margin: 0;
						color: $color-neutral-black-60;
					}
				}
				.nested-entity-accordion-crm-title {
					margin: 0;
					color: $color-black-40;
					font-size: $extra-small-font-size;
				}
			}
		}
	}
}
