.at-btn {
	//Scaled padding down from 25px to 16px
	padding-left: 16px;
	padding-right: 16px;
	height: 36px;
	line-height: 34px;
	border: 1px solid $btn-color;
	border-radius: $border-radius-default;
	user-select: none;
	cursor: pointer;
	display: inline-block;
	color: #fff;
	transition: 180ms;
	text-align: center;
	background: $btn-color;
	box-sizing: border-box;
	font-size: $very-small-font-size;

	img {
		position: relative;
		top: 1px;
		padding-right: 8px;
		transition: 180ms;
	}
	svg {
		box-sizing: content-box;
		position: relative;
		padding-right: 8px;
		transition: 180ms;
	}

	&.at-btn--hover {
		background: #fff;
		color: $btn-color;
	}

	&:hover {
		opacity: 0.6;
		// stroke: $btn-color;
		// background: #fff;
		// color: $btn-color;
		// svg > g > g {
		// 	stroke: #2f58f2;
		// }
	}

	&.at-btn--disabled {
		opacity: 0.2;
		pointer-events: none;
		cursor: default;
	}
	&.btn--disabled {
		background: rgba(47, 88, 242, 0.6);
		border: 1px solid #849df8;
		cursor: default;
		&:hover {
			opacity: 1;
		}
		&[aria-label][data-balloon-pos][data-balloon-pos="down-right"]:before {
			right: 30px;
		}
		&::after {
			line-height: 1;
		}
	}
	&.no-click {
		opacity: 0.6;
		pointer-events: none;
		cursor: default;
	}
	&.at-btn--secondary {
		border: 1px solid $mc-4;
		color: $mc-8;
		background-color: white;
		&:hover {
			opacity: 1;
			color: $color-blue;
			border-color: $color-blue;
			svg > g > g {
				fill: $color-blue;
			}
			svg {
				fill: $color-blue;
				// stroke: $color-blue;
			}
		}
	}

	&.at-btn--filter {
		position: relative;
		svg {
			// top: 2px;
		}
		&:hover {
			svg > path {
				fill: $color-blue;
			}
		}
		.filter-count {
			position: absolute;
			font-size: $extra-small-font-size;
			height: 20px;
			width: 20px;
			border-radius: 50%;
			background-color: $color-blue;
			color: $mc-1;
			text-align: center;
			line-height: 1.6;
			top: -10px;
			right: -10px;
		}
	}

	&.filter-applied {
		color: $color-blue;
		border-color: $color-blue;
		svg > path {
			fill: $color-blue;
		}
	}

	&.at-btn--inverted {
		border: 1px solid $color-blue;
		color: $color-blue;
		background-color: white;
		&:hover {
			background: $color-blue;
			color: #fff;
		}
	}
	&.at-btn--flat {
		border: 1px solid $border-color;
		color: #000;
		background-color: #fff;
	}
	&.at-btn--danger {
		border: 1px solid $color-red;
		color: $color-red;
		background-color: white;
		svg {
			fill: $color-red;
			width: 20px;
			height: 20px;
		}
		&:hover {
			opacity: 1;
			background: $color-red;
			color: #fff;
			svg {
				fill: #fff;
			}
		}
		&.btn--disabled {
			border: 1px solid #ff929d;
			color: #ff929d;
			background-color: white;
			&:hover {
				background-color: white;
				border: 1px solid #ff929d;
				color: #ff929d;
			}
		}
		.at-button-loading {
			border-right-color: $color-red-light;
		}
	}
	&.at-btn--success {
		border: 1px solid $color-green;
		color: $color-green;
		background-color: white;
		svg {
			fill: $color-green;
			width: 20px;
			height: 20px;
		}
		&:hover {
			opacity: 1;
			background: $color-green;
			color: #fff;
			svg {
				fill: #fff;
			}
		}
		&.btn--disabled {
			border: 1px solid #91e1aa;
			color: #91e1aa;
			background-color: white;
			&:hover {
				background-color: white;
				border: 1px solid #91e1aa;
				color: #91e1aa;
			}
		}
	}
	.loading-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;
		width: 16px;
		height: 34px;
		padding: 0 10px;
	}
	.at-button-loading {
		-webkit-animation: animation-spin 0.4s infinite linear;
		-o-animation: animation-spin 0.4s infinite linear;
		animation: animation-spin 0.4s infinite linear;
		width: 16px;
		height: 16px;
		box-sizing: border-box;
		border-radius: 50%;
		border: 2px solid #fff;
		border-right-color: $color-blue-light;
		display: inline-block;
		position: relative;
		vertical-align: middle;
	}
	&.small {
		height: 24px;
		padding: 8px;
		font-size: $extra-small-font-size;
		line-height: 7px;
	}
}
