// colors
$color-blue-1: #f2f5ff;
$color-blue: #2f58f2;
$color-blue-10: #eaeefe;
$color-blue-light: rgba(47, 88, 242, 0.6);
$color-blue-dark: #1e3794;
$color-blue-dark-2: #2543b6;
$color-red: #ff425c;
$color-red-light: rgba(255, 66, 92, 0.6);
$color-red-dark: #ad1010;
$color-red-50: #d64949;
$color-red-70: #b02b2b;
$color-red-05: #fff5f5;
$color-java: #18d0b2;
$color-green: #2ecc71;
$color-green-light: #69bf87;
$color-green-dark: #096329;
$color-green-50: #0da125;
$color-green-15: #f6fbf8;
$color-green-70: #0b6e1b;
$color-orange: #e67e22;
$color-orange-light: #e2683f;
$color-yellow: #ecc900;
$color-yellow-10: #fef6e9;
$color-yellow-20: #e5b917;
$color-yellow-dark: #c2831c;
$color-violet: #543cb1;
$color-error: #c0392b;
$color-brown-dark: #6b2a07;
$color-brown: #9b3c08;
$color-black-20: #d2d2d2;
$color-black-40: #aaaaaa;
$color-black-60: #7a7a7a;
$color-black-80: #363636;
$color-black-100: #171717;
$color-divider: #f3f3f3;
$color-dark-grey: #666666;
$warning-light: #fef6e9;

// yellow color variants
$color-yellow-10: #fef6e9;
$color-yellow-70: #846c18;

// green color variants
$color-green-05: #f6fbf8;
$color-green-10: #ecf7f0;
$color-green-50: #0da125;

//blue color variants
$color-blue-05: #f2f5ff;
$color-blue-20: #c6d2ff;
$color-blue-40: #3b5feb;
$color-blue-50: #2543b6;
$color-blue-15: #f2f5ff;

// red  color variants
$color-red-1: #ffe5e5;
$color-red-5: #d64949;
$color-red-7: #b02b2b;

// black color variants
$color-neutral-black-05: #f5f5f5;
$color-neutral-black-10: #eaeaea;
$color-neutral-black-50: #8a8a8a;
$color-neutral-black-40: #aaa;
$color-neutral-black-60: #646464;
$color-neutral-black-70: #4e4e4e;
$color-neutral-black-80: #363636;
$color-neutral-black-90: #222;

// from lighter to darker
// 1 is -white
// 9 is -black
// mc - monochrome
$mc-1: #fff;
$mc-2: #f3f5f8;
$mc-3: #eaeaea;
$mc-4: #d0dadd;
$mc-5: #7b8a9a;
$mc-6: #979797;
$mc-7: #3e495f;
$mc-8: #3d3d3d;
$mc-9: #000000;

$btn-grey-color: #b8c2c8;
$sidebar-bg-color: $mc-1;
$site-header-default-bg-color: $mc-1;
$btn-color: $color-blue;
$border-color: $mc-3;
$border-color-dark: $mc-4;
$switch-select-color: $color-java;
$link-color: $color-blue;
$grey-color-border-table: #a2a8af;
$color-light-black: #222222;
$color-lightest-grey: #f5f5f5;
$color-neutral-grey: #afafaf;
$color-neutral-black-light: #8a8a8a;
$color-neutral-black-medium: #646464;
$color-neutral-black-dark: #363636;
$color-light-grey: #8a8a8a;

// misc
$border-radius-default: 2px;
$default-radius: 4px;
$moderate-border-radius: 8px;
$heading1-font-size: 24px;
$regular-heading-font-size: 16px;
$body-text-font-size: 16px;
$btn-radius: 4px;
$chk-box-radius: 0px;
$radio-btn-radius: 14px;
$sidebar-width: 210px;
$sidebar-width-minimized: 60px;
$divider-color: #eaeaea;
$default-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
$box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);

// font size
$extra-small-font-size: 12px;
$very-small-font-size: 14px;
$small-font-size: 16px;
$medium-font-size: 18px;
$medium-large-font-size: 24px;
$large-font-size: 28px;
$extra-large-font-size: 32px;

// font weight
$font--semibold: 600;

// z-index
$zI-base: 0;
$zI-level-1: 100;
$zI-level-2: 200;
$zI-level-3: 300;
$zI-top: 1000;
