body {
	font-family: "Source Sans Pro", sans-serif;
	font-weight: 400;
	margin: 0px;
	padding: 0px;
}

p {
	margin: 0;
}

.base-svg {
	stroke: #222;
}

.font--regular {
	font-weight: 400;
}
.font--semibold {
	font-weight: 600;
}
.font--bold {
	font-weight: 600;
}

// add others also
.text--large {
	font-size: $large-font-size;
}
.text--medium {
	font-size: $medium-font-size;
}
.text--small {
	font-size: $small-font-size;
}
.text--light {
	color: $mc-5;
}
.text-uppercase {
	text-transform: uppercase;
}
.text-capitalize {
	text-transform: capitalize;
}

.link-text {
	color: $color-blue;
	text-transform: capitalize;
	text-decoration: none;
	cursor: pointer;
	@extend .font--semibold;
	&.archived,
	&.error {
		color: $color-red;
	}
	&.hover {
		opacity: 0.6;
		&:hover {
			opacity: 1;
		}
	}
}

.hyperlink--no-action {
	color: $color-blue;
	font-weight: $font--semibold;
	cursor: pointer;
}

.hyperlink {
	color: $color-blue;
	font-weight: $font--semibold;
	cursor: pointer;
	text-transform: initial;
	position: relative;
	display: inline-block;
	text-decoration: none;
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		transform: scaleX(0);
		height: 1px;
		bottom: 0;
		left: 0;
		background-color: $color-blue-light;
		transform-origin: bottom right;
		transition: transform 0.25s ease-out;
	}
	&:hover {
		color: $color-blue;
		&::after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	}
	&--black-color {
		color: $color-light-black;
		&:hover {
			color: $color-light-black;
		}
		&::after {
			background-color: $color-light-black;
		}
	}
	&--red-color {
		color: $color-red;
		&:hover {
			color: $color-red;
		}
		&::after {
			background-color: $color-red;
		}
	}
}

.remove-scrollbar {
	scrollbar-width: none;
	overscroll-behavior: contain;
	&::-webkit-scrollbar {
		display: none;
	}
}

.heading1 {
	font-size: $heading1-font-size;
	color: $mc-8;
	@extend .font--semibold;
}

.regularheading {
	font-size: $regular-heading-font-size;
	color: $mc-8;
	@extend .font--regular;
}
.body-text1 {
	font-size: $body-text-font-size;
	color: $mc-8;
	@extend .font--regular;
}
.body-text2 {
	font-size: $body-text-font-size;
	color: $mc-7;
	@extend .font--regular;
}
.body-text3 {
	font-size: $body-text-font-size;
	color: $mc-6;
	@extend .font--regular;
}
.link1 {
	font-size: $regular-heading-font-size;
	@extend .font--semibold;
	color: $color-blue;
}
.link2 {
	font-size: $body-text-font-size;
	@extend .font--semibold;
	color: $color-blue;
}
.table-header {
	font-size: $small-font-size;
	@extend .font--regular;
	color: #a2aab2;
	text-transform: uppercase;
}
.display-none {
	display: none;
}
@mixin font-line-eq($value) {
	font-size: $value;
	line-height: $value;
}

@keyframes placeHolderShimmer {
	0% {
		transform: translateX(-100%);
		opacity: 0;
	}
	50% {
		transform: translateX(100%);
		opacity: 1;
	}
	100% {
		transform: translateX(-100%);
		opacity: 0;
	}
}

.shimmer {
	position: relative;
	overflow: hidden;
	background: #f2f2f2;
	box-sizing: border-box;
	border-radius: $default-radius;
	&:after {
		content: " ";
		white-space: pre;
		animation-duration: 2s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: ease-in-out;
		background: linear-gradient(to right, rgba(228, 226, 226, 0) 0%, #979797 50%, rgba(228, 226, 226, 0) 100%);
		background-size: 100%;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		background-repeat: no-repeat;
		will-change: transform, opacity;
	}
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
	cursor: default;
}

.no-click {
	pointer-events: none;
	cursor: default;
}

// sidebar component css
.at-side-bar {
	position: sticky;
	top: 100px;
	.sidebar-row {
		font-size: $medium-font-size;
		padding: 10px 25px;
		position: relative;
		&.selectable {
			cursor: pointer;
			user-select: none;
		}
		&:after {
			content: " ";
			position: absolute;
			height: 100%;
			top: 0;
			left: 0;
			background: transparent;
			transition: 200ms ease-out;
			width: 0px;
		}
		&.selected {
			color: $color-blue;
			background: rgba(47, 88, 242, 0.1);
			&:after {
				background: $color-blue;
				width: 3px;
			}
		}
		&.archived {
			color: $color-red;
			background: rgba(192, 57, 43, 0.1);
			&:after {
				background: $color-red;
				width: 3px;
			}
		}
	}
}

// table component css
.at-table {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	.at-table-cell {
		box-sizing: border-box;
		flex-grow: 1;
		width: 100%;
		overflow: hidden;
		list-style: none;
		padding: 10px 25px;
		@include bp(768px) {
			padding: 10px 15px;
		}
	}
	.striped {
		background-color: $mc-2;
	}
	.at-table-header {
		background-color: $mc-2;
		height: 32px;
		padding: 8px 25px;
		@include bp(768px) {
			padding: 8px 15px;
		}
	}
	.at-cell-text {
		@extend .font--regular;
		font-size: 14px;
		color: $mc-8;
		text-overflow: ellipsis;
		& > div {
			text-overflow: ellipsis;
		}
	}
	.at-header-text {
		@extend .font--semibold;
		font-size: 12px;
		color: $mc-5;
		text-transform: uppercase;
		line-height: 15px;
	}
	.clickable {
		cursor: pointer;
		&:hover {
			color: $mc-7;
		}
	}

	&.at-table--3cols .at-table-cell {
		width: calc(100 / 3) * 1%;
	}
	&.at-table--4cols .at-table-cell {
		width: calc(100 / 4) * 1%;
	}
	&.at-table--5cols .at-table-cell {
		width: calc(100 / 5) * 1%;
	}
	&.at-table--6cols .at-table-cell {
		width: calc(100 / 6) * 1%;
	}
}

.at-table-row-based {
	.at-table-row {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		transition: all 300ms;
		&:hover {
			background-color: rgba(47, 88, 242, 0.05);
		}
		&:nth-of-type(odd) {
			// background-color: #F3F5F8;
			&:hover {
				background-color: rgba(47, 88, 242, 0.05);
			}
		}
		&.striped {
			background-color: $mc-2;
		}
	}

	.at-table-cell {
		box-sizing: border-box;
		flex-grow: 1;
		width: 100%;
		overflow: hidden;
		list-style: none;
		padding: 7px 15px;
	}

	.at-table-header {
		background-color: #f8f8f8;
	}
	.at-cell-text {
		@extend .font--regular;
		font-size: $very-small-font-size;
		color: $mc-9;
		text-overflow: ellipsis;
		& > div {
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	.at-header-text {
		@extend .font--semibold;
		font-size: 12px;
		color: $mc-9;
		text-transform: uppercase;
		line-height: 15px;
		opacity: 0.6;
	}
	.clickable {
		cursor: pointer;
		&:hover {
			background: #f2f2f2;
		}
	}

	&.at-table--3cols .at-table-cell {
		width: calc(100 / 3) * 1%;
	}
	&.at-table--4cols .at-table-cell {
		width: calc(100 / 4) * 1%;
	}
	&.at-table--5cols .at-table-cell {
		width: calc(100 / 5) * 1%;
	}
	&.at-table--6cols .at-table-cell {
		width: calc(100 / 6) * 1%;
	}
	&.at-table--7cols .at-table-cell {
		width: calc(100 / 7) * 1%;
	}

	@include bp(768px) {
		.at-cell-text,
		.at-header-text {
			padding: 10px 5px;
			font-size: 12px;
		}
	}
}

// custom-table
.custom-table-container {
	&.bordered {
		border: 1px solid $border-color;
		border-radius: $default-radius;
	}
	.table-row-header {
		display: flex;
		flex-wrap: nowrap;
		background-color: $mc-2;
		border-bottom: 1px solid $border-color;
		position: sticky;
		box-sizing: border-box;
		z-index: 500;
		.table-cell {
			width: 100%;
			padding: 10px 15px;
			font-size: 12px;
			color: $mc-9;
			opacity: 0.6;
			text-transform: uppercase;
			box-sizing: border-box;
			flex-grow: 1;
			overflow: hidden;
			list-style: none;
			@extend .font--semibold;
		}
	}
	.table-row-list {
		display: flex;
		flex-wrap: nowrap;
		border-bottom: 1px solid $border-color;
		padding: 8px 0;
		transition: 180ms;
		min-height: 70px;
		box-sizing: border-box;
		&:nth-of-type(odd) {
			background-color: $mc-2;
		}
		&:last-child {
			border-bottom: none;
		}
		&:hover {
			background-color: rgba(47, 88, 242, 0.05);
		}
		.table-cell {
			width: 100%;
			text-overflow: ellipsis;
			box-sizing: border-box;
			flex-grow: 1;
			list-style: none;
			padding: 7px 15px;
			& > div {
				margin-bottom: 4px;
				text-overflow: ellipsis;
			}
		}
	}
}

.no-items-placeholder {
	font-size: $small-font-size;
	text-align: center;
	color: $mc-6;
	padding-top: 10%;
	padding-bottom: 10%;
	box-sizing: border-box;
	&.error {
		color: $color-error;
	}
}

.react-datepicker__time-list {
	padding-left: 0 !important;
}

.at-top-bar-wrapper-v2 {
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	scrollbar-width: none;
	display: grid;
	// prevent scroll-chaining to parent elements
	overscroll-behavior: contain;
	&::-webkit-scrollbar {
		display: none;
	}
	@include bp(768px) {
		margin: 0 10px;
	}
	&.wrapper-sticky-top {
		position: sticky;
		top: 63px;
		background-color: white;
		z-index: $zI-top;
	}
}

.with-banner {
	.at-top-bar-wrapper-v2 {
		&.wrapper-sticky-top {
			top: 120px;
		}
	}
	// .transaction-header-row {
	// 	top: 114px !important;
	// }
	// .custom-table-container {
	// 	.table-header {
	// 		top: 114px !important;
	// 	}
	// }
}

.at-top-bar-v2 {
	border-bottom: 1px solid $divider-color;
	height: 42px;
	box-sizing: border-box;
	&.no-bottom-border {
		border-bottom: none;
	}
	.topbar-column {
		display: inline-block;
		padding: 13px 25px;
		position: relative;
		text-align: center;
		color: $mc-5;
		cursor: pointer;
		user-select: none;
		transition: 200ms ease-out;
		font-weight: 600;
		color: $color-black-40;
		&:after {
			left: 0px;
			width: 100%;
			height: 1px;
			bottom: 0px;
			position: absolute;
			content: "";
			background: transparent;
			transition: 200ms ease-out;
		}
		@include bp(768px) {
			&:after {
				background: #ddd;
			}
		}
		&.selected {
			color: $color-blue-40;
			&:after {
				color: $color-blue-40;
				background: $color-blue-40;
				height: 5px;
			}
		}
		&.archived {
			color: $color-red;
			&:after {
				color: $color-red;
				background: $color-red;
				height: 5px;
			}
		}
		&.scroll-left {
			position: sticky;
			left: 0;
			z-index: 2;
			padding: 12px 10px;
			border-bottom: 1px solid $divider-color;
			background-color: white;
		}
		&.scroll-right {
			position: sticky;
			right: 0;
			z-index: 2;
			padding: 12px 10px;
			border-bottom: 1px solid $divider-color;
			background-color: white;
		}
		img {
			width: 6px;
			opacity: 0.6;
		}
		.count {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: relative;
			bottom: 1px;
			color: $mc-1;
			background-color: $color-blue;
			margin-left: 10px;
			min-width: 6px;
			height: 6px;
			padding: 5px;
			border-radius: 30px;
			text-align: center;
			font-size: $extra-small-font-size;
			box-sizing: content-box;
		}
		.label {
			font-size: 8px;
			font-weight: 900;
			border-radius: $border-radius-default;
			padding: 1px 3px;
			border: 1px solid $color-red;
			color: $color-red;
			position: relative;
			bottom: 2px;
			margin-left: 10px;
			&.beta {
				color: $mc-1;
				background: $color-green-50;
				border: 1px solid $color-green-50;
			}
			&.new {
				border: 1px solid $color-green;
				color: $color-green;
			}
		}
		.new-btn {
			color: #ffffff !important;
			gap: 4px;
			border-radius: 2px;
			background: #0da125;
			font-family: Source Sans Pro;
			font-size: 12px;
			font-weight: 600;
			text-align: left;
			border: none !important;
			overflow: hidden;
			position: relative;
			display: inline-flex;
			padding: 4px 8px;
			bottom: 2px;
		}
		.diagonal-animation {
			width: 7px;
			height: 120px;
			opacity: 0.6;
			background: linear-gradient(90deg, #c3faa2 0%, #c0faa2 35.5%);
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%) rotate(135deg);
			animation: move-bar 2s linear infinite;
		}
		@keyframes move-bar {
			0% {
				left: -50%;
			}
			100% {
				left: 180%;
			}
		}
	}
	.topbar-column-newbtn {
		padding: 10px 25px 10px 25px;
	}
}

.section-container-common {
	background-color: #fff;
	padding-top: 5px;
	padding-bottom: 15px;
	.at-paginator {
		padding: 0 25px;
		padding-bottom: 15px;
	}
}

.section-header-common {
	padding: 15px 25px;
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	&.border-bottom {
		border-bottom: 1px solid $border-color;
	}
	@include bp(768px) {
		padding: 10px;
	}
	.header-text {
		float: left;
		@include bp(768px) {
			float: none;
		}
		.title {
			font-size: $large-font-size;
			margin-bottom: 10px;
			@extend .font--semibold;
		}
		.subtitle {
			color: $mc-6;
			font-size: $small-font-size;
		}
	}
	.header-action-container {
		float: right;
		margin-top: 14px;
		// .action-item {

		// }
	}
}

.back-navigation-arrow {
	cursor: pointer;
	display: inline-block;
	margin-bottom: 10px;
	@include bp(768px) {
		margin-left: 10px;
	}
	img {
		height: 12px;
		transition: 180ms ease;
	}
	.navigation-title {
		color: $color-blue;
		font-size: $small-font-size;
		margin-left: 10px;
		text-transform: capitalize;
		white-space: pre;
		@extend .font--semibold;
	}
	&:hover {
		img {
			transform: translateX(-5px);
		}
	}
	a {
		text-decoration: none;
	}
}

.react-datepicker {
	background-color: white;
	border: 1px solid $border-color;
	border-radius: $default-radius;
	box-shadow: 0 0 15px 0 rgba(51, 51, 51, 0.15);
	.react-datepicker__header {
		border-top-left-radius: $default-radius;
		border-top-right-radius: $default-radius;
		border-bottom: 1px solid $mc-2;
		background-color: $mc-2;
		.react-datepicker__header__dropdown {
			margin-top: 5px;
		}
		.react-datepicker__year-read-view--down-arrow,
		.react-datepicker__month-read-view--down-arrow,
		.react-datepicker__month-year-read-view--down-arrow {
			display: none;
		}
		.react-datepicker__month-dropdown,
		.react-datepicker__year-dropdown {
			top: 50px;
		}
		.react-datepicker__month-read-view,
		.react-datepicker__year-read-view {
			visibility: visible !important;
			&::after {
				content: "";
				position: relative;
				top: -2px;
				border: solid #bbb;
				border-width: 0 2px 2px 0;
				display: inline-block;
				padding: 2px;
				margin-left: 6px;
				transition: 180ms;
				transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
			}
			&:hover {
				&::after {
					border: solid #3e495f;
					border-width: 0 2px 2px 0;
				}
			}
		}
		.react-datepicker__month-dropdown-container {
			margin-right: 15px;
		}
	}
	.react-datepicker__navigation {
		transition: 180ms;
		&:focus {
			outline: none;
		}
		&.react-datepicker__navigation--previous:hover {
			border-right-color: $mc-7;
		}
		&.react-datepicker__navigation--next:hover {
			border-left-color: $mc-7;
		}
	}
	.react-datepicker__day--outside-month {
		visibility: hidden;
	}
	.react-datepicker__day--selected {
		outline: none;
		background-color: $color-blue;
		border-radius: $default-radius;
		color: $mc-1;
	}
	.react-datepicker__day--in-range {
		background-color: rgba($color-blue, 0.2);
		color: $mc-9;
		border-radius: $default-radius;
	}
	.react-datepicker__month {
		margin: 3px;
	}
	.react-datepicker__day {
		width: 25px;
		line-height: 25px;
		&:hover {
			border-radius: $default-radius;
		}
		&--in-selecting-range:not(.react-datepicker__day--in-range) {
			// background-color: $color-blue-light;
			background-color: $mc-1;
			color: $mc-9;
			border-radius: $default-radius;
		}
	}
	.react-datepicker__day--selecting-range-start,
	.react-datepicker__day--selecting-range-end {
		outline: 2px solid $color-blue;
		background-color: $mc-3;
		color: $mc-9;
	}
	.react-datepicker__day--range-start,
	.react-datepicker__day--range-end {
		outline: none;
		background-color: $color-blue;
		border-radius: $default-radius;
		color: $mc-1;
	}
}

.filter-section-item {
	margin-bottom: 20px;
	opacity: 0.6;
	transition: 180ms ease-out;

	&.active {
		opacity: 1;
	}

	&:hover {
		opacity: 1;
	}

	&.filter-applied {
		opacity: 1;
	}

	.meta-info {
		font-size: 12px;
		color: $mc-5;
		margin-top: 3px;
		margin-bottom: 8px;
		@extend .font--semibold;
	}

	&.at-switch-cnt {
		margin-bottom: 20px;
		.meta-info {
			margin-bottom: 0;
		}
		.switch-rail {
			margin: 10px 0 0 0 !important;
		}
	}

	span.seperator {
		font-size: 12px;
		color: $mc-5;
		margin: 0 10px;
	}

	input::placeholder {
		font-size: 12px;
		color: $mc-5;
	}
}

.react-datepicker--inline {
	.react-datepicker {
		background-color: white;
		border: 0;
		box-shadow: none;
		.react-datepicker__header__dropdown {
			.react-datepicker__year-read-view--down-arrow {
				display: none;
			}
			.react-datepicker__year-dropdown {
				background-color: white;
				border: 1px solid $border-color-dark;
				border-radius: $default-radius;
				width: 80px;
				transform: translateX(-50%);
				left: 50%;
				height: auto;
				max-height: 150px;
				overflow-y: auto;
				.react-datepicker__navigation--years {
					display: none;
				}
				.react-datepicker__year-option:first-of-type {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
				}
				.react-datepicker__year-option:last-of-type {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}
				.react-datepicker__year-option--selected {
					left: 5px;
				}
			}
		}
	}
}

.required-sign {
	position: relative;
	margin-right: 10px;
	&:after {
		position: absolute;
		content: "*";
		color: $color-red;
		font-size: $very-small-font-size;
		transform: translateX(3px);
	}
}

.required-sign-blue {
	position: relative;
	padding-left: 10px;
	font-size: 12px;
	&:before {
		position: absolute;
		content: "*";
		color: $color-blue;
		font-size: $very-small-font-size;
		transform: translateX(-8px);
	}
}

.loader-backdrop {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.3);
	pointer-events: none;
	z-index: $zI-top;
	.loading-indicator-container {
		position: relative;
		width: 60px;
		height: 60px;
		@keyframes animation-in-out {
			0%,
			100% {
				transform: scale(0);
			}
			50% {
				transform: scale(1);
			}
		}
		.loading-indicator {
			position: absolute;
			height: 60px;
			width: 60px;
			background-color: $color-blue;
			border-radius: 100%;
			opacity: 0.6;
			top: 0px;
			left: 0px;
		}
		.loading-indicator-in {
			animation: 2.1s ease-in-out 1s infinite normal none running animation-in-out;
		}
		.loading-indicator-out {
			animation: 2.1s ease-in-out 0s infinite normal none running animation-in-out;
		}
	}
}

.loader-circular {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
	box-sizing: content-box;
	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes dashed {
		0% {
			stroke-dasharray: 1px, 200px;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 100px, 200px;
			stroke-dashoffset: -15px;
		}
		100% {
			stroke-dasharray: 100px, 200px;
			stroke-dashoffset: -125px;
		}
	}
	svg {
		width: inherit;
		height: inherit;
		animation: rotate 1.4s linear infinite;
		box-sizing: content-box;
		circle {
			stroke-dasharray: 80px, 200px;
			stroke-dashoffset: 0;
			stroke-linecap: round;
			animation: dashed 1.4s ease-in-out infinite;
		}
	}
	&.filled {
		width: 12px;
		height: 12px;
		background-color: $color-blue-40;
		padding: 2px;
		border-radius: 50%;
	}
}

.validation-message {
	position: absolute;
	font-size: 12px;
	margin-top: 5px;
	color: $color-red;
	width: 100%;
	// white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.validation-message-positive {
	color: $color-blue;
}

.actionable-empty-screen {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.empty-img {
		height: 200px;
		margin: 25px;
	}
	.empty-msg {
		margin-top: 25px;
		margin-bottom: 25px;
		color: $mc-7;
	}
	ul > li {
		margin-bottom: 10px;
	}
	.link-text {
		text-transform: initial;
		position: relative;
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			transform: scaleX(0);
			height: 2px;
			bottom: 0;
			left: 0;
			background-color: $color-blue-light;
			transform-origin: bottom right;
			transition: transform 0.25s ease-out;
		}
		&:hover::after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	}
}

.food-type {
	display: inline-block;
	vertical-align: top;
	position: relative;
	height: 12px;
	width: 12px;
	box-sizing: border-box;
	border-radius: 2px;
	&.Vegetarian {
		border: 1px solid $color-green;
		&:after {
			background: $color-green;
		}
	}
	&.Non-vegetarian {
		border: 1px solid $color-red;
		&:after {
			background: $color-red;
		}
	}
	&.Eggetarian {
		border: 1px solid #ffd900;
		&:after {
			background: #ffd900;
		}
	}
	&.NA {
		border: 1px solid $mc-6;
		&:after {
			background: $mc-6;
		}
	}
	&.none {
		border: 1px solid transparent;
		&:after {
			background: transparent;
		}
	}
	&:after {
		position: absolute;
		height: 6px;
		width: 6px;
		content: "";
		top: 50%;
		left: 50%;
		border-radius: 50%;
		transform: translate(-50%, -50%);
	}
}

.tags-container {
	display: flex;
	align-items: center;
	flex-direction: row;
	align-content: center;
	position: relative;
	// top: 2px;
	.title {
		margin-right: 15px;
		font-size: $very-small-font-size;
		color: $mc-5;
		& > span {
			line-height: 27px;
			margin-right: 5px;
		}
		.add-tags-control {
			display: inline-block;
			vertical-align: middle;
		}
	}
	.list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.tag-item {
			text-decoration: none;
			margin-right: 10px;
			margin-bottom: 5px;
			padding: 5px 10px;
			background-color: white;
			color: $mc-9;
			font-size: $very-small-font-size;
			line-height: $very-small-font-size;
			border: 1px solid $mc-8;
			border-radius: $default-radius;
			transition: 180ms;
			cursor: default;
			// limit tags width and number of characters
			display: inline-block;
			max-width: 53px;
			@include truncate_text;
			&.more-tags {
				opacity: 0.4;
				border: 1px dashed $mc-8;
			}
			&:hover {
				background-color: white;
				color: $mc-9;
			}
		}
	}
	&.flex-right {
		flex-direction: row-reverse;
		.list {
			justify-content: flex-end;
			.tag-item {
				margin-right: 0;
				margin-left: 5px;
			}
		}
	}
	&.table-mode {
		.tag-item {
			margin: 5px 5px 2px 0;
			background-color: white;
			color: $mc-7;
			font-size: 12px;
			line-height: 12px;
			padding: 3px 6px;
			border-color: $mc-4;
		}
	}
	.add-tags-control {
		.at--dropdown {
			position: relative;
			top: -2px;
			width: 180px;
			font-size: $very-small-font-size;
			.react-select__control {
				min-height: 25px;
				border: 1px solid transparent;
				&:hover {
					box-shadow: none;
				}
				.react-select__value-container {
					padding: 2px 6px;
				}
				.react-select__dropdown-indicator {
					visibility: hidden;
				}
			}
			.react-select__placeholder {
				@extend .font--semibold;
				font-size: $very-small-font-size;
				color: $color-blue;
			}
			.react-select__input {
				box-sizing: border-box;
				& > input {
					padding: 4px 0;
					max-width: 110px;
				}
			}
		}
	}
	&.disabled .Select-loading-zone {
		visibility: hidden;
	}
}

.gmap-container {
	margin-bottom: 30px;
	width: 100%;
	height: 250px;
	position: relative;
}

.pac-container {
	z-index: 99999;
}

#pac-input {
	height: 20px;
	width: 50%;
	font-size: 12px;
	color: $mc-8;
	position: absolute;
	top: 0;
	left: 0;
}

// custom styling of tags in select dropdown component
.Select--multi .Select-value {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	float: left;
	height: 24px;
	max-width: 100px;
	border-radius: 12px;
}
.Select--multi .Select-value-label {
	max-width: 85px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.Select--multi .Select-value-icon {
	height: 24px;
	font-size: $small-font-size;
	border-right: 0;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
}
.Select-menu-outer {
	// prevent scroll-chaining to parent elements
	overscroll-behavior: contain;
}

// custom styling for new react dropdown component

.multi-select-filter-container {
	.react-select__control {
		border-radius: $border-radius-default;
	}
	// .react-select__option {
	// 	border: 1px solid yellow;
	// }
	.react-select__menu-list {
		border-radius: $border-radius-default;
		.react-select__option {
			border-radius: $border-radius-default;
			&:first-child {
				border-radius: $border-radius-default;
			}
			&:last-child {
				border-radius: $border-radius-default;
			}
		}
	}
}

// custom styling of dropdown component for null, archived and readOnly cases
.multi-select-filter-container {
	.Select {
		.Select-control {
			border-radius: $border-radius-default;
		}
	}
	.at-dropdown.Select .is-open > .Select-control {
		border-color: $color-red;
	}
}
.at-input-container {
	.at-input-field {
		.at-input {
			border-radius: $border-radius-default;
			&--symbol-enabled {
				padding-left: 40px;
			}
		}
	}
}
.at-dropdown {
	&.Select > .Select-control {
		overflow-y: auto;
		.Select-arrow {
			top: 0.5px;
		}
	}
	&.Select.is-open > .Select-control {
		border-color: $color-blue;
		border-radius: $border-radius-default;
		background-color: white;
		.Select-arrow {
			top: -3px;
		}
	}
	&.at-dropdown--null,
	&.at-dropdown--archived {
		.Select-control {
			border: 1px solid $color-red;
			background-color: rgba(192, 57, 43, 0.1);
		}
	}
	&.at-dropdown--null.is-focused:not(.is-open),
	&.at-dropdown--archived.is-focused {
		.Select-control {
			border: 1px solid $color-red !important;
			background-color: rgba(192, 57, 43, 0.1);
		}
	}
	&.at-dropdown--read-only.Select--single {
		pointer-events: none;
		cursor: default;
		.Select-control {
			border: 1px solid $border-color-dark;
			background-color: #eaeaea;
		}
		.Select-value-label {
			color: #777 !important;
		}
	}
	&.at-dropdown--read-only.Select--multi {
		pointer-events: none;
		cursor: default;
		.Select-control {
			background-color: #eaeaea;
		}
	}
}
