.status-box-wrapper {
	padding: 10px 16px;
	margin-bottom: 24px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	border-radius: 2px;

	.details-wrapper {
		display: flex;
		gap: 12px;
	}

	.details {
		p {
			margin: 0;
		}

		.detail-header {
			color: $color-neutral-black-80;
			font-weight: $font--semibold;
			font-size: $medium-font-size;
		}

		.detail-description {
			margin-top: 4px;
			color: $color-neutral-black-60;
			font-size: $very-small-font-size;
		}
		span {
			margin-top: 16px;
			font-size: 14px;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		img {
			height: 12px;
			width: 12px;
			margin-left: 8px;
		}
		.cta-wrapper {
			display: flex;

			align-items: end;
			.navigation-title {
				font-size: 14px;
				font-weight: 600;
				color: $color-blue-40;
			}
			img {
				width: 16px;
				height: 16px;
			}
		}
	}

	.status-box-img {
		height: 24px;
		width: 24px;
	}

	.close-icon {
		cursor: pointer;
		height: 16px;
		width: 16px;
		padding: 4px;
		cursor: pointer;

		&:hover {
			background-color: $mc-2;
		}
	}

	&.alert {
		border-left: 6px solid $color-red-50;
		background-color: $color-red-05;
	}

	&.inprogress {
		border-left: 6px solid $color-blue-40;
		background-color: $color-blue-15;
	}

	&.success,
	&.completed {
		border-left: 6px solid $color-green-50;
		background-color: $color-green-15;
	}

	&.failed {
		border-left: 6px solid $color-red-50;
		background-color: $color-red-05;
	}
}
