.site-header {
	position: fixed;
	width: 100%;
	top: 0px;
	left: 0px;
	box-sizing: border-box;
	background: $site-header-default-bg-color;
	padding-left: $sidebar-width;
	z-index: $zI-level-2;
	height: 58px;
	border-bottom: 1px solid #ececec;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
	transition: padding 300ms ease-in-out;
	&.minimized {
		padding-left: $sidebar-width-minimized;
	}
	.global-loader-container {
		position: fixed;
		top: 57px;
		z-index: $zI-level-2;
		width: 100%;
		display: none;
		padding-left: $sidebar-width;
		box-sizing: border-box;
		left: 0;
		&.minimized {
			padding-left: $sidebar-width-minimized;
		}
		&.active {
			display: block;
		}
	}
	@include bp(768px) {
		padding-left: 0px;
		.global-loader-container {
			padding-left: 0px;
		}
	}
	.header-section-container {
		// max-width: 95%;
		// margin: auto;
		// margin-top: 13.5px;
		padding: 0 8px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include bp(768px) {
			margin-top: 0px;
		}
	}
	.header-mobile {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-right: 25px;
		height: 57px;
		.header-mobile-menu {
			display: flex;
			align-items: center;
			padding-left: 10px;
		}
		.header-mobile-logo {
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-decoration: none;
			.site-logo {
				height: 30px;
				margin-right: 10px;
			}
			.site-title {
				@extend .font--semibold;
				font-size: $small-font-size;
				color: $color-violet;
				letter-spacing: 4px;
				margin-right: 10px;
			}
		}
	}
	.header-section {
		&.header-section--right {
			// float: right;
			display: flex;
			align-items: center;
			margin-right: 25px;
			.header-section-icon {
				position: relative;
				display: inline-block;
				margin-right: 25px;
				padding: 3px;
				border-radius: 50%;
				background-color: rgba($color: #6c717a, $alpha: 0.1);
				cursor: pointer;
				img {
					height: 25px;
					width: 25px;
					opacity: 0.35;
					text-align: center;
					display: block;
				}
				&.selected {
					background-color: rgba(47, 88, 242, 0.1);
					img {
						opacity: 1;
					}
				}
				&.passing {
					&::before {
						content: "";
						position: absolute;
						top: 3px;
						right: 1px;
						width: 7px;
						height: 7px;
						background-color: $color-green;
						border-radius: 50%;
					}
				}
				&.error {
					&::before {
						content: "";
						position: absolute;
						top: 3px;
						right: 1px;
						width: 7px;
						height: 7px;
						background-color: $color-red;
						border-radius: 50%;
					}
				}
				&.warning {
					&::before {
						content: "";
						position: absolute;
						top: 3px;
						right: 1px;
						width: 7px;
						height: 7px;
						background-color: $color-yellow;
						border-radius: 50%;
					}
				}
			}
			.header-section-btn {
				box-sizing: content-box;
				text-decoration: none;
				.start-here {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 25px;
					height: 18px;
					padding: 6px 10px;
					border-radius: $default-radius;
					border: 1px solid $color-java;
					background-color: rgba(24, 208, 178, 0.1);
					font-size: $very-small-font-size;
					color: $color-java;
					cursor: pointer;
					@extend .font--semibold;
					.play {
						width: 0;
						height: 0;
						border-top: 5px solid transparent;
						border-bottom: 5px solid transparent;
						border-left: 8px solid $color-java;
						margin-right: 5px;
					}
				}
			}
			.meraki-changelog {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				position: relative;
				margin-right: 25px;
				width: 30px;
				height: 30px;
				box-sizing: content-box;
				.icon-changelog {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 25px;
					height: 25px;
					position: absolute;
					top: -1px;
					border-radius: 50%;
					padding: 3px;
					img {
						width: 25px;
					}
				}
				#HW_badge {
					position: relative;
					top: -2px;
					left: 16px;
					transform: scale(0.42);
					background: $color-blue;
					font-size: 12px;
				}
				.HW_animated {
					transform: scale(0.62) !important;
					animation: none !important;
					transform: translate(0);
					transform-origin: unset !important;
				}
				.HW_softHidden {
					transform: scale(0.42) !important;
				}
			}
		}
		&.header-section--left {
			// float: left;
			display: flex;
			flex-grow: 2;
			margin: 0 17px;
			.minimize-side-nav {
				img {
					height: 20px;
					width: 20px;
				}
				margin: auto 0;
				cursor: pointer;
				// padding-right:10px;
				padding-top: 5px;
			}
			.quint-btn {
				position: relative;
				top: -1px;
				margin-left: 15px;
			}
			.message-container {
				display: flex;
				align-items: center;
				// text-align: center;
				width: 480px;
				margin-left: 25px;
				border: 1px solid $mc-4;
				border-radius: 100px;
				padding: 5px 20px;
				font-size: 12px;
				box-shadow: $default-shadow;
				.message {
					display: flex;
					color: $mc-9;
					img {
						width: 45px;
						margin-right: 7px;
						position: relative;
						top: -1px;
					}
				}
				.timer {
					display: flex;
					align-items: center;
					justify-content: space-between;
					div {
						text-align: center;
						width: 60px;
						.value {
							@extend .font--semibold;
						}
						.unit {
							color: $mc-5;
							font-size: 12px;
							@extend .font--semibold;
						}
					}
				}
			}
		}
	}
	.siteheader-icon-block {
		cursor: pointer;
		text-align: right;
		font-size: 14px;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		& > div {
			display: inline-block;
			vertical-align: middle;
		}
		.icon-container {
			line-height: 30px;
			margin-right: 5px;
		}
		.icon-title {
			line-height: 30px;
			color: $mc-5;
		}
		.round-circle-text {
			height: 15px;
			width: 15px;
			line-height: 15px;
			font-size: 12px;
			text-align: center;
			border-radius: 50%;
			background-color: $color-blue;
			color: white;
		}
	}
	.notification-icon-block,
	.settings-icon-block {
		margin-right: 40px;
	}

	.siteheader-user-block {
		cursor: pointer;
		text-align: right;
		font-size: 14px;
		position: relative;
		z-index: $zI-top + 2;
		.user-block-container {
			display: flex;
			align-items: center;
			& > div {
				display: inline-block;
				vertical-align: middle;
			}
			@include bp(768px) {
				position: relative;
				top: 14px;
			}
		}
		.user-name-square {
			width: 30px;
			height: 30px;
			color: #fff;
			text-align: center;
			line-height: 30px;
			border-radius: 4px;
			text-transform: uppercase;
		}
		.biz-logo-square {
			display: inline-block;
			vertical-align: middle;
			height: 30px;
			width: 30px;
			border-radius: 4px;
			object-fit: cover;
		}
		.biz-user-name {
			text-align: left;
			margin-left: 10px;
			max-width: 160px;
			.user-name {
				font-size: 12px;
				color: $color-dark-grey;
				margin-top: 1px;
			}
			.biz-name {
				@extend .font--semibold;
				@include truncate_text;
			}
		}
		.siteheader-user-block-dropdown {
			padding-top: 6px;
		}
		.user-menu {
			position: absolute;
			right: -2px;
			top: 35px;
			background-color: white;
			box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
			border: 1px solid $border-color;
			border-radius: $default-radius;
			width: 320px;
			cursor: default;
			@include bp(768px) {
				top: 50px;
			}
			.biz-container {
				width: 100%;
				padding: 15px;
				display: flex;
				align-items: center;
				background-color: $mc-1;
				box-sizing: border-box;
				border-bottom: 1px solid $border-color;
				.biz-logo {
					padding-right: 10px;
					.avatar {
						width: 45px;
						height: 45px;
						border-radius: 4px;
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						box-shadow:
							0 1px 3px rgba(#000, 0.12),
							0 1px 2px rgba(#000, 0.24);
					}
				}
				.biz-info {
					display: flex;
					flex-direction: column;
					text-align: left;
					@include truncate_text;
					.biz-name {
						width: 100%;
						font-size: $small-font-size;
						font-weight: 600;
						@include truncate_text;
					}
					.biz-id-container {
						display: flex;
						align-items: center;
						margin-top: 4px;
						position: relative;
						font-size: $very-small-font-size;
						.biz-id-content {
							color: $color-dark-grey;
							margin-right: 5px;
						}
						.biz-id-copy-logo {
							position: relative;
							border-radius: 2px;
							cursor: pointer;
							padding: 0 4px;
							transition: 300ms;
							svg {
								g {
									g {
										transition: 300ms;
									}
								}
								height: 12px;
								width: 12px;
							}
							&:hover {
								border-color: $color-blue;
								svg {
									g {
										g {
											fill: $color-blue;
										}
									}
								}
							}
						}
					}
					.link-text {
						&.switch-biz {
							margin-top: 15px;
						}
					}
					.view-profile-link {
						color: $color-blue;
						padding-top: 2px;
						display: inline-block;
						cursor: pointer;
						text-transform: initial;
						position: relative;
						&::after {
							content: "";
							position: absolute;
							width: 100%;
							transform: scaleX(0);
							height: 1px;
							bottom: 0;
							left: 0;
							background-color: $color-blue;
							transform-origin: bottom right;
							transition: transform 0.25s ease-out;
						}
						&:hover::after {
							transform: scaleX(1);
							transform-origin: bottom left;
						}
					}
				}
			}
			.user-info-container {
				width: 100%;
				display: flex;
				align-items: center;
				flex-wrap: nowrap;
				background-color: $mc-1;
				box-sizing: border-box;
				padding: 15px;
				border-bottom: 1px solid $border-color;
				cursor: pointer;
				.user-info {
					display: flex;
					flex-direction: column;
					width: 85%;
					& > div {
						margin-bottom: 4px;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
				.arrow {
					width: 15%;
					transform: translateX(-2px);
					transition: all 180ms;
					opacity: 0.6;
					img {
						position: relative;
						top: 2px;
						width: 20px;
						rotate: 180deg;
					}
				}
				.full-name {
					position: relative;
					width: fit-content;
					font-size: $small-font-size;
					font-weight: 600;
					text-align: left;
					@include truncate_text;
					&::after {
						content: "";
						position: absolute;
						width: 100%;
						transform: scaleX(0);
						height: 1px;
						bottom: 0;
						left: 0;
						background-color: $mc-9;
						transform-origin: bottom right;
						transition: transform 0.25s ease-out;
					}
				}
				.email {
					color: $color-dark-grey;
					font-size: $very-small-font-size;
					text-align: left;
					@include truncate_text;
				}
				&:hover {
					.arrow {
						transform: translateX(2px);
						opacity: 1;
					}
					.full-name {
						&::after {
							transform: scaleX(1);
							transform-origin: bottom left;
						}
					}
				}
			}
			.btn--logout {
				padding: 15px;
				display: flex;
				align-items: center;
				box-sizing: border-box;
			}
		}
	}
}

.atlas-top-banner {
	display: flex;
	align-items: center;
	position: fixed;
	left: 0;
	width: 100%;
	height: 57px;
	box-sizing: border-box;
	z-index: $zI-level-2;
	.hrn-carousel {
		overflow: auto;
	}
	.arrow {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 100%;
		flex-shrink: 0;
		cursor: pointer;
		svg {
			width: 12px;
			height: 12px;
		}
	}
	.banner-message {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		background-color: #18d0b2;
		color: #1d443e;
		height: 57px;
		padding: 0 20px;
		box-sizing: border-box;
		width: 100%;
		border-left: 4px solid #1d443e;
		border-radius: 2px 0 0 2px;
		.icon {
			width: 18px;
			height: 18px;
			margin-right: 8px;
			box-sizing: content-box;
			flex-shrink: 0;
			&.user {
				width: 12px;
				height: 12px;
			}
		}
		.close {
			padding: 5px;
			margin-left: auto;
			opacity: 0.9;
			transition: 180ms;
			cursor: pointer;
			box-sizing: content-box;
			flex-shrink: 0;
			&:hover {
				opacity: 1;
			}
		}
		.links {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 20px;
			margin-left: 15px;
			.link {
				position: relative;
				text-decoration: underline;
				cursor: pointer;
				@extend .font--semibold;
				// &::after {
				// 	content: "";
				// 	position: absolute;
				// 	width: 100%;
				// 	transform: scaleX(0);
				// 	height: 1px;
				// 	bottom: 0;
				// 	left: 0;
				// 	background-color: inherit;
				// 	transform-origin: bottom right;
				// 	transition: transform 0.25s ease-out;
				// }
				.arrow-right {
					margin-left: 5px;
					transition: 250ms transform;
				}
				&:hover {
					&::after {
						transform: scaleX(1);
						transform-origin: bottom left;
					}
					.arrow-right {
						transform: translateX(2px);
					}
				}
			}
		}
		.at-modal {
			.modal-container {
				max-width: 500px;
			}
			.modal-header {
				text-align: left !important;
				border-bottom: 1px solid $border-color !important;
				padding: 20px 25px !important;
				.header-left {
					.title {
						font-size: $medium-font-size !important;
						margin-bottom: 0 !important;
					}
					.subtitle {
						font-size: $very-small-font-size !important;
						color: $color-black-60 !important;
						opacity: 1 !important;
						margin-top: 5px;
					}
				}
			}
			.modal-content {
				display: flex;
				flex-direction: column;
				gap: 25px;
				padding: 20px 25px;
				.email-container,
				.phone-container {
					display: flex;
					gap: 10px;
				}
				.meta-info {
					color: $color-black-60;
					margin-bottom: 5px;
				}
				.value {
					color: $mc-9;
					@extend .font--semibold;
				}
			}
			.modal-footer {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				border-top: 1px solid $border-color;
			}
		}
		&.meraki {
			background-color: #30303d;
			color: $mc-1;
			text-transform: uppercase;
			word-spacing: 2px;
		}
		&.default &.info {
			background-color: $color-blue-05;
			color: $color-black-80;
			font-size: $very-small-font-size;
			justify-content: flex-start;
			border-left-color: $color-blue-40;
			// flex-wrap: wrap;
			.link {
				color: $color-black-80;
				&::after {
					background-color: $color-black-80;
				}
			}
		}
		&.dark {
			background-color: #363636;
			color: $mc-1;
			font-size: $very-small-font-size;
			justify-content: flex-start;
			// flex-wrap: wrap;
			.link {
				color: $mc-1;
				&::after {
					background-color: $mc-1;
				}
			}
		}
		&.neutral {
			background-color: #f2f5ff;
			color: $color-black-80;
			font-size: $very-small-font-size;
			justify-content: flex-start;
			// flex-wrap: wrap;
			.link {
				color: $color-blue;
				&::after {
					background-color: $color-blue;
				}
			}
		}
		&.warning {
			background-color: $color-yellow-10;
			color: $color-black-80;
			font-size: $very-small-font-size;
			justify-content: flex-start;
			border-color: $color-yellow-20;
			// flex-wrap: wrap;
			.link {
				color: $color-black-80;
				&::after {
					background-color: $color-black-80;
				}
			}
		}
		&.error {
			background-color: $color-red-05;
			color: $color-black-80;
			font-size: $very-small-font-size;
			justify-content: flex-start;
			border-color: $color-red-50;
			// flex-wrap: wrap;
			.link {
				color: $color-black-80;
				&::after {
					background-color: $color-black-80;
				}
			}
		}
		&.success {
			background-color: $color-green-05;
			color: $color-black-80;
			font-size: $very-small-font-size;
			justify-content: flex-start;
			border-color: $color-green-50;
			// flex-wrap: wrap;
			.link {
				color: $color-black-80;
				&::after {
					background-color: $color-black-80;
				}
			}
		}
		&.rewind {
			background: linear-gradient(90deg, #2d016b 0%, #471464 19%, #5d1bd2 86.3%);
			color: $mc-1;
			font-size: $medium-font-size;
			justify-content: center;
			border: none;
			border-radius: 0;
			.links {
				margin-left: 2px;
				.link {
					color: $mc-1;
					text-decoration: underline;
					@extend .font--regular;
					&::after {
						background-color: $mc-1;
						height: 0;
					}
				}
			}
			.close {
				margin: 0;
				position: absolute;
				top: 16px;
				right: 20px;
			}
		}
		&.horizon {
			background-color: $mc-1;
			padding: 0;
			.\!border-l-4 {
				border-left-width: 4px !important;
			}
		}
	}
	.banner-text {
		display: flex;
		align-items: center;
		gap: 15px;
		.action {
			text-decoration: underline;
			cursor: pointer;
			@extend .font--semibold;
		}
	}
	.\!border-l-4 {
		border-left-width: 4px !important;
	}
}

.side-bar-holder {
	pointer-events: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: $zI-level-2 + 1;
	transition: 180ms;
	.side-bar {
		pointer-events: all;
		box-sizing: border-box;
		width: $sidebar-width;
		position: fixed;
		left: 0px;
		top: 0px;
		background: $sidebar-bg-color;
		border-right: 1px solid #ececec;
		height: 100%;
		color: #fff;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;
		/* identical to box height */
		letter-spacing: 0.4px;
		@extend .font--semibold;
		@include bp(768px) {
			transition: 180ms ease-in-out;
			transform: translateX(-100%);
		}
		// hide scroll bar
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
		// prevent scroll-chaining to parent elements
		overscroll-behavior: contain;
		& > div {
			&:nth-child(2) {
				margin-bottom: 150px;
			}
		}
		// .spacing-for-help-button {
		// 	height: 80px;
		// }
		.freshchat-support-btn-container {
			position: fixed;
			bottom: 0;
			width: 209px;
			background-color: white;
			height: 55px;
			box-shadow: $default-shadow;
			display: flex;
			align-items: center;
			justify-content: center;
			.freshchat-help-button {
				min-width: 150px;
				width: 100%;
				font-weight: 600;
				// svg {
				// 	top: 4px;
				// }
			}
		}
		.freshchat-left-nav-closed-get-help-btn {
			width: 54px;
		}
	}
	&.active {
		.side-bar {
			width: $sidebar-width-minimized;
			.side-bar-nav-comp {
				& > .side-bar-nav-comp-block {
					&:hover {
						background-color: $mc-3;
					}
				}
				&.selected {
					& > .side-bar-nav-comp-block {
						&:hover {
							background-color: rgba(47, 88, 242, 0.1);
						}
					}
					&.has-sub-modules {
						& > .side-bar-nav-comp-block {
							&:hover {
								background-color: rgba(47, 88, 242, 0.1);
							}
						}
					}
				}
			}
		}
	}
}

.atlas-logo-container {
	position: relative;
	display: flex;
	align-items: center;
	height: auto;
	line-height: initial;
	padding: 0;
	.atlas-logo-img {
		display: inline-block;
		vertical-align: middle;
	}
	.img-first {
		margin-right: 10px;
	}
	a {
		display: flex;
		align-items: center;
		position: relative;
		width: fit-content;
		img {
			flex-shrink: 0;
		}
	}
	.santa-hat {
		position: absolute;
		top: -27px;
		left: 70px;
		width: 40px;
		height: 50px;
		object-fit: cover;
		transform: rotate(26deg);
	}
}
.site-nav-onboarding-progress {
	padding: 10px 15px;
	.site-nav-onboarding-progress-row {
		display: flex;
		align-items: center;
		cursor: pointer;
		.site-nav-onboarding-user-icon {
			height: 20px;
			width: 20px;
			margin-bottom: 4px;
		}
		.site-nav-onboarding-text {
			color: $color-blue-40;
			margin-left: 12px;
			font-weight: 600;
		}
		.site-nav-onboarding-percentage-text {
			color: $color-blue-40;
			flex-grow: 1;
			text-align: right;
			font-weight: 600;
		}
	}
}

.side-bar-nav-link {
	color: #fff;
	text-decoration: none;
}
.side-bar-nav-comp {
	cursor: pointer;
	user-select: none;
	position: relative;
	color: $mc-9;
	&.selected {
		opacity: 1;
		& > .side-bar-nav-comp-block {
			border-left: 3px solid #2f58f2;
			padding-left: 12px;
			background-color: rgba(47, 88, 242, 0.1);
			color: #2f58f2;
			.nav-item-icon {
				opacity: 1;
				filter: brightness(100%);
			}
			.title {
				opacity: 1;
			}
		}
	}
	.side-bar-nav-comp-block-children {
		.side-bar-nav-comp-block {
			padding: 7px 0 7px 50px;
			background-color: rgba($color: #f8f8f8, $alpha: 0.6);
			border-left: none;
			&:hover {
				background-color: rgba($color: #f8f8f8, $alpha: 0.6);
			}
			.side-bar-label {
				right: 20px;
				bottom: 9px;
			}
			.title {
				opacity: 0.4;
			}
		}
		.side-bar-nav-comp {
			&.selected {
				.side-bar-nav-comp-block {
					background-color: rgba($color: #f8f8f8, $alpha: 1);
					&:hover {
						background-color: rgba($color: #f8f8f8, $alpha: 1);
					}
					.title {
						opacity: 1;
					}
				}
			}
		}
	}
	.title {
		position: relative;
		.locked-icon {
			position: absolute;
			top: 0px;
			left: 129px;
		}
	}
	&.expanded {
		.side-bar-nav-comp-block-children {
			display: block;
		}
	}
	.side-bar-label {
		font-size: 8px;
		line-height: 9px;
		font-weight: 900;
		border-radius: $border-radius-default;
		padding: 2px 3px;
		border: 1px solid $color-red;
		color: $color-red;
		position: absolute;
		bottom: 13px;
		right: 38px;
		&.beta {
			border: 1px solid $color-red;
			color: $color-red;
			&.green {
				color: $mc-1;
				background: $color-green-50;
				border: 1px solid $color-green-50;
			}
		}
		&.new {
			border: 1px solid $color-green;
			color: $color-green;
		}
		&.info {
			&.green {
				background: $color-green-50;
				color: white;
				border: none;
			}
		}
	}
	.side-bar-nav-dropdown {
		position: absolute;
		right: 10px;
		filter: brightness(0%);
	}
	.side-bar-nav-comp-block {
		position: relative;
		transition: 180ms;
		padding: 10px 15px;
		background-repeat: no-repeat;
		background-position: center left 18px;
		background-size: 16px;
		.nav-item-icon {
			display: inline-block;
			vertical-align: top;
			height: 20px;
			width: 20px;
			margin-right: 15px;
			opacity: 0.6;
			filter: brightness(0%);
		}
		.title {
			opacity: 0.6;
		}
		.side-bar-nav-comp-block-info {
			position: absolute;
			width: max-content;
			top: 8px;
			left: 62px;
			padding: 4px 12px;
			background: $mc-1;
			color: $color-neutral-black-80;
			border: 1px solid $border-color;
			border-radius: $border-radius-default;
			font-size: $very-small-font-size;
			box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
		}
	}
	.side-bar-sub-nav {
		display: flex;
		align-items: center;
		img {
			margin-left: 5px;
			width: 15px;
		}
	}
}
.sidebar-modal {
	.at-modal {
		.content-show {
			.modal-header {
				color: black;
				opacity: 1;
			}
			.modal-content {
				color: $mc-9;
				text-align: center;
				// font-weight: 600;
				font-size: 16px;
				.meraki-ad-image {
					// border: 1px solid red;
					width: 380px;
					height: 260px;
				}
			}
		}
	}
}

.global-loader-container {
	position: fixed;
	top: 63px;
	z-index: 315;
	width: 100%;
	display: none;
	box-sizing: border-box;
	left: 0;
	&.with-banner {
		top: 120px;
	}
	&.active {
		display: block;
	}
}
.infinite-load-bar {
	position: relative;
	width: 100%;
	height: 2px;
	background-color: #fdba2c;
	&:after {
		content: "";
		position: absolute;
		transform-origin: left;
		background-color: $color-blue;
		animation: infiniteloading 2s ease-in-out infinite;
		height: 100%;
		width: 100%;
	}
}

@keyframes infiniteloading {
	0% {
		transform: scaleX(0);
	}
	50% {
		transform: scaleX(1);
	}
	100% {
		transform: scaleX(0);
	}
}

.sidebar-bottom {
	position: absolute;
	bottom: 20px;
	width: 100%;
	.item {
		color: $mc-4;
		cursor: pointer;
		transition: 180ms;
		&:hover {
			color: $mc-1;
		}
	}
}
