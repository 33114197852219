.user-management {
	.credits-section-header {
		padding: 0 25px 10px 25px;
		.header-text {
			display: flex;
			align-items: center;
			flex-grow: 1;
			margin-top: 25px;
			.subtitle {
				color: $mc-9;
			}
			img {
				margin-right: 10px;
				width: 18px;
			}
		}
	}
	.action-buttons-container {
		margin-right: 10px;
	}
	.search-filter {
		margin-right: 10px;
		.at-input-container {
			width: 100%;
		}
	}
	.users-list-table-container {
		.transaction-rows {
			min-height: initial;
		}
		.name {
			width: 30%;
			padding-left: 25px;
			display: flex;
			.profile-img {
				width: 40px;
				height: 40px;
				border-radius: 20px;
			}
			.name-initials {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;
				background: rgba(47, 88, 242, 0.1);
				border-radius: 20px;
				span {
					color: $color-blue;
					font-size: $medium-font-size;
					@extend .font--semibold;
				}
			}
			.name-desc {
				display: inline-block;
				margin-left: 10px;
				.desc-text {
					margin-top: 5px;
				}
			}
		}
		.roles {
			width: 40%;
			.tag-item {
				text-overflow: clip;
				max-width: fit-content;
				margin: 0px 5px 5px 0;
			}
		}
		.email {
			width: 30%;
			padding-right: 25px;
		}
	}
	.roles-list-table-container {
		.transaction-rows {
			min-height: initial;
		}
		.name {
			width: 70%;
			padding-left: 25px;
		}
		.users {
			width: 30%;
			padding-right: 25px;
		}
	}
}
.user-invite-container {
	.header-container {
		display: flex;
		align-items: center;
		img {
			object-fit: contain;
			height: 52px;
			margin-right: 10px;
		}
		.subtitle {
			margin-top: 5px;
			@extend .font--regular;
		}
	}
	.success-message {
		padding: 20px;
		margin-bottom: 15px;
		color: $color-green;
		font-size: $very-small-font-size;
		border: 1px solid $color-green;
		border-radius: $default-radius;
		background: rgba(46, 204, 113, 0.1);
	}
}
.users-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}
}
.roles-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}
	.header-container {
		display: flex;
		justify-content: space-between;
		border: 1px solid $border-color;
		border-radius: $default-radius;
		padding: 15px;
		margin-bottom: 25px;
		.header-text {
			font-size: $very-small-font-size;
			@extend .font--semibold;
		}
		.header-subtext {
			color: $mc-5;
			font-size: $very-small-font-size;
			padding-top: 5px;
		}
	}
	.role-permissions-tab {
		.permissions-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: initial;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.permission {
				width: 50%;
				padding-left: 25px;
			}
			.description {
				width: 50%;
				padding-right: 25px;
			}
		}
	}
	.role-users-tab {
		.search-container {
			display: flex;
			margin-bottom: 35px;
			.at-input-container {
				width: 30%;
				margin-right: 35px;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.associated-users-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: initial;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.name {
				width: 35%;
				padding-left: 25px;
			}
			.username {
				width: 30%;
			}
			.email {
				width: 35%;
				padding-right: 25px;
			}
			.phone {
				width: 30%;
			}
		}
	}
}

.unified-user-management-section {
	.form-sidebar-container {
		.contact-support {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-top: 50px;
			.sub-header-text {
				color: $color-dark-grey;
				font-size: $medium-large-font-size;
				margin-bottom: 10px;
				@extend .font--semibold;
			}
			.description {
				color: $color-dark-grey;
				margin-bottom: 30px;
			}
		}
	}
}

.unified-user-management {
	.information {
		display: flex;
		align-items: center;
		gap: 7px;
		padding: 15px;
		background-color: #ecf0f7;
		margin: 20px 25px 10px 25px;
		border-radius: $default-radius;
		font-size: $very-small-font-size;
		color: $color-dark-grey;
		.know-more {
			position: relative;
			color: $color-light-black;
			cursor: pointer;
			@extend .font--semibold;
			&::after {
				content: "";
				position: absolute;
				width: 100%;
				transform: scaleX(0);
				height: 1px;
				bottom: 0;
				left: 0;
				background-color: $color-light-black;
				transform-origin: bottom right;
				transition: transform 0.25s ease-out;
			}
			&:hover::after {
				transform: scaleX(1);
				transform-origin: bottom left;
			}
		}
	}
	.filters {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px 25px 25px 25px;
		box-sizing: border-box;
		.filter-buttons {
			display: flex;
			align-items: center;
			.multi-select-filter-container {
				width: 220px;
				margin-right: 10px;
				font-size: $very-small-font-size;
			}
		}
		.header-text {
			display: flex;
			align-items: center;
			flex-grow: 1;
			margin-top: 25px;
			.subtitle {
				color: $mc-9;
			}
			img {
				margin-right: 10px;
				width: 18px;
			}
		}
		.filter-in-header {
			position: relative;
			.filter-count {
				background: $color-blue;
				color: #fff;
				width: 20px;
				height: 20px;
				line-height: 20px;
				font-size: 12px;
				border-radius: 100%;
				display: inline-block;
				position: absolute;
				right: -10px;
				top: -10px;
			}
			&.active {
				.container {
					border: 1px solid $color-blue;
					.filter-title {
						color: $color-blue;
					}
					.filter-icon {
						filter: invert(32%) sepia(89%) saturate(4568%) hue-rotate(224deg) brightness(96%) contrast(97%);
					}
				}
			}
		}
		.search-input-container {
			display: flex;
			align-items: center;
			.search-filter {
				position: relative;
				width: 200px;
				margin: 0;
				.at-input-container {
					width: 100%;
					.at-input-field {
						.at-label {
							border-radius: 0;
							border-left: 0;
							img {
								padding-left: 0;
							}
						}
						.at-input {
							border-left: 0;
							border-radius: 0 4px 4px 0;
						}
					}
				}
				.cancel-search {
					right: 12px;
				}
			}
			.multi-select-filter-container {
				margin: 0;
				width: 100px;
				font-size: $very-small-font-size;
				.react-select__control {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
	}
	.bulk-actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 25px;
		margin: 0 0 15px 0;
		.count {
			display: flex;
			align-items: center;
			font-size: $very-small-font-size;
			color: $mc-5;
			span {
				margin-left: 10px;
				font-size: $very-small-font-size;
				color: $color-blue-light;
				transition: 180ms;
				cursor: pointer;
				&:hover {
					color: $color-blue;
				}
			}
		}
		.actions-container {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			.action {
				font-size: $very-small-font-size;
				color: $color-blue;
				margin-left: 20px;
				cursor: pointer;
				@extend .font--semibold;
				&.archive {
					color: $color-red;
				}
				&.restore {
					color: $color-green;
				}
			}
			.multi-select-filter-container {
				width: 110px;
				margin-left: 20px;
				.react-select__control {
					border: none;
					min-height: auto;
				}
				.react-select__value-container {
					overflow: initial;
					padding: 0;
					width: fit-content;
				}
				.react-select__single-value {
					width: fit-content;
					overflow: initial;
					white-space: initial;
					text-overflow: initial;
					font-size: $very-small-font-size;
					color: $color-blue;
					@extend .font--semibold;
				}
				.react-select__dropdown-indicator {
					color: $color-blue;
					padding: 2px 0 2px 3px;
				}
			}
		}
	}
	.users-list-table-container {
		.transaction-rows {
			min-height: initial;
		}
		.table-header {
			z-index: 70;
			top: 95px;
			.table-cell {
				display: flex;
				align-items: center;
			}
		}
		.name {
			width: 30%;
			padding-left: 25px;
			display: flex;
			.profile-img {
				width: 40px;
				height: 40px;
				border-radius: 20px;
			}
			.name-initials {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;
				background: rgba(47, 88, 242, 0.1);
				border-radius: 20px;
				margin-right: 10px;
				span {
					color: $color-blue;
					font-size: $medium-font-size;
					@extend .font--semibold;
				}
			}
			.name-desc {
				display: flex;
				flex-direction: column;
				overflow: hidden;
				a {
					width: fit-content;
					margin-bottom: 5px;
				}
			}
		}
		.last-active {
			width: 20%;
		}
		.atlas {
			width: 25%;
			.app {
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			.app-name {
				font-size: 12px;
				text-transform: uppercase;
				color: $mc-6;
				@extend .font--semibold;
			}
		}
		.prime {
			width: 25%;
			padding-right: 25px;
			.app {
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			.app-name {
				font-size: 12px;
				text-transform: uppercase;
				color: $mc-6;
				@extend .font--semibold;
			}
		}
	}
	.roles-list-table-container {
		.transaction-rows {
			min-height: initial;
		}
		.name {
			width: 70%;
			padding-left: 25px;
		}
		.users {
			width: 30%;
			padding-right: 25px;
		}
	}
	.at-modal {
		.modal-header {
			padding: 15px 25px;
			border-bottom: 1px solid $border-color;
			.header-left {
				.title {
					text-align: left;
					margin: 0;
					font-size: $medium-font-size;
					color: $color-light-black;
				}
			}
		}
		.modal-container {
			width: 40%;
			max-width: 570px;
			min-height: 5vh;
		}
		.modal-content {
			padding: 15px 25px;
			min-height: 60px;
			.loader-backdrop {
				pointer-events: none;
				background-color: rgba($color: #000000, $alpha: 0.15);
				.loading-indicator-container {
					margin-top: 0px;
				}
			}
			.description {
				margin-bottom: 10px;
				color: $color-light-black;
				&:last-child {
					margin-bottom: 0;
				}
			}
			.success {
				padding: 15px 0;
				text-align: center;
				color: $color-green;
				background-color: rgba($color: $color-green, $alpha: 0.1);
				border: 1px solid $color-green;
				border-radius: $default-radius;
			}
		}
		.modal-footer {
			border-top: 1px solid $border-color;
			text-align: right;
			padding: 15px 25px;
		}
	}
}
.unified-user-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}
	.header {
		.header-right {
			.edit-roles {
				margin-right: 10px;
			}
		}
	}
	.form-content {
		.form-row {
			// override styles for input field and select filter component inside form
			.at-input-container {
				.at-input {
					height: 36px;
				}
				.validation-message {
					margin-top: -1px;
				}
				.at-label {
					width: 36px;
					height: 36px;
					padding: 0;
					justify-content: center;
					font-size: $very-small-font-size;
					img {
						opacity: 0.6;
						position: relative;
						top: 1px;
						width: 16px;
					}
				}
				.multi-select-filter-container {
					width: 68px;
					.menu-wrapper {
						width: 200px !important;
					}
				}
				.at-input--dropdown {
					padding: 0px 10px 0px 77px;
				}
			}
			.select-filter-custom {
				.meta-info {
					margin-bottom: 0;
				}
				.validation-message {
					position: relative;
				}
				.custom-option {
					width: 100%;
					.at-checkbox {
						box-sizing: border-box;
					}
				}
				.custom-label {
					display: flex;
					align-items: baseline;
					.overflow-text {
						font-size: $small-font-size;
					}
				}
			}
		}
	}
	.sub-heading {
		color: $color-dark-grey;
		margin: 10px 0 20px 0;
		@extend .font--semibold;
	}
	.user-info-and-roles {
		margin-top: 10px;
	}
	.card-container {
		border: 1px solid $border-color;
		border-radius: $default-radius;
		margin-bottom: 20px;
		&.read-only {
			.at-switch-cnt {
				opacity: 0.6;
			}
		}
		.card-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 5px 15px;
			height: 50px;
			.product {
				display: flex;
				align-items: center;
				.name {
					font-size: $medium-font-size;
					text-transform: capitalize;
					@extend .font--semibold;
				}
				.logo {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 10px;
					img {
						width: 18px;
						height: 18px;
						object-fit: cover;
					}
				}
			}
			.invite-status {
				display: flex;
				align-items: center;
				gap: 25px;
			}
			.status {
				color: $color-dark-grey;
				background: #f5f5f5;
				padding: 4px 8px;
				font-size: $very-small-font-size;
				border-radius: 2px;
				@extend .font--semibold;
				&.pending {
					color: $color-yellow-dark;
					background-color: rgba($color: $color-yellow-dark, $alpha: 0.1);
				}
				&.cancelled {
					color: $color-red;
					background-color: rgba($color: $color-red, $alpha: 0.1);
				}
			}
			.archived {
				padding: 4px 8px;
				font-size: $very-small-font-size;
				color: $color-red;
				background-color: rgba($color: $color-red, $alpha: 0.1);
				border-radius: 2px;
				@extend .font--semibold;
			}
			.at-switch-cnt {
				display: flex;
				align-items: center;
				.meta-info {
					position: relative;
					top: -1px;
					margin-right: 10px;
					font-size: $very-small-font-size;
				}
				.at-switch {
					&.active {
						.switch-rail {
							background-color: $color-green;
						}
					}
				}
			}
		}
		.description {
			padding: 15px;
			color: $color-dark-grey;
			font-size: $very-small-font-size;
			border-top: 1px solid $border-color;
		}
		.card-content {
			border-top: 1px solid $border-color;
			padding: 15px;
			.meta-info {
				font-size: 12px;
				font-weight: 400;
				color: $mc-5;
				padding-bottom: 5px;
			}
			.business {
				.info {
					display: flex;
					align-items: center;
					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 10px;
						img {
							width: 28px;
							height: 28px;
							border-radius: $default-radius;
							object-fit: cover;
						}
					}
					.name {
						font-size: $very-small-font-size;
					}
					.initials {
						padding: 5px 6px;
						font-size: $small-font-size;
						border-radius: $default-radius;
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						@extend .font--semibold;
					}
				}
			}
			.locations,
			.roles,
			.full-name,
			.email,
			.phone {
				.info {
					font-size: $very-small-font-size;
				}
				.view-all {
					margin-top: 10px;
					color: $color-blue;
					font-size: 12px;
					cursor: pointer;
					@extend .font--semibold;
				}
				.popover-wrapper-container {
					.popover-container {
						top: 1px;
						left: -8px;
						&::before {
							right: 17px;
							z-index: -10;
						}
						&::after {
							display: none;
							right: 13px;
						}
						.locations-list {
							padding: 10px;
							box-sizing: border-box;
							overflow-y: auto;
							background-color: $mc-1;
							max-height: 200px;
							border-radius: $default-radius;
							.name {
								margin-bottom: 3px;
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
			.biz-info {
				display: flex;
				align-items: center;
				margin-bottom: 15px;
				.name {
					font-size: $medium-font-size;
					@extend .font--semibold;
				}
				.logo {
					display: flex;
					align-items: center;
					margin-right: 10px;
					img {
						width: 36px;
						height: 36px;
						border-radius: $default-radius;
						object-fit: cover;
					}
				}
				.initials {
					padding: 8px 9px;
					font-size: $medium-font-size;
					border-radius: $default-radius;
					color: $color-green-dark;
					background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					@extend .font--semibold;
				}
			}
			.form-row {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.card-footer {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 15px;
			font-size: 12px;
			border-top: 1px solid $border-color;
			img {
				width: 14px;
				margin-right: 5px;
			}
			.text {
				color: $color-dark-grey;
				margin-right: 5px;
			}
		}
	}
}
.unified-user-invite-container {
	.header-container {
		display: flex;
		align-items: center;
		img {
			object-fit: contain;
			height: 52px;
			margin-right: 10px;
		}
		.subtitle {
			margin-top: 5px;
			@extend .font--regular;
		}
	}
	.sub-heading {
		color: $color-dark-grey;
		margin-top: 50px;
		margin-bottom: 20px;
		@extend .font--semibold;
	}
	.form-content {
		.form-row {
			// override styles for input field and select filter component inside form
			.at-input-container {
				.at-input {
					height: 36px;
				}
				.at-label {
					width: 36px;
					padding: 0;
					justify-content: center;
					font-size: $very-small-font-size;
				}
				.multi-select-filter-container {
					width: 68px;
					.menu-wrapper {
						width: 200px !important;
					}
				}
				.at-input--dropdown {
					padding: 0px 10px 0px 77px;
				}
			}
			.select-filter-custom {
				.validation-message {
					position: relative;
				}
				.custom-option {
					width: 100%;
					.at-checkbox {
						box-sizing: border-box;
					}
				}
				.custom-label {
					display: flex;
					align-items: baseline;
					.overflow-text {
						font-size: $small-font-size;
					}
				}
			}
		}
	}
	.card-container {
		border: 1px solid $border-color;
		border-radius: $default-radius;
		margin-bottom: 20px;
		.card-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 5px 15px;
			border-bottom: 1px solid $border-color;
			height: 50px;
			.product {
				display: flex;
				align-items: center;
				.name {
					font-size: $medium-font-size;
					text-transform: capitalize;
					@extend .font--semibold;
				}
				.logo {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 10px;
					img {
						width: 18px;
						height: 18px;
						object-fit: cover;
					}
				}
			}
			.at-switch-cnt {
				display: flex;
				align-items: center;
				.meta-info {
					position: relative;
					top: -1px;
					margin-right: 10px;
					font-size: $very-small-font-size;
				}
				.at-switch {
					&.active {
						.switch-rail {
							background-color: $color-green;
						}
					}
				}
			}
		}
		.description {
			padding: 15px;
			color: $color-dark-grey;
			font-size: $very-small-font-size;
		}
		.card-content {
			border-top: 1px solid $border-color;
			padding: 15px;
			.biz-info {
				display: flex;
				align-items: center;
				margin-bottom: 15px;
				.name {
					font-size: $medium-font-size;
					@extend .font--semibold;
				}
				.logo {
					display: flex;
					align-items: center;
					margin-right: 10px;
					img {
						width: 36px;
						height: 36px;
						border-radius: $default-radius;
						object-fit: cover;
					}
				}
				.initials {
					padding: 8px 9px;
					font-size: $medium-font-size;
					border-radius: $default-radius;
					color: $color-green-dark;
					background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					@extend .font--semibold;
				}
			}
			.form-row {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.card-footer {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 15px;
			font-size: 12px;
			border-top: 1px solid $border-color;
			img {
				width: 14px;
				margin-right: 5px;
			}
			.text {
				color: $color-dark-grey;
				margin-right: 5px;
			}
		}
	}
	.user-invite-success {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 90%;
		.illustration {
			img {
				width: 300px;
				object-fit: cover;
			}
		}
		.message {
			color: $color-dark-grey;
			font-size: $medium-font-size;
			margin-bottom: 20px;
			@extend .font--semibold;
		}
		.description {
			color: $color-dark-grey;
			font-size: $very-small-font-size;
			text-align: center;
			margin-bottom: 20px;
			line-height: 1.5;
			span {
				color: $mc-9;
				opacity: 0.8;
				@extend .font--semibold;
			}
		}
		.link-text {
			font-size: $small-font-size;
			margin-top: 20px;
		}
	}
	.success-message {
		padding: 20px;
		margin-bottom: 15px;
		color: $color-green;
		font-size: $very-small-font-size;
		border: 1px solid $color-green;
		border-radius: $default-radius;
		background: rgba(46, 204, 113, 0.1);
	}
}
