.form-table {
	width: 100%;
	&--header {
		display: flex;
		background-color: $color-lightest-grey;
		position: sticky;
		top: 0;
		z-index: 1;
		.header-field {
			text-transform: uppercase;
			font-weight: 600;
			font-size: $extra-small-font-size;
			color: $color-dark-grey;
			padding: 16px;
			display: flex;
			align-items: center;
		}
		.at-checkbox {
			display: block;
			height: 16px;
			width: 15px;
			padding-left: 10px;
		}
	}
	&--list {
		color: $color-light-black;
		display: flex;
		justify-content: flex-start;
		min-height: 70px;
		font-size: $very-small-font-size;
		border-bottom: 1px solid $border-color;
		transition: 300ms;
		&:hover {
			background-color: rgba(47, 88, 242, 0.05);
		}
	}
	&--list-item {
		padding: 16px;
		display: flex;
		.at-checkbox {
			display: block;
			height: 16px;
			width: 15px;
		}
		@include truncate_text;
	}
}
