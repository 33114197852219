@keyframes collapseTasks {
	from {
		width: 400px;
		border-radius: 8px;
	}
	to {
		width: 54px;
		border-radius: 50%;
	}
}
@keyframes expandTasks {
	from {
		width: 54px;
		border-radius: 50%;
	}
	to {
		width: 400px;
		border-radius: 8px;
	}
}
@keyframes showing {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes hiding {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.ongoing-tasks-container {
	position: fixed;
	bottom: 35px;
	right: 35px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
	z-index: 1002;
	.header {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px;
		background-color: $color-blue-40;
		box-sizing: border-box;
		.title {
			display: flex;
			align-items: center;
			gap: 10px;
			color: $mc-1;
			cursor: pointer;
		}
		.hide {
			position: relative;
			width: 22px;
			height: 22px;
			cursor: pointer;
			svg {
				padding: 5px;
				box-sizing: content-box;
			}
		}
		.report {
			position: absolute;
			top: 8px;
			right: 8px;
			width: 22px;
			height: 22px;
			padding: 8px;
			cursor: pointer;
			box-sizing: content-box;
		}
		.count {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			left: 40px;
			top: -5px;
			min-width: 10px;
			width: auto;
			padding: 0 5px;
			height: 20px;
			background-color: $color-red-50;
			border-radius: 50px;
			color: $mc-1;
			font-size: $very-small-font-size;
			box-sizing: content-box;
		}
	}
	.tasks {
		background-color: $mc-1;
		border-radius: 0 0 8px 8px;
		overflow: hidden;
		height: 0;
		width: 100%;
		.task {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 12px 16px;
			box-sizing: border-box;
			.status {
				width: 16px;
				height: 16px;
				svg {
					width: 16px;
					height: 16px;
					vertical-align: initial;
				}
			}
			.name {
				color: $color-neutral-black-80;
				font-size: $very-small-font-size;
			}
		}
		.no-tasks {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 12px 16px;
			color: #d2d2d2;
			font-size: $very-small-font-size;
		}
		.banner {
			font-size: 13.9px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			font-weight: 600;
			padding: 10px;
			margin: 5px 16px 16px 16px;
			background: linear-gradient(323.59deg, #dee4ff 3.85%, #fff5fd 87.25%);
			.banner-head {
				line-height: 22px;
				display: flex;
				.svg {
					margin-right: 8px;
				}
			}
			.btn-free-trial {
				cursor: pointer;
				padding: 8px 12px 8px 12px;
				gap: 8px;
				border-radius: 2px;
				border: 1px solid #eaeaea;
				width: fit-content;
				display: flex;
				background: linear-gradient(0deg, #ffffff, #ffffff),
					linear-gradient(
						185.1deg,
						rgba(0, 0, 0, 0) -111.17%,
						rgba(56, 84, 229, 0.2) -93.61%,
						rgba(56, 84, 229, 0) 41.13%
					);
				.img {
					filter: brightness(0.5);
				}
			}
		}
		.m-top {
			margin: 16px;
		}
	}
	&.expanding {
		width: 54px;
		border-radius: 50%;
		animation: expandTasks 300ms forwards;
		.header {
			height: 54px;
			border-radius: 8px 8px 0 0;
			transition: all 100ms;
			.title,
			.hide {
				animation: showing 100ms forwards;
				animation-delay: 100ms;
				opacity: 0;
			}
			.report,
			.count {
				display: none;
			}
		}
		.tasks {
			height: fit-content;
			.task {
				animation: showing 500ms forwards;
				opacity: 0;
			}
		}
	}
	&.collapsing {
		width: 400px;
		border-radius: 8px;
		animation: collapseTasks 300ms forwards;
		animation-delay: 150ms;
		.header {
			height: 54px;
			border-radius: 50%;
			transition: all 150ms;
			transition-delay: 300ms;
			.title,
			.hide {
				animation: hiding 200ms forwards;
				animation-delay: 150ms;
				opacity: 1;
			}
			.report,
			.count {
				animation: showing 300ms forwards;
				animation-delay: 300ms;
				opacity: 0;
			}
		}
		.tasks {
			.task {
				animation: hiding 300ms forwards;
				opacity: 1;
			}
		}
	}
	&.expanded {
		width: 400px;
		border-radius: 8px;
		.header {
			height: 54px;
			border-radius: 8px 8px 0 0;
			.title,
			.hide {
				display: flex;
				opacity: 1;
			}
			.report,
			.count {
				display: none;
				opacity: 0;
			}
		}
		.tasks {
			.task {
				opacity: 1;
			}
		}
	}
	&.collapsed {
		width: 54px;
		border-radius: 50%;
		.header {
			height: 54px;
			border-radius: 50%;
			.title,
			.hide {
				display: none;
				opacity: 0;
			}
			.report,
			.count {
				display: flex;
				opacity: 1;
			}
		}
		.tasks {
			.task {
				opacity: 0;
			}
		}
	}
}
